import { ButtonRow, Control, Heading, Hook, InfoBlock, Step } from '../../../models';
import {
  BLOCKED,
  MANHOLE,
  NONE,
  ODOUR,
  SLOW_DRAINAGE,
  CELLAR,
  GARAGE,
  GROUND_FLOOR,
  OTHER,
  UPSTAIRS,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  KITCHEN,
  HOUSE,
  BATHROOM,
  APPLIANCE,
  TOILET,
  SINK,
  FLOOR,
  STEP_ACCESS_HOURS_LOOKUP,
} from '../../../../constants';
import { wasteInternalOther } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteInternalOther.heading);
const infoBlock = new InfoBlock('info-block1', wasteInternalOther.detail);
const wasteInternalOtherControl = new Control('checkbox', 'wasteInternalOther');
wasteInternalOtherControl.setLabel(wasteInternalOther.heading);
wasteInternalOtherControl.hideLabel = true;
wasteInternalOtherControl.setDefault('');
wasteInternalOtherControl.addOption(wasteInternalOther.slow, SLOW_DRAINAGE, null);
wasteInternalOtherControl.addOption(wasteInternalOther.blocked, BLOCKED, null);
wasteInternalOtherControl.addOption(wasteInternalOther.mahole, MANHOLE, null);
wasteInternalOtherControl.addOption(wasteInternalOther.odour, ODOUR, null);
wasteInternalOtherControl.addOption(wasteInternalOther.no, NONE, null, true);

const wasteInternalOtherStep = new Step('step', 'waste-internal-other');
wasteInternalOtherStep.addBlock(headingBlock);
wasteInternalOtherStep.addBlock(infoBlock);
wasteInternalOtherStep.addBlock(wasteInternalOtherControl);
wasteInternalOtherStep.addBlock(new ButtonRow());
wasteInternalOtherStep.setIsContained(false);
wasteInternalOtherStep.exitHook = new Hook(STEP_ACCESS_HOURS_LOOKUP, '');
wasteInternalOtherStep.setTitle(wasteInternalOther.title);

wasteInternalOtherStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [FLOOR, CELLAR],
      exclusion: [TOILET, APPLIANCE, SINK],
    },
    wasteInternalArea: {
      inclusion: [GROUND_FLOOR, GARAGE, OTHER, UPSTAIRS, CELLAR],
      exclusion: [],
    },
  },
]);
wasteInternalOtherStep.setValidation({
  wasteInternalOther: {
    presence: {
      message: wasteInternalOther.validation,
      allowEmpty: false,
    },
  },
});

export { wasteInternalOtherStep };
