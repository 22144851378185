import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  CELLAR,
  GARAGE,
  GROUND_FLOOR,
  OTHER,
  UPSTAIRS,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  KITCHEN,
  HOUSE,
  ODOUR,
  BATHROOM,
  APPLIANCE,
  TOILET,
  SINK,
  FLOOR,
} from '../../../../constants';
import { wasteInternalArea } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteInternalArea.heading);
const wasteInternalAreaControl = new Control('question', 'wasteInternalArea');
wasteInternalAreaControl.setLabel(wasteInternalArea.heading);
wasteInternalAreaControl.setDefault('');
wasteInternalAreaControl.addOption(wasteInternalArea.groundFloor, GROUND_FLOOR, 'IconGroundFloor');
wasteInternalAreaControl.addOption(wasteInternalArea.garage, GARAGE, 'IconHouse2');
wasteInternalAreaControl.addOption(wasteInternalArea.cellar, CELLAR, 'IconCellar');
wasteInternalAreaControl.addOption(wasteInternalArea.upstairs, UPSTAIRS, 'IconUpStairs');
wasteInternalAreaControl.addOption(wasteInternalArea.other, OTHER, 'IconCross');
wasteInternalAreaControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteInternalAreaStep = new Step('step', 'waste-internal-area');
wasteInternalAreaStep.addBlock(headingBlock);
wasteInternalAreaStep.addBlock(wasteInternalAreaControl);
wasteInternalAreaStep.addBlock(buttonsBlock);
wasteInternalAreaStep.setIsContained(false);
wasteInternalAreaStep.setTitle(wasteInternalArea.title);

wasteInternalAreaStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [FLOOR, CELLAR],
      exclusion: [TOILET, APPLIANCE, SINK],
    },
  },
]);

export { wasteInternalAreaStep };
