import React from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { Heading } from 'yw-ui.v2';

const HeadingNoDivBlock = ({ id, title, priority, size, answers, className }) => {
  const parsedTitle = validate.format(title, answers);

  return <Heading size={size} priority={priority} id={id} qa={id} title={parsedTitle} className={className} />;
};

HeadingNoDivBlock.propTypes = {
  answers: PropTypes.object.isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  priority: PropTypes.number,
  size: PropTypes.string,
};

export default HeadingNoDivBlock;
