import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FluidForm, Layouts } from 'yw-ui.v2';
import Recaptcha from 'react-google-invisible-recaptcha';

import { RECAPTCHA_KEY, RECAPTCHA_LOCALE, RECAPTCHA_ACTIVE } from '../constants';
import { loadStep, stepForward, stepBack, stepTo, setAnswer, CalculateBlockAddress } from '../actions/StepsAction';
import { attachmentRemove, uploadAttachments } from '../actions/AttachmentAction';
import { SetMapHasDragged } from '../actions/LocationActions';
import { SetIsYorkshire } from '../actions/LocationActions';
import { Preloader, Step } from '../components';

import BlockRenderer from './blocks/BlockRenderer';

export class Steps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasRecaptchaExecuted: false,
    };
  }
  componentDidMount() {
    const { loadStep, initialSteps, route, tracker } = this.props;

    if (initialSteps) {
      if (tracker) {
        tracker.trackEvent({ name: 'loadStep' }, { slug: route.match.params.slug || '' });
      }
      loadStep(route.match.params.slug);
    }
  }

  componentWillUnmount() {
    const {
      route: { history },
    } = this.props;
    if (history && history.action === 'POP' && history.location.pathname !== '/') {
      this.handleStepBack();
    }
  }

  componentDidUpdate(prevProps) {
    const { attachments, route, initialSteps, lookup, lookupError, lookupsLoading, tracker, locations } = this.props;
    if (
      prevProps.lookup !== lookup ||
      prevProps.lookupError !== lookupError ||
      prevProps.lookupsLoading !== lookupsLoading ||
      prevProps.locations !== locations ||
      (!prevProps.initialSteps && initialSteps !== null) ||
      JSON.stringify(prevProps.attachments) !== JSON.stringify(attachments)
    ) {
      if (tracker) {
        tracker.trackEvent({ name: 'reloadStep' }, { slug: route.match.params.slug || '' });
      }
      this.props.loadStep(route.match.params.slug);
    }
  }

  shouldComponentUpdate(prevProps) {
    const {
      route,
      answers,
      lookup,
      initialSteps,
      isLoading,
      lookupsLoading,
      summary,
      isEditing,
      validStep,
      locations,
    } = this.props;
    if (
      prevProps.route.match.params.slug !== route.match.params.slug ||
      prevProps.isLoading !== isLoading ||
      prevProps.lookupsLoading !== lookupsLoading ||
      prevProps.answers !== answers ||
      prevProps.initialSteps !== initialSteps ||
      prevProps.summary !== summary ||
      prevProps.isEditing !== isEditing ||
      prevProps.locations !== locations ||
      prevProps.lookup !== lookup ||
      JSON.stringify(prevProps.validStep) !== JSON.stringify(validStep)
    ) {
      return true;
    }

    return true;
  }

  handleStepForward = (values, isRecaptcha) => {
    const {
      stepForward,
      setAnswer,
      CalculateBlockAddress,
      tracker,
      validStep: { hasRecaptcha, slug },
    } = this.props;

    if (hasRecaptcha && !isRecaptcha && RECAPTCHA_ACTIVE === 'true') {
      setAnswer(values);

      if (this.recaptcha) {
        this.setState({ hasRecaptchaExecuted: true });
        this.recaptcha.execute();
      }
    } else {
      if (tracker) {
        tracker.trackEvent({ name: 'stepForward' }, { slug });
      }

      if (this.recaptcha) {
        this.recaptcha.reset();
      }

      stepForward(slug, { ...values });
      CalculateBlockAddress();
    }
  };

  handleStepTo = (route) => {
    const {
      stepTo,
      tracker,
      validStep: { slug },
    } = this.props;

    if (tracker) {
      tracker.trackEvent({ name: 'stepTo' }, { slug, route });
    }
    stepTo(route);
  };

  handleStepBack = () => {
    const {
      stepBack,
      tracker,
      validStep: { slug },
    } = this.props;

    if (tracker) {
      tracker.trackEvent({ name: 'stepBack' }, { slug });
    }

    stepBack();
  };

  forceExclusiveAnswer = (exclusiveAnswer, answers) => {
    if (answers[exclusiveAnswer.fieldName]) {
      if (answers[exclusiveAnswer.fieldName].includes(`,${exclusiveAnswer.answer}`)) {
        let newAnswer = {};
        newAnswer[exclusiveAnswer.fieldName] = exclusiveAnswer.answer;
        this.props.setAnswer(newAnswer);
      } else {
        let newAnswer = {};
        newAnswer[exclusiveAnswer.fieldName] = answers[exclusiveAnswer.fieldName].replace(
          `${exclusiveAnswer.answer},`,
          '',
        );

        this.props.setAnswer(newAnswer);
      }
    }
  };

  handleChange = (answers) => {
    const {
      validStep: { exclusiveAnswers },
    } = this.props;

    if (exclusiveAnswers) {
      exclusiveAnswers.forEach((exclusive) => this.forceExclusiveAnswer(exclusive, answers));
    }
  };

  render() {
    const {
      route,
      answers,
      isLoading,
      summary,
      validStep,
      lookups,
      lookupsLoading,
      attachments,
      locations,
    } = this.props;
    const currentSlug = route.match.params.slug || null;

    const { blocks, hasRecaptcha, hiddenAnswers, initialAnswers, isContained, slug, validation } = validStep || {};
    const formValues = {
      ...initialAnswers,
      ...answers,
      ...hiddenAnswers,
    };

    return (
      <Fragment>
        <Layouts.CenteredColumn>
          {isLoading && <Preloader qa="step-preloader" />}
          {validStep && (
            <div className="u-anim--fade-in">
              {!isLoading && (
                <FluidForm
                  initialValues={formValues}
                  constraints={validation}
                  onSubmit={(values) => this.handleStepForward(values, false, null)}
                  onChange={this.handleChange}
                >
                  {(form) => (
                    <Step stepId={slug} isContained={isContained}>
                      {RECAPTCHA_ACTIVE === 'true' &&
                        (hasRecaptcha || this.state.hasRecaptchaExecuted) &&
                        currentSlug === slug && (
                          <Recaptcha
                            ref={(ref) => {
                              this.recaptcha = ref;
                              return this.recaptcha;
                            }}
                            sitekey={RECAPTCHA_KEY}
                            onResolved={(response) => this.handleStepForward({ recaptchaCode: response }, true)}
                            locale={RECAPTCHA_LOCALE}
                          />
                        )}

                      {blocks &&
                        blocks.map((block, i) => (
                          <BlockRenderer
                            key={`${block.type}-${i}`}
                            block={block}
                            answers={answers}
                            lookups={lookups}
                            summary={summary}
                            form={form}
                            lookupsLoading={lookupsLoading}
                            validStep={validStep}
                            stepTo={this.handleStepTo}
                            stepForward={this.handleStepForward}
                            onBack={this.handleStepBack}
                            uploadAttachments={this.props.uploadAttachments}
                            attachmentRemove={this.props.attachmentRemove}
                            SetMapHasDragged={this.props.SetMapHasDragged}
                            SetIsYorkshire={this.props.SetIsYorkshire}
                            attachments={attachments}
                            locations={locations}
                          />
                        ))}
                    </Step>
                  )}
                </FluidForm>
              )}
            </div>
          )}
        </Layouts.CenteredColumn>
      </Fragment>
    );
  }
}

Steps.propTypes = {
  loadStep: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  stepBack: PropTypes.func.isRequired,
  stepForward: PropTypes.func.isRequired,
  setAnswer: PropTypes.func.isRequired,
  stepTo: PropTypes.func.isRequired,
  CalculateBlockAddress: PropTypes.func.isRequired,
  uploadAttachments: PropTypes.func.isRequired,
  attachmentRemove: PropTypes.func.isRequired,
  SetMapHasDragged: PropTypes.func.isRequired,
  SetIsYorkshire: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  attachments: PropTypes.object.isRequired,
  locations: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  lookups: PropTypes.object.isRequired,
  lookup: PropTypes.string,
  lookupError: PropTypes.string,
  lookupsLoading: PropTypes.bool,
  validStep: PropTypes.object,
  initialSteps: PropTypes.array,
  summary: PropTypes.string,
  isEditing: PropTypes.bool,
  tracker: PropTypes.object,
};

const mapStateToProps = (state) => ({
  initialSteps: state.steps.initialSteps,
  validStep: state.steps.validStep,
  answers: state.steps.answers,
  isLoading: state.steps.isLoading,
  lookups: state.hooks.lookups,
  lookup: state.hooks.lookup,
  lookupsLoading: state.hooks.isLoading,
  lookupError: state.hooks.error,
  summary: state.outcome.status.summary,
  isEditing: state.steps.isEditing,
  attachments: state.attachments,
  locations: state.locations,
});
const mapDispatchToProps = (dispatch) => ({
  loadStep: (slug) => dispatch(loadStep(slug)),
  setAnswer: (answer) => dispatch(setAnswer(answer)),
  stepBack: (step) => dispatch(stepBack(step)),
  stepForward: (step, values) => dispatch(stepForward(step, values)),
  stepTo: (step, values, editMode) => dispatch(stepTo(step, values, editMode)),
  uploadAttachments: (files, successHandler, errorHandler) =>
    dispatch(uploadAttachments(files, successHandler, errorHandler)),
  attachmentRemove: (attachmentId) => dispatch(attachmentRemove(attachmentId)),
  SetMapHasDragged: (bool) => dispatch(SetMapHasDragged(bool)),
  SetIsYorkshire: (bool) => dispatch(SetIsYorkshire(bool)),
  CalculateBlockAddress: (step) => dispatch(CalculateBlockAddress(step)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Steps));
