import {
  LOCATION_METHOD_CURRENT_LOCATION,
  LOCATION_METHOD_MAP_SEARCH,
  PUBLIC,
  MAIN_WEBSITE_URL,
} from '../../../constants';
import { backToMainWebsite, nonHousehold } from '../../../constants/Copy';
import { Heading, Step, ButtonRow, Link, InfoBlock, Message } from '../../models';

const headingBlock = new Heading('step-heading', nonHousehold.heading);
const hintBlock = new Message('step-hint', nonHousehold.hint).setState('info');
const headingBlock2 = new Heading('step-heading2', nonHousehold.subHeading, 2).setSize('md');
const infoBlock = new InfoBlock('info-block', nonHousehold.detail[0]);
const infoBlock2 = new InfoBlock('info-block2', nonHousehold.detail[1]);
const infoBlock3 = new InfoBlock('info-block3', nonHousehold.detail[2]);
const linkBlock = new Link('link-block', backToMainWebsite, 'p-t')
  .setLink(MAIN_WEBSITE_URL)
  .setTarget('_self')
  .setFormat(true);
const buttonsBlock = new ButtonRow().hideContinueButton();

const nonHouseholdStep = new Step('step', 'non-household');
nonHouseholdStep.setIsContained(true);
nonHouseholdStep.addBlock(headingBlock);
nonHouseholdStep.addBlock({
  type: 'textBlock',
  text: nonHousehold.location,
  name: 'locationDetails',
});
nonHouseholdStep.addBlock(hintBlock);
nonHouseholdStep.addBlock(headingBlock2);
nonHouseholdStep.addBlock(infoBlock);
nonHouseholdStep.addBlock(infoBlock2);
nonHouseholdStep.addBlock(infoBlock3);
nonHouseholdStep.addBlock(linkBlock);
nonHouseholdStep.addBlock(buttonsBlock);
nonHouseholdStep.setTitle(nonHousehold.title);

nonHouseholdStep.setConstraints({
  initialLocation: {
    exclusion: [PUBLIC],
  },
  locateMethod: {
    exclusion: [LOCATION_METHOD_MAP_SEARCH, LOCATION_METHOD_CURRENT_LOCATION],
  },
  addressConfirm: {
    presence: true,
    exclusion: ['N'],
  },
  isCommercial: {
    inclusion: [true],
    exclusion: [false, undefined],
  },
});

export { nonHouseholdStep };
