function Message(id, message) {
  this.type = 'message';
  this.id = id;
  this.message = message;
  this.state = 'error';
  this.constraints = null;
  this.nextSteps = [];
}

Message.prototype.setState = function(state) {
  this.state = state;
  return this;
};

Message.prototype.setConstraints = function(constraints) {
  this.constraints = constraints;
  return this;
};

Message.prototype.setNextStep = function(text, stepForwardText, stepForwardParams) {
  const newStep = { text, stepForwardText, stepForwardParams };
  this.nextSteps = this.nextSteps ? [...this.nextSteps, newStep] : [newStep];
  return this;
};

Message.prototype.setNextSteps = function(nextSteps) {
  this.nextSteps = nextSteps;
  return this;
};

export default Message;
