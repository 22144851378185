import { Control, Heading, Step, Message, ButtonRow } from '../../../models';
import { NO, YES } from '../../../../constants';
import { wasteIncidentConfirm } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteIncidentConfirm.heading);
const instructionMessageBlock = new Message('step-hint', wasteIncidentConfirm.hint).setState('info');
const addressTotalControl = new Control('hidden', 'incidentTotalWaste');
const nearbyControl = new Control('question', 'wasteIncidentResults');
nearbyControl.setLabel('');
nearbyControl.addOption(wasteIncidentConfirm.yes, YES, 'IconCheckTick');
nearbyControl.addOption(wasteIncidentConfirm.no, NO, 'IconCross');
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteIncidentConfirmStep = new Step('step', 'waste-incident-results');
wasteIncidentConfirmStep.addBlock(headingBlock);
wasteIncidentConfirmStep.addBlock(instructionMessageBlock);
wasteIncidentConfirmStep.addBlock({
  type: 'incidentList',
  name: 'incidentList',
});
wasteIncidentConfirmStep.addBlock(addressTotalControl);
wasteIncidentConfirmStep.addBlock(nearbyControl);
wasteIncidentConfirmStep.addBlock(buttonsBlock);
wasteIncidentConfirmStep.setIsContained(true);
wasteIncidentConfirmStep.setTitle(wasteIncidentConfirm.title);

wasteIncidentConfirmStep.setConstraints({
  isWasteInitial: {
    inclusion: [true],
  },
  isCleanInitial: {
    exclusion: [true],
  },
  incidentTotalWaste: {
    presence: { AllowEmpty: true },
    numericality: {
      greaterThan: 0,
    },
  },
});

export { wasteIncidentConfirmStep };
