import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ChoiceTile, Col, Row } from 'yw-ui.v2';
import { Preloader } from '../../../components';

const DayListBlock = ({ name, lookups, stepForward }) => {
  const handleClick = (value) => {
    let values = {};
    values[name] = value;
    stepForward(values);
  };

  const filterEmptySlots = (slot) => {
    return slot.timeSlots.length > 0;
  };

  return (
    <Fragment>
      {!lookups.callbacks && <Preloader qa="day-preloader" height={200} />}
      {lookups.callbacks && (
        <Row>
          {lookups.callbacks.filter(filterEmptySlots).map(({ description }, i) => (
            <Col key={i} sm={6} md={3}>
              <ChoiceTile
                label={description}
                name={name}
                id={description}
                qa={'day-choice'}
                onClick={() => handleClick(description)}
              />
            </Col>
          ))}
        </Row>
      )}
    </Fragment>
  );
};

DayListBlock.propTypes = {
  name: PropTypes.string.isRequired,
  stepForward: PropTypes.func.isRequired,
  lookups: PropTypes.object.isRequired,
};

export default DayListBlock;
