import { Heading, Step, ButtonRow, InfoBlock, Banner, Control } from '../../../models';
import {
  APPLIANCE,
  SHOWER_TOILET_SINK,
  OTHER,
  UNDER_KITCHEN_SINK,
  INSIDE,
  INSIDE_OUTSIDE,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  HOUSE,
  ODOUR,
  DRAIN,
  BLOCKED,
  BOTH,
  NONE,
  FARM,
  CHEMICAL,
  GAS,
  UPSTAIRS,
  MULTIPLE_ROOMS,
  ONE_ROOM,
  WHOLE_PROPERTY,
  GROUND_FLOOR,
  CELLAR,
  BATHROOM,
  KITCHEN,
  NO,
  YES,
} from '../../../../constants';
import {
  privateIssueBanner,
  privateIssueQuestion,
  reviewForCoC,
  wasteInternalOdourInfo,
} from '../../../../constants/Copy';

const banner = new Banner(privateIssueBanner);
const headingBlock = new Heading('step-heading', wasteInternalOdourInfo.heading);
const infoBlock = new InfoBlock('info-block', wasteInternalOdourInfo.detail[0], 'p-tb');
const headingBlock2 = new Heading('step-heading2', wasteInternalOdourInfo.subHeading, 2).setSize('sm');
const infoBlock2 = new InfoBlock('info-block2', wasteInternalOdourInfo.detail[1]);
const infoBlock3 = new InfoBlock('info-block3', wasteInternalOdourInfo.detail[2]);
const infoBlock4 = new InfoBlock('info-block4', wasteInternalOdourInfo.detail[4]);
const infoBlock6 = new InfoBlock('info-block6', wasteInternalOdourInfo.detail[5]);
const buttonsBlock = new ButtonRow();

const wasteInternalOdourInfoColStep = new Step('step', 'waste-internal-odour-info-col');
wasteInternalOdourInfoColStep.setIsContained(true);
wasteInternalOdourInfoColStep.addBlock(new Control('hidden', 'wasteInternalOdourInfoCol').setDefault(true));
wasteInternalOdourInfoColStep.addBlock(
  new Control('hidden', 'wasteInternalOdourInfoColTxt').setLabel(privateIssueQuestion).setDefault(reviewForCoC),
);
wasteInternalOdourInfoColStep.addBlock(banner);
wasteInternalOdourInfoColStep.addBlock(headingBlock);
wasteInternalOdourInfoColStep.addBlock(infoBlock);
wasteInternalOdourInfoColStep.addBlock(headingBlock2);
wasteInternalOdourInfoColStep.addBlock(infoBlock2);
wasteInternalOdourInfoColStep.addBlock(infoBlock3);
wasteInternalOdourInfoColStep.addBlock(infoBlock4);
wasteInternalOdourInfoColStep.addBlock(infoBlock6);
wasteInternalOdourInfoColStep.addBlock(buttonsBlock);
wasteInternalOdourInfoColStep.setTitle(wasteInternalOdourInfo.title);

wasteInternalOdourInfoColStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [ODOUR],
      exclusion: [HOUSE, BATHROOM, KITCHEN],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [NONE],
      exclusion: [DRAIN, BLOCKED, BOTH],
    },
    wasteOdourOutside: {
      inclusion: [INSIDE],
      exclusion: [INSIDE_OUTSIDE],
    },
    wasteOdourSource: {
      inclusion: [APPLIANCE, SHOWER_TOILET_SINK, UNDER_KITCHEN_SINK],
      exclusion: [OTHER],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [ODOUR],
      exclusion: [HOUSE, BATHROOM, KITCHEN],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [NONE],
      exclusion: [DRAIN, BLOCKED, BOTH],
    },
    wasteOdourOutside: {
      inclusion: [INSIDE],
      exclusion: [INSIDE_OUTSIDE],
    },
    wasteOdourSource: {
      inclusion: [OTHER],
      exclusion: [APPLIANCE, SHOWER_TOILET_SINK, UNDER_KITCHEN_SINK],
    },
    wasteOdourLocation: {
      inclusion: [UPSTAIRS, MULTIPLE_ROOMS, ONE_ROOM],
      exclusion: [WHOLE_PROPERTY, GROUND_FLOOR, CELLAR],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
]);

export { wasteInternalOdourInfoColStep };
