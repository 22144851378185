import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import validate from 'validate.js';

const LinkBlock = ({
  answers,
  id,
  isCondensed,
  isFormatted,
  link,
  text,
  route,
  target,
  stepTo,
  state,
  editMode,
  editSlug,
}) => {
  const dynamicClasses = classNames({
    'u-mb--200': isCondensed,
    'u-mt--200': isCondensed,
    'u-mt--400': !isCondensed,
    'u-mb--400': !isCondensed,
    'p-t': isFormatted,
  });

  const dynamicButtonClasses = classNames({
    'c-button': isCondensed,
    'c-button--link': state === 'link',
    'c-button--primary': state === 'primary',
    'c-button--default': state === 'default',
  });

  const handleClick = () => {
    stepTo(route);
  };

  return (
    <Fragment>
      {text && (link || route || editSlug) && (
        <div className={dynamicClasses} id={id} data-qa={id}>
          {link && (
            <a
              href={validate.format(link, answers)}
              className={dynamicButtonClasses}
              rel="noopener noreferrer"
              target={target}
            >
              {validate.format(text, answers)}
            </a>
          )}

          {editSlug && (
            <button
              type="button"
              className={dynamicButtonClasses}
              onClick={() => stepTo({ target: editSlug, editMode: editMode })}
            >
              {validate.format(text, answers)}
            </button>
          )}

          {route && (
            <button type="button" onClick={handleClick} className={dynamicButtonClasses}>
              {validate.format(text, answers)}
            </button>
          )}
        </div>
      )}
    </Fragment>
  );
};

LinkBlock.propTypes = {
  answers: PropTypes.object.isRequired,
  stepTo: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isCondensed: PropTypes.bool,
  isFormatted: PropTypes.bool,
  link: PropTypes.string,
  route: PropTypes.object,
  state: PropTypes.string,
  target: PropTypes.string,
  text: PropTypes.string.isRequired,
  editMode: PropTypes.bool,
  editSlug: PropTypes.string,
};

LinkBlock.defaultProps = {
  isCondensed: true,
  target: '_blank',
  state: 'link',
};

export default LinkBlock;
