import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  APPLIANCE,
  SHOWER_TOILET_SINK,
  OTHER,
  UNDER_KITCHEN_SINK,
  INSIDE,
  INSIDE_OUTSIDE,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  HOUSE,
  ODOUR,
  DRAIN,
  BLOCKED,
  BOTH,
  NONE,
  FARM,
  CHEMICAL,
  GAS,
  BATHROOM,
  KITCHEN,
} from '../../../../constants';
import { wasteOdourSource } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteOdourSource.heading);
const wasteOdourSourceControl = new Control('question', 'wasteOdourSource');
wasteOdourSourceControl.setLabel(wasteOdourSource.heading);
wasteOdourSourceControl.setDefault('');
wasteOdourSourceControl.addOption(wasteOdourSource.appliance, APPLIANCE, 'IconWashingMachine2');
wasteOdourSourceControl.addOption(wasteOdourSource.shower, SHOWER_TOILET_SINK, 'IconShower3');
wasteOdourSourceControl.addOption(wasteOdourSource.kitchen, UNDER_KITCHEN_SINK, 'IconSink');
wasteOdourSourceControl.addOption(wasteOdourSource.other, OTHER, 'IconCross');
wasteOdourSourceControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteOdourSourceStep = new Step('step', 'waste-odour-source');
wasteOdourSourceStep.addBlock(headingBlock);
wasteOdourSourceStep.addBlock(wasteOdourSourceControl);
wasteOdourSourceStep.addBlock(buttonsBlock);
wasteOdourSourceStep.setIsContained(false);
wasteOdourSourceStep.setTitle(wasteOdourSource.title);

wasteOdourSourceStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [ODOUR],
      exclusion: [HOUSE, BATHROOM, KITCHEN],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [NONE],
      exclusion: [DRAIN, BLOCKED, BOTH],
    },
    wasteOdourOutside: {
      inclusion: [INSIDE],
      exclusion: [INSIDE_OUTSIDE],
    },
  },
]);

export { wasteOdourSourceStep };
