import Service from './service';
import { API_ENDPOINT_TIME } from '../constants';

const isAccessHours = async (successHandler, errorHandler) => {
  const url = `${API_ENDPOINT_TIME}/isAccessHours`;
  return Service.get(url)
    .then((resp) => {
      successHandler(resp.data);
    })
    .catch((error) => {
      errorHandler(error);
    });
};

const isNoWaterHours = async (successHandler, errorHandler) => {
  const url = `${API_ENDPOINT_TIME}/isNoWaterHours?justGetTime=true`;
  return Service.get(url)
    .then((resp) => {
      successHandler(resp.data);
    })
    .catch((error) => {
      errorHandler(error);
    });
};

const isNoWaterEnabled = async (successHandler, errorHandler) => {
  const url = `${API_ENDPOINT_TIME}/isNoWaterHours?justGetTime=false`;
  return Service.get(url)
    .then((resp) => {
      successHandler(resp.data);
    })
    .catch((error) => {
      errorHandler(error);
    });
};

const isWorkingHours = async (successHandler, errorHandler) => {
  const url = `${API_ENDPOINT_TIME}/isWorkingHours`;
  return Service.get(url)
    .then((resp) => {
      successHandler(resp.data);
    })
    .catch((error) => {
      errorHandler(error);
    });
};

const timeApi = {
  isAccessHours,
  isNoWaterHours,
  isWorkingHours,
  isNoWaterEnabled,
};

export default timeApi;
