import { ButtonRow, Control, Heading, Hook, Step, Message } from '../../../models';
import {
  YES,
  NO,
  UNSURE,
  WASTE,
  CLEAN,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  GULLY,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  SQUARE,
  BLOCKED,
  GUSHING,
  COVER,
  GROUND,
  ODOUR,
  POLLUTION,
  STEP_MAP_LOCATION_LOOKUP,
  LOCATION_METHOD_MAP_SEARCH,
  LOCATION_METHOD_CURRENT_LOCATION,
  LOCATION_METHOD_ADDRESS_LOOKUP,
  WATER_SUPPLY,
  SLOW_DRAINAGE,
  STEP_INCIDENTS_LOOKUP,
  FARM,
  GAS,
  CHEMICAL,
  DRAIN,
  BOTH,
  NONE,
  INTERMITTENT,
  CONSTANT,
} from '../../../../constants';
import { mapConfirm } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', mapConfirm.heading);
const mapConfirmed = new Control('hidden', 'mapConfirmed').setDefault('TRUE');
mapConfirmed.clear = true;
const addressTotalControl = new Control('hidden', 'locationDetails');
const instructionMessageBlock = new Message('step-hint', mapConfirm.sendTechnician).setState('info');
const mapResultBlock = {
  type: 'staticMapResult',
  name: 'staticMapResult',
  clear: true,
  constraints: [
    {
      'hooks.error': {
        exclusion: ['map'],
      },
    },
  ],
};
const locationDetailsBlock2 = {
  type: 'textarea',
  label: mapConfirm.anyOtherInformation,
  name: 'nb',
  maxLength: '60',
  showMaxLength: true,
};
const buttonsBlock = new ButtonRow().setContinueButtonText(mapConfirm.continue);
const mapConfirmStep = new Step('step', 'mapConfirm');
mapConfirmStep.setIsContained(true);
mapConfirmStep.entryHook = new Hook(STEP_MAP_LOCATION_LOOKUP, '').setPageLoaderHidden(true);
mapConfirmStep.exitHook = new Hook(STEP_INCIDENTS_LOOKUP, '');
mapConfirmStep.addBlock(headingBlock);
mapConfirmStep.addBlock(mapConfirmed);
mapConfirmStep.addBlock(instructionMessageBlock);
mapConfirmStep.addBlock(mapResultBlock);
mapConfirmStep.addBlock({ type: 'textBlock', text: mapConfirm.location, name: 'locationDetails' });
mapConfirmStep.addBlock(addressTotalControl);
mapConfirmStep.addBlock(locationDetailsBlock2);
mapConfirmStep.addBlock(buttonsBlock);
mapConfirmStep.setTitle(mapConfirm.title);

mapConfirmStep.setConstraints([
  {
    initialLocation: {
      presence: true,
      exclusion: [INTERNAL, EXTERNAL],
    },
    initialProblem: {
      exclusion: [ODOUR, WATER_SUPPLY, SLOW_DRAINAGE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    waterFromCover: {
      exclusion: [NO],
    },
  },
  {
    initialLocation: {
      presence: true,
      exclusion: [INTERNAL, EXTERNAL],
    },
    initialProblem: {
      exclusion: [ODOUR, WATER_SUPPLY, SLOW_DRAINAGE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    waterFromCover: {
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [POLLUTION],
      exclusion: [ODOUR, GROUND, COVER],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [POLLUTION],
      exclusion: [ODOUR, GROUND, COVER],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [CLEAN, WASTE],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [CLEAN, WASTE],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      inclusion: [NO],
      exclusion: [YES],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      inclusion: [NO],
      exclusion: [YES],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [YES],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [YES],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [SQUARE],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, OTHER, GULLY],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [SQUARE],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, OTHER, GULLY],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [GULLY],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, OTHER, SQUARE],
    },
    wasteExternalCoverBlocked: {
      inclusion: [GUSHING],
      exclusion: [BLOCKED],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [GULLY],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, OTHER, SQUARE],
    },
    wasteExternalCoverBlocked: {
      inclusion: [GUSHING],
      exclusion: [BLOCKED],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [SLUICE_VALVE, STOPTAP, METER],
      exclusion: [OTHER, SQUARE, GULLY],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [SLUICE_VALVE, STOPTAP, METER],
      exclusion: [OTHER, SQUARE, GULLY],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SQUARE, GULLY, SLUICE_VALVE, STOPTAP, METER],
    },
    boundaryWaterType: {
      inclusion: [CLEAN, WASTE],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SQUARE, GULLY, SLUICE_VALVE, STOPTAP, METER],
    },
    boundaryWaterType: {
      inclusion: [CLEAN, WASTE],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      inclusion: [YES],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      inclusion: [YES],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      inclusion: [YES, NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      inclusion: [YES, NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [FARM],
      exclusion: [GAS, CHEMICAL, DRAIN],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [FARM],
      exclusion: [GAS, CHEMICAL, DRAIN],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [DRAIN, BLOCKED, BOTH],
      exclusion: [NONE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [DRAIN, BLOCKED, BOTH],
      exclusion: [NONE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [NONE],
      exclusion: [DRAIN, BLOCKED, BOTH],
    },
    wasteOdourFrequency: {
      inclusion: [CONSTANT, INTERMITTENT],
      exclusion: [],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [NONE],
      exclusion: [DRAIN, BLOCKED, BOTH],
    },
    wasteOdourFrequency: {
      inclusion: [CONSTANT, INTERMITTENT],
      exclusion: [],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [GAS],
      exclusion: [CHEMICAL, FARM, DRAIN],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [GAS],
      exclusion: [CHEMICAL, FARM, DRAIN],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [GULLY],
      exclusion: [SQUARE, SLUICE_VALVE, STOPTAP, METER, OTHER],
    },
    wasteExternalCoverBlocked: {
      inclusion: [BLOCKED],
      exclusion: [GUSHING],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [GULLY],
      exclusion: [SQUARE, SLUICE_VALVE, STOPTAP, METER, OTHER],
    },
    wasteExternalCoverBlocked: {
      inclusion: [BLOCKED],
      exclusion: [GUSHING],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
]);

export { mapConfirmStep };
