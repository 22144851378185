import { ButtonRow, Control, Heading, Hook, Step } from '../../models';
import {
  YES,
  NO,
  DAMP,
  POOLING,
  STEP_WORKING_HOURS_LOOKUP,
  GARDEN,
  OUTBUILDING,
  PROPERTY,
  GULLY,
  WATERCOURSE,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  COVER,
  DRAIN,
  GROUND,
  ODOUR,
  TOWARDS_WATERCOURSE,
} from '../../../constants';
import { wasteBoundaryEscapeProperty } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteBoundaryEscapeProperty.heading);
const wasteBoundaryEscapePropertyControl = new Control('question', 'wasteBoundaryEscapeProperty');
wasteBoundaryEscapePropertyControl.setLabel(wasteBoundaryEscapeProperty.heading);
wasteBoundaryEscapePropertyControl.addOption(wasteBoundaryEscapeProperty.flowing, POOLING, 'IconConstantPressure');
wasteBoundaryEscapePropertyControl.addOption(wasteBoundaryEscapeProperty.damp, DAMP, 'IconDripping');
wasteBoundaryEscapePropertyControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteBoundaryEscapePropertyStep = new Step('step', 'waste-boundary-escape-property');
wasteBoundaryEscapePropertyStep.addBlock(headingBlock);
wasteBoundaryEscapePropertyStep.addBlock(wasteBoundaryEscapePropertyControl);
wasteBoundaryEscapePropertyStep.addBlock(buttonsBlock);
wasteBoundaryEscapePropertyStep.exitHook = new Hook(STEP_WORKING_HOURS_LOOKUP, '');
wasteBoundaryEscapePropertyStep.setTitle(wasteBoundaryEscapeProperty.title);

wasteBoundaryEscapePropertyStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteBoundaryEscapeLocation: {
      inclusion: [PROPERTY],
      exclusion: [WATERCOURSE, TOWARDS_WATERCOURSE, GULLY, GARDEN, OUTBUILDING],
    },
  },
]);

export { wasteBoundaryEscapePropertyStep };
