import { ButtonRow, Control, Heading, Step } from '../../models';
import {
  YES,
  NO,
  GARDEN,
  OUTBUILDING,
  PROPERTY,
  GULLY,
  WATERCOURSE,
  BOTH,
  NONE,
  ODOUR,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  COVER,
  DRAIN,
  GROUND,
  TOWARDS_WATERCOURSE,
  BLOCKED,
} from '../../../constants';
import { wasteBoundaryIssues } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteBoundaryIssues.heading);
const wasteBoundaryIssuesControl = new Control('question', 'wasteBoundaryIssues');
wasteBoundaryIssuesControl.setLabel(wasteBoundaryIssues.heading);
wasteBoundaryIssuesControl.setDefault('');
wasteBoundaryIssuesControl.addOption(wasteBoundaryIssues.slowToilet, BLOCKED, 'IconInternalBlockage');
wasteBoundaryIssuesControl.addOption(wasteBoundaryIssues.odour, ODOUR, 'IconBadSmell');
wasteBoundaryIssuesControl.addOption(wasteBoundaryIssues.both, BOTH, 'IconHouse2');
wasteBoundaryIssuesControl.addOption(wasteBoundaryIssues.none, NONE, 'IconCross');
wasteBoundaryIssuesControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteBoundaryIssuesStep = new Step('step', 'waste-boundary-issues');
wasteBoundaryIssuesStep.addBlock(headingBlock);
wasteBoundaryIssuesStep.addBlock(wasteBoundaryIssuesControl);
wasteBoundaryIssuesStep.addBlock(buttonsBlock);
wasteBoundaryIssuesStep.setIsContained(false);
wasteBoundaryIssuesStep.setTitle(wasteBoundaryIssues.title);

wasteBoundaryIssuesStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      inclusion: [NO],
      exclusion: [YES],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteBoundaryEscapeLocation: {
      inclusion: [GARDEN, OUTBUILDING],
      exclusion: [WATERCOURSE, TOWARDS_WATERCOURSE, GULLY, PROPERTY],
    },
  },
]);

export { wasteBoundaryIssuesStep };
