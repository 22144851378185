import { ActionTypes } from '../constants';

import outcomeApi from '../api/outcome';

/*
evaluateOutcomeSummary

Take the current values submitted and match against outcomes to produce a high level summary 
of the steps that can be used in the submission.

values - total set of values from all steps
successHandler - a function to call once a successful response received from the lookup
errorHandler  - a function to call once an error has been received from the lookup

*/
export function evaluateOutcomeSummary(valueBag, successHandler, errorHandler) {
  return (dispatch, getState) => {
    dispatch(outcomeIsLoading(true));

    const {
      steps: { initialSteps },
    } = getState();

    return outcomeApi.fetchOutcomeSummary(
      valueBag,
      initialSteps,
      (response) => {
        dispatch(outcomeIsLoading(false));
        dispatch(outcomeSummarySuccess(response));

        if (successHandler) {
          successHandler(response);
        }
      },
      (error) => {
        dispatch(outcomeHasErrored(true, error));
        dispatch(outcomeIsLoading(false));

        if (errorHandler) {
          errorHandler(error);
        }
      },
    );
  };
}

export const outcomeIsLoading = (bool) => ({
  type: ActionTypes.SUBMIT_OUTCOME_LOADING,
  isLoading: bool,
});

export function outcomeHasErrored(hasErrored, error) {
  return {
    type: ActionTypes.SUBMIT_OUTCOME_ERROR,
    hasErrored,
    error,
  };
}

export function outcomeSummarySuccess(status) {
  return {
    type: ActionTypes.SUBMIT_OUTCOME_SUMMARY_SUCCESS,
    status,
  };
}
