import { ActionTypes } from '../constants';

export const initialState = {
  answers: {},
  error: null,
  hasErrored: false,
  initialSteps: null,
  isLoading: true,
  validStep: null,
  visitedSteps: [],
  summary: null,
  isEditing: false,
  breadcrumbs: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_CONFIG_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case ActionTypes.FETCH_CONFIG_ERROR:
      return {
        ...state,
        hasErrored: action.hasErrored,
        error: action.error,
      };
    case ActionTypes.FETCH_LOOKUP_ERROR:
      return {
        ...state,
        lookupErrored: action.hasErrored,
        lookupErrors: state.lookupErrors + 1,
        lookupError: action.error,
        lookupErrorResponse: action.response,
        answers: { ...state.answers, lookupError: action.error },
      };
    case ActionTypes.SET_ANSWER:
      return {
        ...state,
        answers: { ...state.answers, ...action.answer },
      };
    case ActionTypes.SET_ANSWERS:
      return {
        ...state,
        answers: action.answers,
      };
    case ActionTypes.SET_CONFIG:
      return {
        ...state,
        initialSteps: action.config,
      };
    case ActionTypes.SET_STEP:
      return {
        ...state,
        validStep: action.newStep,
      };
    case ActionTypes.SET_VISITED_STEPS:
      return {
        ...state,
        visitedSteps: action.visitedSteps,
      };
    case ActionTypes.SET_EDIT_MODE:
      return {
        ...state,
        isEditing: action.isEditing,
      };
    case ActionTypes.SET_USER_PATH:
      return {
        ...state,
        userPath: action.path,
      };
    case ActionTypes.SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.breadcrumbs,
      };
    default:
      return state;
  }
};
