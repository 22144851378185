import { Control, Heading, Step, ButtonRow } from '../../../models';
import {
  METER,
  PIPE_MEETS_METER,
  STOPTAP,
  UNSURE,
  INSIDE,
  OUTSIDE,
  EXTERNAL,
  NO,
  YES,
  COVER,
  OVERFLOW_PIPE,
  GROUND,
  PUBLIC,
  INTERNAL,
} from '../../../../constants';
import { cleanMeterInternal } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', cleanMeterInternal.heading);
const cleanMeterInternalControl = new Control('question', 'cleanMeterInternal');
cleanMeterInternalControl.setLabel(cleanMeterInternal.heading);
cleanMeterInternalControl.addOption(cleanMeterInternal.meter, METER, 'IconMeter');
cleanMeterInternalControl.addOption(cleanMeterInternal.meterPipe, PIPE_MEETS_METER, 'IconWrench');
cleanMeterInternalControl.addOption(cleanMeterInternal.stopTap, STOPTAP, 'IconTap');
cleanMeterInternalControl.addOption(cleanMeterInternal.unsure, UNSURE, 'IconCross');
const buttonsBlock = new ButtonRow().hideContinueButton();

const cleanMeterInternalExternalStep = new Step('step', 'clean-meter-internal');
cleanMeterInternalExternalStep.addBlock(headingBlock);
cleanMeterInternalExternalStep.addBlock(cleanMeterInternalControl);
cleanMeterInternalExternalStep.addBlock(buttonsBlock);
cleanMeterInternalExternalStep.setTitle(cleanMeterInternal.title);

cleanMeterInternalExternalStep.setConstraints([
  {
    initialLocation: {
      inclusion: [INTERNAL],
      exclusion: [PUBLIC, EXTERNAL],
    },
    internalWaterOrigin: {
      presence: true,
      inclusion: [METER],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      presence: true,
      inclusion: [METER],
      exclusion: [COVER, OVERFLOW_PIPE, GROUND],
    },
    cleanMeterLeaking: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    cleanMeterLocation: {
      presence: true,
      inclusion: [INSIDE],
      exclusion: [OUTSIDE],
    },
  },
]);

export { cleanMeterInternalExternalStep };
