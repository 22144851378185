import { ActionTypes } from '../constants';

export const initialState = {
  status: {},
  isYorkshire: true,
  mapHasDragged: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_LOCATION:
      return {
        ...state,
        mapHasDragged: action.mapHasDragged,
      };
    case ActionTypes.SET_IS_YORKSHIRE:
      return {
        ...state,
        isYorkshire: action.isYorkshire,
      };
    default:
      return state;
  }
};
