function ButtonRow() {
  this.type = 'buttons';
  this.showContinue = true;
  this.showBack = true;
  this.continueButtonDisabled = false;
}

ButtonRow.prototype.setContinueButtonDisabled = function(state) {
  this.continueButtonDisabled = state;
  return this;
};

ButtonRow.prototype.setContinueButtonText = function(text) {
  this.continueButtonText = text;
  return this;
};

ButtonRow.prototype.setContinueButtonState = function(state) {
  this.continueButtonState = state;
  return this;
};

ButtonRow.prototype.showContinueButton = function() {
  this.showContinue = true;
  return this;
};

ButtonRow.prototype.hideContinueButton = function() {
  this.showContinue = false;
  return this;
};

ButtonRow.prototype.showBackButton = function() {
  this.showBack = true;
  return this;
};

ButtonRow.prototype.hideBackButton = function() {
  this.showBack = false;
  return this;
};

ButtonRow.prototype.setConstraints = function(constraints) {
  this.constraints = constraints;
  return this;
};

export default ButtonRow;
