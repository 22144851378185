import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  ACCESS,
  BOTH,
  YES,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  TRIANGLE,
  DAMP,
  LARGE,
  LOCATION_METHOD_CURRENT_LOCATION,
  LOCATION_METHOD_MAP_SEARCH,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  MEDIUM,
  NONE,
  ABOVE_GROUND_STOP_TAP,
  COVER,
  GROUND,
  PROPERTY_DAMAGE,
  SMALL,
  CLEAN,
  WASTE,
  UNSURE,
} from '../../../../constants';
import { wasteSeverity } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteSeverity.heading);

const wasteSeverityControl = new Control('question', 'wasteSeverity');
wasteSeverityControl.setLabel(wasteSeverity.heading);
wasteSeverityControl.addOption(wasteSeverity.dampArea, DAMP, 'IconDampPatch');
wasteSeverityControl.addOption(wasteSeverity.smallArea, SMALL, 'IconSmallArea');
wasteSeverityControl.addOption(wasteSeverity.mediumArea, MEDIUM, 'IconMediumArea');
wasteSeverityControl.addOption(wasteSeverity.largeArea, LARGE, 'IconLargeArea');
wasteSeverityControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteSeverityStep = new Step('step', 'waste-severity');
wasteSeverityStep.addBlock(headingBlock);
wasteSeverityStep.addBlock(wasteSeverityControl);
wasteSeverityStep.addBlock(buttonsBlock);
wasteSeverityStep.setTitle(wasteSeverity.title);

wasteSeverityStep.setConstraints([
  {
    waterType: {
      presence: true,
      inclusion: [WASTE],
    },
  },
  {
    waterSmell: {
      presence: true,
      inclusion: [YES],
    },
  },
  {
    rainfallAffected: {
      presence: true,
      inclusion: [YES],
    },
  },
  {
    coverType: {
      presence: true,
      inclusion: [TRIANGLE, SQUARE],
    },
  },
  {
    initialLocation: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_MAP_SEARCH, LOCATION_METHOD_CURRENT_LOCATION],
    },
    waterOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ABOVE_GROUND_STOP_TAP, COVER, OTHER],
    },
    waterType: {
      inclusion: [WASTE],
      exclusion: [CLEAN, UNSURE],
    },
    wasteRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocation: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_MAP_SEARCH, LOCATION_METHOD_CURRENT_LOCATION],
    },
    waterOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ABOVE_GROUND_STOP_TAP, GROUND, OTHER],
    },
    coverType: {
      inclusion: [OTHER],
      exclusion: [GRATE, SQUARE, STOPTAP, METER, SLUICE_VALVE],
    },
    waterType: {
      inclusion: [WASTE],
      exclusion: [CLEAN, UNSURE],
    },
    wasteRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
]);

export { wasteSeverityStep };
