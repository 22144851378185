import { Heading, Step, ButtonRow, InfoBlock, Link } from '../../../models';
import {
  YES,
  NO,
  EXTERNAL,
  COVER,
  OVERFLOW_PIPE,
  GROUND,
  METER,
  MAIN_WEBSITE_URL,
  LOCAL_PLUMBER_URL,
} from '../../../../constants';
import { cleanSupplyInfo, backToMainWebsite, localPlumber } from '../../../../constants/Copy';

const headingBlock = new Heading('step-heading', cleanSupplyInfo.heading);
const infoBlock = new InfoBlock('info-block', cleanSupplyInfo.detail[0]);
const infoBlock2 = new InfoBlock('info-block2', cleanSupplyInfo.detail[1]);
const infoBlock3 = new InfoBlock('info-block3', cleanSupplyInfo.detail[2]);
const infoBlock4 = new InfoBlock('info-block4', cleanSupplyInfo.detail[3]);
const infoBlock5 = new InfoBlock('info-block5', cleanSupplyInfo.detail[4]);
const linkBlock1 = new Link('link-block1', localPlumber).setLink(LOCAL_PLUMBER_URL).setTarget('_self');
const linkBlock = new Link('link-block', backToMainWebsite, 'p-t')
  .setLink(MAIN_WEBSITE_URL)
  .setTarget('_self')
  .setFormat(true);
const buttonsBlock = new ButtonRow().hideContinueButton();

const cleanSupplyOffRampStep = new Step('step', 'clean-supply-info');
cleanSupplyOffRampStep.setIsContained(true);
cleanSupplyOffRampStep.addBlock(headingBlock);
cleanSupplyOffRampStep.addBlock(infoBlock);
cleanSupplyOffRampStep.addBlock(infoBlock2);
cleanSupplyOffRampStep.addBlock(infoBlock3);
cleanSupplyOffRampStep.addBlock(infoBlock4);
cleanSupplyOffRampStep.addBlock(infoBlock5);
cleanSupplyOffRampStep.addBlock(linkBlock1);
cleanSupplyOffRampStep.addBlock(linkBlock);
cleanSupplyOffRampStep.addBlock(buttonsBlock);
cleanSupplyOffRampStep.setTitle(cleanSupplyInfo.title);

cleanSupplyOffRampStep.setConstraints({
  initialLocation: {
    inclusion: [EXTERNAL],
  },
  externalWaterOrigin: {
    presence: true,
    inclusion: [GROUND],
    exclusion: [COVER, METER, OVERFLOW_PIPE],
  },
  eligibility: {
    presence: true,
    inclusion: [NO],
    exclusion: [YES],
  },
  isColleagueMode: {
    presence: false,
    inclusion: [NO],
    exclusion: [YES],
  },
});

export { cleanSupplyOffRampStep };
