import { ButtonRow, Heading, InfoBlock, Link, Step } from '../../../models';
import {
  GULLY,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  SQUARE,
  BLOCKED,
  GUSHING,
  LOCAL_COUNCIL_URL,
  MAIN_WEBSITE_URL,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  COVER,
  GROUND,
  ODOUR,
  POLLUTION,
  NO,
  YES,
} from '../../../../constants';
import { backToMainWebsite, localCouncil, wasteCouncilInfo } from '../../../../constants/Copy';

const headingBlock = new Heading('step-heading', wasteCouncilInfo.heading);
const infoBlock = new InfoBlock('info-block', wasteCouncilInfo.detail[0], 'p-b');
const headingBlock2 = new Heading('step-heading2', wasteCouncilInfo.subHeading, 2).setSize('md');
const infoBlock2 = new InfoBlock('info-block2', wasteCouncilInfo.detail[1]);
const linkBlock1 = new Link('link-block1', localCouncil).setLink(LOCAL_COUNCIL_URL).setTarget('_self');
const linkBlock2 = new Link('link-block2', backToMainWebsite, 'p-t').setLink(MAIN_WEBSITE_URL).setTarget('_self');
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteCouncilInfoStep = new Step('step', 'waste-council-info');
wasteCouncilInfoStep.setIsContained(true);
wasteCouncilInfoStep.addBlock(headingBlock);
wasteCouncilInfoStep.addBlock(infoBlock);
wasteCouncilInfoStep.addBlock(headingBlock2);
wasteCouncilInfoStep.addBlock(infoBlock2);
wasteCouncilInfoStep.addBlock(linkBlock1);
wasteCouncilInfoStep.addBlock(linkBlock2);
wasteCouncilInfoStep.addBlock(buttonsBlock);
wasteCouncilInfoStep.setTitle(wasteCouncilInfo.title);

wasteCouncilInfoStep.setConstraints({
  initialLocationWaste: {
    inclusion: [PUBLIC],
    exclusion: [EXTERNAL, INTERNAL],
  },
  wasteExternalOrigin: {
    inclusion: [COVER],
    exclusion: [ODOUR, POLLUTION, GROUND],
  },
  wasteExternalCover: {
    inclusion: [GULLY],
    exclusion: [SQUARE, SLUICE_VALVE, STOPTAP, METER, OTHER],
  },
  wasteExternalCoverBlocked: {
    inclusion: [BLOCKED],
    exclusion: [GUSHING],
  },
  isColleagueMode: {
    presence: false,
    inclusion: [NO],
    exclusion: [YES],
  },
});

export { wasteCouncilInfoStep };
