import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import createRootReducer from './reducers/rootReducer';

export const history = createBrowserHistory();

export default function configureStore(middleware) {
  let enhancer;
  if (middleware) {
    enhancer = middleware;
  } else {
    enhancer = composeWithDevTools(
      applyMiddleware(routerMiddleware(history), thunk),
      // other store enhancers if any
    );
  }

  const store = createStore(createRootReducer(history), enhancer);
  return store;
}
