import { Heading, Control, Step, ButtonRow, InfoBlock } from '../../models';
import {
  OWNER,
  UNSURE,
  YORKSHIREWATER,
  YES,
  NO,
  BOTH,
  NONE,
  ODOUR,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  COVER,
  DRAIN,
  GROUND,
  OUTBUILDING,
  PROPERTY,
  GULLY,
  TOWARDS_WATERCOURSE,
  WATERCOURSE,
  GARDEN,
  BLOCKED,
} from '../../../constants';
import { wasteResponsibiltyBoundary } from '../../../constants/Copy';

const headingBlock = new Heading('step-heading', wasteResponsibiltyBoundary.heading);
const infoBlock1 = new InfoBlock('info-block1', wasteResponsibiltyBoundary.detail, 'p-tb');
const heading = new Heading('step-heading2', wasteResponsibiltyBoundary.homeOwnerResp, 2).setSize('sm');
const infoBlock2 = new InfoBlock(
  'bullet-point1',
  wasteResponsibiltyBoundary.homeOwner[0],
  'u-pt--0 u-pb--50 u-pl--450 u-mb--0',
);
const infoBlock3 = new InfoBlock(
  'bullet-point2',
  wasteResponsibiltyBoundary.homeOwner[1],
  'u-pt--0 u-pb--50 u-pl--450 u-mb--0',
);
const infoBlock4 = new InfoBlock(
  'bullet-point3',
  wasteResponsibiltyBoundary.homeOwner[2],
  'u-pt--0 u-pb--150 u-pl--450 u-mb--0',
);
const heading2 = new Heading('step-heading2', wasteResponsibiltyBoundary.ywResp, 2).setSize('sm');
const infoBlock5 = new InfoBlock(
  'bullet-point5',
  wasteResponsibiltyBoundary.yw[0],
  'u-pt--0 u-pb--50 u-pl--450 u-mb--0',
);
const infoBlock6 = new InfoBlock(
  'bullet-point6',
  wasteResponsibiltyBoundary.yw[1],
  'u-pt--0 u-pb--50 u-pl--450 u-mb--0',
);
const infoBlock7 = new InfoBlock(
  'bullet-point7',
  wasteResponsibiltyBoundary.yw[2],
  'u-pt--0 u-pb--150 u-pl--450 u-mb--0',
);
const headingBlock2 = new Heading('step-heading2', wasteResponsibiltyBoundary.subHeading, 2).setSize('md');
const radio = new Control('radio', 'wasteResponsibility')
  .setLabel(wasteResponsibiltyBoundary.subHeading)
  .labelOff()
  .addOption(wasteResponsibiltyBoundary.owner, OWNER, null)
  .addOption(wasteResponsibiltyBoundary.yorkshireWater, YORKSHIREWATER, null)
  .addOption(wasteResponsibiltyBoundary.unsure, UNSURE, null)
  .setDefault(OWNER);
const buttonsBlock = new ButtonRow();

const wasteResponsibiltyBoundaryStep = new Step('step', 'waste-responsibility-boundary');
wasteResponsibiltyBoundaryStep.setIsContained(true);
wasteResponsibiltyBoundaryStep.addBlock(headingBlock);
wasteResponsibiltyBoundaryStep.addBlock(infoBlock1);
wasteResponsibiltyBoundaryStep.addBlock(heading);
wasteResponsibiltyBoundaryStep.addBlock(infoBlock2);
wasteResponsibiltyBoundaryStep.addBlock(infoBlock3);
wasteResponsibiltyBoundaryStep.addBlock(infoBlock4);
wasteResponsibiltyBoundaryStep.addBlock(heading2);
wasteResponsibiltyBoundaryStep.addBlock(infoBlock5);
wasteResponsibiltyBoundaryStep.addBlock(infoBlock6);
wasteResponsibiltyBoundaryStep.addBlock(infoBlock7);
wasteResponsibiltyBoundaryStep.addBlock(headingBlock2);
wasteResponsibiltyBoundaryStep.addBlock(radio);
wasteResponsibiltyBoundaryStep.addBlock(buttonsBlock);
wasteResponsibiltyBoundaryStep.setTitle(wasteResponsibiltyBoundary.title);

wasteResponsibiltyBoundaryStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      inclusion: [NO],
      exclusion: [YES],
    },
    wasteBoundaryIssues: {
      inclusion: [NONE],
      exclusion: [BLOCKED, ODOUR, BOTH],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteBoundaryEscapeLocation: {
      inclusion: [GARDEN, OUTBUILDING],
      exclusion: [WATERCOURSE, TOWARDS_WATERCOURSE, GULLY, PROPERTY],
    },
    wasteBoundaryIssues: {
      inclusion: [NONE],
      exclusion: [BLOCKED, ODOUR, BOTH],
    },
  },
]);

export { wasteResponsibiltyBoundaryStep };
