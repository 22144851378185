function Accordion(id, heading, content) {
  this.id = id;
  this.heading = heading;
  this.type = 'accordionSingle';
}

Accordion.prototype.setContent = function(content) {
  this.content = content;
  return this;
};

export default Accordion;
