import { ButtonRow, Control, Heading, Hook, Step } from '../../../models';
import {
  STEP_MAP_SEARCH_LOOKUP,
  LOCATION_METHOD_MAP_SEARCH,
  LOCATION_METHOD_CURRENT_LOCATION,
  PUBLIC,
  ODOUR,
  WATER_SUPPLY,
  SLOW_DRAINAGE,
  NO,
} from '../../../../constants';
import { mapSearch } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', mapSearch.heading);
const locateMethodControl = new Control('hidden', 'locateMethod').setDefault(LOCATION_METHOD_MAP_SEARCH);
const noResultMessageBlock = {
  id: 'NO-RESULTS',
  type: 'message',
  message: mapSearch.noLocations,
  state: 'error',
  nextSteps: [
    {
      text: mapSearch.searchTerm,
    },
    {
      text: mapSearch.ywArea,
    },
    {
      text: mapSearch.currentLocation,
      stepForwardParams: { target: 'map', params: { locateMethod: LOCATION_METHOD_CURRENT_LOCATION } },
      stepForwardText: mapSearch.currentLocation,
    },
  ],
};
const buttonsBlock = new ButtonRow();

const mapSearchStep = new Step('step', 'map-search');
mapSearchStep.addBlock(headingBlock);
mapSearchStep.addBlock(locateMethodControl);
mapSearchStep.addBlock(noResultMessageBlock);
mapSearchStep.addBlock({
  type: 'mapSearch',
  name: 'mapSearch',
});
mapSearchStep.addBlock(buttonsBlock);
mapSearchStep.exitHook = new Hook(STEP_MAP_SEARCH_LOOKUP, 'mapSearch');
mapSearchStep.setIsContained(true);
mapSearchStep.setTitle(mapSearch.title);

noResultMessageBlock.constraints = [
  {
    'hooks.error': {
      presence: true,
      inclusion: [STEP_MAP_SEARCH_LOOKUP],
    },
  },
];

mapSearchStep.setConstraints({
  initialLocation: {
    inclusion: [PUBLIC],
  },
  initialProblem: {
    exclusion: [ODOUR, WATER_SUPPLY, SLOW_DRAINAGE],
  },
  locateMethod: {
    inclusion: [LOCATION_METHOD_MAP_SEARCH],
  },
  waterFromCover: {
    exclusion: [NO],
  },
});

mapSearchStep.setValidation({
  mapSearch: {
    presence: {
      allowEmpty: false,
      message: mapSearch.validation,
    },
  },
});

export { mapSearchStep };
