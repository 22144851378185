import { ButtonRow, Control, Heading, Step } from '../../../models';
import { pollutionEntering } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', pollutionEntering.heading);
const pollutionEnteringControl = new Control('question', 'pollutionEntering');
pollutionEnteringControl.setLabel(pollutionEntering.heading);
pollutionEnteringControl.addOption(pollutionEntering.enteringSea, 'ENTERING_SEA', 'IconSea');
pollutionEnteringControl.addOption(pollutionEntering.enteringRiver, 'ENTERING_RIVER_OR_STREAM', 'IconRiverStream');
pollutionEnteringControl.addOption(pollutionEntering.enteringCanal, 'ENTERING_CANAL', 'IconCanal');
pollutionEnteringControl.addOption(pollutionEntering.enteringPond, 'ENTERING_POND_LAKE', 'IconPondLake');
pollutionEnteringControl.addOption(pollutionEntering.enteringNature, 'ENTERING_NATURE_RESERVE', 'IconNatureReserve');
pollutionEnteringControl.addOption(pollutionEntering.enteringOther, 'ENTERING_OTHER', 'IconQuestion');
const buttonsBlock = new ButtonRow().hideContinueButton();

const pollutionEnteringStep = new Step('step', 'pollution-entering');
pollutionEnteringStep.addBlock(headingBlock);
pollutionEnteringStep.addBlock(pollutionEnteringControl);
pollutionEnteringStep.addBlock(buttonsBlock);
pollutionEnteringStep.setTitle(pollutionEntering.title);

pollutionEnteringStep.setConstraints([
  {
    initialProblem: { inclusion: ['POLLUTION'] },
  },
]);

export { pollutionEnteringStep };
