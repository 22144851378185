function Heading(id, title, priority = 1, className = null) {
  this.id = id;
  this.priority = priority;
  this.size = 'lg';
  this.title = title;
  this.type = 'heading';
  this.className = className;
}

Heading.prototype.setSize = function(size) {
  this.size = size;
  return this;
};

Heading.prototype.setPriority = function(priority) {
  this.priority = priority;
  return this;
};

Heading.prototype.setClassName = function(className) {
  this.className = className;
  return this;
};

Heading.prototype.setConstraints = function(constraints) {
  this.constraints = constraints;
  return this;
};

export default Heading;
