function ColleagueSummary() {
  this.type = 'colleagueSummary';
}

ColleagueSummary.prototype.setConstraints = function(constraints) {
  this.constraints = constraints;
  return this;
};

export default ColleagueSummary;
