import { Heading, Step, ButtonRow, InfoBlock, Banner, Control } from '../../../models';
import { YES, NO } from '../../../../constants';
import {
  duplicateIssueBanner,
  duplicateIssueQuestion,
  reviewForCoC,
  rtimInformation,
} from '../../../../constants/Copy';

const banner = new Banner(duplicateIssueBanner);
const headingBlock = new Heading('step-heading', rtimInformation.heading);
const infoBlock = new InfoBlock('info-block', rtimInformation.detail[0], 'p-b');
const headingBlock2 = new Heading('step-heading2', rtimInformation.subHeading, 2).setSize('md');
const infoBlock2 = new InfoBlock('info-block2', rtimInformation.detail[1]);
const buttonsBlock = new ButtonRow();

const rtimInformationColStep = new Step('step', 'rtim-information-col');
rtimInformationColStep.setIsContained(true);
rtimInformationColStep.addBlock(new Control('hidden', 'rtimInformationCol').setDefault(true));
rtimInformationColStep.addBlock(
  new Control('hidden', 'rtimInformationColTxt').setLabel(duplicateIssueQuestion).setDefault(reviewForCoC),
);
rtimInformationColStep.addBlock(banner);
rtimInformationColStep.addBlock(headingBlock);
rtimInformationColStep.addBlock(infoBlock);
rtimInformationColStep.addBlock(headingBlock2);
rtimInformationColStep.addBlock(infoBlock2);
rtimInformationColStep.addBlock(buttonsBlock);
rtimInformationColStep.setTitle(rtimInformation.title);

rtimInformationColStep.setConstraints({
  incidentResults: {
    inclusion: [YES],
  },
  incidentTotal: {
    presence: true,
    numericality: {
      greaterThan: 0,
    },
  },
  isColleagueMode: {
    presence: true,
    inclusion: [YES],
    exclusion: [NO],
  },
});

export { rtimInformationColStep };
