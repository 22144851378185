import { Control, Heading, Step, ButtonRow } from '../../models';
import { INTERNAL, EXTERNAL, PUBLIC } from '../../../constants';
import { initialLocationWaste } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', initialLocationWaste.heading);
const initialLocationWasteControl = new Control('question', 'initialLocationWaste');
initialLocationWasteControl.setLabel(initialLocationWaste.heading);
initialLocationWasteControl.addOption(initialLocationWaste.internal, INTERNAL, 'IconFloodHouseInternal');
initialLocationWasteControl.addOption(initialLocationWaste.external, EXTERNAL, 'IconFloodHouseExternal');
initialLocationWasteControl.addOption(initialLocationWaste.public, PUBLIC, 'IconPublicArea');
const buttonsBlock = new ButtonRow().hideContinueButton();

const initialLocationWasteStep = new Step('step', 'initial-location-waste');
initialLocationWasteStep.addBlock(headingBlock);
initialLocationWasteStep.addBlock(initialLocationWasteControl);
initialLocationWasteStep.addBlock(buttonsBlock);
initialLocationWasteStep.setTitle(initialLocationWaste.title);

initialLocationWasteStep.setConstraints({
  isWasteInitial: {
    inclusion: [true],
  },
  isCleanInitial: {
    exclusion: [true],
  },
  isNwlpInitial: {
    exclusion: [true],
  },
});

export { initialLocationWasteStep };
