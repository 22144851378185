import { Step, ButtonRow, Banner } from '../../../models';
import {
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  CHEMICAL,
  DRAIN,
  FARM,
  GAS,
  COVER,
  GROUND,
  HOUSE,
  ODOUR,
  POLLUTION,
  BATHROOM,
  KITCHEN,
  wasteChemicalInfo,
  NO,
  YES,
  chemicalBanner,
} from '../../../../constants';

const banner = new Banner(chemicalBanner);
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteChemicalInfoColStep = new Step('step', 'waste-chemical-info-col');
wasteChemicalInfoColStep.setIsContained(true);
wasteChemicalInfoColStep.addBlock(banner);
wasteChemicalInfoColStep.addBlock(buttonsBlock);
wasteChemicalInfoColStep.setTitle(wasteChemicalInfo.title);

wasteChemicalInfoColStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [ODOUR],
      exclusion: [HOUSE, BATHROOM, KITCHEN],
    },
    wasteOdourType: {
      inclusion: [CHEMICAL],
      exclusion: [GAS, FARM, DRAIN],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [ODOUR],
      exclusion: [COVER, GROUND, DRAIN],
    },
    wasteOdourType: {
      inclusion: [CHEMICAL],
      exclusion: [GAS, FARM, DRAIN],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [CHEMICAL],
      exclusion: [GAS, FARM, DRAIN],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
]);

export { wasteChemicalInfoColStep };
