import { Heading, Step, ButtonRow, InfoBlock, Banner, Control } from '../../../models';
import {
  METER,
  PIPE_MEETS_METER,
  STOPTAP,
  UNSURE,
  INSIDE,
  OUTSIDE,
  INTERNAL,
  EXTERNAL,
  NO,
  YES,
  COVER,
  OVERFLOW_PIPE,
  GROUND,
  PUBLIC,
  PLUMBING,
} from '../../../../constants';
import {
  cleanIntStopTapInfo,
  privateIssueBanner,
  privateIssueQuestion,
  reviewForCoC,
} from '../../../../constants/Copy';

const banner = new Banner(privateIssueBanner);
const headingBlock = new Heading('step-heading', cleanIntStopTapInfo.heading);
const infoBlock = new InfoBlock('info-block', cleanIntStopTapInfo.detail[0], 'p-b');
const headingBlock2 = new Heading('step-heading2', cleanIntStopTapInfo.subHeading, 2).setSize('md');
const infoBlock2 = new InfoBlock('info-block2', cleanIntStopTapInfo.detail[1]);
const infoBlock3 = new InfoBlock('info-block3', cleanIntStopTapInfo.detail[2]);
const infoBlock4 = new InfoBlock('info-block4', cleanIntStopTapInfo.detail[3]);
const infoBlock5 = new InfoBlock('info-block5', cleanIntStopTapInfo.detail[4], 'p-b');
const buttonsBlock = new ButtonRow();

const cleanIntstoptapInfoColStep = new Step('step', 'clean-intstoptap-info-col');
cleanIntstoptapInfoColStep.setIsContained(true);
cleanIntstoptapInfoColStep.addBlock(new Control('hidden', 'cleanIntstoptapInfoCol').setDefault(true));
cleanIntstoptapInfoColStep.addBlock(
  new Control('hidden', 'cleanIntstoptapInfoColTxt').setLabel(privateIssueQuestion).setDefault(reviewForCoC),
);
cleanIntstoptapInfoColStep.addBlock(banner);
cleanIntstoptapInfoColStep.addBlock(headingBlock);
cleanIntstoptapInfoColStep.addBlock(infoBlock);
cleanIntstoptapInfoColStep.addBlock(headingBlock2);
cleanIntstoptapInfoColStep.addBlock(infoBlock2);
cleanIntstoptapInfoColStep.addBlock(infoBlock3);
cleanIntstoptapInfoColStep.addBlock(infoBlock4);
cleanIntstoptapInfoColStep.addBlock(infoBlock5);
cleanIntstoptapInfoColStep.addBlock(buttonsBlock);
cleanIntstoptapInfoColStep.setTitle(cleanIntStopTapInfo.title);

cleanIntstoptapInfoColStep.setConstraints([
  {
    initialLocation: {
      inclusion: [INTERNAL],
      exclusion: [PUBLIC, EXTERNAL],
    },
    internalWaterOrigin: {
      presence: true,
      inclusion: [METER],
    },
    cleanMeterInternal: {
      presence: true,
      inclusion: [STOPTAP],
      exclusion: [METER, PIPE_MEETS_METER, UNSURE],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocation: {
      inclusion: [INTERNAL],
      exclusion: [PUBLIC, EXTERNAL],
    },
    internalWaterOrigin: {
      presence: true,
      inclusion: [PLUMBING],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      presence: true,
      inclusion: [METER],
      exclusion: [COVER, OVERFLOW_PIPE, GROUND],
    },
    cleanMeterLeaking: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    cleanMeterLocation: {
      presence: true,
      inclusion: [INSIDE],
      exclusion: [OUTSIDE],
    },
    cleanMeterInternal: {
      presence: true,
      inclusion: [STOPTAP],
      exclusion: [METER, PIPE_MEETS_METER, UNSURE],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
]);

export { cleanIntstoptapInfoColStep };
