import { Heading, Step, ButtonRow, InfoBlock, Link } from '../../../models';
import {
  EXTERNAL,
  NO,
  YES,
  COVER,
  OVERFLOW_PIPE,
  GROUND,
  METER,
  MAIN_WEBSITE_URL,
  LOCAL_PLUMBER_URL,
} from '../../../../constants';
import { backToMainWebsite, cleanLeakingMeterInfo, localPlumber } from '../../../../constants/Copy';

const headingBlock = new Heading('step-heading', cleanLeakingMeterInfo.heading);
const infoBlock = new InfoBlock('info-block', cleanLeakingMeterInfo.detail[0], 'p-b');
const headingBlock2 = new Heading('step-heading2', cleanLeakingMeterInfo.subHeading, 2).setSize('md');
const infoBlock2 = new InfoBlock('info-block2', cleanLeakingMeterInfo.detail[1]);
const infoBlock3 = new InfoBlock('info-block3', cleanLeakingMeterInfo.detail[2]);
const infoBlock4 = new InfoBlock('info-block4', cleanLeakingMeterInfo.detail[3]);
const linkBlock = new Link('link-block', localPlumber).setLink(LOCAL_PLUMBER_URL).setTarget('_self');
const linkBlock2 = new Link('link-block2', backToMainWebsite).setLink(MAIN_WEBSITE_URL).setTarget('_self');
const buttonsBlock = new ButtonRow().hideContinueButton();

const cleanLeakingMeterInfoStep = new Step('step', 'clean-leakingmeter-info');
cleanLeakingMeterInfoStep.setIsContained(true);
cleanLeakingMeterInfoStep.addBlock(headingBlock);
cleanLeakingMeterInfoStep.addBlock(infoBlock);
cleanLeakingMeterInfoStep.addBlock(headingBlock2);
cleanLeakingMeterInfoStep.addBlock(infoBlock2);
cleanLeakingMeterInfoStep.addBlock(infoBlock3);
cleanLeakingMeterInfoStep.addBlock(infoBlock4);
cleanLeakingMeterInfoStep.addBlock(linkBlock);
cleanLeakingMeterInfoStep.addBlock(linkBlock2);
cleanLeakingMeterInfoStep.addBlock(buttonsBlock);
cleanLeakingMeterInfoStep.setTitle(cleanLeakingMeterInfo.title);

cleanLeakingMeterInfoStep.setConstraints({
  initialLocation: {
    inclusion: [EXTERNAL],
  },
  externalWaterOrigin: {
    presence: true,
    inclusion: [METER],
    exclusion: [COVER, OVERFLOW_PIPE, GROUND],
  },
  cleanMeterLeaking: {
    presence: true,
    inclusion: [NO],
    exclusion: [YES],
  },
  isColleagueMode: {
    presence: false,
    inclusion: [NO],
    exclusion: [YES],
  },
});

export { cleanLeakingMeterInfoStep };
