import { ButtonRow, Control, Heading, Hook, Step } from '../../../models';
import {
  YES,
  NO,
  UNSURE,
  WASTE,
  CLEAN,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  COVER,
  GROUND,
  ODOUR,
  POLLUTION,
  LOCATION_METHOD_CURRENT_LOCATION,
  LOCATION_METHOD_MAP_SEARCH,
  STEP_MAP_SEARCH_LOOKUP,
  SQUARE,
  GULLY,
  OTHER,
  METER,
  STOPTAP,
  SLUICE_VALVE,
  GUSHING,
  BLOCKED,
  FARM,
  GAS,
  CHEMICAL,
  DRAIN,
  BOTH,
  NONE,
  INTERMITTENT,
  CONSTANT,
} from '../../../../constants';
import { externalRoughLocation } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', externalRoughLocation.heading);
const locateMethodControl = new Control('hidden', 'locateMethod').setDefault(LOCATION_METHOD_MAP_SEARCH);
const noResultMessageBlock = {
  id: 'NO-RESULTS',
  type: 'message',
  message: externalRoughLocation.noLocations,
  state: 'error',
  nextSteps: [
    {
      text: externalRoughLocation.searchTerm,
    },
    {
      text: externalRoughLocation.ywArea,
    },
    {
      text: externalRoughLocation.currentLocation,
      stepForwardParams: { target: 'map', params: { locateMethod: LOCATION_METHOD_CURRENT_LOCATION } },
      stepForwardText: externalRoughLocation.currentLocation,
    },
  ],
};
noResultMessageBlock.constraints = [
  {
    'hooks.error': {
      presence: true,
      inclusion: [STEP_MAP_SEARCH_LOOKUP],
    },
  },
];
const buttonsBlock = new ButtonRow();

const externalRoughLocationStep = new Step('step', 'external-rough-location');
externalRoughLocationStep.addBlock(headingBlock);
externalRoughLocationStep.addBlock(locateMethodControl);
externalRoughLocationStep.addBlock(noResultMessageBlock);
externalRoughLocationStep.addBlock({ type: 'mapSearch', name: 'mapSearch' });
externalRoughLocationStep.addBlock(buttonsBlock);
externalRoughLocationStep.exitHook = new Hook(STEP_MAP_SEARCH_LOOKUP, 'mapSearch');
externalRoughLocationStep.setIsContained(true);
externalRoughLocationStep.setTitle(externalRoughLocation.title);

externalRoughLocationStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [POLLUTION],
      exclusion: [ODOUR, GROUND, COVER],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND, POLLUTION],
      exclusion: [ODOUR, COVER],
    },
    externalWasteWaterType: {
      inclusion: [CLEAN, WASTE],
      exclusion: [UNSURE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      inclusion: [NO],
      exclusion: [YES],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [YES],
      exclusion: [UNSURE],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [SQUARE],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, OTHER, GULLY],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [GULLY],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, OTHER, SQUARE],
    },
    wasteExternalCoverBlocked: {
      inclusion: [GUSHING],
      exclusion: [BLOCKED],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [SLUICE_VALVE, STOPTAP, METER],
      exclusion: [OTHER, SQUARE, GULLY],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [WASTE, CLEAN],
      exclusion: [UNSURE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      inclusion: [YES],
      exclusion: [UNSURE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      inclusion: [YES, NO],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [FARM],
      exclusion: [GAS, CHEMICAL, DRAIN],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [DRAIN, BLOCKED, BOTH],
      exclusion: [NONE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [NONE],
      exclusion: [DRAIN, BLOCKED, BOTH],
    },
    wasteOdourFrequency: {
      inclusion: [CONSTANT, INTERMITTENT],
      exclusion: [],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [GAS],
      exclusion: [CHEMICAL, FARM, DRAIN],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [GULLY],
      exclusion: [SQUARE, SLUICE_VALVE, STOPTAP, METER, OTHER],
    },
    wasteExternalCoverBlocked: {
      inclusion: [BLOCKED],
      exclusion: [GUSHING],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
]);

externalRoughLocationStep.setValidation({
  mapSearch: {
    presence: {
      allowEmpty: false,
      message: externalRoughLocation.validation,
    },
  },
});

export { externalRoughLocationStep };
