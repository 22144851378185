import { ActionTypes } from '../constants';

export const initialState = {
  status: {},
  error: undefined,
  hasErrored: false,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SUBMIT_OUTCOME_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case ActionTypes.SUBMIT_OUTCOME_SUMMARY_SUCCESS:
      return {
        ...state,
        status: action.status,
      };
    case ActionTypes.SUBMIT_OUTCOME_ERROR:
      return {
        ...state,
        hasErrored: action.hasErrored,
        error: action.error,
      };
    default:
      return state;
  }
};
