import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Option_NA,
  SentimentCustomerUsedComplaintWording,
  SentimentCustomerUsedComplaintWording_Options,
  SentimentLogComplaint,
  SentimentLogComplaint_Options,
  SentimentReportOutcome,
  SentimentReportOutcome2ndLevel,
  SentimentReportOutcome2ndLevel_DisSatisfied_Options,
  SentimentReportOutcome2ndLevel_Satisfied_Options,
  SentimentReportOutcome_Option_Dissatisfied_Deemed,
  SentimentReportOutcome_Option_Escalation,
  SentimentReportOutcome_Option_Satisfied_Deemed,
  SentimentReportOutcome_Options,
} from '../../../constants';
import { SentimentSection } from './SentimentSection';
import { FormRow } from 'yw-ui.v2/dist/components';

export class Sentiment extends Component {
  constructor(form) {
    super(form);
    this.state = {
      reportOutcomeDirty: false,
      usedComplaintDirty: false,
      logComplaintDirty: false,

      sentimentReportOutcome_Valid: true,
      sentimentReportOutcome2ndLevel_Valid: true,
      sentimentCustomerUsedComplaintWording_Valid: true,
      sentimentLogComplaint_Valid: true,

      sentimentReportOutcome2ndLevel_Options: [],

      sentimentValid: false,
      secondOutcome: false,

      reportOutcomeValid: null,
      secondReportOutcomeValid: null,
      usedComplaintValid: null,
      logComplaintValid: null,
    };
  }

  handleChange(dirty) {
    const isValid = (bool, valueName) => {
      const value = this.props.form.values[valueName];
      return bool ? value !== '' && value !== undefined : value !== '';
    };
    const isInBooleanArray = (arr, valueName) => {
      const value = this.props.form.values[valueName];
      const inArr = arr.includes(value);
      if (inArr) {
        return inArr;
      }
      return ['true', 'false'].includes(value);
    };

    const inArray = (arr, valueName) => {
      const value = this.props.form.values[valueName];
      return arr.includes(value);
    };

    this.setState(
      {
        [dirty]: true,
      },
      () => {
        const hasSubmitted = this.props.form.submitCount !== 0;

        let reportOutcomeDirty = this.state.reportOutcomeDirty;
        let usedComplaintDirty = this.state.usedComplaintDirty;
        let logComplaintDirty = this.state.logComplaintDirty;

        if (hasSubmitted) {
          reportOutcomeDirty = true;
          usedComplaintDirty = true;
          logComplaintDirty = true;
        }

        const isEscalation = this.props.form.values.SentimentReportOutcome === SentimentReportOutcome_Option_Escalation;
        if (isEscalation) {
          logComplaintDirty = true;
          if (SentimentLogComplaint_Options.includes(Option_NA) === false) {
            SentimentLogComplaint_Options.push(Option_NA);
          }
          this.props.form.values.SentimentLogComplaint = Option_NA;
        } else if (SentimentLogComplaint_Options.includes(Option_NA)) {
          SentimentLogComplaint_Options.pop(Option_NA);
          this.props.form.values.SentimentLogComplaint = undefined;
        }

        const isSatisfiedNotConfirmed =
          this.props.form.values.SentimentReportOutcome === SentimentReportOutcome_Option_Satisfied_Deemed;
        const isDissatisfiedNotConfirmed =
          this.props.form.values.SentimentReportOutcome === SentimentReportOutcome_Option_Dissatisfied_Deemed;
        const secondLevel = isSatisfiedNotConfirmed || isDissatisfiedNotConfirmed;
        let secondLevelOptions = [];
        if (secondLevel) {
          if (isSatisfiedNotConfirmed) {
            secondLevelOptions = SentimentReportOutcome2ndLevel_Satisfied_Options;
          }
          if (isDissatisfiedNotConfirmed) {
            secondLevelOptions = SentimentReportOutcome2ndLevel_DisSatisfied_Options;
          }
        } else {
          this.props.form.values.SentimentReportOutcome2ndLevel = Option_NA;
        }

        const reportOutcomeExists = inArray(SentimentReportOutcome_Options, SentimentReportOutcome);
        const secondReportOutcomeExists = inArray(secondLevelOptions, SentimentReportOutcome2ndLevel);
        const usedComplaintExists = isInBooleanArray(
          SentimentCustomerUsedComplaintWording_Options,
          SentimentCustomerUsedComplaintWording,
        );
        const logComplaintExists = isInBooleanArray(SentimentLogComplaint_Options, SentimentLogComplaint);

        const secondLevel_Valid =
          this.props.form.values.SentimentReportOutcome2ndLevel !== Option_NA &&
          isValid(reportOutcomeDirty, SentimentReportOutcome2ndLevel);

        const sentimentReportOutcome_Valid = isValid(reportOutcomeDirty, SentimentReportOutcome);
        const sentimentReportOutcome2ndLevel_Valid = secondLevel ? secondLevel_Valid : true;
        const sentimentCustomerUsedComplaintWording_Valid = isValid(
          usedComplaintDirty,
          SentimentCustomerUsedComplaintWording,
        );
        const sentimentLogComplaint_Valid = isValid(logComplaintDirty, SentimentLogComplaint);

        const reportOutcomeCheck = hasSubmitted || reportOutcomeDirty;
        const secondReportOutcomeChecked = reportOutcomeCheck ? secondReportOutcomeExists : null;
        const usedComplaintCheck = hasSubmitted || usedComplaintDirty;
        const logComplaintCheck = hasSubmitted || logComplaintDirty;

        const reportOutcomeValid = reportOutcomeCheck ? reportOutcomeExists : null;
        const secondReportOutcomeValid = secondLevel ? secondReportOutcomeChecked : null;
        const usedComplaintValid = usedComplaintCheck ? usedComplaintExists : null;
        const logComplaintValid = logComplaintCheck ? logComplaintExists : null;

        const sentimentValid =
          (reportOutcomeCheck ? sentimentReportOutcome_Valid : false) &&
          (reportOutcomeCheck ? sentimentReportOutcome2ndLevel_Valid : false) &&
          (usedComplaintCheck ? sentimentCustomerUsedComplaintWording_Valid : false) &&
          (logComplaintCheck ? sentimentLogComplaint_Valid : false);

        this.setState({
          sentimentReportOutcome_Valid: sentimentReportOutcome_Valid,
          sentimentReportOutcome2ndLevel_Valid: sentimentReportOutcome2ndLevel_Valid,
          sentimentCustomerUsedComplaintWording_Valid: sentimentCustomerUsedComplaintWording_Valid,
          sentimentLogComplaint_Valid: sentimentLogComplaint_Valid,
          sentimentReportOutcome2ndLevel_Options: secondLevelOptions,
          reportOutcomeValid: reportOutcomeValid,
          secondReportOutcomeValid: secondReportOutcomeValid,
          usedComplaintValid: usedComplaintValid,
          logComplaintValid: logComplaintValid,
          secondOutcome: secondLevel,
          sentimentValid: sentimentValid,
        });
      },
    );
  }

  render() {
    this.props.form.values.sentimentValid = this.state.sentimentValid;

    const isValid = (v) => {
      return v || (v === null && this.props.form.submitCount === 0);
    };

    return (
      <FormRow name={'sentiment_row'} qa={'sentiment_row'} label={''}>
        <SentimentSection
          name={SentimentReportOutcome}
          title={'What was the outcome?'}
          placeholder={'Please select an outcome'}
          isRequired={true}
          form={this.props.form}
          options={SentimentReportOutcome_Options}
          message={'Please select an outcome'}
          valid={isValid(this.state.reportOutcomeValid)}
          onChange={() => {
            this.handleChange('reportOutcomeDirty');
          }}
        />
        {this.state.secondOutcome && (
          <SentimentSection
            name={SentimentReportOutcome2ndLevel}
            title={'What was the second level outcome?'}
            placeholder={'Please select a second level outcome'}
            isRequired={true}
            form={this.props.form}
            options={this.state.sentimentReportOutcome2ndLevel_Options}
            message={'Please select a second level outcome'}
            valid={isValid(this.state.secondReportOutcomeValid)}
            onChange={() => {
              this.handleChange('reportOutcomeDirty');
            }}
          />
        )}
        <SentimentSection
          name={SentimentCustomerUsedComplaintWording}
          title={'Did the customer use the words "Complain" or "Complaint"?'}
          isRequired={true}
          form={this.props.form}
          options={SentimentCustomerUsedComplaintWording_Options}
          valid={isValid(this.state.usedComplaintValid)}
          onChange={() => {
            this.handleChange('usedComplaintDirty');
          }}
        />
        <SentimentSection
          name={SentimentLogComplaint}
          title={'Would the customer like to log a complaint?'}
          isRequired={true}
          form={this.props.form}
          options={SentimentLogComplaint_Options}
          disabled={this.props.form.values.SentimentReportOutcome === SentimentReportOutcome_Option_Escalation}
          valid={isValid(this.state.logComplaintValid)}
          onChange={() => {
            this.handleChange('logComplaintDirty');
          }}
        />
      </FormRow>
    );
  }
}

Sentiment.propTypes = {
  form: PropTypes.object,
};

export default Sentiment;
