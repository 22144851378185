import { ButtonRow, Heading, InfoBlock, Link, Step } from '../../../models';
import { INTERNAL, EXTERNAL, ODOUR, SLOW_DRAINAGE, NO, CALLBACK_1_URL, SLOW_DRAINING_URL } from '../../../../constants';
import { blockedDrainsInfo } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', blockedDrainsInfo.heading);
const blockageInfoHeading1 = new Heading('read-help-heading', blockedDrainsInfo.subheadings[0], 2)
  .setPriority(2)
  .setSize('md');
const blockageInfoBlock1 = new InfoBlock('read-help-block', blockedDrainsInfo.detail[0]);
const blockageInfoLink1 = new Link('read-help-link', blockedDrainsInfo.helpPages)
  .setState('primary')
  .setLink(SLOW_DRAINING_URL);
const blockageInfoHeading2 = new Heading('next-steps-heading', blockedDrainsInfo.subheadings[1], 2).setSize('md');
const blockageInfoBlock2 = new InfoBlock('next-steps-block', blockedDrainsInfo.detail[1]);
const callbackLink = new Link('callback-link', blockedDrainsInfo.callback).setLink(CALLBACK_1_URL);
const buttonsBlock = new ButtonRow().hideContinueButton();

const blockedDrainsInfoStep = new Step('step', 'blocked-drains');
blockedDrainsInfoStep.addBlock(headingBlock);
blockedDrainsInfoStep.addBlock(blockageInfoHeading1);
blockedDrainsInfoStep.addBlock(blockageInfoBlock1);
blockedDrainsInfoStep.addBlock(blockageInfoLink1);
blockedDrainsInfoStep.addBlock(blockageInfoHeading2);
blockedDrainsInfoStep.addBlock(blockageInfoBlock2);
blockedDrainsInfoStep.addBlock(callbackLink);
blockedDrainsInfoStep.addBlock(buttonsBlock);
blockedDrainsInfoStep.setIsContained(true);
blockedDrainsInfoStep.setTitle(blockedDrainsInfo.title);

blockedDrainsInfoStep.setConstraints({
  initialLocation: {
    inclusion: [EXTERNAL, INTERNAL],
  },
  initialProblem: {
    inclusion: [ODOUR, SLOW_DRAINAGE],
  },
  waterFromCover: {
    exclusion: [NO],
  },
});

export { blockedDrainsInfoStep };
