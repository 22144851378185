function Step(type, slug) {
  this.type = type;
  this.slug = slug;
  this.constraints = {};
  this.validation = {};
  this.title = null;
}

Step.prototype.setRedirectUrl = function(url) {
  this.redirectURL = url;
  return this;
};

Step.prototype.setConstraints = function(constraints) {
  this.constraints = constraints;
  return this;
};

Step.prototype.setTitle = function(title) {
  this.title = title;
  return this;
};

Step.prototype.setIsContained = function(bool) {
  this.isContained = bool;
  return this;
};

Step.prototype.setValidation = function(validation) {
  this.validation = validation;
  return this;
};

Step.prototype.addBlock = function(block) {
  if (this.blocks === undefined) {
    this.blocks = [block];
  } else {
    this.blocks.push(block);
  }
  return this;
};

export default Step;
