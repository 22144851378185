import { ButtonRow, Control, Heading, Step } from '../../models';
import {
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  COVER,
  DRAIN,
  GROUND,
  ODOUR,
} from '../../../constants';
import { wasteCoverBoundary, images } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteCoverBoundary.heading);
const coverTypeControl = new Control('question', 'wasteExternalCoverType');
coverTypeControl.setLabel(wasteCoverBoundary.heading);
coverTypeControl.setDefault('');
coverTypeControl.addOption(wasteCoverBoundary.grate, GRATE, null, false, false, images.gully);
coverTypeControl.addOption(wasteCoverBoundary.largeSquare, SQUARE, null, false, false, images.squareSewage);
coverTypeControl.addOption(wasteCoverBoundary.rectangle, SLUICE_VALVE, null, false, false, images.sluiceRectangle);
coverTypeControl.addOption(wasteCoverBoundary.smallSquare, STOPTAP, null, false, false, images.sluiceSquare);
coverTypeControl.addOption(wasteCoverBoundary.circle, METER, null, false, false, images.meter);
coverTypeControl.addOption(wasteCoverBoundary.other, OTHER, 'IconQuestion', false, false, null);
coverTypeControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteCoverBoundaryStep = new Step('step', 'waste-cover-boundary');
wasteCoverBoundaryStep.addBlock(headingBlock);
wasteCoverBoundaryStep.addBlock(coverTypeControl);
wasteCoverBoundaryStep.addBlock(buttonsBlock);
wasteCoverBoundaryStep.setTitle(wasteCoverBoundary.title);

wasteCoverBoundaryStep.setConstraints({
  initialLocationWaste: {
    inclusion: [EXTERNAL],
    exclusion: [INTERNAL, PUBLIC],
  },
  wasteOriginBoundary: {
    inclusion: [COVER],
    exclusion: [DRAIN, GROUND, ODOUR],
  },
});

export { wasteCoverBoundaryStep };
