import { Control, Heading, Step, ButtonRow, InfoBlock } from '../../models';
import {
  VALIDATION_TELEPHONE_REGEX,
  VALIDATION_EMAIL,
  LEAK_OR_FLOOD,
  YW_NOREPLY_EMAIL,
  YES,
  NO,
  GUID_REGEX,
  SOURCE_CLEAN,
  SOURCE_WASTE,
  SOURCE_NWLP,
} from '../../../constants';
import { contactDetails } from '../../../constants/Copy';
import NwlpContact from '../../models/NwlpContact';

const headingBlock = new Heading('step-title', contactDetails.heading);
const infoBlock = new InfoBlock('info', contactDetails.info);
const firstNameControl = new Control('text', 'firstName')
  .setLabel(contactDetails.firstName)
  .isRequired(true)
  .setCols(12)
  .setDefault('')
  .setMaxLength('30');
const lastNameControl = new Control('text', 'lastName')
  .setLabel(contactDetails.lastName)
  .setCols(12)
  .isRequired(true)
  .setDefault('')
  .setMaxLength('30');
const emailAddressControl = new Control('text', 'email')
  .setLabel(contactDetails.email)
  .setCols(12)
  .isRequired(true)
  .setDefault('')
  .setMaxLength('80');
const phoneNumberControl = new Control('text', 'telephone')
  .setLabel(contactDetails.phone)
  .setCols(4)
  .isRequired(true)
  .setDefault('')
  .setMaxLength('11');
const hiddenContactControl = new Control('hidden', 'text').setDefault('TELEPHONE');
const hiddenStepControl = new Control('hidden', 'initialProblem').setDefault(LEAK_OR_FLOOD);
const termsAndConditions = new Control('TermsAndConditions', 'TermsAndConditions');
const hiddenQueryStringController = new Control('hidden', 'mapSearch');
const buttonsBlock = new ButtonRow().hideBackButton();

const contactDetailsStep = new Step('step', 'personal-details');
contactDetailsStep.setIsContained(true);
contactDetailsStep.addBlock(new NwlpContact());
contactDetailsStep.addBlock(headingBlock);
contactDetailsStep.addBlock(infoBlock);
contactDetailsStep.addBlock(firstNameControl);
contactDetailsStep.addBlock(lastNameControl);
contactDetailsStep.addBlock(emailAddressControl);
contactDetailsStep.addBlock(phoneNumberControl);
contactDetailsStep.addBlock(termsAndConditions);
contactDetailsStep.addBlock(buttonsBlock);
contactDetailsStep.addBlock(hiddenContactControl);
contactDetailsStep.addBlock(hiddenStepControl);
contactDetailsStep.addBlock(hiddenQueryStringController);
contactDetailsStep.setTitle(contactDetails.title);

(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const name = urlParams.get('searchQuery');
  if (name) {
    hiddenQueryStringController.setDefault(name);
  }
  const source = urlParams.get('source');
  if (source !== null) {
    window.sessionStorage.setItem('source', source);
  }
  const altSource = window.sessionStorage.getItem('source');

  if (source || altSource) {
    if (source === SOURCE_CLEAN || altSource === SOURCE_CLEAN) {
      const isCleanIntial = new Control('hidden', 'isCleanIntial');
      isCleanIntial.setDefault(true);
      contactDetailsStep.addBlock(isCleanIntial);
    } else if (source === SOURCE_WASTE || altSource === SOURCE_WASTE) {
      const isWasteInitial = new Control('hidden', 'isWasteInitial');
      isWasteInitial.setDefault(true);
      contactDetailsStep.addBlock(isWasteInitial);
    } else if (source === SOURCE_NWLP || altSource === SOURCE_NWLP) {
      const isNwlpInitial = new Control('hidden', 'isNwlpInitial');
      isNwlpInitial.setDefault(true);
      contactDetailsStep.addBlock(isNwlpInitial);
    }
  }

  let colleagueMode = NO;
  window.sessionStorage.removeItem('token');
  let token = urlParams.get('token') || undefined;

  if (token !== null) {
    let regex = new RegExp(GUID_REGEX);
    if (regex.test(token)) {
      window.sessionStorage.setItem('token', token);
      emailAddressControl.setDefault(YW_NOREPLY_EMAIL);
      colleagueMode = YES;
    } else {
      token = undefined;
    }
  }
  const colleagueToken = new Control('hidden', 'colleagueToken');
  colleagueToken.setDefault(token);
  contactDetailsStep.addBlock(colleagueToken);
  const isColleagueMode = new Control('hidden', 'isColleagueMode');
  isColleagueMode.setDefault(colleagueMode);
  contactDetailsStep.addBlock(isColleagueMode);

  contactDetailsStep.addBlock(new Control('hidden', 'isAccessHours').setDefault(undefined));
  contactDetailsStep.addBlock(new Control('hidden', 'isWorkingHours').setDefault(undefined));
  contactDetailsStep.addBlock(new Control('hidden', 'isNoWaterEnabled').setDefault(undefined));
})();

contactDetailsStep.setConstraints([
  {
    initialLocation: {},
    initialProblem: {},
    supplyAffected: {},
    waterFromCover: {},
  },
  {
    initialLocation: {},
    contactPreference: {
      presence: true,
      exclusion: ['EMAIL', 'ANON'],
      inclusion: ['TELEPHONE', 'EMAIL'],
    },
  },
]);

contactDetailsStep.setValidation({
  firstName: {
    presence: { allowEmpty: false, message: contactDetails.validation.firstName },
  },
  lastName: {
    presence: { allowEmpty: false, message: contactDetails.validation.lastName },
  },
  email: {
    presence: { allowEmpty: false, message: contactDetails.validation.email },
    format: {
      pattern: new RegExp(VALIDATION_EMAIL),
      message: contactDetails.validation.emailFormat,
    },
  },
  telephone: {
    presence: { allowEmpty: false, message: contactDetails.validation.phone },
    format: {
      pattern: new RegExp(VALIDATION_TELEPHONE_REGEX),
      message: contactDetails.validation.phoneFormat,
    },
  },
  TermsAndConditions: {
    presence: { allowEmpty: false, message: contactDetails.validation.terms },
  },
});

export { contactDetailsStep };
