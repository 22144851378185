import { ButtonRow, Control, Heading, Hook, Step } from '../../../models';
import {
  APPLIANCE,
  BATHROOM,
  CELLAR,
  DIFFERENT,
  DOWNSTAIRS_BATH,
  DOWNSTAIRS_FACILITIES,
  DOWNSTAIRS_TOILET,
  EXTERNAL,
  FLOOR,
  HOUSE,
  INTERNAL,
  KITCHEN,
  MULTIPLE,
  MULTIPLE_TOILETS,
  ODOUR,
  PUBLIC,
  SAME,
  SINGLE,
  SINK,
  STEP_ACCESS_HOURS_LOOKUP,
  TOILET,
  UPSTAIRS_BATH,
  UPSTAIRS_FACILITIES,
  UPSTAIRS_TOILET,
} from '../../../../constants';
import { wasteInternalSame } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteInternalSame.heading);
const wasteInternalSameControl = new Control('question', 'wasteInternalSame');
wasteInternalSameControl.setLabel(wasteInternalSame.heading);
wasteInternalSameControl.setDefault('');
wasteInternalSameControl.addOption(wasteInternalSame.different, DIFFERENT, 'IconCross');
wasteInternalSameControl.addOption(wasteInternalSame.same, SAME, 'IconCheckTick');
wasteInternalSameControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteInternalSameStep = new Step('step', 'waste-internal-same');
wasteInternalSameStep.addBlock(headingBlock);
wasteInternalSameStep.addBlock(wasteInternalSameControl);
wasteInternalSameStep.addBlock(buttonsBlock);
wasteInternalSameStep.exitHook = new Hook(STEP_ACCESS_HOURS_LOOKUP, '');
wasteInternalSameStep.setTitle(wasteInternalSame.title);

wasteInternalSameStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [BATHROOM],
      exclusion: [HOUSE, ODOUR, KITCHEN],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [MULTIPLE],
      exclusion: [SINGLE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [MULTIPLE],
      exclusion: [SINGLE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [MULTIPLE],
      exclusion: [SINGLE],
    },
    wasteInternalSame: {
      inclusion: [DIFFERENT],
      exclusion: [SAME],
    },
  },
]);

export { wasteInternalSameStep };
