function RedirectButton(text, redirectUrl) {
  this.redirectUrl = redirectUrl;
  this.type = 'redirectButton';
  this.text = text;
}

RedirectButton.prototype.setConstraints = function(constraints) {
  this.constraints = constraints;
  return this;
};

export default RedirectButton;
