import { Step } from '../../../models';
import { INTERNAL, WATER_HARDNESS_URL } from '../../../../constants';

export const hardWaterRedirect = new Step('redirect', 'Hard Water Redirection', 'hard-water-redirect')
  .setRedirectUrl(WATER_HARDNESS_URL)
  .setConstraints({
    initialLocation: {
      presence: true,
      inclusion: [INTERNAL],
    },
    waterQualityIssue: {
      presence: true,
      inclusion: ['HARD_WATER'],
    },
  });
