import { ButtonRow, Control, Heading, Hook, Step } from '../../../models';
import {
  YES,
  NO,
  STEP_ACCESS_HOURS_LOOKUP,
  APPLIANCE,
  BATHROOM,
  CELLAR,
  DOWNSTAIRS_BATH,
  DOWNSTAIRS_FACILITIES,
  DOWNSTAIRS_TOILET,
  EXTERNAL,
  FLOOR,
  GRATE,
  HOUSE,
  INTERNAL,
  KITCHEN,
  MANHOLE,
  MANY,
  MULTIPLE_TOILETS,
  NONE,
  ODOUR,
  ONCE,
  PUBLIC,
  SINK,
  TOILET,
  UPSTAIRS_BATH,
  UPSTAIRS_FACILITIES,
  UPSTAIRS_TOILET,
  SINGLE,
  MULTIPLE,
  DIFFERENT,
  SAME,
  BLOCKED,
  CLEAR,
  UNSURE,
  GROUND_FLOOR,
  GARAGE,
  OTHER,
  UPSTAIRS,
  SLOW_DRAINAGE,
  CHUTE,
  AIRBRICK,
  PIPE,
  CONSTANT,
  INTERMITTENT,
} from '../../../../constants';
import { wasteTimedAccessToday } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteTimedAccessToday.heading);
const wasteTimedAccessTodayControl = new Control('question', 'wasteTimedAccessToday');
wasteTimedAccessTodayControl.setLabel(wasteTimedAccessToday.heading);
wasteTimedAccessTodayControl.setDefault('');
wasteTimedAccessTodayControl.addOption(wasteTimedAccessToday.yes, YES, 'IconCheckTick');
wasteTimedAccessTodayControl.addOption(wasteTimedAccessToday.no, NO, 'IconCross');
wasteTimedAccessTodayControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteTimedAccessTodayStep = new Step('step', 'waste-timed-access-today');
wasteTimedAccessTodayStep.addBlock(headingBlock);
wasteTimedAccessTodayStep.addBlock(wasteTimedAccessTodayControl);
wasteTimedAccessTodayStep.addBlock(buttonsBlock);
wasteTimedAccessTodayStep.setIsContained(false);
wasteTimedAccessTodayStep.exitHook = new Hook(STEP_ACCESS_HOURS_LOOKUP, '');
wasteTimedAccessTodayStep.setTitle(wasteTimedAccessToday.title);

wasteTimedAccessTodayStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_TOILET],
      exclusion: [
        UPSTAIRS_BATH,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalExtother: {
      inclusion: [GRATE, MANHOLE, ODOUR],
      exclusion: [NO],
    },
    wasteInternalToilet: {
      inclusion: [ONCE, NONE],
      exclusion: [MANY],
    },
    isAccessHours: {
      presence: true,
      inclusion: [true],
      exclusion: [false],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [SINGLE],
      exclusion: [MULTIPLE],
    },
    wasteInternalExtother: {
      inclusion: [GRATE, MANHOLE, ODOUR],
      exclusion: [NO],
    },
    isAccessHours: {
      presence: true,
      inclusion: [true],
      exclusion: [false],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [MULTIPLE],
      exclusion: [SINGLE],
    },
    wasteInternalSame: {
      inclusion: [SAME],
      exclusion: [DIFFERENT],
    },
    isAccessHours: {
      presence: true,
      inclusion: [true],
      exclusion: [false],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [MULTIPLE],
      exclusion: [SINGLE],
    },
    wasteInternalSame: {
      inclusion: [DIFFERENT],
      exclusion: [SAME],
    },
    wasteInternalExtother: {
      inclusion: [GRATE, MANHOLE, ODOUR],
      exclusion: [NO],
    },
    isAccessHours: {
      presence: true,
      inclusion: [true],
      exclusion: [false],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [MULTIPLE_TOILETS, DOWNSTAIRS_TOILET],
      exclusion: [UPSTAIRS_TOILET, DOWNSTAIRS_BATH, UPSTAIRS_BATH, UPSTAIRS_FACILITIES, DOWNSTAIRS_FACILITIES],
    },
    wasteInternalToilet: {
      inclusion: [ONCE, NONE],
      exclusion: [MANY],
    },
    isAccessHours: {
      presence: true,
      inclusion: [true],
      exclusion: [false],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [DOWNSTAIRS_BATH, DOWNSTAIRS_FACILITIES, UPSTAIRS_FACILITIES],
      exclusion: [MULTIPLE_TOILETS, DOWNSTAIRS_TOILET, UPSTAIRS_BATH, UPSTAIRS_TOILET],
    },
    isAccessHours: {
      presence: true,
      inclusion: [true],
      exclusion: [false],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [APPLIANCE],
      exclusion: [TOILET, SINK, FLOOR, CELLAR],
    },
    wasteInternalSink: {
      inclusion: [BLOCKED],
      exclusion: [CLEAR],
    },
    wasteInternalExtdrain: {
      inclusion: [BLOCKED, UNSURE],
      exclusion: [CLEAR],
    },
    isAccessHours: {
      presence: true,
      inclusion: [true],
      exclusion: [false],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [SINK],
      exclusion: [TOILET, APPLIANCE, FLOOR, CELLAR],
    },
    wasteInternalExtdrain: {
      inclusion: [BLOCKED, UNSURE],
      exclusion: [CLEAR],
    },
    isAccessHours: {
      presence: true,
      inclusion: [true],
      exclusion: [false],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [FLOOR, CELLAR],
      exclusion: [TOILET, APPLIANCE, SINK],
    },
    wasteInternalArea: {
      inclusion: [GROUND_FLOOR, GARAGE, OTHER, UPSTAIRS, CELLAR],
      exclusion: [],
    },
    wasteInternalOther: {
      inclusion: [BLOCKED, MANHOLE, ODOUR, SLOW_DRAINAGE],
      exclusion: [NONE],
    },
    isAccessHours: {
      presence: true,
      inclusion: [true],
      exclusion: [false],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [FLOOR, CELLAR],
      exclusion: [TOILET, APPLIANCE, SINK],
    },
    wasteInternalArea: {
      inclusion: [CELLAR],
      exclusion: [GROUND_FLOOR, GARAGE, OTHER, UPSTAIRS],
    },
    wasteInternalOther: {
      inclusion: [NONE],
      exclusion: [BLOCKED, MANHOLE, ODOUR, SLOW_DRAINAGE],
    },
    wasteInternalSource: {
      inclusion: [OTHER],
      exclusion: [CHUTE, AIRBRICK, PIPE],
    },
    wasteInternalText: {
      presence: true,
    },
    isAccessHours: {
      presence: true,
      inclusion: [true],
      exclusion: [false],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [FLOOR, CELLAR],
      exclusion: [TOILET, APPLIANCE, SINK],
    },
    wasteInternalArea: {
      inclusion: [CELLAR],
      exclusion: [GROUND_FLOOR, GARAGE, OTHER, UPSTAIRS],
    },
    wasteInternalOther: {
      inclusion: [NONE],
      exclusion: [BLOCKED, MANHOLE, ODOUR, SLOW_DRAINAGE],
    },
    wasteInternalSource: {
      inclusion: [PIPE],
      exclusion: [CHUTE, AIRBRICK, OTHER],
    },
    wasteInternalFreq: {
      inclusion: [CONSTANT],
      exclusion: [INTERMITTENT],
    },
    isAccessHours: {
      presence: true,
      inclusion: [true],
      exclusion: [false],
    },
  },
]);

export { wasteTimedAccessTodayStep };
