function DateTimeSelect(name) {
  this.type = 'dateTimeSelect';
  this.setMaxTime = true;
  this.name = name;
}

DateTimeSelect.prototype.setConstraints = function(constraints) {
  this.constraints = constraints;
  return this;
};

export default DateTimeSelect;
