import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ChoiceTile, Col, Row } from 'yw-ui.v2';

const ChoiceBlock = ({ name, options, stepForward }) => {
  const handleClick = (value) => {
    let values = {};
    values[name] = value;
    stepForward(values);
  };

  let colSpanSm = 6;
  let colSpanMd = options.length === 3 ? 4 : 6;

  return (
    <Fragment>
      {options && (
        <Row>
          {options.map((option, i) => (
            <Col key={i} sm={colSpanSm} md={colSpanMd}>
              <ChoiceTile
                label={option.label}
                name={name}
                icon={option.icon}
                image={option.image}
                id={option.value}
                qa={option.value}
                onClick={handleClick}
              />
            </Col>
          ))}
        </Row>
      )}
    </Fragment>
  );
};

ChoiceBlock.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  stepForward: PropTypes.func.isRequired,
  validStep: PropTypes.object.isRequired,
};

export default ChoiceBlock;
