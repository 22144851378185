import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import InfoBlock from '../InfoBlock';
import { SOURCE_NWLP, nwlpContactBanner } from '../../../constants';
import timeApi from '../../../api/time';

export class NwlpContactBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNwlpJourney: window.sessionStorage.getItem('source') === SOURCE_NWLP,
      isNwlpHours: null,
      loading: true,
      error: null,
    };
  }

  async componentDidMount() {
    if (this.state.isNwlpJourney === true) {
      try {
        let isNwlpHours;
        await timeApi.isNoWaterHours((data) => {
          isNwlpHours = data;
        });
        this.setState({ isNwlpHours, loading: false });
      } catch (e) {
        this.setState({ loading: false });
      }
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <Fragment>
        {this.state.loading === false && this.state.isNwlpHours === false && (
          <>
            <div className="c-message c-message--warning">
              <h2 className="nwlpcontact_title">{nwlpContactBanner.header}</h2>
              <InfoBlock text={nwlpContactBanner.infos[0]} />
              <InfoBlock text={nwlpContactBanner.infos[1]} />
            </div>
            <br />
          </>
        )}
      </Fragment>
    );
  }
}

NwlpContactBlock.propTypes = {
  form: PropTypes.object.isRequired,
};

export default NwlpContactBlock;
