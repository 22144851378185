import { ButtonRow, Control, Heading, Step } from '../../models';
import {
  YES,
  NO,
  UNSURE,
  WASTE,
  CLEAN,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  OTHER,
  COVER,
  GROUND,
  ODOUR,
  POLLUTION,
} from '../../../constants';
import { externalWaterSmell } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', externalWaterSmell.heading);
const waterSmellControl = new Control('question', 'externalWaterSmell');
waterSmellControl.setLabel(externalWaterSmell.heading);
waterSmellControl.addOption(externalWaterSmell.yes, YES, 'IconBadSmell');
waterSmellControl.addOption(externalWaterSmell.no, NO, 'IconCross');
waterSmellControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const externalWaterSmellStep = new Step('step', 'external-water-smell');
externalWaterSmellStep.addBlock(headingBlock);
externalWaterSmellStep.addBlock(waterSmellControl);
externalWaterSmellStep.addBlock(buttonsBlock);
externalWaterSmellStep.setTitle(externalWaterSmell.title);

externalWaterSmellStep.setConstraints([
  {
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [UNSURE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
    },
    externalConstantPressure: {
      presence: true,
      inclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      inclusion: [NO],
      exclusion: [YES],
    },
  },
]);

export { externalWaterSmellStep };
