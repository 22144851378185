import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  APPLIANCE,
  BATHROOM,
  CELLAR,
  EXTERNAL,
  FLOOR,
  HOUSE,
  INTERNAL,
  KITCHEN,
  ODOUR,
  PUBLIC,
  SINK,
  TOILET,
} from '../../../../constants';
import { wasteInternalEntry } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteInternalEntry.heading);
const wasteInternalEntryControl = new Control('question', 'wasteInternalEntry');
wasteInternalEntryControl.setLabel(wasteInternalEntry.heading);
wasteInternalEntryControl.setDefault('');
wasteInternalEntryControl.addOption(wasteInternalEntry.toilet, TOILET, 'IconToilet');
wasteInternalEntryControl.addOption(wasteInternalEntry.appliance, APPLIANCE, 'IconWashingMachine2');
wasteInternalEntryControl.addOption(wasteInternalEntry.sink, SINK, 'IconSink');
wasteInternalEntryControl.addOption(wasteInternalEntry.floor, FLOOR, 'IconFloodHouseInternal');
wasteInternalEntryControl.addOption(wasteInternalEntry.cellar, CELLAR, 'IconCellar');
wasteInternalEntryControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteInternalEntryStep = new Step('step', 'waste-internal-entry');
wasteInternalEntryStep.addBlock(headingBlock);
wasteInternalEntryStep.addBlock(wasteInternalEntryControl);
wasteInternalEntryStep.addBlock(buttonsBlock);
wasteInternalEntryStep.setIsContained(false);
wasteInternalEntryStep.setTitle(wasteInternalEntry.title);

wasteInternalEntryStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
  },
]);

export { wasteInternalEntryStep };
