function Control(type, name, className) {
  this.type = type;
  this.name = name;
  this.default = '';
  this.hint = null;
  this.hideLabel = false;
  this.className = className;
}

Control.prototype.setLabel = function(label) {
  this.label = label;
  return this;
};

Control.prototype.setMaxLength = function(max) {
  this.maxLength = max;
  return this;
};

Control.prototype.setHint = function(hint) {
  this.hint = hint;
  return this;
};

Control.prototype.setChars = function(chars) {
  this.chars = chars;
  return this;
};

Control.prototype.setCols = function(cols) {
  this.cols = cols;
  return this;
};

Control.prototype.isRequired = function(bool) {
  this.isRequiredMarker = bool;
  return this;
};

Control.prototype.isCollapsed = function(bool) {
  this.collapse = bool;
  return this;
};

Control.prototype.isCondensed = function(bool) {
  this.condensed = bool;
  return this;
};

Control.prototype.isRequired = function(bool) {
  this.isRequiredMarker = bool;
  return this;
};

Control.prototype.setDefault = function(defaultValue) {
  this.default = defaultValue;
  return this;
};

Control.prototype.setClassName = function(className) {
  this.className = className;
  return this;
};

Control.prototype.labelOff = function() {
  this.hideLabel = true;
  return this;
};

Control.prototype.addOption = function(label, value, icon, isExclusive, readable, image) {
  const option = {
    label: label,
    value: value,
    id: value,
    icon: icon,
    image: image,
    isExclusive: isExclusive,
    readable: readable,
  };

  if (this.options === undefined) {
    this.options = [option];
  } else {
    this.options.push(option);
  }
  return this;
};

export default Control;
