import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  YES,
  NO,
  UNSURE,
  CLEAN,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  GUSHING,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  COVER,
  OVERFLOW_PIPE,
  GROUND,
  TRICKLING,
} from '../../../../constants';
import { cleanCoverSeverity } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', cleanCoverSeverity.heading);
const cleanCoverSeverityControl = new Control('question', 'cleanCoverSeverity');
cleanCoverSeverityControl.setLabel(cleanCoverSeverity.heading);
cleanCoverSeverityControl.addOption(cleanCoverSeverity.yes, GUSHING, 'IconGushing', false, GUSHING);
cleanCoverSeverityControl.addOption(cleanCoverSeverity.no, TRICKLING, 'IconCross', false, TRICKLING);
cleanCoverSeverityControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const cleanCoverSeverityStep = new Step('step', 'clean-cover-severity');
cleanCoverSeverityStep.addBlock(headingBlock);
cleanCoverSeverityStep.addBlock(cleanCoverSeverityControl);
cleanCoverSeverityStep.addBlock(buttonsBlock);
cleanCoverSeverityStep.setTitle(cleanCoverSeverity.title);

cleanCoverSeverityStep.setConstraints([
  {
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [UNSURE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
    },
    externalConstantPressure: {
      inclusion: [NO],
    },
    externalWaterSmell: {
      inclusion: [NO],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [UNSURE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
    },
    externalConstantPressure: {
      inclusion: [YES],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [CLEAN],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
      exclusion: [OVERFLOW_PIPE, METER, GROUND],
    },
    externalCoverType: {
      inclusion: [STOPTAP, METER, SLUICE_VALVE],
      exclusion: [GRATE, SQUARE, OTHER],
    },
  },
]);

export { cleanCoverSeverityStep };
