import { Step } from '../../../models';
import { EXTERNAL, PUBLIC, DAMAGED_COVER, NO, MANHOLE_DRAIN_URL } from '../../../../constants';

const damagedCoverRedirect = new Step('redirect', 'damaged-cover-redirect');
damagedCoverRedirect.setRedirectUrl(MANHOLE_DRAIN_URL);
damagedCoverRedirect.setConstraints({
  initialLocation: {
    inclusion: [EXTERNAL, PUBLIC],
  },
  initialProblem: {
    presence: true,
    inclusion: [DAMAGED_COVER],
  },
  waterFromCover: {
    inclusion: [NO],
  },
});

export { damagedCoverRedirect };
