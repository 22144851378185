import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactStringReplace from 'react-string-replace-recursively';
import validate from 'validate.js';
import ReactMarkdown from 'react-markdown';
import { Button, Message } from 'yw-ui.v2';

export const MessageBlock = ({ id, message, state, nextSteps, stepTo, answers, renderHtml }) => {
  const renderNextStepLink = (processed, params, key, stepTo) => {
    const qaIdentifier = 'messageStep - ' + key;

    return (
      <Button
        id={qaIdentifier}
        qa={qaIdentifier}
        key={key}
        className="c-button--link"
        onClick={() => stepTo(params || {})}
      >
        {processed}
      </Button>
    );
  };

  let newNextSteps;

  if (nextSteps) {
    newNextSteps = nextSteps.map(({ stepForwardParams, stepForwardText, text }) => {
      const parsedText = validate.format(text, answers);
      const stepForwardRegEx = new RegExp('(' + stepForwardText + ')', 'gi');
      const config = {
        searchTerm: {
          pattern: stepForwardRegEx,
          matcherFn: (rawText, processed, key) => renderNextStepLink(processed, stepForwardParams, key, stepTo),
        },
      };

      if (stepForwardParams && stepForwardText) {
        return ReactStringReplace(config)(parsedText);
      } else {
        return text;
      }
    });
  }

  return (
    <Fragment>
      <Message id={id} qa={id} state={state} className={`u-mb--200 c-message--${state}`}>
        <ReactMarkdown source={message} escapeHtml={false} />
        {newNextSteps && newNextSteps.length > 0 && (
          <ul>
            {newNextSteps.map((step, i) =>
              renderHtml === true ? (
                <li key={`message-step-${i}`} dangerouslySetInnerHTML={{ __html: step }} />
              ) : (
                <li key={`message-step-${i}`}>{step}</li>
              ),
            )}
          </ul>
        )}
      </Message>
    </Fragment>
  );
};

MessageBlock.propTypes = {
  answers: PropTypes.object,
  stepTo: PropTypes.func,
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  nextSteps: PropTypes.array,
  state: PropTypes.string,
  renderHtml: PropTypes.bool,
};

MessageBlock.defaultProps = {
  nextSteps: [],
  state: 'info',
  renderHtml: false,
};

export default MessageBlock;
