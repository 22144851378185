import { Heading, Step, ButtonRow, InfoBlock, Control, Banner } from '../../../models';
import { YES, NO, EXTERNAL, COVER, OVERFLOW_PIPE, GROUND, METER } from '../../../../constants';
import { cleanSupplyInfo, privateIssueBanner, privateIssueQuestion, reviewForCoC } from '../../../../constants/Copy';

const banner = new Banner(privateIssueBanner);
const headingBlock = new Heading('step-heading', cleanSupplyInfo.heading);
const infoBlock = new InfoBlock('info-block', cleanSupplyInfo.detail[0]);
const infoBlock2 = new InfoBlock('info-block2', cleanSupplyInfo.detail[1]);
const infoBlock3 = new InfoBlock('info-block3', cleanSupplyInfo.detail[2]);
const infoBlock4 = new InfoBlock('info-block4', cleanSupplyInfo.detail[3]);
const infoBlock5 = new InfoBlock('info-block5', cleanSupplyInfo.detail[4]);
const infoBlock6 = new InfoBlock('info-block6', cleanSupplyInfo.detail[5]);
const buttonsBlock = new ButtonRow();

const cleanSupplyInfoColStep = new Step('step', 'clean-supply-info-col');
cleanSupplyInfoColStep.setIsContained(true);
cleanSupplyInfoColStep.addBlock(new Control('hidden', 'cleanSupplyInfoCol').setDefault(true));
cleanSupplyInfoColStep.addBlock(
  new Control('hidden', 'cleanSupplyInfoColTxt').setLabel(privateIssueQuestion).setDefault(reviewForCoC),
);
cleanSupplyInfoColStep.addBlock(banner);
cleanSupplyInfoColStep.addBlock(headingBlock);
cleanSupplyInfoColStep.addBlock(infoBlock);
cleanSupplyInfoColStep.addBlock(infoBlock2);
cleanSupplyInfoColStep.addBlock(infoBlock3);
cleanSupplyInfoColStep.addBlock(infoBlock4);
cleanSupplyInfoColStep.addBlock(infoBlock5);
cleanSupplyInfoColStep.addBlock(infoBlock6);
cleanSupplyInfoColStep.addBlock(buttonsBlock);
cleanSupplyInfoColStep.setTitle(cleanSupplyInfo.title);

cleanSupplyInfoColStep.setConstraints({
  initialLocation: {
    inclusion: [EXTERNAL],
  },
  externalWaterOrigin: {
    presence: true,
    inclusion: [GROUND],
    exclusion: [COVER, METER, OVERFLOW_PIPE],
  },
  eligibility: {
    presence: true,
    inclusion: [NO],
    exclusion: [YES],
  },
  isColleagueMode: {
    presence: true,
    inclusion: [YES],
    exclusion: [NO],
  },
});

export { cleanSupplyInfoColStep };
