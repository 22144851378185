import { Control, Heading, Step, ButtonRow } from '../../models';
import {
  CONSTANT,
  INTERMITTENT,
  PRESSURE,
  NO_WATER,
  HIGH_PRESSURE,
  LOW_PRESSURE,
  YES,
  NO,
  UNSURE,
} from '../../../constants';
import { nwlpProblemFrequency } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', nwlpProblemFrequency.heading);
const nwlpProblemFrequencyControl = new Control('question', 'nwlpProblemFrequency');
nwlpProblemFrequencyControl.setLabel(nwlpProblemFrequency.heading);
nwlpProblemFrequencyControl.addOption(nwlpProblemFrequency.constant, CONSTANT, 'Icon24Hours');
nwlpProblemFrequencyControl.addOption(nwlpProblemFrequency.intermittent, INTERMITTENT, 'IconTime');
const buttonsBlock = new ButtonRow().hideContinueButton();

const nwlpProblemFrequencyStep = new Step('step', 'nwlp-problem-frequency');
nwlpProblemFrequencyStep.addBlock(headingBlock);
nwlpProblemFrequencyStep.addBlock(nwlpProblemFrequencyControl);
nwlpProblemFrequencyStep.addBlock(buttonsBlock);
nwlpProblemFrequencyStep.setTitle(nwlpProblemFrequency.title);

nwlpProblemFrequencyStep.setConstraints({
  nwlpInitialProblem: {
    inclusion: [PRESSURE],
    exclusion: [NO_WATER],
  },
  nwlpPressureProblem: {
    inclusion: [HIGH_PRESSURE],
    exclusion: [LOW_PRESSURE],
  },
  nwlpProblemNeighbour: {
    inclusion: [YES, NO, UNSURE],
  },
});

export { nwlpProblemFrequencyStep };
