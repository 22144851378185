import { Accordion, ButtonRow, Heading, Message, Step, Control, InfoBlock } from '../../../models';
import { INTERNAL, EXTERNAL, PUBLIC, ODOUR, WATER_SUPPLY, SLOW_DRAINAGE, NO, YES } from '../../../../constants';
import { uploadPage } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', uploadPage.heading);
const infoBlock = new InfoBlock('step-info', uploadPage.subHeader);
const hintBlockPublic = new Message('step-hint', uploadPage.calloutBoxTop).setState('info');
const safetyBlockPublic = new Message('step-warning', uploadPage.calloutBoxSafety).setState('warning');
const tipsBlock = new Accordion('accordion-photos', uploadPage.accordionHeader).setContent(uploadPage.accordionBody);
const uploadBlock = {
  id: 'image-upload',
  type: 'fileUpload',
  name: 'attachments',
  ctaText: uploadPage.buttonText,
};
const buttonsBlock = new ButtonRow();
const buttonsBlockDisabled = new ButtonRow();
buttonsBlockDisabled.setContinueButtonDisabled(true);
buttonsBlockDisabled.setContinueButtonText('Uploading');
buttonsBlockDisabled.setContinueButtonState('default');
buttonsBlockDisabled.setConstraints({
  'attachments.isUploading': {
    presence: true,
    inclusion: [true],
  },
});
const photoControl = new Control('hidden', 'photoStep').setDefault(YES);

const photoStep = new Step('step', 'photos');
photoStep.addBlock(headingBlock);
photoStep.addBlock(infoBlock);
photoStep.addBlock(hintBlockPublic);
photoStep.addBlock(safetyBlockPublic);
photoStep.addBlock(tipsBlock);
photoStep.addBlock(uploadBlock);
photoStep.addBlock(buttonsBlock);
photoStep.addBlock(buttonsBlockDisabled);
photoStep.addBlock(photoControl);
photoStep.setIsContained(true);
photoStep.setTitle('Photos');

buttonsBlock.setConstraints({
  'attachments.isUploading': {
    inclusion: [false],
    exclusion: [true],
  },
});

photoStep.setConstraints({
  initialLocation: {
    inclusion: [PUBLIC],
    exclusion: [EXTERNAL, INTERNAL],
  },
  initialProblem: {
    exclusion: [WATER_SUPPLY, SLOW_DRAINAGE, ODOUR],
  },
  waterFromCover: {
    exclusion: [NO],
  },
  rtimInformationCol: {
    presence: false,
    exclusion: [true],
  },
});

export { photoStep };
