import { Control, Heading, Step, ButtonRow } from '../../../models';
import {
  YES,
  NO,
  UNSURE,
  METER,
  PIPE_MEETS_METER,
  STOPTAP,
  INSIDE,
  OUTSIDE,
  FAST_DRIP,
  GUSHING,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  COVER,
  GROUND,
  OVERFLOW_PIPE,
  SLOW_DRIP,
  STEADY_FLOW,
} from '../../../../constants';
import { cleanMeterRiskInternal } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', cleanMeterRiskInternal.heading);
const cleanMeterRiskIntControl = new Control('question', 'cleanMeterRiskInt');
cleanMeterRiskIntControl.setLabel(cleanMeterRiskInternal.heading);
cleanMeterRiskIntControl.addOption(cleanMeterRiskInternal.yes, YES, 'IconFloodHouseInternal');
cleanMeterRiskIntControl.addOption(cleanMeterRiskInternal.no, NO, 'IconCross');
const buttonsBlock = new ButtonRow().hideContinueButton();

const cleanMeterRiskInternalStep = new Step('step', 'clean-meter-risk-int');
cleanMeterRiskInternalStep.addBlock(headingBlock);
cleanMeterRiskInternalStep.addBlock(cleanMeterRiskIntControl);
cleanMeterRiskInternalStep.addBlock(buttonsBlock);
cleanMeterRiskInternalStep.setTitle(cleanMeterRiskInternal.title);

cleanMeterRiskInternalStep.setConstraints([
  {
    initialLocation: {
      inclusion: [INTERNAL],
      exclusion: [PUBLIC, EXTERNAL],
    },
    internalWaterOrigin: {
      presence: true,
      inclusion: [METER],
    },
    cleanMeterInternal: {
      inclusion: [METER, PIPE_MEETS_METER, UNSURE],
      exclusion: [STOPTAP],
    },
    cleanMeterSeverityInt: {
      presence: true,
      inclusion: [SLOW_DRIP, FAST_DRIP, STEADY_FLOW, GUSHING],
    },
  },
  {
    cleanMeterSeverityInt: {
      presence: true,
      inclusion: [SLOW_DRIP, FAST_DRIP, STEADY_FLOW, GUSHING],
    },
    cleanCondenConfirm: {
      inclusion: [YES],
    },
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      presence: true,
      inclusion: [METER],
      exclusion: [COVER, OVERFLOW_PIPE, GROUND],
    },
    cleanMeterLeaking: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    cleanMeterLocation: {
      presence: true,
      inclusion: [INSIDE],
      exclusion: [OUTSIDE],
    },
  },
]);

export { cleanMeterRiskInternalStep };
