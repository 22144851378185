import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  CONSTANT,
  INTERMITTENT,
  INSIDE,
  INSIDE_OUTSIDE,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  HOUSE,
  ODOUR,
  DRAIN,
  BLOCKED,
  BOTH,
  NONE,
  FARM,
  CHEMICAL,
  GAS,
  APPLIANCE,
  SHOWER_TOILET_SINK,
  OTHER,
  UNDER_KITCHEN_SINK,
  UPSTAIRS,
  MULTIPLE_ROOMS,
  ONE_ROOM,
  WHOLE_PROPERTY,
  GROUND_FLOOR,
  CELLAR,
  COVER,
  GROUND,
  POLLUTION,
  BATHROOM,
  KITCHEN,
} from '../../../../constants';
import { wasteOdourFrequency } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteOdourFrequency.heading);
const wasteOdourFrequencyControl = new Control('question', 'wasteOdourFrequency');
wasteOdourFrequencyControl.setLabel(wasteOdourFrequency.heading);
wasteOdourFrequencyControl.setDefault('');
wasteOdourFrequencyControl.addOption(wasteOdourFrequency.constant, CONSTANT, 'Icon24Hours');
wasteOdourFrequencyControl.addOption(wasteOdourFrequency.intermittent, INTERMITTENT, 'IconTime');
wasteOdourFrequencyControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteOdourFrequencyStep = new Step('step', 'waste-odour-frequency');
wasteOdourFrequencyStep.addBlock(headingBlock);
wasteOdourFrequencyStep.addBlock(wasteOdourFrequencyControl);
wasteOdourFrequencyStep.addBlock(buttonsBlock);
wasteOdourFrequencyStep.setIsContained(false);
wasteOdourFrequencyStep.setTitle(wasteOdourFrequency.title);

wasteOdourFrequencyStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [ODOUR],
      exclusion: [HOUSE, BATHROOM, KITCHEN],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [NONE],
      exclusion: [DRAIN, BLOCKED, BOTH],
    },
    wasteOdourOutside: {
      inclusion: [INSIDE_OUTSIDE],
      exclusion: [INSIDE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [ODOUR],
      exclusion: [HOUSE, BATHROOM, KITCHEN],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [NONE],
      exclusion: [DRAIN, BLOCKED, BOTH],
    },
    wasteOdourOutside: {
      inclusion: [INSIDE],
      exclusion: [INSIDE_OUTSIDE],
    },
    wasteOdourSource: {
      inclusion: [OTHER],
      exclusion: [APPLIANCE, SHOWER_TOILET_SINK, UNDER_KITCHEN_SINK],
    },
    wasteOdourLocation: {
      inclusion: [WHOLE_PROPERTY, GROUND_FLOOR, CELLAR],
      exclusion: [UPSTAIRS, MULTIPLE_ROOMS, ONE_ROOM],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [ODOUR],
      exclusion: [COVER, GROUND, DRAIN],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [NONE],
      exclusion: [DRAIN, BLOCKED, BOTH],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [NONE],
      exclusion: [DRAIN, BLOCKED, BOTH],
    },
  },
]);

export { wasteOdourFrequencyStep };
