import { Control, Heading, Step, ButtonRow } from '../../../models';
import {
  INSIDE,
  OUTSIDE,
  BOTH,
  NONE,
  PROPERTY_DAMAGE,
  WORSENING,
  EXTERNAL,
  NO,
  YES,
  COVER,
  GROUND,
  METER,
  OVERFLOW_PIPE,
} from '../../../../constants';
import { cleanMeterSeverityExt } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', cleanMeterSeverityExt.heading);
const cleanMeterSeverityExtControl = new Control('question', 'cleanMeterSeverityExt');
cleanMeterSeverityExtControl.setLabel(cleanMeterSeverityExt.heading);
cleanMeterSeverityExtControl.addOption(cleanMeterSeverityExt.propertyRisk, PROPERTY_DAMAGE, 'IconHouse');
cleanMeterSeverityExtControl.addOption(cleanMeterSeverityExt.worsening, WORSENING, 'IconLargeArea');
cleanMeterSeverityExtControl.addOption(cleanMeterSeverityExt.both, BOTH, 'IconPropertyAccess');
cleanMeterSeverityExtControl.addOption(cleanMeterSeverityExt.none, NONE, 'IconCross');
const buttonsBlock = new ButtonRow().hideContinueButton();

const cleanMeterSeverityExtStep = new Step('step', 'clean-meter-severity-ext');
cleanMeterSeverityExtStep.addBlock(headingBlock);
cleanMeterSeverityExtStep.addBlock(cleanMeterSeverityExtControl);
cleanMeterSeverityExtStep.addBlock(buttonsBlock);
cleanMeterSeverityExtStep.setTitle(cleanMeterSeverityExt.title);

cleanMeterSeverityExtStep.setConstraints({
  initialLocation: {
    inclusion: [EXTERNAL],
  },
  externalWaterOrigin: {
    presence: true,
    inclusion: [METER],
    exclusion: [COVER, OVERFLOW_PIPE, GROUND],
  },
  cleanMeterLeaking: {
    presence: true,
    inclusion: [YES],
    exclusion: [NO],
  },
  cleanMeterLocation: {
    presence: true,
    inclusion: [OUTSIDE],
    exclusion: [INSIDE],
  },
});

export { cleanMeterSeverityExtStep };
