import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  PUBLIC,
  INTERNAL,
  EXTERNAL,
  COVER,
  OVERFLOW_PIPE,
  GROUND,
} from '../../../../constants';
import { cleanCoverBoundary, images } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', cleanCoverBoundary.heading);
const coverTypeControl = new Control('question', 'externalCoverType');
coverTypeControl.setLabel(cleanCoverBoundary.heading);
coverTypeControl.setDefault('');
coverTypeControl.addOption(cleanCoverBoundary.grate, GRATE, null, false, false, images.gully);
coverTypeControl.addOption(cleanCoverBoundary.largeSquare, SQUARE, null, false, false, images.squareSewage);
coverTypeControl.addOption(cleanCoverBoundary.rectangle, SLUICE_VALVE, null, false, false, images.sluiceRectangle);
coverTypeControl.addOption(cleanCoverBoundary.smallSquare, STOPTAP, null, false, false, images.sluiceSquare);
coverTypeControl.addOption(cleanCoverBoundary.circle, METER, null, false, false, images.meter);
coverTypeControl.addOption(cleanCoverBoundary.other, OTHER, 'IconQuestion', false, false, null);
coverTypeControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const cleanCoverBoundaryStep = new Step('step', 'clean-cover-boundary');
cleanCoverBoundaryStep.addBlock(headingBlock);
cleanCoverBoundaryStep.addBlock(coverTypeControl);
cleanCoverBoundaryStep.addBlock(buttonsBlock);
cleanCoverBoundaryStep.setTitle(cleanCoverBoundary.title);

cleanCoverBoundaryStep.setConstraints({
  initialLocation: {
    inclusion: [EXTERNAL],
    exclusion: [PUBLIC, INTERNAL],
  },
  externalWaterOrigin: {
    inclusion: [COVER],
    exclusion: [OVERFLOW_PIPE, METER, GROUND],
  },
});

export { cleanCoverBoundaryStep };
