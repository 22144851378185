import { ButtonRow, Control, Heading, InfoBlock, Step } from '../../models';
import { FIRST_FEED, NO, NOT_FIRST_FEED, NO_WATER, PRESSURE, UNSURE, YES } from '../../../constants';
import { nwlpNoticeDateTime } from '../../../constants/Copy';
import DateTimeSelect from '../../models/DateTimeSelect';

const nwlpNoticeDateTimeControl = new Control('hidden', 'nwlpNoticeDateTime');
const dateTimeSelect = new DateTimeSelect('nwlpNoticeDateTime');
nwlpNoticeDateTimeControl.setLabel(nwlpNoticeDateTime.heading);
const headingBlock = new Heading('step-title', nwlpNoticeDateTime.heading);
const infoBlock = new InfoBlock('infoBlock', nwlpNoticeDateTime.detail[0], 'c-message c-message--info');
const infoBlock2 = new InfoBlock('infoBlock', nwlpNoticeDateTime.detail[1]);
const buttonsBlock = new ButtonRow();

const nwlpNoticeDateTimeStep = new Step('step', 'nwlp-notice-datetime');
nwlpNoticeDateTimeStep.addBlock(headingBlock);
nwlpNoticeDateTimeStep.addBlock(infoBlock);
nwlpNoticeDateTimeStep.addBlock(infoBlock2);
nwlpNoticeDateTimeStep.addBlock(dateTimeSelect);
nwlpNoticeDateTimeStep.addBlock(nwlpNoticeDateTimeControl);
nwlpNoticeDateTimeStep.addBlock(buttonsBlock);
nwlpNoticeDateTimeStep.setIsContained(true);
nwlpNoticeDateTimeStep.setTitle(nwlpNoticeDateTime.title);

nwlpNoticeDateTimeStep.setConstraints({
  nwlpInitialProblem: {
    inclusion: [NO_WATER],
    exclusion: [PRESSURE],
  },
  nwlpFacilitiesAffected: {
    inclusion: [FIRST_FEED],
    exclusion: [NOT_FIRST_FEED],
  },
  nwlpStopTap: {
    inclusion: [YES, NO, UNSURE],
  },
  nwlpAnyWater: {
    inclusion: [NO],
    exclusion: [YES],
  },
  isNoWaterEnabled: {
    presence: true,
    inclusion: [true],
    exclusion: [false],
  },
});

nwlpNoticeDateTimeStep.setValidation({
  nwlpNoticeDateTimeValid: {
    inclusion: [true],
    exclusion: [false],
  },
});

export { nwlpNoticeDateTimeStep };
