import { ActionTypes, GA_FORM_SUBMIT } from '../constants';
import * as R from 'ramda';
import { LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH } from '../constants';

import addressApi from '../api/addresses';
import locationApi from '../api/location';
import incidentApi from '../api/incidents';
import callbacksApi from '../api/callbacks';
import outcomeApi from '../api/outcome';
import timeApi from '../api/time';
import validationService from '../api/validation';
import googleAnalytics from '../api/googleAnalytics';

export const lensMap = (tree, vals) => {
  let lensParts = Object.keys(tree).reduce((accumulator, key) => {
    const value = tree[key];

    const lens = R.lensPath(value.split('.'));
    const lensVal = R.view(lens, vals);

    accumulator[key] = lensVal;
    return accumulator;
  }, {});

  return lensParts;
};

export const lensString = (params, vals) => {
  if (params === '') {
    return vals;
  }
  const lens = R.lensPath(params.split('.'));
  const lensVal = R.view(lens, vals);
  return lensVal;
};

export const hookIsLoading = (bool) => ({
  type: ActionTypes.HOOK_LOADING,
  isLoading: bool,
});

export function hookSuccess(key, response) {
  return {
    type: ActionTypes.HOOK_SUCCESS,
    key,
    response,
  };
}

export function hookCount() {
  return {
    type: ActionTypes.HOOK_COUNT,
  };
}

export function hookHasError(hasError, error, errorResponse) {
  return {
    type: ActionTypes.HOOK_ERROR,
    hasError,
    error,
    errorResponse,
  };
}

// hooks

export function stepSubmission(lookup, valueBag, successHandler, errorHandler) {
  return (dispatch, getState) => {
    const {
      steps: { userPath },
    } = getState();

    const params =
      typeof lookup.params === 'string' ? lensString(lookup.params, valueBag) : lensMap(lookup.params || {}, valueBag);
    if (lookup.validation) {
      const validationResult = validationService.validate(valueBag, lookup.validation);
      if (validationResult) {
        dispatch(hookSuccess(lookup.type, null));
        dispatch(hookHasError(true, lookup.type, validationResult));
        dispatch(hookIsLoading(false));

        if (errorHandler) {
          errorHandler(validationResult[0]);
        }

        return;
      }
    }
    dispatch(hookIsLoading(true));
    dispatch(hookCount());

    outcomeApi.postOutcome(
      params,
      (response) => {
        dispatch(hookSuccess(lookup.type, {}));
        dispatch(hookHasError(false));
        dispatch(hookIsLoading(false));
        if (successHandler) {
          successHandler({}, { problemSubmitted: true, isWorkingHours: response.data?.isWorkingHours });
        }

        googleAnalytics.trackEvent(GA_FORM_SUBMIT, 'your-summary');
      },
      (error) => {
        dispatch(hookSuccess(lookup.type, null));
        dispatch(hookHasError(true, lookup.type, error));
        dispatch(hookIsLoading(false));
        if (errorHandler) {
          errorHandler(error);
        }
      },
      userPath,
    );
  };
}

export function incidentLookup(lookup, valueBag, successHandler, errorHandler) {
  return (dispatch) => {
    const params =
      typeof lookup.params === 'string' ? lensString(lookup.params, valueBag) : lensMap(lookup.params || {}, valueBag);

    dispatch(hookIsLoading(true));
    dispatch(hookCount());
    return incidentApi.getIncidents(
      params,
      (response) => {
        dispatch(hookSuccess(lookup.type, response));
        dispatch(hookHasError(false));
        dispatch(hookIsLoading(false));
        if (successHandler) {
          const cleanOrWasteIncidentTotal = params.isWasteInitial
            ? { incidentTotalWaste: response.length }
            : { incidentTotal: response.length };
          successHandler(response, cleanOrWasteIncidentTotal);
        }

        return;
      },
      (error) => {
        dispatch(hookSuccess(lookup.type, null));
        dispatch(hookHasError(true, lookup.type, error));
        dispatch(hookIsLoading(false));
        if (errorHandler) {
          errorHandler(error);
        }
      },
    );
  };
}

export function workingHoursLookup(lookup, valueBag, successHandler, errorHandler) {
  return (dispatch) => {
    dispatch(hookIsLoading(true));
    dispatch(hookCount());

    return timeApi.isWorkingHours(
      (response) => {
        dispatch(hookSuccess(lookup.type, response));
        dispatch(hookHasError(false));
        dispatch(hookIsLoading(false));
        if (successHandler) {
          successHandler(response, { isWorkingHours: response });
        }
        return;
      },
      (error) => {
        dispatch(hookSuccess(lookup.type, null));
        dispatch(hookHasError(true, lookup.type, error));
        dispatch(hookIsLoading(false));
        if (errorHandler) {
          errorHandler(error);
        }
      },
    );
  };
}

export function accessHoursLookup(lookup, valueBag, successHandler, errorHandler) {
  return (dispatch) => {
    dispatch(hookIsLoading(true));
    dispatch(hookCount());

    return timeApi.isAccessHours(
      (response) => {
        dispatch(hookSuccess(lookup.type, response));
        dispatch(hookHasError(false));
        dispatch(hookIsLoading(false));
        if (successHandler) {
          successHandler(response, { isAccessHours: response });
        }
        return;
      },
      (error) => {
        dispatch(hookSuccess(lookup.type, null));
        dispatch(hookHasError(true, lookup.type, error));
        dispatch(hookIsLoading(false));
        if (errorHandler) {
          errorHandler(error);
        }
      },
    );
  };
}

export function noWaterEnabledLookup(lookup, valueBag, successHandler, errorHandler) {
  return (dispatch) => {
    dispatch(hookIsLoading(true));
    dispatch(hookCount());

    return timeApi.isNoWaterEnabled(
      (response) => {
        dispatch(hookSuccess(lookup.type, response));
        dispatch(hookHasError(false));
        dispatch(hookIsLoading(false));
        if (successHandler) {
          successHandler(response, { isNoWaterEnabled: response });
        }
        return;
      },
      (error) => {
        dispatch(hookSuccess(lookup.type, null));
        dispatch(hookHasError(true, lookup.type, error));
        dispatch(hookIsLoading(false));
        if (errorHandler) {
          errorHandler(error);
        }
      },
    );
  };
}

export function addressLookup(lookup, valueBag, successHandler, errorHandler) {
  return (dispatch) => {
    const params =
      typeof lookup.params === 'string' ? lensString(lookup.params, valueBag) : lensMap(lookup.params || {}, valueBag);

    dispatch(hookIsLoading(true));
    dispatch(hookCount());
    return addressApi.fetchAddresses(
      params,
      (response) => {
        dispatch(hookSuccess(lookup.type, response));
        dispatch(hookHasError(false));
        dispatch(hookIsLoading(false));
        if (successHandler) {
          successHandler(response, { addressTotal: response.length });
        }

        return;
      },
      (error) => {
        dispatch(hookSuccess(lookup.type, null));
        dispatch(hookHasError(true, lookup.type, error));
        dispatch(hookIsLoading(false));
        if (errorHandler) {
          errorHandler(error);
        }
      },
    );
  };
}

export function geocodeLocation(lookup, valueBag, successHandler, errorHandler) {
  return (dispatch) => {
    let params =
      typeof lookup.params === 'string' ? lensString(lookup.params, valueBag) : lensMap(lookup.params || {}, valueBag);

    dispatch(hookIsLoading(true));
    dispatch(hookCount());
    let address = params;

    locationApi.geocodeLocation(
      address,
      (response) => {
        dispatch(hookSuccess(lookup.type, response));
        dispatch(hookIsLoading(false));
        dispatch(hookHasError(false, null));
        if (successHandler) {
          successHandler(response, {});
        }
      },
      (status) => {
        dispatch(hookSuccess(lookup.type, undefined));
        dispatch(hookHasError(true, lookup.type, status));
        dispatch(hookIsLoading(false));
        if (errorHandler) {
          errorHandler(status);
        }
      },
    );
  };
}

export function checkMapLocation(lookup, valueBag, successHandler, errorHandler) {
  return (dispatch) => {
    const params =
      typeof lookup.params === 'string' ? lensString(lookup.params, valueBag) : lensMap(lookup.params || {}, valueBag);

    dispatch(hookIsLoading(true));
    dispatch(hookCount());

    if (params.mapResult && params.mapConfirmed === 'TRUE') {
      // 1. Map already confirmed
      dispatch(hookSuccess(lookup.type, params.mapResult));
      dispatch(hookHasError(false, null));
      dispatch(hookIsLoading(false));
      if (successHandler) {
        successHandler(params.mapResult);
      }
    } else if (params.locateMethod === LOCATION_METHOD_MAP_SEARCH && params.hooks.lookups.mapSearch) {
      let response = { lat: params.hooks.lookups.mapSearch.lat, lng: params.hooks.lookups.mapSearch.lng };
      let matchedResponses;
      if (valueBag.mapResultConfirm) {
        matchedResponses = params.hooks.lookups.mapSearch.results.filter(
          (row) => row.place_id === valueBag.mapResultConfirm,
        );

        if (matchedResponses) {
          response = {
            lat: matchedResponses[0].geometry.location.lat(),
            lng: matchedResponses[0].geometry.location.lng(),
          };
        }
      } else {
        matchedResponses = params.hooks.lookups.mapSearch.results;
      }

      const location = {
        location: {
          lat: response.lat,
          lng: response.lng,
        },
      };
      dispatch(hookSuccess(lookup.type, response));
      dispatch(hookHasError(false, null));
      dispatch(hookIsLoading(false));
      if (successHandler && matchedResponses) {
        locationApi.reverseGeocodeLocation({ location }, (response) => {
          successHandler(response, {
            mapResult: { lat: response.lat, lng: response.lng, locationDetails: response.formattedAddress || '' },
          });
        });
      }
    } else if (params.locateMethod === LOCATION_METHOD_CURRENT_LOCATION) {
      locationApi.getCurrentLocation(
        (lat, lng) => {
          let latLng = { lat, lng };
          dispatch(hookSuccess(lookup.type, latLng));
          dispatch(hookHasError(false, null));
          dispatch(hookIsLoading(false));
          if (successHandler) {
            locationApi.reverseGeocodeLocation({ location: latLng }, (response) => {
              successHandler(latLng, {
                mapResult: { lat: response.lat, lng: response.lng, locationDetails: response.formattedAddress || '' },
              });
            });
          }
        },
        (err) => {
          dispatch(hookSuccess(lookup.type, null));
          dispatch(hookHasError(true, lookup.type, err));
          dispatch(hookIsLoading(false));
          errorHandler();
        },
      );
    } else {
      dispatch(hookSuccess(lookup.type, null));
      dispatch(hookHasError(true, lookup.type, 'No location found'));
      dispatch(hookIsLoading(false));
      errorHandler();
    }
  };
}

export function callbacksLookup(lookup, valueBag, successHandler, errorHandler) {
  return (dispatch) => {
    const params =
      typeof lookup.params === 'string' ? lensString(lookup.params, valueBag) : lensMap(lookup.params || {}, valueBag);

    dispatch(hookIsLoading(true));
    dispatch(hookCount());
    return callbacksApi.fetchSlots(
      params.reasonId,
      (response) => {
        dispatch(hookSuccess(lookup.type, response));
        dispatch(hookHasError(false));
        dispatch(hookIsLoading(false));

        if (successHandler) {
          successHandler(response);
        }

        return;
      },
      (error) => {
        dispatch(hookSuccess(lookup.type, undefined));
        dispatch(hookHasError(true, lookup.type, error));
        dispatch(hookIsLoading(false));
        if (errorHandler) {
          errorHandler(error);
        }
      },
    );
  };
}
