import { ButtonRow, Control, Heading, InfoBlock, Step } from '../../../models';
import {
  SINK,
  SHOWER,
  BATH,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  BATHROOM,
  HOUSE,
  ODOUR,
  KITCHEN,
  UPSTAIRS_TOILET,
  DOWNSTAIRS_BATH,
  MULTIPLE_TOILETS,
  DOWNSTAIRS_TOILET,
  UPSTAIRS_FACILITIES,
  DOWNSTAIRS_FACILITIES,
  UPSTAIRS_BATH,
  TOILET,
  APPLIANCE,
  FLOOR,
  CELLAR,
} from '../../../../constants';
import { wasteInternalFclty2 } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteInternalFclty2.heading);
const wasteInternalFclty2CountControl = new Control('hidden', 'wasteInternalFclty2Count');
const wasteInternalFclty2Control = new Control('hidden', 'wasteInternalFclty2');
wasteInternalFclty2Control.setLabel(wasteInternalFclty2.heading);
const infoBlock = new InfoBlock('info-block1', wasteInternalFclty2.detail);
var checkboxBlock = {
  type: 'countedCheckbox',
  count: 'wasteInternalFclty2Count',
  name: 'wasteInternalFclty2',
  error: wasteInternalFclty2.error,
  options: [
    { value: SINK, text: wasteInternalFclty2.sink },
    { value: SHOWER, text: wasteInternalFclty2.shower },
    { value: BATH, text: wasteInternalFclty2.bath },
  ],
};

const wasteInternalFclty2Step = new Step('step', 'waste-internal-fclty2');
wasteInternalFclty2Step.addBlock(headingBlock);
wasteInternalFclty2Step.addBlock(infoBlock);
wasteInternalFclty2Step.addBlock(checkboxBlock);
wasteInternalFclty2Step.addBlock(wasteInternalFclty2Control);
wasteInternalFclty2Step.addBlock(wasteInternalFclty2CountControl);
wasteInternalFclty2Step.addBlock(new ButtonRow());
wasteInternalFclty2Step.setIsContained(false);
wasteInternalFclty2Step.setTitle(wasteInternalFclty2.title);

wasteInternalFclty2Step.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [BATHROOM],
      exclusion: [HOUSE, ODOUR, KITCHEN],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
  },
]);

wasteInternalFclty2Step.setValidation({
  wasteInternalFclty2: {
    presence: {
      allowEmpty: false,
    },
  },
});

export { wasteInternalFclty2Step };
