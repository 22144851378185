import { Heading, Step, ButtonRow, InfoBlock, Link } from '../../../models';
import { FIX_PROBLEMS_URL, YES, MAIN_WEBSITE_URL, NO } from '../../../../constants';
import { backToMainWebsite, howWeFixProblems, wasteRtimInformation } from '../../../../constants/Copy';

const headingBlock = new Heading('step-heading', wasteRtimInformation.heading);
const infoBlock = new InfoBlock('info-block', wasteRtimInformation.detail[0], 'p-b');
const headingBlock2 = new Heading('step-heading2', wasteRtimInformation.subHeading).setSize('md');
const infoBlock2 = new InfoBlock('info-block2', wasteRtimInformation.detail[1]);
const linkBlock1 = new Link('link-block1', howWeFixProblems).setLink(FIX_PROBLEMS_URL).setTarget('_self');
const linkBlock2 = new Link('link-block2', backToMainWebsite, 'p-t').setLink(MAIN_WEBSITE_URL).setTarget('_self');
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteRtimOffRampStep = new Step('step', 'waste-rtim-information');
wasteRtimOffRampStep.setIsContained(true);
wasteRtimOffRampStep.addBlock(headingBlock);
wasteRtimOffRampStep.addBlock(infoBlock);
wasteRtimOffRampStep.addBlock(headingBlock2);
wasteRtimOffRampStep.addBlock(infoBlock2);
wasteRtimOffRampStep.addBlock(linkBlock1);
wasteRtimOffRampStep.addBlock(linkBlock2);
wasteRtimOffRampStep.addBlock(buttonsBlock);
wasteRtimOffRampStep.setTitle(wasteRtimInformation.title);

wasteRtimOffRampStep.setConstraints({
  wasteIncidentResults: {
    inclusion: [YES],
  },
  incidentTotalWaste: {
    presence: { AllowEmpty: true },
    numericality: {
      greaterThan: 0,
    },
  },
  isColleagueMode: {
    presence: false,
    inclusion: [NO],
    exclusion: [YES],
  },
});

export { wasteRtimOffRampStep };
