import { Heading, Step, ButtonRow, InfoBlock, Link } from '../../../models';
import {
  YES,
  NO,
  UNSURE,
  METER,
  PIPE_MEETS_METER,
  STOPTAP,
  INSIDE,
  OUTSIDE,
  EXTERNAL,
  COVER,
  OVERFLOW_PIPE,
  GROUND,
  INTERNAL,
  PUBLIC,
  MAIN_WEBSITE_URL,
  LOCAL_PLUMBER_URL,
} from '../../../../constants';
import { backToMainWebsite, cleanCondenInfo, localPlumber } from '../../../../constants/Copy';

const headingBlock = new Heading('step-heading', cleanCondenInfo.heading);
const infoBlock = new InfoBlock('info-block', cleanCondenInfo.detail[0], 'p-tb');
const headingBlock2 = new Heading('step-heading2', cleanCondenInfo.subHeading1, 2).setSize('md');
const infoBlock2 = new InfoBlock('info-block2', cleanCondenInfo.detail[1], 'p-b');
const headingBlock3 = new Heading('step-heading2', cleanCondenInfo.subHeading3, 2).setSize('md');
const infoBlock3 = new InfoBlock('info-block3', cleanCondenInfo.detail[2], 'p-b');
const headingBlock4 = new Heading('step-heading2', cleanCondenInfo.subHeading2, 2).setSize('md');
const infoBlock4 = new InfoBlock('info-block4', cleanCondenInfo.detail[3]);
const infoBlock5 = new InfoBlock('info-block5', cleanCondenInfo.detail[4]);
const infoBlock6 = new InfoBlock('info-block6', cleanCondenInfo.detail[5]);
const infoBlock7 = new InfoBlock('info-block7', cleanCondenInfo.detail[6]);
const linkBlock1 = new Link('link-block1', localPlumber).setLink(LOCAL_PLUMBER_URL).setTarget('_self');
const linkBlock2 = new Link('link-block', backToMainWebsite, 'p-t')
  .setLink(MAIN_WEBSITE_URL)
  .setTarget('_self')
  .setFormat(true);
const buttonsBlock = new ButtonRow().hideContinueButton();

const meterCondensationInfoStep = new Step('step', 'clean-conden-info');
meterCondensationInfoStep.setIsContained(true);
meterCondensationInfoStep.addBlock(headingBlock);
meterCondensationInfoStep.addBlock(infoBlock);
meterCondensationInfoStep.addBlock(headingBlock2);
meterCondensationInfoStep.addBlock(infoBlock2);
meterCondensationInfoStep.addBlock(headingBlock3);
meterCondensationInfoStep.addBlock(infoBlock3);
meterCondensationInfoStep.addBlock(headingBlock4);
meterCondensationInfoStep.addBlock(infoBlock4);
meterCondensationInfoStep.addBlock(infoBlock5);
meterCondensationInfoStep.addBlock(infoBlock6);
meterCondensationInfoStep.addBlock(infoBlock7);
meterCondensationInfoStep.addBlock(linkBlock1);
meterCondensationInfoStep.addBlock(linkBlock2);
meterCondensationInfoStep.addBlock(buttonsBlock);
meterCondensationInfoStep.setTitle(cleanCondenInfo.title);

meterCondensationInfoStep.setConstraints([
  {
    initialLocation: {
      inclusion: [INTERNAL],
      exclusion: [PUBLIC, EXTERNAL],
    },
    internalWaterOrigin: {
      inclusion: [METER],
    },
    cleanMeterInternal: {
      inclusion: [METER],
      exclusion: [PIPE_MEETS_METER, STOPTAP, UNSURE],
    },
    cleanCondenConfirm: {
      inclusion: [NO],
    },
    isColleagueMode: {
      presence: false,
      inclusion: [NO],
      exclusion: [YES],
    },
  },
  {
    cleanCondenConfirm: {
      inclusion: [NO],
    },
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      presence: true,
      inclusion: [METER],
      exclusion: [COVER, OVERFLOW_PIPE, GROUND],
    },
    cleanMeterLeaking: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    cleanMeterLocation: {
      presence: true,
      inclusion: [INSIDE],
      exclusion: [OUTSIDE],
    },
    cleanMeterInternal: {
      presence: true,
      inclusion: [METER],
      exclusion: [PIPE_MEETS_METER, STOPTAP, UNSURE],
    },
    isColleagueMode: {
      presence: false,
      inclusion: [NO],
      exclusion: [YES],
    },
  },
]);

export { meterCondensationInfoStep };
