import { Control, Heading, Step, ButtonRow, Hook } from '../../models';
import {
  YES,
  NO,
  NO_WATER,
  PRESSURE,
  FIRST_FEED,
  NOT_FIRST_FEED,
  UNSURE,
  STEP_NOWATER_ENABLED_LOOKUP,
} from '../../../constants';
import { nwlpAnyWater } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', nwlpAnyWater.heading);
const isNoWaterEnabledControl = new Control('hidden', 'isNoWaterEnabled');
const nwlpAnyWaterControl = new Control('question', 'nwlpAnyWater');
nwlpAnyWaterControl.setLabel(nwlpAnyWater.heading);
nwlpAnyWaterControl.addOption(nwlpAnyWater.yes, YES, 'IconTap2');
nwlpAnyWaterControl.addOption(nwlpAnyWater.no, NO, 'IconCross');
const buttonsBlock = new ButtonRow().hideContinueButton();

const nwlpAnyWaterStep = new Step('step', 'nwlp-any-water');
nwlpAnyWaterStep.addBlock(headingBlock);
nwlpAnyWaterStep.addBlock(isNoWaterEnabledControl);
nwlpAnyWaterStep.addBlock(nwlpAnyWaterControl);
nwlpAnyWaterStep.addBlock(buttonsBlock);
nwlpAnyWaterStep.setTitle(nwlpAnyWater.title);
nwlpAnyWaterStep.exitHook = new Hook(STEP_NOWATER_ENABLED_LOOKUP, '');

nwlpAnyWaterStep.setConstraints({
  nwlpInitialProblem: {
    inclusion: [NO_WATER],
    exclusion: [PRESSURE],
  },
  nwlpFacilitiesAffected: {
    inclusion: [FIRST_FEED],
    exclusion: [NOT_FIRST_FEED],
  },
  nwlpStopTap: {
    inclusion: [YES, NO, UNSURE],
  },
});

export { nwlpAnyWaterStep };
