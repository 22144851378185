import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { UKAddressResult } from '../../../components/';

const AddressListBlock = ({ answers, lookups, route, stepTo, form }) => {
  return (
    <Fragment>
      {lookups.addresses.length > 1 && (
        <p>
          Showing results for <strong>{answers.postcode}</strong>{' '}
          <button
            type="button"
            className="c-button--link u-ml--100"
            onClick={() => stepTo(route)}
            data-qa="search-again-link"
          >
            Search again
          </button>
          .
        </p>
      )}
      {lookups.addresses.length < 2 && lookups.addresses[0] !== undefined && (
        <p>
          <strong>{lookups.addresses[0].label}</strong>{' '}
          <button
            type="button"
            className="c-button--link u-ml--100"
            onClick={() => stepTo(route)}
            data-qa="search-again-link"
          >
            Search again
          </button>
          .
        </p>
      )}
      <UKAddressResult form={form} addresses={lookups.addresses} answers={answers} />
    </Fragment>
  );
};

AddressListBlock.propTypes = {
  stepTo: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  lookups: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  form: PropTypes.object,
};

export default AddressListBlock;
