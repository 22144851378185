import { ButtonRow, Heading, Hook, Step } from '../../../models';
import {
  WI_TEXT_CHAR_COUNT,
  AIRBRICK,
  CHUTE,
  OTHER,
  PIPE,
  BLOCKED,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  KITCHEN,
  HOUSE,
  ODOUR,
  BATHROOM,
  APPLIANCE,
  TOILET,
  SINK,
  FLOOR,
  CELLAR,
  GROUND_FLOOR,
  GARAGE,
  UPSTAIRS,
  SLOW_DRAINAGE,
  MANHOLE,
  NONE,
  STEP_ACCESS_HOURS_LOOKUP,
} from '../../../../constants';
import { wasteInternalText } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteInternalText.heading);
const textBlock = {
  type: 'textarea',
  label: wasteInternalText.label,
  name: 'wasteInternalText',
  maxLength: WI_TEXT_CHAR_COUNT,
  showMaxLength: true,
  placeholder: wasteInternalText.placeholder,
  isRequiredMarker: true,
};
const buttonsBlock = new ButtonRow();

const wasteInternalTextStep = new Step('step', 'waste-internal-text');
wasteInternalTextStep.addBlock(headingBlock);
wasteInternalTextStep.addBlock(textBlock);
wasteInternalTextStep.addBlock(buttonsBlock);
wasteInternalTextStep.setIsContained(false);
wasteInternalTextStep.exitHook = new Hook(STEP_ACCESS_HOURS_LOOKUP, '');
wasteInternalTextStep.setTitle(wasteInternalText.title);

wasteInternalTextStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [FLOOR, CELLAR],
      exclusion: [TOILET, APPLIANCE, SINK],
    },
    wasteInternalArea: {
      inclusion: [CELLAR],
      exclusion: [GROUND_FLOOR, GARAGE, OTHER, UPSTAIRS],
    },
    wasteInternalOther: {
      inclusion: [NONE],
      exclusion: [BLOCKED, MANHOLE, ODOUR, SLOW_DRAINAGE],
    },
    wasteInternalSource: {
      inclusion: [OTHER],
      exclusion: [CHUTE, AIRBRICK, PIPE],
    },
  },
]);

wasteInternalTextStep.setValidation({
  wasteInternalText: {
    presence: {
      message: wasteInternalText.validation,
      allowEmpty: false,
    },
  },
});

export { wasteInternalTextStep };
