import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FluidField } from 'yw-ui.v2';

export const SlotListBlock = ({ name, lookups, answers }) => {
  const activeSlots = lookups.callbacks.filter((slot) => {
    return slot.description === answers.appointmentDay;
  });

  let activeTimeSlots;
  if (activeSlots.length) {
    activeTimeSlots = activeSlots[0].timeSlots;
  }

  return (
    <Fragment>
      {answers.appointmentDay && <h2>{answers.appointmentDay}</h2>}

      <FluidField
        id={name}
        qa={name}
        component="radio"
        name={name}
        label={'Select a time slot'}
        options={
          activeTimeSlots
            ? activeTimeSlots.map((timeSlot) => {
                return { id: timeSlot.time, label: timeSlot.description };
              })
            : []
        }
        hideLabel={true}
        isScrollable={true}
      />
    </Fragment>
  );
};

SlotListBlock.propTypes = {
  lookups: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

SlotListBlock.defaultProps = {};

export default SlotListBlock;
