function SummaryTableRow(id, label, value) {
  this.id = id;
  this.label = label;
  this.value = value;
  this.editMode = true;
}

SummaryTableRow.prototype.setFormatter = function(format) {
  this.format = format;
  return this;
};

SummaryTableRow.prototype.setEditSlug = function(editSlug) {
  this.editSlug = editSlug;
  return this;
};

SummaryTableRow.prototype.setEditMode = function(bool) {
  this.editMode = bool;
  return this;
};

export default SummaryTableRow;
