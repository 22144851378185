import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

export class TextBlock extends Component {
  constructor(props) {
    super(props);

    const { form, text, name, answers } = this.props;
    const address = form.values.mapResult ? form.values.mapResult[name] : answers[name];

    this.state = {
      text: text,
      name: address,
    };
  }
  render() {
    return (
      <Fragment>
        <p className="u-mb--250">
          {this.state.text}
          <strong>{this.state.name}</strong>
        </p>
      </Fragment>
    );
  }
}

TextBlock.propTypes = {
  form: PropTypes.object,
  text: PropTypes.string,
  name: PropTypes.string,
  answers: PropTypes.object,
};

export default TextBlock;
