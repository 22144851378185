import { ButtonRow, Control, Heading, Hook, Step } from '../../../models';
import {
  APPLIANCE,
  BATHROOM,
  CELLAR,
  DIFFERENT,
  DOWNSTAIRS_BATH,
  DOWNSTAIRS_FACILITIES,
  DOWNSTAIRS_TOILET,
  EXTERNAL,
  FLOOR,
  GRATE,
  HOUSE,
  INTERNAL,
  KITCHEN,
  MANHOLE,
  MULTIPLE,
  MULTIPLE_TOILETS,
  NO,
  ODOUR,
  PUBLIC,
  SAME,
  SINGLE,
  SINK,
  STEP_ACCESS_HOURS_LOOKUP,
  TOILET,
  UPSTAIRS_BATH,
  UPSTAIRS_FACILITIES,
  UPSTAIRS_TOILET,
} from '../../../../constants';
import { wasteInternalExtother } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteInternalExtother.heading);
const wasteInternalExtotherControl = new Control('question', 'wasteInternalExtother');
wasteInternalExtotherControl.setLabel(wasteInternalExtother.heading);
wasteInternalExtotherControl.setDefault('');
wasteInternalExtotherControl.addOption(wasteInternalExtother.grate, GRATE, 'IconInternalBlockage');
wasteInternalExtotherControl.addOption(wasteInternalExtother.manhole, MANHOLE, 'IconConstantPressure');
wasteInternalExtotherControl.addOption(wasteInternalExtother.odour, ODOUR, 'IconBadSmell');
wasteInternalExtotherControl.addOption(wasteInternalExtother.no, NO, 'IconCross');
wasteInternalExtotherControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteInternalExtotherStep = new Step('step', 'waste-internal-extother');
wasteInternalExtotherStep.addBlock(headingBlock);
wasteInternalExtotherStep.addBlock(wasteInternalExtotherControl);
wasteInternalExtotherStep.addBlock(buttonsBlock);
wasteInternalExtotherStep.setIsContained(false);
wasteInternalExtotherStep.exitHook = new Hook(STEP_ACCESS_HOURS_LOOKUP, '');
wasteInternalExtotherStep.setTitle(wasteInternalExtother.title);

wasteInternalExtotherStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [BATHROOM],
      exclusion: [HOUSE, ODOUR, KITCHEN],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_TOILET],
      exclusion: [
        UPSTAIRS_BATH,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [BATHROOM],
      exclusion: [HOUSE, ODOUR, KITCHEN],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [SINGLE],
      exclusion: [MULTIPLE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [BATHROOM],
      exclusion: [HOUSE, ODOUR, KITCHEN],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [MULTIPLE],
      exclusion: [SINGLE],
    },
    wasteInternalSame: {
      inclusion: [DIFFERENT],
      exclusion: [SAME],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_TOILET],
      exclusion: [
        UPSTAIRS_BATH,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [SINGLE],
      exclusion: [MULTIPLE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [MULTIPLE],
      exclusion: [SINGLE],
    },
    wasteInternalSame: {
      inclusion: [DIFFERENT],
      exclusion: [SAME],
    },
  },
]);
wasteInternalExtotherStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [BATHROOM],
      exclusion: [HOUSE, ODOUR, KITCHEN],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_TOILET],
      exclusion: [
        UPSTAIRS_BATH,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [BATHROOM],
      exclusion: [HOUSE, ODOUR, KITCHEN],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [SINGLE],
      exclusion: [MULTIPLE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [BATHROOM],
      exclusion: [HOUSE, ODOUR, KITCHEN],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [MULTIPLE],
      exclusion: [SINGLE],
    },
    wasteInternalSame: {
      inclusion: [DIFFERENT],
      exclusion: [SAME],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_TOILET],
      exclusion: [
        UPSTAIRS_BATH,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [SINGLE],
      exclusion: [MULTIPLE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [MULTIPLE],
      exclusion: [SINGLE],
    },
    wasteInternalSame: {
      inclusion: [DIFFERENT],
      exclusion: [SAME],
    },
  },
]);

export { wasteInternalExtotherStep };
