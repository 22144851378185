import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  TRIANGLE,
  GROUND,
  WASTE,
  CLEAN,
  UNSURE,
  INTERNAL,
} from '../../../../constants';
import { waterType } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', waterType.heading);
const waterTypeControl = new Control('question', 'waterType');
waterTypeControl.setLabel(waterType.heading);
waterTypeControl.addOption(waterType.clean, CLEAN, 'IconGlass', false, 'clean water');
waterTypeControl.addOption(waterType.waste, WASTE, 'IconToiletRoll', false, 'sewage');
waterTypeControl.addOption(waterType.unsure, UNSURE, 'IconQuestion', false, 'unsure');
waterTypeControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const waterTypeStep = new Step('step', 'water-type');
waterTypeStep.addBlock(headingBlock);
waterTypeStep.addBlock({
  type: 'message',
  id: 'step-hint',
  message: waterType.hint,
});
waterTypeStep.addBlock(waterTypeControl);
waterTypeStep.addBlock(buttonsBlock);
waterTypeStep.setTitle(waterType.title);

waterTypeStep.setConstraints([
  {
    initialLocation: {
      exclusion: [INTERNAL],
    },
    waterOrigin: {
      presence: true,
      inclusion: [OTHER, GROUND],
    },
    externalCoverType: {
      inclusion: [GRATE, SQUARE],
      exclusion: [STOPTAP, METER, SLUICE_VALVE, OTHER],
    },
  },
  {
    initialLocation: {
      exclusion: [INTERNAL],
    },
    coverType: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [TRIANGLE, SQUARE, SLUICE_VALVE, STOPTAP, METER],
    },
    externalCoverType: {
      inclusion: [GRATE, SQUARE],
      exclusion: [STOPTAP, METER, SLUICE_VALVE, OTHER],
    },
  },
]);

export { waterTypeStep };
