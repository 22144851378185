import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  AIRBRICK,
  CHUTE,
  OTHER,
  PIPE,
  BLOCKED,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  KITCHEN,
  HOUSE,
  ODOUR,
  BATHROOM,
  APPLIANCE,
  TOILET,
  SINK,
  FLOOR,
  CELLAR,
  GROUND_FLOOR,
  GARAGE,
  UPSTAIRS,
  SLOW_DRAINAGE,
  MANHOLE,
  NONE,
} from '../../../../constants';
import { wasteInternalSource } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteInternalSource.heading);
const wasteInternalSourceControl = new Control('question', 'wasteInternalSource');
wasteInternalSourceControl.setLabel(wasteInternalSource.heading);
wasteInternalSourceControl.setDefault('');
wasteInternalSourceControl.addOption(wasteInternalSource.pipe, PIPE, 'IconInternalBlockage');
wasteInternalSourceControl.addOption(wasteInternalSource.chute, CHUTE, 'IconPropertyAccess');
wasteInternalSourceControl.addOption(wasteInternalSource.airbrick, AIRBRICK, 'IconCover');
wasteInternalSourceControl.addOption(wasteInternalSource.other, OTHER, 'IconQuestion');
wasteInternalSourceControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteInternalSourceStep = new Step('step', 'waste-internal-source');
wasteInternalSourceStep.addBlock(headingBlock);
wasteInternalSourceStep.addBlock(wasteInternalSourceControl);
wasteInternalSourceStep.addBlock(buttonsBlock);
wasteInternalSourceStep.setTitle(wasteInternalSource.title);

wasteInternalSourceStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [FLOOR, CELLAR],
      exclusion: [TOILET, APPLIANCE, SINK],
    },
    wasteInternalArea: {
      inclusion: [CELLAR],
      exclusion: [GROUND_FLOOR, GARAGE, OTHER, UPSTAIRS],
    },
    wasteInternalOther: {
      inclusion: [NONE],
      exclusion: [BLOCKED, MANHOLE, ODOUR, SLOW_DRAINAGE],
    },
  },
]);

export { wasteInternalSourceStep };
