import {
  FIND_SUPPLIER_URL,
  NATIONAL_GAS_NUMBER,
  NORTHUMBRIAN_WATER_URL,
  Option_No,
  Option_Yes,
  SEVERN_TRENT_URL,
  UNITED_UTILITIES_URL,
  WASTE_BOUNDARY_ESCAPE_LOCATION_QUESTION,
  YW_PHONE_NUMBER,
} from './Settings';

export const SHORT_MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const commonQuestions = {
  whatProblem: "What's the problem?",
  whereProblem: "Where's the problem?",
  leakRisk: 'Is the leak causing any of the following?',
  gushingInAir: 'Is the water gushing in the air?',
  waterOrigin: 'Where exactly is the water coming from?',
  waterOrigin2: 'Where is the water coming from?',
  waterOrigin3: "Where's the water coming from?",
  propertyRiskOrAccess: 'Is it a risk to property or obstructing access?',
  problemScale: 'How would you describe the scale of the problem?',
  waterType: 'Do you know what type of water this is?',
  coverType: 'What does the cover look like?',
  incidentConfirm: 'Could it be one of these problems?',
  flowConstant: 'Is flow constant and under pressure?',
  access: 'Can we access the garden or driveway?',
  facilitiesAffected: 'Which facilities are affected?',
  frequency: 'When is it happening?',
  frequency2: 'When is this happening?',
};

const yesItIs = 'Yes, it is';
const noItIsnt = "No, it isn't";
const yesItDoes = 'Yes, it does';
const noItDoesnt = "No, it doesn't";
const yesThereIs = 'Yes, there is';
const noThereIsnt = "No, there isn't";
const yesItCould = 'Yes, it could';
const accessYes = "Yes, it's accessible at any time";
const accessNo = "No, I'll need to be there to let you in";
const cantCheck = "I can't check";
const notSure = "I'm not sure";
const other = 'Other';
const atLeastOne = 'Please select at least one';
const atLeastOne2 = 'Please select at least one of the options below';
const specifySearchTerm = 'Please specify a search term';
const searchTerm = "check you've used the correct search term";
const ywArea = 'check the location is covered by Yorkshire Water';
const currentLocation = 'use your current location';
const noLocations = "**Sorry, we can't find any locations matching your search.** \n\n To try again:";
const identifyWaterType =
  "It can really help our technicians if you can accurately identify the water type causing this issue.\nDon't worry if you can't identify it, you can just click **I'm not sure** below.";
const constant = 'It is happening all the time';
const intermittent = 'It happens intermittently';
const pleaseCallUs = 'Please call us';
const getInTouch = 'Get in touch';
const callUs = '<a href="tel:03451242424">call us on 0345 1 24 24 24</a>';
const whatNext = 'What next?';
const thankyouForReporting = 'Thank you for letting us know about the problem.';
const locationNearTo = "You've told us the problem is on or near to ";
const contactPlumberRaw =
  'You can find a local approved plumber by visiting the <strong>WaterSafe website</strong> or <strong>call 01156 472 850</strong> to speak to our trusted partner CET.';
const contactPlumber = "If your insurance doesn't cover the problem, you'll need to contact a plumber.";
const homeInsurance =
  'If you own the property, you should check if your plumbing is covered by your home insurance or a separate policy. Your insurer will be able to give you advice about making a claim.';
const contactLandlord =
  'If you live in a rented property, you should contact your landlord and tell them about the problem.';
const under10 =
  'If your home is under 10 years old, you should contact your property developer as your drainage may be under warranty.';
const contactInsurance =
  "If you own the property, contact your insurance company before you start looking for a drainage contractor. They will be able to give you help and advice about making a claim. If you don't notify your insurance company, it could affect any future insurance claim. We have no liability for blockages which are beyond our control, so your insurance company are best placed to help you regarding loss and damage.";
const somewhereElse = 'Somewhere else';
const propertyRisk = 'Risk to property';
const propertyDamage = 'Damage to property';
const accident = 'A clear risk of an accident';
const both = 'Both';
const none = 'None';
const meter = 'The meter';
const meterPipe = 'Where the pipe connects to the meter';
const stopTap = 'Stop tap or pipe';
const access = 'Obstructing access';
const dampArea = 'Damp patch';
const smallArea = 'Covers small area';
const mediumArea = 'Covers medium area';
const largeArea = 'Covers significant area';
const leakingStopTap = 'Leaking stop tap or pipe';
const workingNearHere = "We're working in this area. Could the problem you saw be one of the ones we're working on?";
const internalLocation = 'Inside my property';
const externalLocation = 'In my garden or on my driveway';
const publicLocation = 'Public area or road';
const slowToilet = 'My toilet or sink is draining slowly or not emptying';
const isAnOdour = "There's an odour";
const sewageFromCover = 'Sewage is coming from a cover';
const sewageFromGround = 'Sewage is coming from the ground';
const allTheTime = 'All the time';
const comesAndGoes = 'It comes and goes';
const blockedDrain = "I've got a blocked drain outside";
const allThatApply = 'Select <strong>all</strong> that apply:';

export const howWeFixLeaks = 'Find out how we fix leaks';
export const howWeFixProblems = 'Find out how we fix problems';
export const backToMainWebsite = 'Back to the main website';
export const localPlumber = 'Find a local approved plumber';
export const localCouncil = 'Find your local council';
export const pipeResponsibility = 'Find out more about pipe responsibility';

export const images = {
  highwayGully: '/images/processed/highway-gully.jpg',
  gully: '/images/processed/domestic-gully.png',
  squareSewage: '/images/processed/choice-square-sewerage-1.png',
  sluiceSquare: '/images/processed/choice-sluice.jpg',
  sluiceRectangle: '/images/processed/choice-sluice2.jpg',
  meter: '/images/processed/cover-meter.jpg',
  triangleSewage: '/images/processed/choice-triangle-sewerage.jpg',
  largeSquareSewage: '/images/processed/choice-square-sewerage.jpg',
};

export const nwlpInitialProblem = {
  title: 'NWLP Initial Problem',
  heading: commonQuestions.whatProblem,
  pressure: 'I have a problem with my water pressure',
  noWater: 'I have no water',
};

export const nwlpStopTap = {
  title: 'NWLP Stop Tap',
  heading: 'Please test your Stop Tap',
  solveIssue: 'Did this solve the issue?',
  blurb:
    'The internal stop tap is likely to be found under or near the kitchen sink as this is the most common place for the supply to enter a building. Other locations could include a downstairs bathroom, utility room, attached garage or cellar.',
  actions: 'Please check your internal stop tap by taking the following actions:',
  actionsArr: [
    '1. Turn on the cold tap that is nearest to the stop tap.',
    '2. Turn the stop tap on and off fully three or four times ensuring that it is left in the on position.',
  ],
  yes: Option_Yes,
  no: Option_No,
  unsure: cantCheck,
};

export const nwlpNoticeToday = {
  title: 'NWLP Notice Today',
  heading: 'Did you first notice today?',
  yes: Option_Yes,
  no: Option_No,
};

export const nwlpPressureProblem = {
  title: 'NWLP Pressure Problem',
  heading: "What's the problem with your pressure?",
  highPressure: 'My water pressure is high',
  lowPressure: 'My water pressure is low',
};

export const nwlpAnyWater = {
  title: 'NWLP Any Water',
  heading: 'Is any water at all coming out of your tap?',
  yes: 'A tiny bit',
  no: 'None at all',
};

export const nwlpPropertyType = {
  title: 'NWLP Property Type',
  heading: 'Is the property a flat or apartment?',
  yes: yesItIs,
  no: "No, it's not",
};

export const nwlpProblemNeighbour = {
  title: 'NWLP Problem Neighbour',
  heading: 'Are your neighbours having similar problems?',
  yes: Option_Yes,
  no: Option_No,
  notSure: notSure,
};

export const nwlpProblemFrequency = {
  title: 'NWLP Problem Frequency',
  heading: commonQuestions.frequency,
  constant: constant,
  intermittent: intermittent,
};

export const nwlpPressureSupply = {
  title: 'NWLP Pressure Supply',
  heading: 'Which supply is affected?',
  notFirstFeed: 'Any tap that is not first feed',
  hotOnly: 'Hot tap only',
  hotCold: 'Cold supply only / Cold & Hot',
};

export const nwlpFacilitiesAffected = {
  title: 'NWLP Facilities Affected',
  heading: 'Which facilities are affected?',
  detail:
    'The main feed is likely to be found under or near the kitchen sink as this is the most common place for the supply to enter a building. If your kitchen sink isn’t located on the ground floor, other locations could include a downstairs bathroom, utility room, attached garage or cellar etc.',
  firstFeed: 'All taps including the main feed tap',
  notFirstFeed: 'Any tap that is not the main feed tap',
};

export const nwlpNoticeDateTime = {
  title: 'NWLP Notice DateTime',
  heading: 'When did you first notice this problem?',
  detail: [
    "Please tell us exactly when the problem started. If you're struggling to remember, please select a rough date and time.",
    'Select a <strong>date and time</strong>:',
  ],
  noDate: 'Please select a date',
  noTime: 'Please select a time',
  future: 'Please select a time that is not in the future',
};

export const nwlpNoticeConfirm = {
  title: 'NWLP Notice Confirm',
  heading: 'Is this time correct?',
  detail:
    "You've told us you first noticed the problem on the <strong>{nwlpNoticeDateTimeString}</strong> would you like to continue?",
};

export const nwlpOohInfo = {
  title: 'NWLP OOH Info',
  heading: "You can't report this online",
  callUs: pleaseCallUs,
  detail: [
    'From what you have told us it seems to be a no water problem, unfortunately you can not report this type of problem at this time',
    "Incidents reported online won't be looked at until the morning.",
    `We want to get your water back on as soon as possible, so please ${callUs}.`,
  ],
};

export const uploadPage = {
  heading: 'Can you show us the problem?',
  subHeader: 'Photos or videos may help our technicians resolve your problem quicker',
  buttonText: 'Take or upload',
  uploading: 'Uploading',
  calloutBoxTop: `Please keep **videos under 30 seconds.** - If that's not long enough you can upload up to three videos. You can also upload up to 10 photos.`,
  calloutBoxSafety: `**You are responsible for your own safety while filming:**  

  * If you feel unsafe at any point, stop and continue without uploading the photo or video.
  * Be careful not to step onto the road or any uneven ground when capturing your photo or video.
  * Keep an eye on your surroundings (try holding your camera still and look at where you walk rather than at your screen).  

  **Please try not to record other people or conversations. If you need a reminder, please see [report a problem online terms and conditions](https://www.yorkshirewater.com/about-us/our-policies/legal/#report-a-problem-online).**`,
  accordionHeader: 'How to take and upload:',
  accordionBody: `How to **take a good photo or video:**  
  * Stand back - make sure to show us where the water is coming from and anything that's at risk.
  * Use flash - if the problem is in a tight space or the light isn't great, this will help us see the problem clearly.
  * Hold still - holding your phone or camera steady will give us a clear picture of what's going on.  


How to up **upload your photo or video:**
  * Select 'Take or upload' below and follow the onscreen instructions, just like you would for attaching a document. You can select multiple files at once.
  * Don't worry if you upload something incorrectly - a 'remove' button will appear when the fils is ready, so you can always try again before selecting 'Continue'.`,
};

export const duplicateIssueQuestion = 'Duplicate Issue:';
export const privateIssueQuestion = 'Private Issue:';
export const reviewForCoC = 'Review for Close on Contact';

export const nwlpContactBanner = {
  header: 'No water after 8pm?',
  infos: [
    "Incidents reported online won't be looked at until the morning.",
    `We want to get your water back on as soon as possible, so please ${callUs}.`,
  ],
};

export const duplicateIssueBanner = {
  header: 'Duplicate issue',
  infos: ['A job has already been raised for this problem. Please <strong>continue to register this contact.</strong>'],
  level: 'warning',
};

export const privateBannerInfo =
  "This is the homeowner's responsibility. Please <strong>continue to register this contact.</strong>";

export const privateIssueBanner = {
  header: 'Private issue',
  infos: [privateBannerInfo],
  level: 'warning',
};

export const emergencyPrivateIssueBanner = {
  header: 'Emergency private issue',
  infos: [privateBannerInfo],
  level: 'error',
};

export const councilIssueBanner = {
  header: 'Council issue',
  infos: ['The customer needs to contact the council. Please <strong>continue to register this contact.</strong>'],
  level: 'warning',
};

export const chemicalBanner = {
  header: null,
  infos: [
    `This can not be reported via Report a Problem.
  <strong>Chemical odours need to be reported on ICE.</strong> If you are not able to report this on ICE, please transfer the customer to ext 853503.`,
  ],
  level: 'warning',
};

export const overflowPrivateBanner = {
  header: 'Private issue',
  infos: [
    privateBannerInfo,
    "If the leak is coming from an overflow pipe on another property, please click back until you reach the 'where's the problem' page and report this issue as a 'public area or road'.",
  ],
  level: 'warning',
};

export const rtimInformation = {
  title: 'Results Info',
  heading: "We're on it",
  subHeading: whatNext,
  detail: [
    "We're working on this leak. Thanks for checking whether we knew about it.",
    "We'll fix the leak as quickly as possible.",
  ],
};

export const wasteRtimInformation = {
  title: 'Results Info',
  heading: "We're on it",
  subHeading: whatNext,
  detail: [
    "We're working on this problem. Thanks for checking whether we knew about it.",
    "We'll fix the problem as quickly as possible.",
  ],
};

export const cleanIntStopTapInfo = {
  title: 'Clean internal stop tap info',
  heading: leakingStopTap,
  subHeading: whatNext,
  detail: [
    "We wouldn't be able to fix a leaking stop tap or pipe in your home. Repairs are the homeowner's responsibility.",
    contactLandlord,
    homeInsurance,
    contactPlumber,
    contactPlumberRaw,
  ],
};

export const cleanCondenInfo = {
  title: 'Clean Overflow Info',
  heading: 'Fixing problems in your home',
  subHeading1: whatNext,
  subHeading2: 'Fixing leaks',
  subHeading3: 'Dealing with condensation',
  detail: [
    "If you dry the meter and the water doesn't appear immediately, condensation or a leaky pipe might be the cause. We wouldn't be able to fix this as it's the homeowner's responsibility.",
    'The next steps depend on whether you need to deal with condensation or fixing a leak.',
    "To stop condensation, you can cover the fittings with an insulated material called lagging. You can buy lagging in DIY stores for a couple of quid. Just cut it to size and clip it on. If you've got some cable ties handy, pop a couple on to keep it secure.",
    "If your meter is near a washing machine or sink, take a look and see if the leak is coming from one of the pipes. If the leak is coming from a pipe, it's the homeowner's responsibility to fix it.",
    contactLandlord,
    homeInsurance,
    contactPlumber,
    contactPlumberRaw,
  ],
};

export const internalInformation = {
  title: 'Internal Info',
  heading: 'Leaks inside your property',
  subHeading: whatNext,
  detail: [
    "If the leak is coming from anywhere else in your home, it's your responsibility as a homeowner to get it fixed.",
    contactLandlord,
    homeInsurance,
    contactPlumber,
    contactPlumberRaw,
  ],
};

export const cleanOverflowInformation = {
  title: 'Clean Overflow Info',
  heading: 'Leak is coming from an overflow pipe',
  subHeading: whatNext,
  detail: [
    "It's the responsibility of the homeowner to fix a leaky overflow pipe.",
    "If the leak is coming from an overflow pipe on your property, you'll need to contact a plumber.",
    'If the leak is coming from an overflow pipe on another property, please get in touch so we can contact the homeowner about the problem.',
    contactPlumberRaw,
  ],
  getInTouch: getInTouch,
};

export const cleanLeakingMeterInfo = {
  title: 'Clean leaking meter info',
  heading: "There's probably a leak in your home",
  subHeading: whatNext,
  detail: [
    "If your meter is turning when you're not using any water, there's probably a leak in your home's plumbing. We wouldn't be able to fix this as it's the homeowner's responsibility.",
    contactLandlord,
    homeInsurance,
    contactPlumber,
    contactPlumberRaw,
  ],
};

export const cleanSupplyInfo = {
  title: 'Clean Overflow Info',
  heading: 'Fixing a leak in your garden or driveway',
  detail: [
    contactLandlord,
    "If you're a business customer, you should contact your retailer.",
    "If you own the property and it's under 2 years old, you should contact your developer as your supply pipe will still be under warranty.",
    "If you own the property and it's over 2 years old, you should check if your plumbing is covered by your insurance. Your insurer will be able to give you advice about making a claim.",
    contactPlumber,
    contactPlumberRaw,
  ],
};

export const wasteGasInfo = {
  title: 'Waste gas info',
  heading: 'Call National Gas immediately',
  info: `If you can smell gas, or think there's a gas leak, please <a href="tel:${NATIONAL_GAS_NUMBER}">call National Gas Emergencies on ${NATIONAL_GAS_NUMBER}</a> as soon as you can.`,
  subHeading: whatNext,
  bulletHead: "Once you've called the National Gas Emergency line:",
  bullets: [
    '\u2022 open all your doors and windows',
    "\u2022 don't use anything electrical",
    '\u2022 put out any flames (candles, matches, cigarettes etc).',
  ],
};

export const wasteGasInfoCol = {
  title: 'Waste gas info',
  heading: 'Call National Gas immediately',
  info: `If you can smell gas, or think there's a gas leak, please <strong>call National Gas Emergencies on ${NATIONAL_GAS_NUMBER}</strong> as soon as you can.`,
  subHeading: whatNext,
  bulletHead: "Once you've called the National Gas Emergency line:",
  bullets: [
    '\u2022 open all your doors and windows',
    "\u2022 don't use anything electrical",
    '\u2022 put out any flames (candles, matches, cigarettes etc).',
  ],
};

export const wasteChemicalInfo = {
  title: 'Waste chemical info',
  heading: "You can't report this problem online",
  subHeading: whatNext,
  detail: [
    "You can't report this kind of problem online at the moment - we're working on it!",
    `Please <a href="tel:${YW_PHONE_NUMBER}"> call us on ${YW_PHONE_NUMBER}</a> so we can help.`,
  ],
};

export const wasteCouncilInfo = {
  title: 'Waste council info',
  heading: 'Blocked highway gullies',
  subHeading: whatNext,
  detail: [
    'Your local council is responsible for problems with highway gullies. These are the medium sized grates that are found by the side of the road.',
    `Please contact your local council to let them know so they can investigate the problem.`,
  ],
};

export const wasteInternalOdourInfo = {
  title: 'Waste internal odour info',
  heading: 'Smells inside your property',
  subHeading: whatNext,
  detail: [
    "From what you've told us, it sounds like there's a blockage in your property's pipework. It's the homeowner's responsibility to fix problems inside your property, we won't be able to help.",
    contactLandlord,
    contactInsurance,
    contactPlumber,
    under10,
    contactPlumberRaw,
  ],
};

export const wasteDrainageInfo = {
  title: 'Waste Drainage Info',
  heading: 'Fixing a problem in your property',
  subHeading: whatNext,
  detail: [
    "It sounds like the problem is in your property's plumbing and it's the homeowner's responsibility to sort it.",
    contactLandlord,
    contactInsurance,
    contactPlumber,
    under10,
    contactPlumberRaw,
  ],
};

export const addressLookupInfo = {
  title: 'address info',
  heading: "We can't find your address",
  subHeading: whatNext,
  detail: [
    "This might be because your property isn't on our system just yet or it could be served by a different water company.",
    `We share boundaries with <a href="${SEVERN_TRENT_URL}">Severn Trent</a>, <a href="${UNITED_UTILITIES_URL}">United Utilities</a> and <a href="${NORTHUMBRIAN_WATER_URL}">Northumbrian Water</a>. They might be the water companies you're looking for.`,
    `If you're not sure which water company you need to contact, you can find out by visiting Water UK and entering your postcode. <a href="${FIND_SUPPLIER_URL}">Find your supplier</a>`,
    `If you think you're within the Yorkshire Water boundary and your address isn't on our system, please <a href="tel:${YW_PHONE_NUMBER}"> give us a call on ${YW_PHONE_NUMBER}</a> and we'll get this sorted.`,
  ],
};

export const addressLookup = {
  title: 'Address lookup',
  heading: "What's the address?",
  postcode: 'Postcode',
  buildingNumber: 'Building name or number (optional)',
  continue: 'Find address',
  invalidPostcode: 'Please enter a valid UK postcode',
};

export const addressResultSingle = {
  title: 'Confirm address',
  heading: 'Is this the correct address?',
  validation: 'Please confirm the address',
};

export const addressResultMultiple = {
  title: 'Select an address',
  heading: 'Which address should we use?',
  missingAddress: "My address isn't listed",
};

export const contactDetails = {
  title: 'Your name',
  heading: 'Please enter your contact details',
  info: `We'll only use your details if we need to contact you about the problem`,
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  phone: 'Contact Number',
  validation: {
    terms: 'To continue, you need to consent to our terms and conditions',
    firstName: 'Please enter a first name',
    lastName: 'Please enter a last name',
    email: 'Please enter your email address',
    emailFormat: 'Please enter a valid email address',
    phone: 'Please enter your contact number',
    phoneFormat: 'Please enter a valid telephone number',
  },
};

export const externalFlowConstant = {
  title: 'External Constant flow',
  heading: commonQuestions.flowConstant,
  yes: yesItIs,
  no: noItIsnt,
};

export const externalRainfallAffected = {
  title: 'External Rainfall affected',
  heading: 'Could recent rainfall have affected this problem?',
  yes: yesItCould,
  notSure: notSure,
};

export const externalWaterSmell = {
  title: 'External Water smell',
  heading: 'Does the water smell?',
  yes: yesItDoes,
  no: noItDoesnt,
};

export const nonHousehold = {
  title: 'Non household step',
  heading: 'This is a business address',
  subHeading: whatNext,
  hint: "Sorry, you can't use this form to report a problem if your property is used for business.",
  detail: [
    "Please check the address is correct. If it's not, you can go back and change it.",
    "If you're a business customer, please contact your retailer for help.",
    `If your address is correct and your property is not used for business, please <a href="tel:${YW_PHONE_NUMBER}"> call us on ${YW_PHONE_NUMBER}</a> so we can update our system and help you with your problem.`,
  ],
  location: locationNearTo,
};

export const thankYouPollution = {
  title: 'Thank you pollution',
  heading: 'Please call us',
  subHeading: 'From what you have told us, this sounds like an emergency.',
  redirect: 'Start new report',
  detail: [
    thankyouForReporting,
    `Please <a href="tel:${YW_PHONE_NUMBER}">call us on ${YW_PHONE_NUMBER}</a> as soon as possible.`,
  ],
};

export const thankYou = {
  title: 'Thank you',
  heading: 'Thanks!',
  subHeading: whatNext,
  redirect: 'Start new report',
  detail: [thankyouForReporting, "We'll review what you've told us and get the problem sorted."],
};

export const summary = {
  title: 'Problem summary',
  heading: "You're almost done!",
  error:
    '**We’re sorry, something went wrong.** \n\n There was an unexpected problem with our system. Our technical team have been notified and will hopefully fix it soon. Please check back later.',
  timePassed: '**Sorry, your call back time has passed.**',
  newTime: 'Please choose a new time.',
  sendTechnician: "This is where we'll send our technician.",
  givenDetails: "We might need to contact you about the problem. The contact details you've given us are:",
  changeDetails: 'Change your contact details',
  confirm: 'Confirm and submit',
  alreadySubmitted: "**You've already submitted this report**",
  sameReport: "We're really sorry, you can't report the same problem more than once.",
  differentReport: '<a href="/">Report a different problem</a>',
  location: locationNearTo,
};

export const appointmentSlot = {
  title: 'Best time to call',
  heading: "When's the best time?",
  validation: 'Please select a time slot',
};

export const flowConstant = {
  title: 'Constant flow',
  heading: commonQuestions.flowConstant,
  yes: yesItIs,
  no: noItIsnt,
};

export const initialLocation = {
  title: 'Initial location',
  heading: commonQuestions.whereProblem,
  internal: internalLocation,
  external: externalLocation,
  public: publicLocation,
};

export const pollutionEntering = {
  title: 'Pollution entering',
  heading: "What's the pollution entering?",
  enteringSea: 'Sea',
  enteringRiver: 'River or stream',
  enteringCanal: 'Canal',
  enteringPond: 'Pond or lake',
  enteringNature: 'Nature Reserve',
  enteringOther: other,
};

export const pollutionFrom = {
  title: 'Pollution source',
  heading: "Where's the pollution coming from?",
  pipe: 'Pipe',
  cover: 'Cover or grate',
  ywSite: 'A Yorkshire Water site',
  ground: 'Through the ground',
  pumping: 'Green pumping station',
};

export const pollutionType = {
  title: 'Pollution type',
  heading: 'What type of pollution?',
  sewage: 'Sewage',
  wipes: 'Wipes or sanitary products',
  soap: 'Soapy or discoloured water',
  chemical: 'Chemicals, oil or petrol',
  fish: 'Dead fish',
  other: other,
  validation: atLeastOne,
};

export const rainfallAffected = {
  title: 'Rainfall affected',
  heading: 'Could recent rainfall have affected this problem?',
  yes: yesItCould,
  unsure: notSure,
};

export const waterFromCover = {
  title: 'Water from cover',
  heading: 'Is there water coming from the cover?',
  yes: yesThereIs,
  no: noThereIsnt,
};

export const waterSmell = {
  title: 'Water smell',
  heading: 'Does the water smell?',
  yes: yesItDoes,
  no: noItDoesnt,
};

export const waterType = {
  title: 'Water type',
  heading: commonQuestions.waterType,
  clean: 'Fresh water',
  waste: 'Sewage',
  unsure: notSure,
  hint: identifyWaterType,
};

export const appointmentPreference = {
  title: 'Appointment preference',
  heading: 'When can we send a technician?',
  asap: 'Straight away',
  appointment: "I'll book an appointment",
};

export const blockedDrainsInfo = {
  title: 'Blocked drains',
  heading: 'There might be a blockage',
  subheadings: ['Have you read our help pages?', "If that didn't help"],
  detail: [
    'If sewage and water from drains can’t leave your property quickly then you might experience bad odours or slow drainage.\nRead our online help pages to see if this is something you can sort yourself.',
    'We need to call you to help get your drainage sorted as soon as possible, we just need a few details.',
  ],
  helpPages: 'Read our help pages',
  callback: 'Request a callback',
};

export const cleanAccessSupply = {
  title: 'External problem',
  heading: commonQuestions.access,
  yes: accessYes,
  no: accessNo,
};

export const cleanCondensationConfirm = {
  title: 'Clean condensation confirmation',
  heading: "There's water on or around my meter",
  condensation: "Let's see if it's condensation",
  label: 'Does the water reappear immediately?',
  yes: yesItDoes,
  no: noItDoesnt,
  detail: [
    'Water on or around your meter could be condensation or from a leaky pipe. Before you continue please check a couple of things.',
    "Is your meter near a washing machine or sink? Take a look and see if the leak is coming from a pipe and dripping onto the meter. If the leak is coming from a pipe, it's the homeowner's responsibility to fix it.",
    'Dry the meter and surrounding pipes with kitchen paper.',
    "\u2022 If the water takes a while to show, it's probably just condensation.",
    '\u2022 If water reappears immediately in drips, then you have a leaky meter.',
  ],
};

export const cleanCoverBoundary = {
  title: 'Clean cover boundary',
  heading: commonQuestions.coverType,
  grate: 'Small grate',
  largeSquare: 'Large square',
  rectangle: 'Medium rectangle',
  smallSquare: 'Small square',
  circle: 'Small circle',
  other: other,
};

export const cleanCoverRisk = {
  title: 'Clean cover risk',
  heading: commonQuestions.leakRisk,
  propertyDamage: propertyDamage,
  accident: accident,
  both: both,
  none: none,
  validation: atLeastOne,
};

export const cleanCoverSeverity = {
  title: 'Clean cover severity',
  heading: commonQuestions.gushingInAir,
  yes: yesItIs,
  no: noItIsnt,
};

export const cleanMeterInternal = {
  title: 'Clean meter control',
  heading: commonQuestions.waterOrigin,
  meter: meter,
  meterPipe: meterPipe,
  stopTap: stopTap,
  unsure: notSure,
};

export const cleanMeterLeaking = {
  title: 'Clean meter leaking',
  heading: 'Is there water on or around the meter?',
  yes: yesThereIs,
  no: noThereIsnt,
};

export const cleanMeterLocation = {
  title: 'Clean meter location',
  heading: 'Is the meter inside or outside the property?',
  inside: "It's on the inside",
  outside: "It's on the outside",
};

export const cleanMeterRiskInternal = {
  title: 'Clean meter risk internal',
  heading: 'Is there a risk to the property?',
  yes: yesThereIs,
  no: noThereIsnt,
};

export const cleanMeterSeverityExt = {
  title: 'Clean meter severity external',
  heading: 'Is the problem getting worse and is there risk to property?',
  propertyRisk: propertyRisk,
  worsening: 'The problem is getting worse',
  both: both,
  none: none,
};

export const cleanMeterSeverityInt = {
  title: 'Clean meter severity internal',
  heading: 'Can you describe the leak?',
  slow: 'Slow drip',
  fast: 'Fast drip',
  steady: 'Steady flow',
  gushing: 'Gushing',
};

export const externalProblem = {
  title: 'External problem',
  heading: commonQuestions.whatProblem,
  leak: 'A leak or flood',
  odour: 'An odour',
  cover: 'A damaged cover',
};

export const externalWasteRisk = {
  title: 'External waste risk',
  heading: commonQuestions.propertyRiskOrAccess,
  propertyRisk: propertyRisk,
  access: access,
  both: both,
  none: none,
  validation: atLeastOne,
};

export const externalWasteSeverity = {
  title: 'External waste water severity',
  heading: commonQuestions.problemScale,
  dampArea: dampArea,
  smallArea: smallArea,
  mediumArea: mediumArea,
  largeArea: largeArea,
};

export const externalWaterType = {
  title: 'External water type',
  heading: commonQuestions.waterType,
  clean: 'Fresh water',
  waste: 'Sewage',
  unsure: notSure,
  hint: identifyWaterType,
};

export const internalMeter = {
  title: 'Internal meter',
  heading: commonQuestions.waterOrigin,
  meter: meter,
  meterPipe: meterPipe,
  stopTap: stopTap,
  unsure: notSure,
};

export const noWaterInfo = {
  title: 'Water supply problem',
  heading: 'Your next steps',
  subHeading: 'Have you read our help pages?',
  subHeading2: "If that didn't help",
  callback: 'Request a callback',
  detail: [
    "If you turn on your taps and find you have no cold water, or very low water pressure, it may simply be because there's work going on in your area. You can find everything you need to resolve the problem below.",
    'We need to call you to help get your water on as soon as possible, we just need a few details.',
  ],
  helpPages: 'Read our help pages',
};

export const supplyIssue = {
  title: 'Supply issue',
  heading: "What's your water supply problem?",
  noWater: 'No water',
  lowPressure: 'Low pressure',
  poorQuality: 'Poor quality',
};

export const supplyPipeEligibility = {
  title: 'Supply Pipe Eligibilty',
  heading: 'Leaks in your garden or driveway',
  detail:
    "As a homeowner, you're responsible for the supply pipe that goes from the street boundary to your home. But don't worry, if there's a leak in your garden or driveway, you might be eligible for a free investigation.",
  subHeading1: "Who's eligible?",
  mayQualify: 'You <strong>may</strong> qualify for this service if:',
  may: "\u2022 you're a household customer and the property is billed by Yorkshire Water for water services.",
  dontQualify: "You <strong>don't</strong> qualify for this service if:",
  dont: [
    '\u2022 your property is not billed by Yorkshire Water for water services',
    '\u2022 your home is less than 2 years old',
    "\u2022 you're a business customer.",
  ],
  subHeading2: "Do you think you're eligible for a free investigation?",
  no: "No, I don't think so",
  yes: Option_Yes,
};

export const wasteRisk = {
  title: 'Waste water risk',
  heading: commonQuestions.propertyRiskOrAccess,
  propertyRisk: propertyRisk,
  access: access,
  both: both,
  none: none,
  validation: atLeastOne,
};

export const waterOriginExternal = {
  title: 'External water origin',
  heading: commonQuestions.waterOrigin2,
  cover: 'A cover',
  pipe: 'Overflow pipe',
  meter: 'External meter',
  ground: 'The ground',
};

export const waterOriginInternal = {
  title: 'Internal water origin',
  heading: commonQuestions.waterOrigin3,
  meter: 'On or around the meter',
  stoptap: leakingStopTap,
  somewhereElse: somewhereElse,
  hint:
    "Tell us where the leak in your home is coming from. If you're a business customer, you won't be able to report your problem using this form, please contact your retailer for help.",
};

export const waterQualityIssue = {
  title: 'Water quality issue',
  heading: "How's your water quality affected?",
  discoloured: 'It is discoloured',
  smells: 'It tastes or smells',
  hardWater: 'It is hard water',
};

export const cleanRisk = {
  title: 'Clean water risk',
  heading: commonQuestions.leakRisk,
  propertyDamage: propertyDamage,
  accident: accident,
  both: both,
  none: none,
  validation: atLeastOne,
};

export const cleanSeverity = {
  title: 'Clean water severity',
  heading: commonQuestions.gushingInAir,
  yes: yesItIs,
  no: noItIsnt,
};

export const coverType = {
  title: 'Cover type',
  heading: commonQuestions.coverType,
  triangle: 'Large triangle',
  rectangle: 'Medium rectangle',
  smallSquare: 'Small square',
  circle: 'Small circle',
  other: other,
};

export const incidentConfirm = {
  title: 'Location search results',
  heading: commonQuestions.incidentConfirm,
  yes: yesItIs,
  no: noItIsnt,
  hint: workingNearHere,
};

export const mapConfirm = {
  title: 'MapConfirm',
  heading: 'Is this location accurate?',
  sendTechnician: 'This is where we will send our technician. Is that correct?',
  anyOtherInformation: 'Is there any other information you can tell us to help our technician find the problem?',
  continue: "Yes, It's there",
  location: locationNearTo,
};

export const mapSearchResult = {
  title: 'Location search results',
  heading: 'Which location are you looking for?',
  label: 'Which location should we use?',
};

export const mapSearch = {
  title: 'Location search',
  heading: "Let's get a rough idea of where this problem is",
  noLocations: noLocations,
  searchTerm: searchTerm,
  ywArea: ywArea,
  currentLocation: currentLocation,
  validation: specifySearchTerm,
};

export const externalRoughLocation = {
  title: 'External rough location',
  heading: "Let's get a rough idea of where this problem is",
  noLocations: noLocations,
  searchTerm: searchTerm,
  ywArea: ywArea,
  currentLocation: currentLocation,
  validation: specifySearchTerm,
};

export const map = {
  title: 'Map',
  heading: 'Tell us exactly where the problem is',
  pinpoint: 'Drag the map to pinpoint where our technicians can find the problem.',
  cantUse: "**Sorry, we can't use this location.**",
  notYwArea:
    "The location you have selected isn't in the Yorkshire Water area. \nPlease check the location is correct and try again.",
  boundaries: `If the location is correct, please contact the water company responsible for the area. We share boundaries with <a href="${SEVERN_TRENT_URL}">Severn Trent</a>, <a href="${UNITED_UTILITIES_URL}">United Utilities</a> and <a href="${NORTHUMBRIAN_WATER_URL}">Northumbrian Water</a>`,
  continue: "It's there",
  findLocation1: 'find your location on a map.',
  findLocation2: 'find your location on a map',
  checkBrowser: 'check your browser location services are enabled and try again',
  tryAgain: 'try again',
  locationInstructions: "**We can't detect your current location** \n\n To tell us your location:",
};

export const wasteSeverity = {
  title: 'Waste water severity',
  heading: commonQuestions.problemScale,
  dampArea: dampArea,
  smallArea: smallArea,
  mediumArea: mediumArea,
  largeArea: largeArea,
};

export const waterOriginPublic = {
  title: 'Public water origin',
  heading: commonQuestions.waterOrigin3,
  cover: 'A cover',
  ground: 'The ground',
  stopTap: 'Above ground stop tap',
  other: other,
};

export const boundaryFlowConstant = {
  title: 'Boundary Flow Constant Cover',
  heading: commonQuestions.flowConstant,
  yes: yesItIs,
  no: noItIsnt,
};

export const boundaryRainfallAffected = {
  title: 'Boundary rainfall affected cover',
  heading: 'Could recent rainfall have affected this problem?',
  yes: yesItCould,
  notSure: notSure,
};

export const boundaryWaterSmell = {
  title: 'Boundary water smell cover',
  heading: 'Does the water smell?',
  yes: yesItDoes,
  no: noItDoesnt,
};

export const boundaryWaterType = {
  title: 'External water type',
  heading: commonQuestions.waterType,
  clean: 'Fresh water',
  waste: 'Sewage',
  unsure: notSure,
  hint: identifyWaterType,
};

export const externalWasteWaterType = {
  title: 'External waste water type',
  heading: commonQuestions.waterType,
  clean: 'Fresh water',
  waste: 'Sewage',
  unsure: notSure,
  hint: identifyWaterType,
};

export const cleanBoundaryAccessSupply = {
  title: 'Clean boundary access supply cover',
  heading: commonQuestions.access,
  yes: accessYes,
  no: accessNo,
};

export const wasteBoundaryAccessSupply = {
  title: 'Waste boundary access supply cover',
  heading: commonQuestions.access,
  yes: accessYes,
  no: accessNo,
};

export const initialLocationWaste = {
  title: 'Initial location waste',
  heading: commonQuestions.whereProblem,
  internal: internalLocation,
  external: externalLocation,
  public: publicLocation,
};

export const wasteBoundaryEscapeInternal = {
  title: 'Waste boundary escape internal',
  heading: 'Are your toilets and sinks draining properly?',
  yes: 'Yes, they are',
  no: "No, they're not",
};

export const wasteExternalCoverBlocked = {
  title: 'Waste external cover blocked',
  heading: "What's happening?",
  blocked: "Water can't enter the grate",
  gushing: 'Water is gushing out of the grate',
};

export const wasteExternalCover = {
  title: 'Waste external cover',
  heading: commonQuestions.coverType,
  grate: 'Medium grate',
  largeSquare: 'Large square',
  rectangle: 'Medium rectangle',
  smallSquare: 'Small square',
  circle: 'Small circle',
  other: other,
};

export const wasteExternalOrigin = {
  title: 'Waste external origin',
  heading: commonQuestions.whatProblem,
  ground: sewageFromGround,
  odour: isAnOdour,
  pollution: "I've spotted pollution in a river, stream or coastal area",
  cover: sewageFromCover,
};

export const wasteIncidentConfirm = {
  title: 'Location search results',
  heading: commonQuestions.incidentConfirm,
  yes: yesItIs,
  no: noItIsnt,
  hint: workingNearHere,
};

export const wasteOriginPollution = {
  title: 'Waste origin pollution',
  heading: 'Where is the pollution coming from?',
  grate: 'Public grate or sewer',
  private: 'Private property',
  ywSite: 'Yorkshire Water equipment or site',
  unsure: notSure,
};

export const wasteBoundaryEscapeLocation = {
  title: 'Waste boundary escape location',
  heading: WASTE_BOUNDARY_ESCAPE_LOCATION_QUESTION,
  detail: allThatApply,
  options: [
    'Into a watercourse (eg. river, stream, sea)',
    'Towards a watercourse (eg. river, stream, sea) or drain at the side of the road',
    'Into a drain at the side of the road',
    'Into my property or attached garage',
    'Into a detached garage or other outside building',
    'My garden or driveway',
  ],
  validation: atLeastOne,
};

export const wasteBoundaryEscapeProperty = {
  title: 'Waste boundary escape property',
  heading: 'How would you describe the problem inside your property or attached garage?',
  flowing: 'Flowing or pooling water',
  damp: dampArea,
};

export const wasteBoundaryEscape = {
  title: 'Waste boundary escape',
  heading: 'Is the drain overflowing?',
  yes: yesItIs,
  no: noItIsnt,
};

export const wasteBoundaryIssues = {
  title: 'Waste boundary issues',
  heading: 'Apart from your drain being blocked, do you have any of these problems?',
  slowToilet: slowToilet,
  odour: isAnOdour,
  both: both,
  none: none,
};

export const wasteCoverBoundary = {
  title: 'Waste cover boundary',
  heading: commonQuestions.coverType,
  grate: 'Small grate',
  largeSquare: 'Large square',
  rectangle: 'Medium rectangle',
  smallSquare: 'Small square',
  circle: 'Small circle',
  other: other,
};

export const wasteOriginBoundary = {
  title: 'Waste origin boundary',
  heading: commonQuestions.whatProblem,
  cover: sewageFromCover,
  ground: sewageFromGround,
  odour: isAnOdour,
  blocked: "I've lifted a cover and the drain is blocked",
};

export const cleanBoundaryRisk = {
  title: 'Clean boundary risk cover',
  heading: commonQuestions.leakRisk,
  propertyDamage: propertyDamage,
  accident: accident,
  both: both,
  none: none,
  validation: atLeastOne,
};

export const cleanBoundarySeverity = {
  title: 'Clean boundary severity cover',
  heading: commonQuestions.gushingInAir,
  yes: yesItIs,
  no: noItIsnt,
};

export const wasteBoundaryRisk = {
  title: 'Waste boundary risk cover',
  heading: commonQuestions.propertyRiskOrAccess,
  propertyRisk: propertyRisk,
  access: access,
  both: both,
  none: none,
  validation: atLeastOne,
};

export const wasteBoundarySeverity = {
  title: 'Waste boundary severity cover',
  heading: commonQuestions.problemScale,
  dampArea: dampArea,
  smallArea: smallArea,
  mediumArea: mediumArea,
  largeArea: largeArea,
};

export const wasteOdourFrequency = {
  title: 'Waste odour frequency',
  heading: 'When can you smell it?',
  constant: allTheTime,
  intermittent: comesAndGoes,
};

export const wasteOdourOther = {
  title: 'Waste odour other',
  heading: 'Apart from the odour, do you have any of these problems?',
  blockedDrain: blockedDrain,
  slowToilet: slowToilet,
  both: both,
  none: none,
};

export const wasteOdourType = {
  title: 'Waste odour type',
  heading: 'What does it smell like?',
  gas: 'Gas',
  chemical: 'Chemical, petrol, diesel or oil',
  farm: 'Farm or muck spreading',
  drain: 'Drains, sewage or eggy',
};

export const wasteOoh = {
  title: 'Waste Ooh',
  heading: 'Reporting an emergency?',
  detail: [
    'Incidents reported online will be looked at during our working hours:',
    '6.30am to 10pm Monday to Saturday\n7.30am to 10pm on Sundays',
    "If it's not an emergency, please continue and we'll pick this up during working hours.",
    `If there's an emergency, please <a href="tel:${YW_PHONE_NUMBER}">call us on ${YW_PHONE_NUMBER}</a>.`,
  ],
};

export const wasteInternalArea = {
  title: 'Waste internal area',
  heading: 'Which part of your property is affected?',
  groundFloor: 'Ground floor',
  garage: 'An attached garage',
  cellar: 'Cellar or basement',
  upstairs: 'Upstairs or above ground floor',
  other: other,
};

export const wasteInternalEntry = {
  title: 'Waste internal entry',
  heading: 'How is the water getting into the property?',
  toilet: 'Overflowing toilet, sink, shower or bath',
  appliance: 'Coming out of a dishwasher, washing machine or pipe work under the kitchen sink',
  sink: 'Overflowing kitchen sink',
  floor: 'Through the wall or floor',
  cellar: 'Through a door or cellar',
};

export const wasteInternalExtdrain = {
  title: 'Waste internal extdrain',
  heading: 'Is the outside drain from the kitchen sink clear?',
  clear: "Yes it's clear and flowing",
  blocked: "No it's blocked or overflowing",
  unsure: "I'm not sure or can't check",
};

export const wasteInternalExtother = {
  title: 'Waste internal extother',
  heading: 'Do you have any problems outside?',
  grate: "I've got a blocked grate outside",
  manhole: 'Sewage is coming out of an outside manhole or grate',
  odour: "There's an odour outside",
  no: Option_No,
};

export const wasteInternalFclty1 = {
  title: 'Waste internal fclty1',
  heading: commonQuestions.facilitiesAffected,
  multipleToilets: 'Downstairs and upstairs toilet',
  downstairsToilet: 'Downstairs toilet only',
  downstairsBath: 'Downstairs shower, sink or bath',
  downstairsFacilities: 'Downstairs toilet and another downstairs facility',
  upstairsToilet: 'Upstairs toilet only',
  upstairsBath: 'Upstairs sink, shower or bath',
  upstairsFacilities: 'Upstairs toilet and another facility in the same room',
};

export const wasteInternalFclty2 = {
  title: 'Waste internal fclty2',
  heading: commonQuestions.facilitiesAffected,
  detail: allThatApply,
  error: atLeastOne2,
  sink: 'Sink',
  shower: 'Shower',
  bath: 'Bath',
};

export const wasteInternalFreq = {
  title: 'Waste internal freq',
  heading: commonQuestions.frequency2,
  constant: allTheTime,
  intermittent: 'It stops and starts',
};

export const wasteInternalOther = {
  title: 'Waste internal other',
  heading: 'Do you have any of these other problems?',
  detail: allThatApply,
  slow: 'Toilet and or sink not draining properly',
  blocked: "I've got a blocked drain outside",
  mahole: 'Sewage is coming out of an outside manhole or grate',
  odour: "There's a sewage odour",
  no: 'None of the above',
  validation: atLeastOne2,
};

export const wasteInternalSame = {
  title: 'Waste internal same',
  heading: 'Are the affected facilities in the same room?',
  different: 'No, they are all in separate rooms',
  same: 'Yes, more than one are in the same room',
};

export const wasteInternalSink = {
  title: 'Waste internal sink',
  heading: 'Is the kitchen sink affected?',
  clear: 'The kitchen sink is fine',
  blocked: 'The kitchen sink is overflowing or not draining properly',
};

export const wasteInternalSource = {
  title: 'Waste internal source',
  heading: commonQuestions.waterOrigin2,
  pipe: 'Above ground wastewater or sewage pipe',
  chute: 'Through an outside coal chute hatch or opening',
  airbrick: 'Outside air brick above the ground level',
  other: 'Somewhere else',
};

export const wasteInternalText = {
  title: 'Waste internal text',
  heading: 'In your own words, where is the water coming from?',
  label: 'Add a brief description',
  placeholder: 'Eg: The water is coming from a pipe',
  validation: 'Please write a brief description below',
};

export const wasteInternalToilet = {
  title: 'Waste internal toilet',
  heading: "What's happening with your toilet?",
  once: 'My toilet flushed once and overflowed',
  none: 'My toilet has overflowed without flushing',
  many: 'My toilet overflowed after more than one flush',
};

export const wasteOdourLocation = {
  title: 'Waste odour location',
  heading: 'Whereabouts can you smell it?',
  once: 'My toilet flushed once and overflowed',
  none: 'My toilet has overflowed without flushing',
  many: 'My toilet overflowed after more than one flush',

  wholeProperty: 'Throughout the whole property',
  groundFloor: 'Whole ground floor',
  upstairs: 'Upstairs only',
  multipleRooms: 'More than one room but not the whole floor',
  oneRoom: 'One room only',
  cellar: 'Cellar only',
};

export const wasteOdourOutside = {
  title: 'Waste odour outside',
  heading: 'Where is the smell?',
  inside: 'Only inside my property',
  insideOutside: 'Inside and outside',
};

export const wasteOdourSource = {
  title: 'Waste odour source',
  heading: 'Where is the smell coming from?',
  appliance: 'Washing machine or dishwasher',
  shower: 'Shower, toilet or sink',
  kitchen: 'Under the kitchen sink',
  other: 'Somewhere else',
};

export const wasteOriginInternal = {
  title: 'Waste Origin Internal',
  heading: commonQuestions.whatProblem,
  house: 'I have wastewater in my house',
  odour: "There's an odour",
  bathroom: "My toilet, sink, shower or bath isn't draining properly or is blocked",
  kitchen: 'My kitchen sink, dishwasher or washing machine is not draining properly',
};

export const wasteTimedAccessToday = {
  title: 'Waste timed access today',
  heading: 'Will somebody over the age of 18 be at the property for the rest of the day up until 10pm?',
  yes: Option_Yes,
  no: Option_No,
};

export const wasteTimedAccessTomorrow = {
  title: 'Waste timed access tomorrow',
  heading:
    'Will somebody over the age of 18 be at the property for the rest of the day up until 10pm tonight or 12pm tomorrow?',
  yes: Option_Yes,
  no: Option_No,
};

export const wasteResponsibiltyBoundary = {
  title: 'Waste Responsibilty Boundary',
  heading: "Who's responsible for the blocked drain?",
  detail:
    "If there's a blockage in a drainage pipe or sewer, responsibility for fixing it depends on where the problem is.",
  homeOwnerResp: 'The homeowner is responsible for:',
  homeOwner: [
    '\u2022 all the pipes inside the property. These include toilets, sink drains and any outdoor guttering or pipes attached to the property.',
    '\u2022 the section of pipe that goes into the ground, either directly or into a gully close to the property, until it reaches a shared drainage pipe or public sewer.',
    "\u2022 drains shared between more than one property if your home was built after 2011, unless they've been adopted by Yorkshire Water.",
  ],
  ywResp: 'Yorkshire Water are responsible for:',
  yw: [
    '\u2022 drains shared between more than one property which flow into a public sewer, if your home was built before 2011.',
    '\u2022 drains outside of the property boundary which flow into a public sewer.',
    '\u2022 public sewers.',
  ],
  subHeading: 'Whose responsibility is it?',
  owner: "I think it's the homeowner's responsibility",
  yorkshireWater: "I think it's Yorkshire Water's responsibility",
  unsure: notSure,
};
