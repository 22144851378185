import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  GULLY,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  SQUARE,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  COVER,
  GROUND,
  ODOUR,
  POLLUTION,
} from '../../../../constants';
import { images, wasteExternalCover } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteExternalCover.heading);
const coverControl = new Control('question', 'wasteExternalCover');
coverControl.setLabel(wasteExternalCover.heading);
coverControl.setDefault('');
coverControl.addOption(wasteExternalCover.grate, GULLY, null, false, false, images.highwayGully);
coverControl.addOption(wasteExternalCover.largeSquare, SQUARE, null, false, false, images.squareSewage);
coverControl.addOption(wasteExternalCover.rectangle, SLUICE_VALVE, null, false, false, images.sluiceRectangle);
coverControl.addOption(wasteExternalCover.smallSquare, STOPTAP, null, false, false, images.sluiceSquare);
coverControl.addOption(wasteExternalCover.circle, METER, null, false, false, images.meter);
coverControl.addOption(wasteExternalCover.other, OTHER, 'IconQuestion', false, false, null);
coverControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteExternalCoverStep = new Step('step', 'waste-external-cover');
wasteExternalCoverStep.addBlock(headingBlock);
wasteExternalCoverStep.addBlock(coverControl);
wasteExternalCoverStep.addBlock(buttonsBlock);
wasteExternalCoverStep.setTitle(wasteExternalCover.title);

wasteExternalCoverStep.setConstraints({
  initialLocationWaste: {
    inclusion: [PUBLIC],
    exclusion: [INTERNAL, EXTERNAL],
  },
  wasteExternalOrigin: {
    inclusion: [COVER],
    exclusion: [ODOUR, POLLUTION, GROUND],
  },
});

export { wasteExternalCoverStep };
