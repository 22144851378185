import {
  YES,
  NO,
  WASTE,
  CLEAN,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  METER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  TRIANGLE,
  GUSHING,
  NONE,
  ABOVE_GROUND_STOP_TAP,
  PLUMBING,
  POLLUTION,
  PRESSURE,
} from '../constants';

const outcomeConfig = [
  {
    name: 'location',
    default: null,
    possibleValues: [
      { value: 'INTERNAL', constraints: [{ nwlpInitialProblem: { presence: true } }] },
      {
        value: 'INTERNAL',
        constraints: [{ initialLocation: { presence: true, inclusion: [INTERNAL] } }],
      },
      {
        value: 'INTERNAL',
        constraints: [
          {
            initialLocationWaste: {
              presence: true,
              inclusion: [INTERNAL],
            },
          },
        ],
      },
      {
        value: 'EXTERNAL',
        constraints: [{ initialLocation: { presence: true, inclusion: [EXTERNAL] } }],
      },
      {
        value: 'EXTERNAL',
        constraints: [
          {
            initialLocationWaste: {
              presence: true,
              inclusion: [EXTERNAL],
            },
          },
        ],
      },
      {
        value: 'PUBLIC',
        constraints: [{ initialLocation: { presence: true, inclusion: [PUBLIC] } }],
      },
      {
        value: 'PUBLIC',
        constraints: [
          {
            initialLocationWaste: {
              presence: true,
              inclusion: [PUBLIC],
            },
          },
        ],
      },
    ],
  },
  {
    name: 'group',
    default: null,
    possibleValues: [
      { value: 'NOWATER', constraints: [{ nwlpAnyWater: { presence: true, inclusion: [NO] } }] },
      {
        value: 'PRESSURE',
        constraints: [
          { nwlpInitialProblem: { presence: true, inclusion: [PRESSURE] } },
          { nwlpAnyWater: { presence: true, inclusion: [YES] } },
        ],
      },
      { value: 'POLLUTION', constraints: [{ initialProblem: { inclusion: [POLLUTION] } }] },
      {
        value: 'METER',
        constraints: [
          { waterOrigin: { presence: true, inclusion: [METER] } },
          { coverType: { presence: true, inclusion: [METER] } },
        ],
      },
      {
        value: 'METER',
        constraints: [
          { externalWaterOrigin: { presence: true, inclusion: [METER] } },
          { externalCoverType: { presence: true, inclusion: [METER] } },
        ],
      },
      {
        value: 'CLEAN',
        constraints: [
          {
            waterOrigin: {
              presence: true,
              inclusion: [ABOVE_GROUND_STOP_TAP, PLUMBING],
            },
          },
          {
            coverType: {
              presence: true,
              inclusion: [SLUICE_VALVE, STOPTAP],
            },
          },
          { waterType: { presence: true, inclusion: [CLEAN] } },
          { constantPressure: { presence: true, inclusion: [YES] } },
          { waterSmell: { presence: true, inclusion: [NO] } },
        ],
      },
      {
        value: 'CLEAN',
        constraints: [
          {
            externalCoverType: {
              presence: true,
              inclusion: [SLUICE_VALVE, STOPTAP],
            },
          },
          { externalWaterType: { presence: true, inclusion: [CLEAN] } },
          { externalConstantPressure: { presence: true, inclusion: [YES] } },
          { externalWaterSmell: { presence: true, inclusion: [NO] } },
          { cleanSupplyInfoCol: { presence: true, inclusion: [true] } },
          { cleanLeakingmeterInfoCol: { presence: true, inclusion: [true] } },
          { cleanIntstoptapInfoCol: { presence: true, inclusion: [true] } },
          { cleanCondenInfoCol: { presence: true, inclusion: [true] } },
          { cleanOverflowInfoCol: { presence: true, inclusion: [true] } },
          { internalInformationCol: { presence: true, inclusion: [true] } },
        ],
      },
      {
        value: 'CLEAN',
        constraints: [
          {
            initialLocation: {
              presence: true,
              inclusion: [INTERNAL],
            },
          },
        ],
      },
      {
        value: 'WASTE',
        constraints: [
          { waterType: { presence: true, inclusion: [WASTE] } },
          { rainfallAffected: { presence: true, inclusion: [YES] } },
          { waterSmell: { presence: true, inclusion: [YES] } },
          {
            coverType: {
              presence: true,
              inclusion: [TRIANGLE, SQUARE],
            },
          },
          { wasteGasInfoCol: { presence: true, inclusion: [true] } },
        ],
      },
      {
        value: 'WASTE',
        constraints: [
          { externalWaterType: { presence: true, inclusion: [WASTE] } },
          {
            externalRainfallAffected: { presence: true, inclusion: [YES] },
          },
          { externalWaterSmell: { presence: true, inclusion: [YES] } },
          {
            externalCoverType: {
              presence: true,
              inclusion: [GRATE, SQUARE],
            },
          },
        ],
      },
      {
        value: 'WASTE',
        constraints: [
          {
            initialLocationWaste: {
              presence: true,
              inclusion: [PUBLIC],
            },
          },
        ],
      },
      {
        value: 'WASTE',
        constraints: [
          {
            initialLocationWaste: {
              presence: true,
              inclusion: [PUBLIC],
            },
          },
        ],
      },
      {
        value: 'WASTE',
        constraints: [
          {
            initialLocationWaste: {
              presence: true,
              inclusion: [PUBLIC],
            },
          },
        ],
      },
      {
        value: 'WASTE',
        constraints: [
          {
            initialLocationWaste: {
              presence: true,
              inclusion: [EXTERNAL],
            },
          },
        ],
      },
      {
        value: 'WASTE',
        constraints: [
          {
            initialLocationWaste: {
              presence: true,
              inclusion: [INTERNAL],
            },
          },
        ],
      },
    ],
  },
  {
    name: 'reasonId',
    default: null,
    possibleValues: [
      {
        value: 5,
        constraints: [
          { initialProblem: { inclusion: [POLLUTION] } },
          { waterType: { presence: true, inclusion: [WASTE] } },
          { rainfallAffected: { presence: true, inclusion: [YES] } },
          { waterSmell: { presence: true, inclusion: [YES] } },
          {
            coverType: {
              presence: true,
              inclusion: [TRIANGLE, SQUARE],
            },
          },
        ],
      },
      {
        value: 4,
        constraints: [
          { waterOrigin: { presence: true, inclusion: [METER] } },

          {
            coverType: {
              presence: true,
              inclusion: [SLUICE_VALVE, STOPTAP, METER],
            },
          },
          {
            waterOrigin: {
              presence: true,
              inclusion: [ABOVE_GROUND_STOP_TAP, PLUMBING],
            },
          },
          { waterType: { presence: true, inclusion: [CLEAN] } },
          { constantPressure: { presence: true, inclusion: [YES] } },
          { waterSmell: { presence: true, inclusion: [NO] } },
        ],
      },
    ],
  },
  {
    name: 'isSevere',
    default: false,
    possibleValues: [
      {
        value: true,
        constraints: [
          { wasteSeverity: { presence: true } },
          { cleanSeverity: { presence: true, inclusion: [GUSHING] } },
          {
            cleanRisk: {
              presence: true,
              exclusion: [NONE],
            },
          },
        ],
      },
    ],
  },

  {
    name: 'hasShutoff',
    default: false,
    possibleValues: [],
  },
  {
    name: 'isAnonymous',
    default: false,
    possibleValues: [],
  },
];

export default outcomeConfig;
