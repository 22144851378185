function DateTimeConfirm() {
  this.type = 'dateTimeConfirm';
}

DateTimeConfirm.prototype.setConstraints = function(constraints) {
  this.constraints = constraints;
  return this;
};

export default DateTimeConfirm;
