import React from 'react';
import PropTypes from 'prop-types';
import { FluidField } from 'yw-ui.v2';
import { YES } from '../../../constants';

export const FieldBlock = ({
  chars,
  collapse,
  cols,
  condensed,
  form,
  hideLabel,
  hint,
  isRequiredMarker,
  label,
  maxLength,
  name,
  options,
  showMaxLength,
  type,
  className,
  placeholder,
  onlyShowInColleagueMode,
}) => {
  const showComponent = onlyShowInColleagueMode === false || form.values.isColleagueMode === YES;
  return (
    <>
      {showComponent && (
        <div className={className}>
          <FluidField
            id={name}
            qa={name}
            label={label}
            name={name}
            component={type}
            options={options}
            hint={hint}
            hideLabel={hideLabel}
            characters={chars}
            collapse={collapse}
            condensed={condensed}
            isRequiredMarker={isRequiredMarker}
            cols={cols}
            maxLength={maxLength}
            placeholder={placeholder}
          />
          {showMaxLength && maxLength && form && (
            <p className="u-pull-top--400 u-text-align--right">
              <span id="chars">{maxLength - (form.values[name] ? form.values[name].length : 0)}</span> characters
              remaining
            </p>
          )}
        </div>
      )}
    </>
  );
};

FieldBlock.propTypes = {
  chars: PropTypes.number,
  collapse: PropTypes.bool,
  cols: PropTypes.number,
  condensed: PropTypes.bool,
  form: PropTypes.object,
  hideLabel: PropTypes.bool,
  hint: PropTypes.string,
  isRequiredMarker: PropTypes.bool,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  showMaxLength: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onlyShowInColleagueMode: PropTypes.bool,
};

FieldBlock.defaultProps = {
  chars: null,
  cols: null,
  collapse: false,
  condensed: false,
  form: null,
  hideLabel: false,
  hint: null,
  isRequiredMarker: false,
  maxLength: null,
  options: null,
  showMaxLength: false,
  type: 'checkbox',
  className: '',
  placeholder: null,
  onlyShowInColleagueMode: false,
};
export default FieldBlock;
