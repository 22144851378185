import { ButtonRow, Control, Heading, Step } from '../../models';
import {
  YES,
  UNSURE,
  WASTE,
  CLEAN,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  OTHER,
  COVER,
  GROUND,
  ODOUR,
  POLLUTION,
} from '../../../constants';
import { externalRainfallAffected } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', externalRainfallAffected.heading);
const rainAffectedControl = new Control('question', 'externalRainfallAffected');
rainAffectedControl.setLabel(externalRainfallAffected.heading);
rainAffectedControl.addOption(externalRainfallAffected.yes, YES, 'IconRain');
rainAffectedControl.addOption(externalRainfallAffected.notSure, UNSURE, 'IconCross');
rainAffectedControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const externalRainfallAffectedStep = new Step('step', 'external-rainfall-affected');
externalRainfallAffectedStep.addBlock(headingBlock);
externalRainfallAffectedStep.addBlock(rainAffectedControl);
externalRainfallAffectedStep.addBlock(buttonsBlock);
externalRainfallAffectedStep.setTitle(externalRainfallAffected.title);

externalRainfallAffectedStep.setConstraints([
  {
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      presence: true,
      inclusion: [UNSURE],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
  },
]);

export { externalRainfallAffectedStep };
