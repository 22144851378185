import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { AccordionSingle, Message } from 'yw-ui.v2/dist/components';

export const AccordionBlock = ({ heading, id, content }) => {
  return (
    <AccordionSingle
      className="u-mb--300 u-mt--400"
      title={heading}
      contentPadding="sm"
      contentBackground="ywLightGrey"
      qa={id}
      id={id}
      contentBg="ywLightGrey"
    >
      <Message id={id} qa={id} className="u-mb--200">
        <ReactMarkdown source={content} escapeHtml={false} />
      </Message>
    </AccordionSingle>
  );
};

AccordionBlock.propTypes = {
  content: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default AccordionBlock;
