import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import InfoBlock from '../InfoBlock';

const BannerBlock = ({ header, infos, level }) => {
  return (
    <Fragment>
      <div className={`c-message c-message--${level}`}>
        {header && <h2 className="banner_header">{header}</h2>}
        {infos.map((info) => (
          <>
            <InfoBlock text={info} answers={{}} id="bannerBlockInfo" key={info} />
          </>
        ))}
      </div>
      <br />
    </Fragment>
  );
};

BannerBlock.propTypes = {
  header: PropTypes.string,
  infos: PropTypes.array,
  level: PropTypes.string,
};

export default BannerBlock;
