import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export const SummaryBlock = ({ summary, id }) => {
  return (
    <Fragment>
      {summary && (
        <p size="sm" className="u-mb--300" id={id} data-qa={id}>
          {summary}
        </p>
      )}
    </Fragment>
  );
};

SummaryBlock.propTypes = {
  id: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
};

export default SummaryBlock;
