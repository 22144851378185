import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  YES,
  NO,
  UNSURE,
  WASTE,
  CLEAN,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GULLY,
  GRATE,
  SQUARE,
  GUSHING,
  LOCATION_METHOD_ADDRESS_LOOKUP,
  LOCATION_METHOD_CURRENT_LOCATION,
  LOCATION_METHOD_MAP_SEARCH,
  TRICKLING,
  COVER,
  DRAIN,
  GROUND,
  ODOUR,
  POLLUTION,
} from '../../../../constants';
import { cleanBoundarySeverity } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', cleanBoundarySeverity.heading);
const cleanBoundarySeverityControl = new Control('question', 'cleanBoundarySeverity');
cleanBoundarySeverityControl.setLabel(cleanBoundarySeverity.heading);
cleanBoundarySeverityControl.addOption(cleanBoundarySeverity.yes, GUSHING, 'IconGushing', false, GUSHING);
cleanBoundarySeverityControl.addOption(cleanBoundarySeverity.no, TRICKLING, 'IconCross', false, TRICKLING);
cleanBoundarySeverityControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const cleanBoundarySeverityStep = new Step('step', 'clean-boundary-severity');
cleanBoundarySeverityStep.addBlock(headingBlock);
cleanBoundarySeverityStep.addBlock(cleanBoundarySeverityControl);
cleanBoundarySeverityStep.addBlock(buttonsBlock);
cleanBoundarySeverityStep.setTitle(cleanBoundarySeverity.title);

cleanBoundarySeverityStep.setConstraints([
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [COVER],
      exclusion: [DRAIN, GROUND, ODOUR],
    },
    wasteExternalCoverType: {
      presence: true,
      inclusion: [METER, SLUICE_VALVE, STOPTAP],
      exclusion: [OTHER, GRATE, SQUARE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [COVER],
      exclusion: [DRAIN, GROUND, ODOUR],
    },
    wasteExternalCoverType: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [METER, SLUICE_VALVE, STOPTAP, GRATE, SQUARE],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [WASTE, UNSURE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [COVER],
      exclusion: [DRAIN, GROUND, ODOUR],
    },
    wasteExternalCoverType: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [METER, SLUICE_VALVE, STOPTAP, GRATE, SQUARE],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [COVER],
      exclusion: [DRAIN, GROUND, ODOUR],
    },
    wasteExternalCoverType: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [METER, SLUICE_VALVE, STOPTAP, GRATE, SQUARE],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [DRAIN, COVER, ODOUR],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [DRAIN, COVER, ODOUR],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [WASTE, UNSURE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [WASTE, UNSURE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [WASTE, UNSURE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [WASTE, UNSURE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [SLUICE_VALVE, STOPTAP, METER],
      exclusion: [OTHER, SQUARE, GULLY],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [SLUICE_VALVE, STOPTAP, METER],
      exclusion: [OTHER, SQUARE, GULLY],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SQUARE, GULLY, SLUICE_VALVE, STOPTAP, METER],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [UNSURE, WASTE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SQUARE, GULLY, SLUICE_VALVE, STOPTAP, METER],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [UNSURE, WASTE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
]);

export { cleanBoundarySeverityStep };
