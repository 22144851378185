/* ADDRESS */
export const FETCH_ADDRESSES_REQUEST = 'FETCH_ADDRESSES_REQUEST';
export const FETCH_ADDRESSES_SUCCESS = 'FETCH_ADDRESSES_SUCCESS';
export const FETCH_ADDRESSES_ERROR = 'FETCH_ADDRESSES_ERROR';
export const FETCH_ADDRESSES_LOADING = 'FETCH_ADDRESSES_LOADING';

/* LOCATION */
export const FETCH_LOCATION_CHECKING = 'FETCH_LOCATION_CHECKING';
export const SET_SEARCHED_LOCATION = 'SET_SEARCHED_LOCATION';
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
export const SET_IS_YORKSHIRE = 'SET_IS_YORKSHIRE';
export const FETCH_LOCATION_ERROR = 'FETCH_LOCATION_ERROR';
export const SET_MAP_LOCATION = 'SET_MAP_LOCATION';

/* CONFIG */
export const FETCH_CONFIG_LOADING = 'FETCH_CONFIG_LOADING';
export const FETCH_CONFIG_ERROR = 'FETCH_CONFIG_ERROR';
export const FETCH_LOOKUP_ERROR = 'FETCH_LOOKUP_ERROR';
export const SET_CONFIG = 'SET_CONFIG';
export const LOAD_STEP = 'LOAD_STEP';
export const SET_ANSWER = 'SET_ANSWER';
export const SET_ANSWERS = 'SET_ANSWERS';
export const SET_STEP = 'SET_STEP';
export const SET_VISITED_STEPS = 'SET_VISITED_STEPS';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
export const SET_SUMMARY = 'SET_SUMMARY';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';
export const SET_LOOKUP = 'SET_LOOKUP';
export const SET_USER_PATH = 'SET_USER_PATH';

/* ATTACHMENTS */
export const ATTACHMENT_UPLOAD_ADD = 'ATTACHMENT_UPLOAD_ADD';
export const ATTACHMENT_UPLOAD_REQUEST = 'ATTACHMENT_UPLOAD_REQUEST';
export const ATTACHMENT_UPLOAD_SUCCESS = 'ATTACHMENT_UPLOAD_SUCCESS';
export const ATTACHMENT_UPLOAD_ERROR = 'ATTACHMENT_UPLOAD_ERROR';
export const ATTACHMENT_UPLOADING = 'ATTACHMENT_UPLOADING';
export const ATTACHMENT_UPLOAD_REMOVE = 'ATTACHMENT_UPLOAD_REMOVE';

/* CALLBACK */
export const FETCH_CALLBACKS_REQUEST = 'FETCH_CALLBACKS_REQUEST';
export const FETCH_CALLBACKS_SUCCESS = 'FETCH_CALLBACKS_SUCCESS';
export const FETCH_CALLBACKS_ERROR = 'FETCH_CALLBACKS_ERROR';
export const FETCH_CALLBACKS_LOADING = 'FETCH_CALLBACKS_LOADING';

/* PROBLEM */
export const SUBMIT_OUTCOME_REQUEST = 'SUBMIT_OUTCOME_REQUEST';
export const SUBMIT_OUTCOME_SUCCESS = 'SUBMIT_OUTCOME_SUCCESS';
export const SUBMIT_OUTCOME_ERROR = 'SUBMIT_OUTCOME_ERROR';
export const SUBMIT_OUTCOME_LOADING = 'SUBMIT_OUTCOME_LOADING';
export const SUBMIT_OUTCOME_SUMMARY_SUCCESS = 'SUBMIT_OUTCOME_SUMMARY_SUCCESS';

/* HOOK */
export const HOOK_SUCCESS = 'HOOK_SUCCESS';
export const HOOK_ERROR = 'HOOK_ERROR';
export const HOOK_LOADING = 'HOOK_LOADING';
export const HOOK_COUNT = 'HOOK_COUNT';
