import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  YES,
  NO,
  UNSURE,
  WASTE,
  CLEAN,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  ACCESS,
  BOTH,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  GULLY,
  DAMP,
  LARGE,
  LOCATION_METHOD_ADDRESS_LOOKUP,
  LOCATION_METHOD_CURRENT_LOCATION,
  LOCATION_METHOD_MAP_SEARCH,
  MEDIUM,
  NONE,
  PROPERTY_DAMAGE,
  SMALL,
  COVER,
  DRAIN,
  GROUND,
  ODOUR,
  POLLUTION,
  GUSHING,
  BLOCKED,
} from '../../../../constants';
import { wasteBoundarySeverity } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteBoundarySeverity.heading);
const wasteBoundarySeverityControl = new Control('question', 'wasteBoundarySeverity');
wasteBoundarySeverityControl.setLabel(wasteBoundarySeverity.heading);
wasteBoundarySeverityControl.addOption(wasteBoundarySeverity.dampArea, DAMP, 'IconDampPatch');
wasteBoundarySeverityControl.addOption(wasteBoundarySeverity.smallArea, SMALL, 'IconSmallArea');
wasteBoundarySeverityControl.addOption(wasteBoundarySeverity.mediumArea, MEDIUM, 'IconMediumArea');
wasteBoundarySeverityControl.addOption(wasteBoundarySeverity.largeArea, LARGE, 'IconLargeArea');
wasteBoundarySeverityControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteBoundarySeverityStep = new Step('step', 'waste-boundary-severity');
wasteBoundarySeverityStep.addBlock(headingBlock);
wasteBoundarySeverityStep.addBlock(wasteBoundarySeverityControl);
wasteBoundarySeverityStep.addBlock(buttonsBlock);
wasteBoundarySeverityStep.setTitle(wasteBoundarySeverity.title);

wasteBoundarySeverityStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [COVER],
      exclusion: [DRAIN, GROUND, ODOUR],
    },
    wasteExternalCoverType: {
      inclusion: [OTHER, GRATE, SQUARE],
      exclusion: [METER, SLUICE_VALVE, STOPTAP],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [ACCESS, BOTH, NONE, PROPERTY_DAMAGE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [GROUND],
      exclusion: [DRAIN, COVER, ODOUR],
    },
    boundaryRainfallAffected: {
      inclusion: [YES],
      exclusion: [UNSURE],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [GROUND],
      exclusion: [DRAIN, COVER, ODOUR],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [WASTE],
      exclusion: [CLEAN, UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [WASTE],
      exclusion: [CLEAN, UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [YES],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [YES],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [SQUARE],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, OTHER, GULLY],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [SQUARE],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, OTHER, GULLY],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [GULLY],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, OTHER, SQUARE],
    },
    wasteExternalCoverBlocked: {
      inclusion: [GUSHING],
      exclusion: [BLOCKED],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [GULLY],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, OTHER, SQUARE],
    },
    wasteExternalCoverBlocked: {
      inclusion: [GUSHING],
      exclusion: [BLOCKED],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, SQUARE, GULLY],
    },
    boundaryWaterType: {
      inclusion: [WASTE],
      exclusion: [UNSURE, CLEAN],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, SQUARE, GULLY],
    },
    boundaryWaterType: {
      inclusion: [WASTE],
      exclusion: [UNSURE, CLEAN],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      inclusion: [YES],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, SQUARE, GULLY],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      inclusion: [YES],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
]);

export { wasteBoundarySeverityStep };
