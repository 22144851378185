import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'yw-ui.v2';

class Step extends Component {
  renderContainer = ({ children, isContained, stepId }) => {
    if (isContained) {
      return (
        <Card id={stepId} qa={stepId}>
          <div className="u-pr--400-lg u-pl--400-lg u-pt--300-lg u-pb--300-lg">{children}</div>
        </Card>
      );
    } else {
      return children;
    }
  };

  render() {
    const { children, isContained, stepId } = this.props;
    const RenderContainer = this.renderContainer;
    return (
      <RenderContainer isContained={isContained} stepId={stepId}>
        {children}
      </RenderContainer>
    );
  }
}

Step.propTypes = {
  children: PropTypes.node,
  isContained: PropTypes.bool,
  stepId: PropTypes.string.isRequired,
};

Step.defaultProps = {
  isContained: false,
};

export default Step;
