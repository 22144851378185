import { ButtonRow, Control, Heading, Hook, Step } from '../../models';
import {
  YES,
  NO,
  STEP_WORKING_HOURS_LOOKUP,
  GARDEN,
  OUTBUILDING,
  PROPERTY,
  GULLY,
  WATERCOURSE,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  COVER,
  DRAIN,
  GROUND,
  ODOUR,
  TOWARDS_WATERCOURSE,
} from '../../../constants';
import { wasteBoundaryEscapeInternal } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteBoundaryEscapeInternal.heading);
const wasteBoundaryEscapeInternalControl = new Control('question', 'wasteBoundaryEscapeInternal');
wasteBoundaryEscapeInternalControl.setLabel(wasteBoundaryEscapeInternal.heading);
wasteBoundaryEscapeInternalControl.addOption(wasteBoundaryEscapeInternal.yes, YES, 'IconCheckTick');
wasteBoundaryEscapeInternalControl.addOption(wasteBoundaryEscapeInternal.no, NO, 'IconCross');
wasteBoundaryEscapeInternalControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteBoundaryEscapeInternalStep = new Step('step', 'waste-boundary-escape-internal');
wasteBoundaryEscapeInternalStep.addBlock(headingBlock);
wasteBoundaryEscapeInternalStep.addBlock(wasteBoundaryEscapeInternalControl);
wasteBoundaryEscapeInternalStep.addBlock(buttonsBlock);
wasteBoundaryEscapeInternalStep.exitHook = new Hook(STEP_WORKING_HOURS_LOOKUP, '');
wasteBoundaryEscapeInternalStep.setTitle(wasteBoundaryEscapeInternal.title);

wasteBoundaryEscapeInternalStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteBoundaryEscapeLocation: {
      inclusion: [GULLY, TOWARDS_WATERCOURSE],
      exclusion: [WATERCOURSE, PROPERTY, GARDEN, OUTBUILDING],
    },
  },
]);

export { wasteBoundaryEscapeInternalStep };
