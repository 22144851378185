import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import moment from 'moment';

export const SummaryTableBlock = ({ id, rows, answers, stepTo }) => {
  const formatValue = (value, format) => {
    if (format) {
      if (format.type === 'time') {
        return moment(value).format('HH:mma');
      } else if (format.type === 'interval') {
        if (format.options && format.options.interval) {
          let newMoment = moment(value);
          let newMomentEnd = moment(value).add(format.options.interval, 'minutes');
          return newMoment.format('HH:mma') + ' to ' + newMomentEnd.format('HH:mma');
        }
      }
    }
    return value;
  };

  return (
    <Fragment>
      {rows && (
        <div className="c-review-details c-review-details--inline" id={`${id}`} data-qa={id}>
          {rows.map(({ id, value, label, format, prefix, postfix, editSlug, editMode, editParams }, i) => {
            let processedValue = !validate.format(value, answers).includes('undefined')
              ? validate.format(value, answers)
              : null;

            return (
              <Fragment key={i}>
                {processedValue && (
                  <Fragment>
                    <div id={`${id}`} data-qa={`${id}`} className="c-review-details__item">
                      <div className="c-review-details__key" id={`${id}_label`} data-qa={`${id}_label`}>
                        {label}
                      </div>
                      <div
                        className="c-review-details__value"
                        role="gridcell"
                        id={`${id}_value`}
                        data-qa={`${id}_value`}
                      >
                        {prefix}
                        {formatValue(processedValue, format)}
                        {postfix}
                      </div>
                    </div>

                    {editSlug && (
                      <div className="c-review-details__actions" id={`${id}_actions`} data-qa={`${id}_actions`}>
                        <button
                          type="button"
                          className="c-button--link c-button c-button--text-centre"
                          icon="button"
                          id={`${id}_change`}
                          data-qa={`${id}_change`}
                          aria-label="Change button"
                          onClick={() => stepTo({ target: editSlug, editMode: editMode, params: editParams })}
                        >
                          Change
                        </button>
                      </div>
                    )}
                  </Fragment>
                )}
              </Fragment>
            );
          })}
        </div>
      )}
    </Fragment>
  );
};

SummaryTableBlock.propTypes = {
  answers: PropTypes.object.isRequired,
  stepTo: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
};

export default SummaryTableBlock;
