import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  YES,
  NO,
  UNSURE,
  WASTE,
  CLEAN,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  ACCESS,
  BOTH,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  DAMP,
  LOCATION_METHOD_CURRENT_LOCATION,
  LOCATION_METHOD_MAP_SEARCH,
  LARGE,
  MEDIUM,
  NONE,
  ABOVE_GROUND_STOP_TAP,
  COVER,
  GROUND,
  OVERFLOW_PIPE,
  PROPERTY_DAMAGE,
  SMALL,
} from '../../../../constants';
import { externalWasteSeverity } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', externalWasteSeverity.heading);
const externalWasteSeverityControl = new Control('question', 'externalWasteSeverity');
externalWasteSeverityControl.setLabel(externalWasteSeverity.heading);
externalWasteSeverityControl.addOption(externalWasteSeverity.dampArea, DAMP, 'IconDampPatch');
externalWasteSeverityControl.addOption(externalWasteSeverity.smallArea, SMALL, 'IconSmallArea');
externalWasteSeverityControl.addOption(externalWasteSeverity.mediumArea, MEDIUM, 'IconMediumArea');
externalWasteSeverityControl.addOption(externalWasteSeverity.largeArea, LARGE, 'IconLargeArea');
externalWasteSeverityControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const externalWasteSeverityStep = new Step('step', 'external-waste-severity');
externalWasteSeverityStep.addBlock(headingBlock);
externalWasteSeverityStep.addBlock(externalWasteSeverityControl);
externalWasteSeverityStep.addBlock(buttonsBlock);
externalWasteSeverityStep.setTitle(externalWasteSeverity.title);

externalWasteSeverityStep.setConstraints([
  {
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [UNSURE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
    },
    externalConstantPressure: {
      inclusion: [NO],
    },
    externalWaterSmell: {
      inclusion: [YES],
    },
    externalWasteRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [UNSURE],
    },
    externalRainfallAffected: {
      inclusion: [YES],
    },
    externalWasteRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [WASTE],
    },
    externalWasteRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
      exclusion: [OVERFLOW_PIPE, METER, GROUND],
    },
    externalCoverType: {
      inclusion: [GRATE, SQUARE],
      exclusion: [STOPTAP, METER, SLUICE_VALVE, OTHER],
    },
    externalWasteRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
  },
  {
    initialLocation: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_MAP_SEARCH, LOCATION_METHOD_CURRENT_LOCATION],
    },
    waterOrigin: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [ABOVE_GROUND_STOP_TAP, COVER, GROUND],
    },
    externalWaterType: {
      presence: true,
      inclusion: [WASTE],
      exclusion: [CLEAN, UNSURE],
    },
    externalWasteRisk: {
      presence: true,
      inclusion: [ACCESS, BOTH, NONE, PROPERTY_DAMAGE],
    },
  },
]);

export { externalWasteSeverityStep };
