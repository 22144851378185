import { Accordion, ButtonRow, Heading, Message, Step, Control, InfoBlock } from '../../../models';
import { EXTERNAL, YES, INTERNAL, PUBLIC } from '../../../../constants';
import { uploadPage } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', uploadPage.heading);
const infoBlock = new InfoBlock('step-info', uploadPage.subHeader);
const hintBlockExternal = new Message('step-hint', uploadPage.calloutBoxTop).setState('info');
const safetyBlockExternal = new Message('step-hint', uploadPage.calloutBoxSafety).setState('warning');
const tipsBlock = new Accordion('accordion-photos', uploadPage.accordionHeader).setContent(uploadPage.accordionBody);
const uploadBlock = {
  id: 'image-upload',
  type: 'fileUpload',
  name: 'attachments',
  ctaText: uploadPage.buttonText,
};
const buttonsBlock = new ButtonRow();
buttonsBlock.setConstraints({
  'attachments.isUploading': {
    inclusion: [false],
    exclusion: [true],
  },
});
const buttonsBlockDisabled = new ButtonRow();
buttonsBlockDisabled.setContinueButtonDisabled(true);
buttonsBlockDisabled.setContinueButtonText(uploadPage.uploading);
buttonsBlockDisabled.setContinueButtonState('default');
buttonsBlockDisabled.setConstraints({
  'attachments.isUploading': {
    presence: true,
    inclusion: [true],
  },
});
const photoControl = new Control('hidden', 'externalPhotoStep').setDefault(YES);

const externalPhotoStep = new Step('step', 'external-photos');
externalPhotoStep.addBlock(headingBlock);
externalPhotoStep.addBlock(infoBlock);
externalPhotoStep.addBlock(hintBlockExternal);
externalPhotoStep.addBlock(safetyBlockExternal);
externalPhotoStep.addBlock(tipsBlock);
externalPhotoStep.addBlock(uploadBlock);
externalPhotoStep.addBlock(buttonsBlock);
externalPhotoStep.addBlock(buttonsBlockDisabled);
externalPhotoStep.addBlock(photoControl);
externalPhotoStep.setIsContained(true);
externalPhotoStep.setTitle('External Photos');

externalPhotoStep.setConstraints({
  initialLocation: {
    inclusion: [EXTERNAL, INTERNAL],
    exclusion: [PUBLIC],
  },
  cleanIntstoptapInfoCol: {
    presence: false,
    exclusion: [true],
  },
  cleanCondenInfoCol: {
    presence: false,
    exclusion: [true],
  },
  internalInformationCol: {
    presence: false,
    exclusion: [true],
  },
  cleanOverflowInfoCol: {
    presence: false,
    exclusion: [true],
  },
  cleanLeakingmeterInfoCol: {
    presence: false,
    exclusion: [true],
  },
  cleanSupplyInfoCol: {
    presence: false,
    exclusion: [true],
  },
});

export { externalPhotoStep };
