import { ButtonRow, Control, Heading, Step } from '../../../models';
import { pollutionType } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', pollutionType.heading);
const pollutionTypeControl = new Control('checkbox', 'pollutionType');
pollutionTypeControl
  .setLabel(pollutionType.heading)
  .labelOff()
  .setDefault('');
pollutionTypeControl.addOption(pollutionType.sewage, 'SEWAGE', null);
pollutionTypeControl.addOption(pollutionType.wipes, 'WIPES_OR_SANITARY', null);
pollutionTypeControl.addOption(pollutionType.soap, 'SOAPY_DISCOLOURED_WATER', null);
pollutionTypeControl.addOption(pollutionType.chemical, 'CHEMICALS_OIL_PETROL', null);
pollutionTypeControl.addOption(pollutionType.fish, 'DEAD_FISH', null);
pollutionTypeControl.addOption(pollutionType.other, 'OTHER', null);
const buttonsBlock = new ButtonRow();

const pollutionTypeStep = new Step('step', 'pollution-type');
pollutionTypeStep.addBlock(headingBlock);
pollutionTypeStep.addBlock(pollutionTypeControl);
pollutionTypeStep.addBlock(buttonsBlock);
pollutionTypeStep.setIsContained(false);
pollutionTypeStep.setTitle(pollutionType.title);

pollutionTypeStep.setConstraints([
  {
    initialProblem: { inclusion: ['POLLUTION'] },
  },
]);

pollutionTypeStep.setValidation({
  pollutionType: {
    presence: {
      message: pollutionType.validation,
      allowEmpty: false,
    },
  },
});

export { pollutionTypeStep };
