import {
  LOCATION_BOUND_NORTH,
  LOCATION_BOUND_EAST,
  LOCATION_BOUND_SOUTH,
  LOCATION_BOUND_WEST,
  REGION_BOUNDARY_PATH,
} from '../constants';

/*
geocodeLocation

Get lat and lng for given location

location - A string representing the location you wish to geocode
successHandler -Function to call on successful response
errorHandler -Function to call on erroneous response

*/
export const geocodeLocation = (location, successHandler, errorHandler) => {
  let request = {
    query: location,
    fields: ['place_id', 'name', 'geometry', 'formatted_address'],
    bounds: {
      north: LOCATION_BOUND_NORTH,
      east: LOCATION_BOUND_EAST,
      south: LOCATION_BOUND_SOUTH,
      west: LOCATION_BOUND_WEST,
    },
  };
  const service = new window.google.maps.places.PlacesService(document.createElement('div'));
  service.textSearch(request, function(results, status) {
    if (status === 'OK') {
      // Check result within the boundaries of Yorkshire
      const filteredResults = results.filter((result) => {
        let resultLocation = result.geometry.location;
        let polyline = new window.google.maps.Polygon({
          path: window.google.maps.geometry.encoding.decodePath(String(REGION_BOUNDARY_PATH)),
        });
        let isWithinPolygon = window.google.maps.geometry.poly.containsLocation(resultLocation, polyline);
        if (isWithinPolygon) {
          return true;
        }
        return false;
      });
      // check we still have results
      if (filteredResults.length) {
        const firstLocation = filteredResults[0].geometry.location;
        const formattedAddress = filteredResults[0].formatted_address
          .split(',')
          .filter((part) => !part.includes('UK'))
          .join(',');
        const totalResults = filteredResults.length;
        const lat = firstLocation.lat();
        const lng = firstLocation.lng();
        successHandler({ lat, lng, formattedAddress, totalResults, results: filteredResults });
      } else {
        errorHandler('No results');
      }
    } else {
      errorHandler(status);
    }
  });
};

/*
reverseGeocodeLocation

Get location for given lat lng

location - A string representing the location you wish to reverse geocode { l`t, lng}
successHandler -Function to call on successful response
errorHandler -Function to call on erroneous response

*/
export const reverseGeocodeLocation = (location, successHandler, errorHandler) => {
  // Decalre instance of the Geocoder
  const geocoder = new window.google.maps.Geocoder();

  // Call geocoder method with passed location
  geocoder.geocode(location, function(results, status) {
    // Check status is ok
    if (status === 'OK') {
      // Check we have results
      if (results.length) {
        const postcodes = getPostcodesFromGooglePlacesResponse(results);
        const firstResult = results[0];
        const firstFormattedAddress = firstResult.address_components
          .filter((component) => {
            return (
              component.types.includes('route') ||
              component.types.includes('locality') ||
              component.types.includes('sublocality') ||
              component.types.includes('postal_code_prefix') ||
              component.types.includes('postal_town') ||
              component.types.includes('administrative_area_level_2')
            );
          })
          .map((component) => {
            return component.short_name;
          })
          .join(', ');
        const firstLat = location.location.lat;
        const firstLng = location.location.lng;
        successHandler({ lat: firstLat, lng: firstLng, formattedAddress: firstFormattedAddress, postcodes: postcodes });
      } else {
        if (errorHandler) {
          errorHandler('No results');
        }
      }
    } else {
      if (errorHandler) {
        errorHandler(status);
      }
    }
  });
};

export const getCurrentLocation = (successHandler, errorHandler) => {
  if (global.navigator.geolocation) {
    global.navigator.geolocation.getCurrentPosition(
      (pos) => {
        successHandler(pos.coords.latitude, pos.coords.longitude);
      },
      () => {
        errorHandler('Geolocation disabled');
      },
      { timeout: 10000 },
    );
  } else {
    errorHandler('Geolocation unavailable');
  }
};

const getPostcodesFromGooglePlacesResponse = (locationResults) => {
  const postcodes = [];
  if (locationResults) {
    locationResults.forEach((entry) => {
      const postcodeEntry = entry.address_components
        .filter((address) => address.types.includes('postal_code'))
        .map((o) => o.short_name);
      if (postcodeEntry.length > 0) {
        postcodes.push(postcodeEntry[0]);
      }
    });
    return postcodes.sort((a, b) => (a.length < b.length ? 1 : -1));
  }
  return [];
};

const locationApi = {
  geocodeLocation,
  getCurrentLocation,
  reverseGeocodeLocation,
  getPostcodesFromGooglePlacesResponse,
};

export default locationApi;
