import { Heading, Step, ButtonRow, InfoBlock, Link } from '../../../models';
import {
  MAIN_WEBSITE_URL,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  CHEMICAL,
  DRAIN,
  FARM,
  GAS,
  COVER,
  GROUND,
  HOUSE,
  ODOUR,
  POLLUTION,
  BATHROOM,
  KITCHEN,
  backToMainWebsite,
  wasteChemicalInfo,
  NO,
  YES,
} from '../../../../constants';

const headingBlock = new Heading('step-heading', wasteChemicalInfo.heading);
const infoBlock1 = new InfoBlock('info-block1', wasteChemicalInfo.detail[0], 'p-b');
const headingBlock2 = new Heading('step-heading2', wasteChemicalInfo.subHeading, 2).setSize('sm');
const infoBlock2 = new InfoBlock('info-block2', wasteChemicalInfo.detail[1]);
const linkBlock = new Link('main-website-link', backToMainWebsite).setLink(MAIN_WEBSITE_URL).setTarget('_self');
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteChemicalInfoStep = new Step('step', 'waste-chemical-info');
wasteChemicalInfoStep.setIsContained(true);
wasteChemicalInfoStep.addBlock(headingBlock);
wasteChemicalInfoStep.addBlock(infoBlock1);
wasteChemicalInfoStep.addBlock(headingBlock2);
wasteChemicalInfoStep.addBlock(infoBlock2);
wasteChemicalInfoStep.addBlock(linkBlock);
wasteChemicalInfoStep.addBlock(buttonsBlock);
wasteChemicalInfoStep.setTitle(wasteChemicalInfo.title);

wasteChemicalInfoStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [ODOUR],
      exclusion: [HOUSE, BATHROOM, KITCHEN],
    },
    wasteOdourType: {
      inclusion: [CHEMICAL],
      exclusion: [GAS, FARM, DRAIN],
    },
    isColleagueMode: {
      presence: false,
      inclusion: [NO],
      exclusion: [YES],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [ODOUR],
      exclusion: [COVER, GROUND, DRAIN],
    },
    wasteOdourType: {
      inclusion: [CHEMICAL],
      exclusion: [GAS, FARM, DRAIN],
    },
    isColleagueMode: {
      presence: false,
      inclusion: [NO],
      exclusion: [YES],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [CHEMICAL],
      exclusion: [GAS, FARM, DRAIN],
    },
    isColleagueMode: {
      presence: false,
      inclusion: [NO],
      exclusion: [YES],
    },
  },
]);

export { wasteChemicalInfoStep };
