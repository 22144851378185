import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  INSIDE,
  INSIDE_OUTSIDE,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  HOUSE,
  ODOUR,
  DRAIN,
  BLOCKED,
  BOTH,
  NONE,
  FARM,
  CHEMICAL,
  GAS,
  BATHROOM,
  KITCHEN,
} from '../../../../constants';
import { wasteOdourOutside } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteOdourOutside.heading);
const wasteOdourOutsideControl = new Control('question', 'wasteOdourOutside');
wasteOdourOutsideControl.setLabel(wasteOdourOutside.heading);
wasteOdourOutsideControl.setDefault('');
wasteOdourOutsideControl.addOption(wasteOdourOutside.inside, INSIDE, 'IconHouse2');
wasteOdourOutsideControl.addOption(wasteOdourOutside.insideOutside, INSIDE_OUTSIDE, 'IconNatureReserve2');
wasteOdourOutsideControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteOdourOutsideStep = new Step('step', 'waste-odour-outside');
wasteOdourOutsideStep.addBlock(headingBlock);
wasteOdourOutsideStep.addBlock(wasteOdourOutsideControl);
wasteOdourOutsideStep.addBlock(buttonsBlock);
wasteOdourOutsideStep.setIsContained(false);
wasteOdourOutsideStep.setTitle(wasteOdourOutside.title);

wasteOdourOutsideStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [ODOUR],
      exclusion: [HOUSE, BATHROOM, KITCHEN],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [NONE],
      exclusion: [DRAIN, BLOCKED, BOTH],
    },
  },
]);

export { wasteOdourOutsideStep };
