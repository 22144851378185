import validateJs from 'validate.js';
import moment from 'moment';

/*
isFuture

isFuture validator to check a date value is in the future

value - The value being validated
*/
export const isFuture = (value) => {
  if (!value) return;
  const nowDate = moment.utc().format();
  const result = validateJs({ field: value }, { field: { datetime: { earliest: nowDate } } });
  return result ? result.field : result;
};

/*
validateJs.validators.isFuture

Extend validate.js to contain isFuture validator

value - The value being validated
*/

validateJs.validators.isFuture = isFuture;

/*
validate

Wrapper for validation library

values - Object containing value bag
constraints - The conditions whicch will be checked against the value
*/
export const validate = (values, constraints) => {
  return validateJs(values, constraints);
};

const validationService = {
  validate,
};

export default validationService;
