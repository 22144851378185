import { ButtonRow, Control, Heading, Step } from '../../models';
import { EXTERNAL, INTERNAL, PUBLIC, COVER, DRAIN, GROUND, ODOUR } from '../../../constants';
import { wasteOriginBoundary } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteOriginBoundary.heading);
const wasteOriginBoundaryControl = new Control('question', 'wasteOriginBoundary');
wasteOriginBoundaryControl.setLabel(wasteOriginBoundary.heading);
wasteOriginBoundaryControl.addOption(wasteOriginBoundary.cover, COVER, 'IconCover');
wasteOriginBoundaryControl.addOption(wasteOriginBoundary.ground, GROUND, 'IconGround');
wasteOriginBoundaryControl.addOption(wasteOriginBoundary.odour, ODOUR, 'IconBadSmell');
wasteOriginBoundaryControl.addOption(wasteOriginBoundary.blocked, DRAIN, 'IconInternalBlockage');
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteOriginBoundaryStep = new Step('step', 'waste-origin-boundary');
wasteOriginBoundaryStep.addBlock(headingBlock);
wasteOriginBoundaryStep.addBlock(wasteOriginBoundaryControl);
wasteOriginBoundaryStep.addBlock(buttonsBlock);
wasteOriginBoundaryStep.setTitle(wasteOriginBoundary.title);

wasteOriginBoundaryStep.setConstraints({
  initialLocationWaste: {
    inclusion: [EXTERNAL],
    exclusion: [INTERNAL, PUBLIC],
  },
});

export { wasteOriginBoundaryStep };
