import { ButtonRow, Control, Heading, Hook, Step, Message } from '../../../models';
import '../../../../constants';
import {
  YES,
  NO,
  UNSURE,
  WASTE,
  CLEAN,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  GULLY,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  SQUARE,
  BLOCKED,
  GUSHING,
  COVER,
  GROUND,
  ODOUR,
  POLLUTION,
  STEP_MAP_LOCATION_LOOKUP,
  LOCATION_METHOD_MAP_SEARCH,
  LOCATION_METHOD_CURRENT_LOCATION,
  LOCATION_METHOD_ADDRESS_LOOKUP,
  WATER_SUPPLY,
  SLOW_DRAINAGE,
  FARM,
  GAS,
  CHEMICAL,
  DRAIN,
  BOTH,
  NONE,
  CONSTANT,
  INTERMITTENT,
} from '../../../../constants';
import { map } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', map.heading);
const mapConfirmed = new Control('hidden', 'mapConfirmed').setDefault('TRUE');
mapConfirmed.clear = true;
const instructionMessageBlock = new Message('step-hint', map.pinpoint).setState('info');
const invalidMessageBlock = {
  id: 'NO-RESULTS',
  type: 'message',
  message: map.cantUse,
  state: 'error',
  renderHtml: true,
  nextSteps: [
    {
      text: map.notYwArea,
    },
    {
      text: map.boundaries,
    },
  ],
};
const noResultMessageBlock = new Message('no-location', map.locationInstructions);
noResultMessageBlock.setNextStep(map.checkBrowser, map.tryAgain, {
  target: 'map',
  params: { locateMethod: LOCATION_METHOD_CURRENT_LOCATION },
});
noResultMessageBlock.setNextStep(map.findLocation1, map.findLocation2, {
  target: 'map-search',
  params: { locateMethod: LOCATION_METHOD_MAP_SEARCH },
});
noResultMessageBlock.setState('info');
const mapResultBlock = {
  type: 'mapResult',
  name: 'mapResult',
  clear: true,
  constraints: [
    {
      'hooks.error': {
        exclusion: ['map'],
      },
    },
  ],
};
const buttonsBlockMapDraggedAndInYorkshire = new ButtonRow().setContinueButtonText(map.continue);
const buttonsBlockMapNotYetDragged = new ButtonRow();
buttonsBlockMapNotYetDragged.setContinueButtonDisabled(true);
buttonsBlockMapNotYetDragged.setContinueButtonText(map.continue);
buttonsBlockMapNotYetDragged.setContinueButtonState('default');
const buttonsBlockMapDraggedButNotInYorkshire = new ButtonRow();
buttonsBlockMapDraggedButNotInYorkshire.setContinueButtonDisabled(true);
buttonsBlockMapDraggedButNotInYorkshire.setContinueButtonText(map.continue);
buttonsBlockMapDraggedButNotInYorkshire.setContinueButtonState('default');
const noResultsButtonsBlock = new ButtonRow().hideContinueButton();

const mapStep = new Step('step', 'map');
mapStep.setIsContained(true);
mapStep.entryHook = new Hook(STEP_MAP_LOCATION_LOOKUP, '').setPageLoaderHidden(true);
mapStep.addBlock(headingBlock);
mapStep.addBlock(mapConfirmed);
mapStep.addBlock(instructionMessageBlock);
mapStep.addBlock(invalidMessageBlock);
mapStep.addBlock(noResultMessageBlock);
mapStep.addBlock(mapResultBlock);
mapStep.addBlock(buttonsBlockMapNotYetDragged);
mapStep.addBlock(buttonsBlockMapDraggedButNotInYorkshire);
mapStep.addBlock(buttonsBlockMapDraggedAndInYorkshire);
mapStep.addBlock(noResultsButtonsBlock);
mapStep.setTitle(map.title);

instructionMessageBlock.setConstraints([
  {
    'locations.isYorkshire': {
      presence: true,
      inclusion: [true],
    },
    'hooks.error': {
      exclusion: ['map'],
    },
    'hooks.lookups.map': {
      presence: {
        AllowEmpty: false,
      },
    },
  },
]);

invalidMessageBlock.constraints = [
  {
    'locations.isYorkshire': {
      presence: true,
      inclusion: [false],
    },
  },
];

noResultMessageBlock.setConstraints([
  {
    'hooks.error': {
      presence: true,
      inclusion: ['map'],
    },
  },
]);

buttonsBlockMapDraggedAndInYorkshire.setConstraints([
  {
    'locations.mapHasDragged': {
      inclusion: [true],
    },
    'locations.isYorkshire': {
      presence: true,
      inclusion: [true],
    },
    'hooks.error': {
      exclusion: ['map'],
    },
    'hooks.lookups.map': {
      presence: {
        AllowEmpty: false,
      },
    },
    'hooks.isloading': {
      exclusion: [true],
    },
  },
]);

buttonsBlockMapNotYetDragged.setConstraints([
  {
    'locations.mapHasDragged': {
      inclusion: [undefined, false],
    },
    'locations.isYorkshire': {
      inclusion: [true, false],
    },
    'hooks.error': {
      exclusion: ['map'],
    },
    'hooks.lookups.map': {
      presence: {
        AllowEmpty: false,
      },
    },
    'hooks.isloading': {
      exclusion: [true],
    },
  },
]);

buttonsBlockMapDraggedButNotInYorkshire.setConstraints([
  {
    'locations.mapHasDragged': {
      presence: true,
      inclusion: [true],
    },
    'locations.isYorkshire': {
      inclusion: [false],
    },
    'hooks.error': {
      exclusion: ['map'],
    },
    'hooks.lookups.map': {
      presence: {
        AllowEmpty: false,
      },
    },
    'hooks.isloading': {
      exclusion: [true],
    },
  },
]);

noResultsButtonsBlock.setConstraints([
  {
    'hooks.error': {
      presence: true,
      inclusion: ['map'],
    },
  },
]);

mapStep.setConstraints([
  {
    initialLocation: {
      presence: true,
      exclusion: [INTERNAL, EXTERNAL],
    },
    initialProblem: {
      exclusion: [ODOUR, WATER_SUPPLY, SLOW_DRAINAGE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    waterFromCover: {
      exclusion: [NO],
    },
  },
  {
    initialLocation: {
      presence: true,
      exclusion: [INTERNAL, EXTERNAL],
    },
    initialProblem: {
      exclusion: [ODOUR, WATER_SUPPLY, SLOW_DRAINAGE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    waterFromCover: {
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [POLLUTION],
      exclusion: [ODOUR, GROUND, COVER],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [POLLUTION],
      exclusion: [ODOUR, GROUND, COVER],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [CLEAN, WASTE],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [CLEAN, WASTE],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      inclusion: [NO],
      exclusion: [YES],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      inclusion: [NO],
      exclusion: [YES],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [YES],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [YES],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [SQUARE],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, OTHER, GULLY],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [SQUARE],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, OTHER, GULLY],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [GULLY],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, OTHER, SQUARE],
    },
    wasteExternalCoverBlocked: {
      inclusion: [GUSHING],
      exclusion: [BLOCKED],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [GULLY],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, OTHER, SQUARE],
    },
    wasteExternalCoverBlocked: {
      inclusion: [GUSHING],
      exclusion: [BLOCKED],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [SLUICE_VALVE, STOPTAP, METER],
      exclusion: [OTHER, SQUARE, GULLY],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [SLUICE_VALVE, STOPTAP, METER],
      exclusion: [OTHER, SQUARE, GULLY],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SQUARE, GULLY, SLUICE_VALVE, STOPTAP, METER],
    },
    boundaryWaterType: {
      inclusion: [CLEAN, WASTE],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SQUARE, GULLY, SLUICE_VALVE, STOPTAP, METER],
    },
    boundaryWaterType: {
      inclusion: [CLEAN, WASTE],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      inclusion: [YES],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      inclusion: [YES],
      exclusion: [UNSURE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      inclusion: [YES, NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      inclusion: [YES, NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [FARM],
      exclusion: [GAS, CHEMICAL, DRAIN],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [FARM],
      exclusion: [GAS, CHEMICAL, DRAIN],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [DRAIN, BLOCKED, BOTH],
      exclusion: [NONE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [DRAIN, BLOCKED, BOTH],
      exclusion: [NONE],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [NONE],
      exclusion: [DRAIN, BLOCKED, BOTH],
    },
    wasteOdourFrequency: {
      inclusion: [CONSTANT, INTERMITTENT],
      exclusion: [],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [NONE],
      exclusion: [DRAIN, BLOCKED, BOTH],
    },
    wasteOdourFrequency: {
      inclusion: [CONSTANT, INTERMITTENT],
      exclusion: [],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [GAS],
      exclusion: [CHEMICAL, FARM, DRAIN],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [GAS],
      exclusion: [CHEMICAL, FARM, DRAIN],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [GULLY],
      exclusion: [SQUARE, SLUICE_VALVE, STOPTAP, METER, OTHER],
    },
    wasteExternalCoverBlocked: {
      inclusion: [BLOCKED],
      exclusion: [GUSHING],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [GULLY],
      exclusion: [SQUARE, SLUICE_VALVE, STOPTAP, METER, OTHER],
    },
    wasteExternalCoverBlocked: {
      inclusion: [BLOCKED],
      exclusion: [GUSHING],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
]);

export { mapStep };
