/* 1. Kickoff Step */
import { contactDetailsStep } from './steps/CleanAndWasteJourney/ContactDetailsStep';
import { initialLocationStep } from './steps/CleanJourney/Common/initialLocationStep';

/* 2. Triage Steps */
import { supplyIssueStep } from './steps/CleanJourney/InternalExternal/supplyIssueStep';
import { waterFromCoverStep } from './steps/CleanJourney/Common/waterFromCoverStep';
import { waterQualityIssueStep } from './steps/CleanJourney/InternalExternal/waterQualityIssueStep';

/* 3. Location Steps */
import { addressLookupStep } from './steps/CleanAndWasteJourney/addressLookupStep';
import { addressLookupInfoStep } from './steps/CleanAndWasteJourney/addressLookupInfoStep';
import { addressResultStep, addressResultSingleStep } from './steps/CleanAndWasteJourney/addressResultStep';
import { mapSearchStep } from './steps/CleanJourney/Public/mapSearchStep';
import { mapSearchResultsStep } from './steps/CleanJourney/Public/mapSearchResultsStep';
import { mapStep } from './steps/CleanJourney/Public/mapStep';
import { mapConfirmStep } from './steps/CleanJourney/Public/mapConfirmStep';
import { incidentConfirmStep } from './steps/CleanJourney/Public/incidentConfirm';

/* 4. Problem identification Steps */
import { waterOriginInternalStep } from './steps/CleanJourney/InternalExternal/waterOriginInternalStep';
import { waterOriginExternalStep } from './steps/CleanJourney/InternalExternal/waterOriginExternalStep';
import { waterOriginPublicStep } from './steps/CleanJourney/Public/waterOriginPublicStep';
import { coverTypeStep } from './steps/CleanJourney/Public/coverTypeStep';
import { cleanCoverBoundaryStep } from './steps/CleanJourney/InternalExternal/cleanCoverBoundaryStep';
import { cleanCoverSeverityStep } from './steps/CleanJourney/InternalExternal/cleanCoverSeverityStep';
import { cleanCoverRiskStep } from './steps/CleanJourney/InternalExternal/cleanCoverRiskStep';
import { externalWasteRiskStep } from './steps/CleanJourney/InternalExternal/externalWasteRiskStep';
import { externalWasteSeverityStep } from './steps/CleanJourney/InternalExternal/externalWasteSeverityStep';
import { waterTypeStep } from './steps/CleanJourney/Common/waterTypeStep';
import { externalWaterTypeStep } from './steps/CleanJourney/InternalExternal/externalWaterType';
import { rainfallAffectedStep } from './steps/CleanJourney/Common/rainfallAffectedStep';
import { externalRainfallAffectedStep } from './steps/CleanAndWasteJourney/externalRainfallAffectedStep';
import { externalFlowConstantStep } from './steps/CleanAndWasteJourney/externalFlowConstantStep';
import { flowConstantStep } from './steps/CleanJourney/Common/flowConstantStep';
import { externalWaterSmellStep } from './steps/CleanAndWasteJourney/externalWaterSmellStep';
import { waterSmellStep } from './steps/CleanJourney/Common/waterSmellStep';
import { supplyPipeEligibilityStep } from './steps/CleanJourney/InternalExternal/supplyPipeEligibilityStep';
import { cleanAccessSupplyStep } from './steps/CleanJourney/InternalExternal/cleanAccessSupplyStep';
import { pollutionEnteringStep } from './steps/CleanJourney/Common/pollutionEnteringStep';
import { pollutionFromStep } from './steps/CleanJourney/Common/pollutionFromStep';
import { pollutionTypeStep } from './steps/CleanJourney/Common/pollutionTypeStep';
import { cleanMeterLeakingStep } from './steps/CleanJourney/InternalExternal/cleanMeterLeakingStep';
import { cleanMeterLocationStep } from './steps/CleanJourney/InternalExternal/cleanMeterLocationStep';
import { cleanMeterSeverityExtStep } from './steps/CleanJourney/InternalExternal/cleanMeterSeverityExtStep';
import { cleanMeterSeverityIntStep } from './steps/CleanJourney/InternalExternal/cleanMeterSeverityIntStep';
import { cleanMeterInternalExternalStep } from './steps/CleanJourney/InternalExternal/cleanMeterInternalExternalStep';
import { cleanCondensationConfirmStep } from './steps/CleanJourney/InternalExternal/cleanCondensationConfirmStep';
import { cleanMeterRiskInternalStep } from './steps/CleanJourney/InternalExternal/cleanMeterRiskInternalStep';

/* 5. Info Step */
import { noWaterInfoStep } from './steps/CleanJourney/InternalExternal/noWaterInfoStep';
import { blockedDrainsInfoStep } from './steps/CleanJourney/InternalExternal/blockedDrainsInfoStep';
import { privateProblemInfoStep } from './steps/CleanJourney/InternalExternal/privateProblemInfoStep';

/* 6. Severity Steps */
import { cleanSeverityStep } from './steps/CleanJourney/Public/cleanSeverityStep';
import { wasteSeverityStep } from './steps/CleanJourney/Public/wasteSeverityStep';

/* 7. Risk Steps */
import { cleanRiskStep } from './steps/CleanJourney/Public/cleanRiskStep';
import { wasteRiskStep } from './steps/CleanJourney/InternalExternal/wasteRiskStep';

/* 8. Details Steps */
import { photoStep } from './steps/CleanJourney/Public/photoStep';
import { externalPhotoStep } from './steps/CleanJourney/InternalExternal/externalPhotoStep';

/* 9. Redirects */
import { hardWaterRedirect } from './steps/CleanJourney/Common/hardWaterRedirect';
import { tasteSmellRedirect } from './steps/CleanJourney/InternalExternal/tasteSmellRedirect';
import { discolouredRedirect } from './steps/CleanJourney/InternalExternal/discolouredRedirect';
import { damagedCoverRedirect } from './steps/CleanJourney/Common/damagedCoverRedirect';
import { callbackRedirect } from './steps/CleanJourney/InternalExternal/callbackRedirect';

/* 10. Finalise */
import { summaryStep } from './steps/CleanAndWasteJourney/summaryStep';
import { thankYouPollutionStep } from './steps/CleanAndWasteJourney/thankYouPollutionStep';
import { thankYouStep } from './steps/CleanAndWasteJourney/thankYouStep';

/* 11. Off Ramp Steps */
import { overflowPipeOffRampStep } from './steps/CleanJourney/InternalExternal/overflowPipeOffRampStep';
import { meterCondensationInfoStep } from './steps/CleanJourney/InternalExternal/meterCondensationInfoStep';
import { rtimOffRampStep } from './steps/CleanJourney/Public/rtimOffRampStep';
import { cleanSupplyOffRampStep } from './steps/CleanJourney/InternalExternal/cleanSupplyOffRampStep';
import { nonHouseholdStep } from './steps/CleanAndWasteJourney/nonHouseholdStep';
import { cleanInternalStopTapInfoStep } from './steps/CleanJourney/InternalExternal/cleanInternalStopTapInfoStep';
import { cleanLeakingMeterInfoStep } from './steps/CleanJourney/InternalExternal/cleanLeakingMeterInfoStep';
import { internalOffRampStep } from './steps/CleanJourney/InternalExternal/internalOffRampStep';

/* Waste Journey Steps */
import { initialLocationWasteStep } from './steps/WasteJourney/initialLocationWasteStep';
import { wasteOriginBoundaryStep } from './steps/WasteJourney/wasteOriginBoundaryStep';
import { wasteExternalOriginStep } from './steps/WasteJourney/Public/wasteExternalOriginStep';
import { externalRoughLocationStep } from './steps/WasteJourney/Common/externalRoughLocationStep';
import { wasteOriginInternalStep } from './steps/WasteJourney/Internal/wasteOriginInternalStep';
import { wasteCoverBoundaryStep } from './steps/WasteJourney/wasteCoverBoundaryStep';
import { cleanBoundarySeverityStep } from './steps/WasteJourney/Common/cleanBoundarySeverityStep';
import { cleanBoundaryRiskStep } from './steps/WasteJourney/Common/cleanBoundaryRiskStep';
import { cleanBoundaryAccessSupplyStep } from './steps/WasteJourney/cleanBoundaryAccessSupplyStep';
import { wastePhotoStep } from './steps/WasteJourney/Common/wastePhotoStep';
import { wasteBoundaryRiskStep } from './steps/WasteJourney/Common/wasteBoundaryRiskStep';
import { wasteBoundarySeverityStep } from './steps/WasteJourney/Common/wasteBoundarySeverityStep';
import { wasteBoundaryAccessSupplyStep } from './steps/WasteJourney/wasteBoundaryAccessSupplyStep';
import { boundaryWaterTypeStep } from './steps/WasteJourney/boundaryWaterTypeStep';
import { boundaryRainfallAffectedStep } from './steps/WasteJourney/boundaryRainfallAffectedStep';
import { boundaryFlowConstantStep } from './steps/WasteJourney/boundaryFlowConstantStep';
import { boundaryWaterSmellStep } from './steps/WasteJourney/boundaryWaterSmellStep';
import { wasteBoundaryEscapeStep } from './steps/WasteJourney/wasteBoundaryEscapeStep';
import { wasteBoundaryIssuesStep } from './steps/WasteJourney/wasteBoundaryIssuesStep';
import { wasteResponsibiltyBoundaryStep } from './steps/WasteJourney/wasteResponsibiltyBoundaryStep';
import { wasteDrainageInfoStep } from './steps/WasteJourney/wasteDrainageInfoStep';
import { externalWasteWaterTypeStep } from './steps/WasteJourney/Public/externalWasteWaterType';
import { wasteOriginPollutionStep } from './steps/WasteJourney/Public/wasteOriginPollutionStep';
import { wasteExternalCoverStep } from './steps/WasteJourney/Public/wasteExternalCoverStep';
import { wasteExternalCoverBlockedStep } from './steps/WasteJourney/Public/wasteExternalCoverBlockedStep';
import { wasteCouncilInfoStep } from './steps/WasteJourney/Public/wasteCouncilInfoStep';
import { wasteBoundaryEscapeLocationStep } from './steps/WasteJourney/wasteBoundaryEscapeLocationStep';
import { wasteBoundaryEscapePropertyStep } from './steps/WasteJourney/wasteBoundaryEscapePropertyStep';
import { wasteOohStep } from './steps/WasteJourney/Common/wasteOohStep';
import { wasteBoundaryEscapeInternalStep } from './steps/WasteJourney/wasteBoundaryEscapeInternalStep';
import { wasteOdourTypeStep } from './steps/WasteJourney/Common/wasteOdourTypeStep';
import { wasteGasInfoStep } from './steps/WasteJourney/Common/wasteGasInfoStep';
import { wasteChemicalInfoStep } from './steps/WasteJourney/Common/wasteChemicalInfoStep';
import { wasteOdourOtherStep } from './steps/WasteJourney/Common/wasteOdourOtherStep';
import { wasteOdourOutsideStep } from './steps/WasteJourney/Internal/wasteOdourOutsideStep';
import { wasteOdourSourceStep } from './steps/WasteJourney/Internal/wasteOdourSourceStep';
import { wasteOdourFrequencyStep } from './steps/WasteJourney/Common/wasteOdourFrequencyStep';
import { wasteOdourLocationStep } from './steps/WasteJourney/Internal/wasteOdourLocationStep';
import { wasteInternalOdourInfoStep } from './steps/WasteJourney/Common/wasteInternalOdourInfoStep';
import { wasteInternalExtdrainStep } from './steps/WasteJourney/Internal/wasteInternalExtdrainStep';
import { wasteInternalExtotherStep } from './steps/WasteJourney/Internal/wasteInternalExtotherStep';
import { wasteInternalFclty1Step } from './steps/WasteJourney/Internal/wasteInternalFclty1Step';
import { wasteInternalFclty2Step } from './steps/WasteJourney/Internal/wasteInternalFclty2Step';
import { wasteInternalSameStep } from './steps/WasteJourney/Internal/wasteInternalSameStep';
import { wasteInternalEntryStep } from './steps/WasteJourney/Internal/wasteInternalEntryStep';
import { wasteInternalToiletStep } from './steps/WasteJourney/Internal/wasteInternalToiletStep';
import { wasteTimedAccessTodayStep } from './steps/WasteJourney/Internal/wasteTimedAccessTodayStep';
import { wasteTimedAccessTomorrowStep } from './steps/WasteJourney/Internal/wasteTimedAccessTomorrowStep';
import { wasteInternalSinkStep } from './steps/WasteJourney/Internal/wasteInternalSinkStep';
import { wasteInternalAreaStep } from './steps/WasteJourney/Internal/wasteInternalAreaStep';
import { wasteInternalOtherStep } from './steps/WasteJourney/Internal/wasteInternalOtherStep';
import { wasteInternalSourceStep } from './steps/WasteJourney/Internal/wasteInternalSourceStep';
import { wasteInternalFreqStep } from './steps/WasteJourney/Internal/wasteInternalFreqStep';
import { wasteInternalTextStep } from './steps/WasteJourney/Internal/wasteInternalTextStep';
import { nwlpInitialProblemStep } from './steps/Nwlp/nwlpInitialProblemStep';
import { nwlpPressureProblemStep } from './steps/Nwlp/nwlpPressureProblemStep';
import { nwlpProblemNeighbourStep } from './steps/Nwlp/nwlpProblemNeighbourStep';
import { nwlpProblemFrequencyStep } from './steps/Nwlp/nwlpProblemFrequencyStep';
import { nwlpPressureSupplyStep } from './steps/Nwlp/nwlpPressureSupplyStep';
import { nwlpStopTapStep } from './steps/Nwlp/nwlpStopTapStep';
import { nwlpPropertyTypeStep } from './steps/Nwlp/nwlpPropertyTypeStep';
import { nwlpNoticeTodayStep } from './steps/Nwlp/nwlpNoticeTodayStep';
import { nwlpFacilitiesAffectedStep } from './steps/Nwlp/nwlpFacilitiesAffectedStep';
import { nwlpAnyWaterStep } from './steps/Nwlp/nwlpAnyWaterStep';
import { nwlpNoticeDateTimeStep } from './steps/Nwlp/nwlpNoticeDateTimeStep';
import { nwlpNoticeConfirmStep } from './steps/Nwlp/nwlpNoticeConfirmStep';
import { nwlpOohInfoStep } from './steps/Nwlp/nwlpOohInfoStep';

import { wasteIncidentConfirmStep } from './steps/WasteJourney/Public/wasteIncidentConfirm';
import { wasteRtimOffRampStep } from './steps/WasteJourney/Public/wasteRtimOffRampStep';
import { rtimInformationColStep } from './steps/Info/Colleague/rtimInformationColStep';
import { wasteRtimInformationColStep } from './steps/Info/Colleague/wasteRtimInformationColStep';
import { cleanIntstoptapInfoColStep } from './steps/Info/Colleague/cleanIntstoptapInfoColStep';
import { cleanCondenInfoColStep } from './steps/Info/Colleague/cleanCondenInfoColStep';
import { internalInformationColStep } from './steps/Info/Colleague/internalInformationColStep';
import { cleanOverflowInfoColStep } from './steps/Info/Colleague/cleanOverflowInfoColStep';
import { cleanLeakingMeterInfoColStep } from './steps/Info/Colleague/cleanLeakingMeterInfoColStep';
import { cleanSupplyInfoColStep } from './steps/Info/Colleague/cleanSupplyInfoColStep';
import { wasteGasInfoColStep } from './steps/Info/Colleague/wasteGasInfoColStep';
import { wasteChemicalInfoColStep } from './steps/Info/Colleague/wasteChemicalInfoColStep';
import { wasteCouncilInfoColStep } from './steps/Info/Colleague/wasteCouncilInfoColStep';
import { wasteInternalOdourInfoColStep } from './steps/Info/Colleague/wasteInternalOdourInfoColStep';
import { wasteDrainageInfoColStep } from './steps/Info/Colleague/wasteDrainageInfoColStep';

/*
Step Config
An array containing all possible steps in the order of appearance.
*/

export const nwlpStepConfig = [
  contactDetailsStep,
  nwlpInitialProblemStep,
  nwlpPressureProblemStep,
  nwlpPressureSupplyStep,
  nwlpFacilitiesAffectedStep,
  nwlpStopTapStep,
  nwlpAnyWaterStep,
  nwlpOohInfoStep,
  nwlpNoticeDateTimeStep,
  nwlpNoticeConfirmStep,
  nwlpPropertyTypeStep,
  nwlpProblemNeighbourStep,
  nwlpNoticeTodayStep,
  nwlpProblemFrequencyStep,
  wasteDrainageInfoStep,
  wasteDrainageInfoColStep,
  addressLookupStep,
  addressResultStep,
  addressResultSingleStep,
  addressLookupInfoStep,
  summaryStep,
  thankYouStep,
];

export const cleanStepConfig = [
  contactDetailsStep,
  initialLocationStep,
  waterOriginInternalStep,
  internalOffRampStep,
  internalInformationColStep,
  waterOriginExternalStep,
  cleanCoverBoundaryStep,
  externalWaterTypeStep,
  externalRainfallAffectedStep,
  externalFlowConstantStep,
  externalWaterSmellStep,
  cleanCoverSeverityStep,
  cleanCoverRiskStep,
  externalWasteRiskStep,
  externalWasteSeverityStep,
  overflowPipeOffRampStep,
  cleanOverflowInfoColStep,
  cleanMeterLeakingStep,
  cleanMeterLocationStep,
  cleanMeterInternalExternalStep,
  cleanCondensationConfirmStep,
  cleanMeterSeverityExtStep,
  cleanMeterSeverityIntStep,
  cleanMeterRiskInternalStep,
  cleanInternalStopTapInfoStep,
  cleanIntstoptapInfoColStep,
  cleanLeakingMeterInfoStep,
  cleanLeakingMeterInfoColStep,
  meterCondensationInfoStep,
  cleanCondenInfoColStep,
  supplyPipeEligibilityStep,
  cleanSupplyOffRampStep,
  cleanSupplyInfoColStep,
  cleanAccessSupplyStep,
  supplyIssueStep,
  waterFromCoverStep,
  blockedDrainsInfoStep,
  waterQualityIssueStep,
  mapSearchStep,
  mapSearchResultsStep,
  mapStep,
  mapConfirmStep,
  incidentConfirmStep,
  rtimOffRampStep,
  rtimInformationColStep,
  waterOriginPublicStep,
  coverTypeStep,
  waterTypeStep,
  rainfallAffectedStep,
  flowConstantStep,
  waterSmellStep,
  pollutionEnteringStep,
  pollutionFromStep,
  pollutionTypeStep,
  noWaterInfoStep,
  privateProblemInfoStep,
  cleanSeverityStep,
  cleanRiskStep,
  wasteRiskStep,
  wasteSeverityStep,
  externalPhotoStep,
  photoStep,
  hardWaterRedirect,
  tasteSmellRedirect,
  discolouredRedirect,
  damagedCoverRedirect,
  callbackRedirect,
  addressLookupStep,
  addressResultStep,
  addressResultSingleStep,
  addressLookupInfoStep,
  nonHouseholdStep,
  summaryStep,
  thankYouStep,
];

export const wasteStepConfig = [
  contactDetailsStep,
  initialLocationWasteStep,
  wasteOriginBoundaryStep,
  wasteCoverBoundaryStep,
  wasteExternalOriginStep,
  wasteExternalCoverStep,
  boundaryWaterTypeStep,
  boundaryRainfallAffectedStep,
  boundaryFlowConstantStep,
  boundaryWaterSmellStep,
  wasteExternalCoverBlockedStep,
  wasteCouncilInfoStep,
  wasteCouncilInfoColStep,
  externalWasteWaterTypeStep,
  externalRainfallAffectedStep,
  externalFlowConstantStep,
  externalWaterSmellStep,
  wasteOriginInternalStep,
  wasteInternalEntryStep,
  wasteInternalAreaStep,
  wasteInternalOtherStep,
  wasteInternalSourceStep,
  wasteInternalFreqStep,
  wasteInternalTextStep,
  wasteInternalSinkStep,
  wasteInternalFclty1Step,
  wasteInternalFclty2Step,
  wasteInternalSameStep,
  wasteInternalExtotherStep,
  wasteInternalToiletStep,
  wasteInternalExtdrainStep,
  wasteOdourTypeStep,
  wasteGasInfoStep,
  wasteGasInfoColStep,
  wasteChemicalInfoStep,
  wasteChemicalInfoColStep,
  wasteOdourOtherStep,
  wasteOdourOutsideStep,
  wasteOdourSourceStep,
  wasteOdourLocationStep,
  wasteInternalOdourInfoStep,
  wasteInternalOdourInfoColStep,
  wasteOdourFrequencyStep,
  wasteBoundaryEscapeStep,
  wasteBoundaryEscapeLocationStep,
  wasteBoundaryEscapeInternalStep,
  wasteBoundaryEscapePropertyStep,
  wasteOohStep,
  wasteBoundaryIssuesStep,
  wasteResponsibiltyBoundaryStep,
  wasteDrainageInfoStep,
  wasteDrainageInfoColStep,
  externalRoughLocationStep,
  mapSearchResultsStep,
  mapStep,
  mapConfirmStep,
  wasteIncidentConfirmStep,
  wasteRtimOffRampStep,
  wasteRtimInformationColStep,
  wasteOriginPollutionStep,
  cleanBoundarySeverityStep,
  cleanBoundaryRiskStep,
  cleanBoundaryAccessSupplyStep,
  wasteBoundaryRiskStep,
  wasteBoundarySeverityStep,
  wasteBoundaryAccessSupplyStep,
  wastePhotoStep,
  wasteTimedAccessTodayStep,
  wasteTimedAccessTomorrowStep,
  addressLookupStep,
  addressResultStep,
  addressResultSingleStep,
  addressLookupInfoStep,
  nonHouseholdStep,
  summaryStep,
  thankYouPollutionStep,
  thankYouStep,
];
