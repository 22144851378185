import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { DOCUMENT_TITLE, TITLE_DIVIDER, APP_INSIGHTS_INSTRUMENTATION_KEY } from '../constants';

class TelemetryService {
  constructor() {
    this.reactPlugin = new ReactPlugin();
    this.lastUri = null;
  }

  initialize(reactPluginConfig) {
    let INSTRUMENTATION_KEY = APP_INSIGHTS_INSTRUMENTATION_KEY;

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: INSTRUMENTATION_KEY,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [this.reactPlugin],
        extensionConfig: {
          [this.reactPlugin.identifier]: reactPluginConfig,
        },
      },
    });
    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer(this.pageViewInitializer);
  }

  pageViewInitializer = (envelope) => {
    if (envelope.baseType === 'PageviewData') {
      // dont track same page again
      if (this.lastUri === envelope.baseData.uri) {
        return false;
      }

      // Removes base document title fro start of logged page name
      envelope.baseData.name = envelope.baseData.name.replace(`${DOCUMENT_TITLE}${TITLE_DIVIDER}`, '');
      this.lastUri = envelope.baseData.uri;
    }
    return true;
  };
}

export let ai = new TelemetryService();
