import { ButtonRow, Control, Heading, InfoBlock, Step } from '../../models';
import {
  YES,
  NO,
  GARDEN,
  OUTBUILDING,
  PROPERTY,
  GULLY,
  WATERCOURSE,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  COVER,
  DRAIN,
  GROUND,
  ODOUR,
  TOWARDS_WATERCOURSE,
} from '../../../constants';
import { wasteBoundaryEscapeLocation } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteBoundaryEscapeLocation.heading);
const infoBlock1 = new InfoBlock('info-block1', wasteBoundaryEscapeLocation.detail, 'p-b');
const wasteBoundaryEscapeLocationControl = new Control('hidden', 'wasteBoundaryEscapeLocation');
const wasteBoundaryEscapeLocationsControl = new Control('hidden', 'wasteBoundaryEscapeLocations');
const buttonsBlock = new ButtonRow();

const wasteBoundaryEscapeLocationStep = new Step('step', 'waste-boundary-escape-location');
wasteBoundaryEscapeLocationStep.addBlock(headingBlock);
wasteBoundaryEscapeLocationStep.addBlock(infoBlock1);
wasteBoundaryEscapeLocationStep.addBlock(wasteBoundaryEscapeLocationControl);
wasteBoundaryEscapeLocationStep.addBlock(wasteBoundaryEscapeLocationsControl);
wasteBoundaryEscapeLocationStep.addBlock({
  type: 'prioritisedCheckbox',
  selector: 'wasteBoundaryEscapeLocation',
  items: 'wasteBoundaryEscapeLocations',
  options: [
    { priority: 1, value: WATERCOURSE, text: wasteBoundaryEscapeLocation.options[0] },
    { priority: 4, value: TOWARDS_WATERCOURSE, text: wasteBoundaryEscapeLocation.options[1] },
    { priority: 3, value: GULLY, text: wasteBoundaryEscapeLocation.options[2] },
    { priority: 2, value: PROPERTY, text: wasteBoundaryEscapeLocation.options[3] },
    { priority: 5, value: OUTBUILDING, text: wasteBoundaryEscapeLocation.options[4] },
    { priority: 6, value: GARDEN, text: wasteBoundaryEscapeLocation.options[5] },
  ],
});
wasteBoundaryEscapeLocationStep.addBlock(buttonsBlock);
wasteBoundaryEscapeLocationStep.setTitle(wasteBoundaryEscapeLocation.title);

wasteBoundaryEscapeLocationStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      inclusion: [YES],
      exclusion: [NO],
    },
  },
]);

wasteBoundaryEscapeLocationStep.setValidation({
  wasteBoundaryEscapeLocation: {
    presence: {
      message: wasteBoundaryEscapeLocation.validation,
      allowEmpty: false,
    },
  },
});

export { wasteBoundaryEscapeLocationStep };
