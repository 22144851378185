import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { FileList } from '../../../components';
import { ATTACHMENTS_CTA_DEFAULT_TEXT, SVG_NAMESPACE_URL } from '../../../constants';

export class FileUploadBlock extends Component {
  handleChange = (e) => {
    const files = Array.from(e.target.files);
    this.props.uploadAttachments(files);
  };

  handleRemove = (attachmentId) => {
    this.props.attachmentRemove(attachmentId);
  };

  handleKeyUp = (e) => {
    if (e.key === 'Enter' || e.key === 'Space') {
      this.inputElement.click();
    }
  };

  render() {
    const { name, attachments, ctaText } = this.props;
    const forRowClasses = classNames('c-form-row');
    return (
      <Fragment>
        <div className={forRowClasses} id={`${name}_row`} data-qa={`${name}_row`}>
          <label className="c-form-row__label" htmlFor={name} id={`${name}_label`} data-qa={`${name}_label`}>
            <span
              className="c-form-row__label-text u-visually-hidden"
              id={`${name}_label_text`}
              data-qa={`${name}_label_text`}
            >
              Select a file
            </span>
            <div className="c-input-file">
              <button
                type="button"
                className="c-button c-button--primary"
                onKeyUp={this.handleKeyUp}
                id={`${name}_button`}
                data-qa={`${name}_button`}
              >
                {ctaText}
                <i className="c-icon c-icon--200 c-icon--weight-150 c-button__icon c-button__icon--right">
                  <svg xmlns={SVG_NAMESPACE_URL} viewBox="0 0 60 60">
                    <path d="M47.7 19L30 1 12.3 19H21v28h18V19z" fill="none" />
                    <path d="M59 46v13H1V46" fill="none" />
                  </svg>
                </i>
              </button>
              <input
                ref={(input) => (this.inputElement = input)}
                className="c-input-file__input"
                type="file"
                id={name}
                data-qa={name}
                name={name}
                accept="image/*, video/*"
                capture="user"
                multiple
                onChange={this.handleChange}
                tabIndex="-1"
              />
            </div>
          </label>
        </div>

        <FileList id="uploaded-images" files={attachments.files} onRemove={this.handleRemove} />
      </Fragment>
    );
  }
}

FileUploadBlock.propTypes = {
  attachments: PropTypes.object.isRequired,
  attachmentRemove: PropTypes.func.isRequired,
  ctaText: PropTypes.string,
  name: PropTypes.string.isRequired,
  uploadAttachments: PropTypes.func.isRequired,
};

FileUploadBlock.defaultProps = {
  ctaText: ATTACHMENTS_CTA_DEFAULT_TEXT,
};

export default FileUploadBlock;
