import { Heading, Step, ButtonRow, InfoBlock, Control, Banner } from '../../../models';
import { PUBLIC, OVERFLOW_PIPE, COVER, METER, GROUND, INTERNAL, NO, YES } from '../../../../constants';
import {
  cleanOverflowInformation,
  overflowPrivateBanner,
  privateIssueQuestion,
  reviewForCoC,
} from '../../../../constants/Copy';

const banner = new Banner(overflowPrivateBanner);
const headingBlock = new Heading('step-heading', cleanOverflowInformation.heading);
const infoBlock = new InfoBlock('info-block', cleanOverflowInformation.detail[0], 'p-tb');
const headingBlock2 = new Heading('step-heading2', cleanOverflowInformation.subHeading, 2).setSize('md');
const infoBlock2 = new InfoBlock('info-block2', cleanOverflowInformation.detail[1]);
const infoBlock3 = new InfoBlock('info-block3', cleanOverflowInformation.detail[3]);
const buttonsBlock = new ButtonRow();

const cleanOverflowInfoColStep = new Step('step', 'clean-overflow-info-col');
cleanOverflowInfoColStep.setIsContained(true);
cleanOverflowInfoColStep.addBlock(new Control('hidden', 'cleanOverflowInfoCol').setDefault(true));
cleanOverflowInfoColStep.addBlock(
  new Control('hidden', 'cleanOverflowInfoColTxt').setLabel(privateIssueQuestion).setDefault(reviewForCoC),
);
cleanOverflowInfoColStep.addBlock(banner);
cleanOverflowInfoColStep.addBlock(headingBlock);
cleanOverflowInfoColStep.addBlock(infoBlock);
cleanOverflowInfoColStep.addBlock(headingBlock2);
cleanOverflowInfoColStep.addBlock(infoBlock2);
cleanOverflowInfoColStep.addBlock(infoBlock3);
cleanOverflowInfoColStep.addBlock(buttonsBlock);
cleanOverflowInfoColStep.setTitle(cleanOverflowInformation.title);

cleanOverflowInfoColStep.setConstraints({
  externalWaterOrigin: {
    inclusion: [OVERFLOW_PIPE],
    exclusion: [COVER, METER, GROUND],
  },
  initialLocation: {
    exclusion: [PUBLIC, INTERNAL],
  },
  isColleagueMode: {
    presence: true,
    inclusion: [YES],
    exclusion: [NO],
  },
});

export { cleanOverflowInfoColStep };
