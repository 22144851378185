import { Heading, Step, ButtonRow, InfoBlock, Link } from '../../models';
import { MAIN_WEBSITE_URL, NO_WATER, PRESSURE, FIRST_FEED, NOT_FIRST_FEED, YES, NO, UNSURE } from '../../../constants';
import { backToMainWebsite, nwlpOohInfo } from '../../../constants/Copy';

const headingBlock = new Heading('step-heading', nwlpOohInfo.heading);
const infoBlock = new InfoBlock('info-block', nwlpOohInfo.detail[0], 'p-b');
const headingBlock2 = new Heading('step-heading', nwlpOohInfo.callUs, 2).setSize('sm');
const infoBlock2 = new InfoBlock('info-block2', nwlpOohInfo.detail[1], 'p-b');
const infoBlock3 = new InfoBlock('info-block3', nwlpOohInfo.detail[2]);
const linkBlock = new Link('link-block', backToMainWebsite)
  .setLink(MAIN_WEBSITE_URL)
  .setTarget('_self')
  .setFormat(true);
const buttonsBlock = new ButtonRow().hideContinueButton();

const nwlpOohInfoStep = new Step('step', 'nwlp-ooh-info');
nwlpOohInfoStep.setIsContained(true);
nwlpOohInfoStep.addBlock(headingBlock);
nwlpOohInfoStep.addBlock(infoBlock);
nwlpOohInfoStep.addBlock(headingBlock2);
nwlpOohInfoStep.addBlock(infoBlock2);
nwlpOohInfoStep.addBlock(infoBlock3);
nwlpOohInfoStep.addBlock(linkBlock);
nwlpOohInfoStep.addBlock(buttonsBlock);
nwlpOohInfoStep.setTitle(nwlpOohInfo.title);

nwlpOohInfoStep.setConstraints({
  nwlpInitialProblem: {
    inclusion: [NO_WATER],
    exclusion: [PRESSURE],
  },
  nwlpFacilitiesAffected: {
    inclusion: [FIRST_FEED],
    exclusion: [NOT_FIRST_FEED],
  },
  nwlpStopTap: {
    inclusion: [YES, NO, UNSURE],
  },
  nwlpAnyWater: {
    inclusion: [NO],
    exclusion: [YES],
  },
  isNoWaterEnabled: {
    presence: true,
    inclusion: [false],
    exclusion: [true],
  },
});

export { nwlpOohInfoStep };
