import validationService from '../api/validation';
import { BLOCK_QUESTION_TYPES, SOURCE_NWLP, SOURCE_WASTE } from '../constants';
import { cleanStepConfig, wasteStepConfig } from '../data';
import { nwlpStepConfig } from '../data/config';
/*
cleanseSteps

Initial parse of received config to produce convenience properties with each step (initialAnswers, exclusiveAnswers, hiddenAnswers)

steps - Array of step objects

*/
export const cleanseSteps = (steps) => {
  return steps.map((step, counter) => {
    let initialAnswers = step.blocks ? step.blocks.reduce(reduceQuestionBlocks, {}) : {};
    let exclusiveAnswers = step.blocks ? step.blocks.reduce(reduceExclusiveAnswers, []) : [];
    let hiddenAnswers = step.blocks ? step.blocks.reduce(reduceHiddenBlocks, {}) : {};
    return {
      ...step,
      initialAnswers,
      exclusiveAnswers,
      hiddenAnswers,
    };
  });
};

/*
fetchSteps

Resolve config object from remote source. At present we are loading this locally but could be swapped to be http request returning a Promise.

successHandler -Function to call on successful response
errorHandler -Function to call on erroneous response
source - indicates which config to use (clean or waste)

*/
export const fetchSteps = (successHandler, errorHandler, source) => {
  let config;
  if (source.source === SOURCE_WASTE || source.localStorageSource === SOURCE_WASTE) {
    config = wasteStepConfig;
  } else if (source.source === SOURCE_NWLP || source.localStorageSource === SOURCE_NWLP) {
    config = nwlpStepConfig;
  } else {
    config = cleanStepConfig;
  }

  return new Promise(function(resolve) {
    setTimeout(() => resolve(config), 500); // mock delay until async source
  })
    .then((steps) => {
      successHandler(cleanseSteps(steps));
    })
    .catch(errorHandler);
};

/*
validateStep

Compares a list of values against the constraints of the given step

step - The step object being validated
values - A value bag to compare constraints against

*/
export const validateStep = (step, values) => {
  let constraintArr = Array.isArray(step.constraints) ? step.constraints : [step.constraints];
  let hasPassed = false;

  constraintArr.forEach((constraints) => {
    if (!validationService.validate(values, constraints)) {
      hasPassed = true;
    }
  });

  return hasPassed;
};

/*
validateBlock

Compares a list of values against the constraints of the given block

block - The step object being validated
values - A value bag to compare constraints against

*/
export const validateBlock = (block, values) => {
  let constraintArr = Array.isArray(block.constraints) ? block.constraints : [block.constraints];
  let hasPassed = false;

  constraintArr.forEach((constraints) => {
    if (!validationService.validate(values, constraints)) {
      hasPassed = true;
    }
  });

  return hasPassed;
};

/*
reduceQuestionBlocks

Reducer to extract only blocks which are classified as a question
and will therefore ned to be stored in state as the user
progresses through the steps

accumulator - An object to accumulate the matching values
block - A block object which to evaluate

*/
export const reduceQuestionBlocks = (accumulator, block) => {
  if (BLOCK_QUESTION_TYPES.includes(block.type) && block.name) {
    accumulator[block.name] = block.default || '';
  }
  return accumulator;
};

export const reduceClearBlocks = (accumulator, block) => {
  if (block.clear === true) {
    accumulator[block.name] = undefined;
  }
  return accumulator;
};

/*
reduceHiddenBlocks

Reducer to extract only blocks which are classified as hidden fields

accumulator - An object to accumulate the matching values
block - A block object which to evaluate

*/
export const reduceHiddenBlocks = (accumulator, block) => {
  if (block.type === 'hidden') {
    accumulator[block.name] = block.default || '';
  }
  return accumulator;
};

/*
reduceExclusiveAnswers

Reducer to extract only blocks which are classified as exclusive fields - which means that the answers
must be checked to see if the some answers can only be answered on their own

accumulator - An object to accumulate the matching values
block - A block object which to evaluate

*/
export const reduceExclusiveAnswers = (accumulator, block) => {
  if (block.type === 'checkbox') {
    block.options.forEach((option) => {
      if (option.isExclusive) {
        accumulator.push({ fieldName: block.name, answer: option.value });
      }
    });
  }
  return accumulator;
};

/*
getNextValidStep

Process a given array of steps against the values and array of visited slugs to see what are the next valid steps

steps - Array of all step objects
values - Object containing answers to questions within the steps
visited - An array of slugs that relate to visited steps and therefore are no longer valid as forward steps

*/
export const getNextValidStep = (steps, values, currentSlug) => {
  const validSteps = steps.filter((step) => {
    return validateStep(step, values);
  });

  let nextValidStep;
  let isNext = false;
  let prevSlug;
  let i = 0;
  if (validSteps) {
    while (!isNext && i < validSteps.length) {
      const thisStep = validSteps[i];

      if (prevSlug === currentSlug) {
        isNext = true;
        nextValidStep = thisStep;
      }
      prevSlug = thisStep.slug;

      i++;
    }
  }

  if (!nextValidStep) {
    nextValidStep = validSteps[0];
  }
  return nextValidStep;
};

/*
getValidBlocks

Process a given array of blocks against the values to see what are the next valid blocks to display on this step

blocks - Array of all blocks objects within a step
values - Object containing answers to questions within the steps for constraint comparison

*/
export const getValidBlocks = (blocks, values) => {
  return blocks
    ? blocks.filter((block) => {
        return validateBlock(block, values);
      })
    : [];
};

/*
removeForwardAnswers

Removes answers from steps that are beyond the current step from the currentAnswers object

currentAnswers - Object containing current value bag
currentSteps - Array of all step objects currently possible
currentSlug - The slug of the current active step

*/
export const removeForwardClearableAnswers = (currentAnswers, currentSteps, currentSlug) => {
  const validSteps = currentSteps.filter((step) => {
    return validateStep(step, currentAnswers);
  });

  let counterNum = 0;
  let futureClearQuestionsObj = {};
  let hasMetCurrentSlug = false;

  while (counterNum < validSteps.length) {
    const thisStep = validSteps[counterNum];
    if (thisStep) {
      if (hasMetCurrentSlug) {
        const stepClearQuestions = thisStep.blocks ? thisStep.blocks.reduce(reduceClearBlocks, {}) : [];
        futureClearQuestionsObj = { ...futureClearQuestionsObj, ...stepClearQuestions };
      }

      if (currentSlug === thisStep.slug) {
        hasMetCurrentSlug = true;
      }
    }
    counterNum++;
  }

  const forwardClearQuestions = Object.keys(futureClearQuestionsObj);

  let newAnswers = {};
  Object.keys(currentAnswers).forEach((key) => {
    if (!forwardClearQuestions.includes(key)) {
      newAnswers[key] = currentAnswers[key];
    }
  });

  return newAnswers;
};

/*
removeForwardAnswers

Removes answers from steps that are beyond the current step from the currentAnswers object

currentAnswers - Object containing current value bag
currentSteps - Array of all step objects currently possible
currentSlug - The slug of the current active step

*/
export const removeForwardAnswers = (currentAnswers, currentSteps) => {
  const validSteps = currentSteps.filter((step) => {
    return validateStep(step, currentAnswers);
  });

  let counterNum = 0;
  let futureQuestionsObj = {};

  while (counterNum < validSteps.length) {
    const thisStep = validSteps[counterNum];
    if (thisStep) {
      const stepQuestions = thisStep.blocks ? thisStep.blocks.reduce(reduceQuestionBlocks) : [];

      futureQuestionsObj = { ...futureQuestionsObj, ...stepQuestions };
    }
    counterNum++;
  }

  const forwardQuestions = Object.keys(futureQuestionsObj);

  let newAnswers = {};
  Object.keys(currentAnswers).forEach((key) => {
    if (forwardQuestions.includes(key)) {
      newAnswers[key] = currentAnswers[key];
    }
  });

  return newAnswers;
};

// REAL VISITED STEPS
export const realVisitedSteps = (visitedSteps, initialSteps, newAnswersThisStep, currentSlug) => {
  const validSteps = initialSteps.filter((step) => {
    return validateStep(step, newAnswersThisStep);
  });
  let counterNum = 0;
  let realStep;

  while (counterNum < validSteps.length) {
    const thisStep = validSteps[counterNum];

    if (currentSlug === thisStep.slug) {
      realStep = thisStep.slug;
    }

    counterNum++;
  }

  return visitedSteps.includes(currentSlug) ? visitedSteps : [...visitedSteps, realStep];
};

const stepsApi = {
  cleanseSteps,
  fetchSteps,
  getNextValidStep,
  getValidBlocks,
  removeForwardAnswers,
  removeForwardClearableAnswers,
  realVisitedSteps,
  validateStep,
  reduceQuestionBlocks,
  reduceClearBlocks,
  reduceHiddenBlocks,
  reduceExclusiveAnswers,
};

export default stepsApi;
