import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormRow } from 'yw-ui.v2';
import { nwlpNoticeDateTime } from '../../../constants/Copy';

export class DateTimeSelectBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maxDate: this.calculateMaxDate(),
      dateValid: false,
      timeValid: false,
      dateError: nwlpNoticeDateTime.noDate,
      timeError: nwlpNoticeDateTime.noTime,
    };
  }

  noticeDate = undefined;
  noticeTime = undefined;

  calculateMaxDate() {
    if (this.props.setMaxTime === false) {
      return null;
    }
    const date = new Date();
    let dd = date.getDate();
    dd = dd < 10 ? '0' + dd : dd;
    let mm = date.getMonth() + 1;
    mm = mm < 10 ? '0' + mm : mm;
    return `${date.getFullYear()}-${mm}-${dd}`;
  }

  sameDate(d1, d2) {
    return (
      d1.getUTCFullYear() === d2.getUTCFullYear() &&
      d1.getUTCMonth() === d2.getUTCMonth() &&
      d1.getUTCDate() === d2.getUTCDate()
    );
  }

  onChange(data) {
    const key = data.target.name;
    const value = data.target.value;
    this[key] = value;
    const dateSelected = this.noticeDate !== undefined && this.noticeDate !== null && this.noticeDate !== '';
    const timeSelected = this.noticeTime !== undefined && this.noticeTime !== null && this.noticeTime !== '';
    this.setState({
      dateValid: dateSelected,
    });

    if (dateSelected !== true || timeSelected !== true) {
      return;
    }

    const date = new Date(`${this.noticeDate} ${this.noticeTime}`);
    const isToday = this.sameDate(date, new Date());

    let timeValid = timeSelected;
    let timeError = nwlpNoticeDateTime.noTime;
    if (isToday === true) {
      if (date > new Date()) {
        timeValid = false;
        timeError = nwlpNoticeDateTime.future;
      }
    }

    this.setState({
      timeValid: timeValid,
      timeError: timeError,
    });

    this.props.form.handleChange(this.props.name, date.toISOString());
  }

  render() {
    this.props.form.values.nwlpNoticeDateTimeValid = this.state.dateValid === true && this.state.timeValid === true;
    return (
      <FormRow
        id="datetimeselect-block-row"
        qa="datetimeselect-block-row"
        name="field_name"
        condensed={true}
        messages={[]}
        hideLabel={true}
        label=""
      >
        <FormRow
          label="Date"
          name="dateRow"
          qa="dateRow"
          valid={this.props.form.submitCount === 0 || this.state.dateValid === true}
          messages={[this.state.dateError]}
          isRequiredMarker={true}
        >
          <input
            type="date"
            className="c-input c-input--col-4-sm"
            name="noticeDate"
            id="noticeDate"
            onChange={(value) => this.onChange(value)}
            max={this.state.maxDate}
          />
        </FormRow>

        <FormRow
          label="Time"
          name="timeRow"
          qa="timeRow"
          valid={this.props.form.submitCount === 0 || this.state.timeValid === true}
          messages={[this.state.timeError]}
          isRequiredMarker={true}
        >
          <input
            type="time"
            className="c-input c-input--col-3-sm"
            name="noticeTime"
            id="noticeTime"
            onChange={(value) => this.onChange(value)}
          />
        </FormRow>
      </FormRow>
    );
  }
}

DateTimeSelectBlock.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  setMaxTime: PropTypes.bool.isRequired,
};

export default DateTimeSelectBlock;
