import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  BOTH,
  DRAIN,
  NONE,
  BLOCKED,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  CHEMICAL,
  FARM,
  GAS,
  HOUSE,
  ODOUR,
  COVER,
  GROUND,
  POLLUTION,
  BATHROOM,
  KITCHEN,
} from '../../../../constants';
import { wasteOdourOther } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteOdourOther.heading);
const wasteOdourOtherControl = new Control('question', 'wasteOdourOther');
wasteOdourOtherControl.setLabel(wasteOdourOther.heading);
wasteOdourOtherControl.setDefault('');
wasteOdourOtherControl.addOption(wasteOdourOther.blockedDrain, DRAIN, 'IconInternalBlockage');
wasteOdourOtherControl.addOption(wasteOdourOther.slowToilet, BLOCKED, 'IconToilet');
wasteOdourOtherControl.addOption(wasteOdourOther.both, BOTH, 'IconHouse2');
wasteOdourOtherControl.addOption(wasteOdourOther.none, NONE, 'IconCross');
wasteOdourOtherControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteOdourOtherStep = new Step('step', 'waste-odour-other');
wasteOdourOtherStep.addBlock(headingBlock);
wasteOdourOtherStep.addBlock(wasteOdourOtherControl);
wasteOdourOtherStep.addBlock(buttonsBlock);
wasteOdourOtherStep.setIsContained(false);
wasteOdourOtherStep.setTitle(wasteOdourOther.title);

wasteOdourOtherStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [ODOUR],
      exclusion: [HOUSE, BATHROOM, KITCHEN],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [ODOUR],
      exclusion: [COVER, GROUND, DRAIN],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
  },
]);

export { wasteOdourOtherStep };
