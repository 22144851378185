import { ButtonRow, Control, Heading, Step } from '../../models';
import {
  YES,
  NO,
  UNSURE,
  WASTE,
  CLEAN,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  GULLY,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  COVER,
  DRAIN,
  GROUND,
  ODOUR,
  POLLUTION,
} from '../../../constants';
import { boundaryWaterSmell } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', boundaryWaterSmell.heading);
const boundaryWaterSmellControl = new Control('question', 'boundaryWaterSmell');
boundaryWaterSmellControl.setLabel(boundaryWaterSmell.heading);
boundaryWaterSmellControl.addOption(boundaryWaterSmell.yes, YES, 'IconBadSmell');
boundaryWaterSmellControl.addOption(boundaryWaterSmell.no, NO, 'IconCross');
boundaryWaterSmellControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const boundaryWaterSmellStep = new Step('step', 'boundary-water-smell');
boundaryWaterSmellStep.addBlock(headingBlock);
boundaryWaterSmellStep.addBlock(boundaryWaterSmellControl);
boundaryWaterSmellStep.addBlock(buttonsBlock);
boundaryWaterSmellStep.setTitle(boundaryWaterSmell.title);

boundaryWaterSmellStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [COVER],
      exclusion: [DRAIN, GROUND, ODOUR],
    },
    wasteExternalCoverType: {
      inclusion: [OTHER],
      exclusion: [METER, SLUICE_VALVE, STOPTAP, GRATE, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      inclusion: [NO],
      exclusion: [YES],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [GROUND],
      exclusion: [DRAIN, COVER, ODOUR],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      inclusion: [NO],
      exclusion: [YES],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [INTERNAL, EXTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [GULLY, SQUARE, SLUICE_VALVE, STOPTAP, METER],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      inclusion: [NO],
      exclusion: [YES],
    },
  },
]);

export { boundaryWaterSmellStep };
