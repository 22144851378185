import Service from './service';
import { API_ENDPOINT_TOKEN } from '../constants';

const getToken = async (id) => {
  return await Service.get(`${API_ENDPOINT_TOKEN}?id=${id}`).then(({ data }) => {
    return data;
  });
};

const tokenApi = { getToken };

export default tokenApi;
