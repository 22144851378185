import { Control, Heading, Step, ButtonRow } from '../../models';
import {
  YES,
  NO,
  PRESSURE,
  NO_WATER,
  LOW_PRESSURE,
  HIGH_PRESSURE,
  HOT_COLD,
  NOT_FIRST_FEED,
  HOT_ONLY,
  UNSURE,
  FIRST_FEED,
} from '../../../constants';
import { nwlpPropertyType } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', nwlpPropertyType.heading);
const nwlpPropertyTypeControl = new Control('question', 'nwlpPropertyType');
nwlpPropertyTypeControl.setLabel(nwlpPropertyType.heading);
nwlpPropertyTypeControl.addOption(nwlpPropertyType.yes, YES, 'IconCheckTick');
nwlpPropertyTypeControl.addOption(nwlpPropertyType.no, NO, 'IconCross');
const buttonsBlock = new ButtonRow().hideContinueButton();

const nwlpPropertyTypeStep = new Step('step', 'nwlp-property-type');
nwlpPropertyTypeStep.addBlock(headingBlock);
nwlpPropertyTypeStep.addBlock(nwlpPropertyTypeControl);
nwlpPropertyTypeStep.addBlock(buttonsBlock);
nwlpPropertyTypeStep.setTitle(nwlpPropertyType.title);

nwlpPropertyTypeStep.setConstraints([
  {
    nwlpInitialProblem: {
      inclusion: [PRESSURE],
      exclusion: [NO_WATER],
    },
    nwlpPressureProblem: {
      inclusion: [LOW_PRESSURE],
      exclusion: [HIGH_PRESSURE],
    },
    nwlpPressureSupply: {
      inclusion: [HOT_COLD],
      exclusion: [NOT_FIRST_FEED, HOT_ONLY],
    },
    nwlpStopTap: {
      inclusion: [YES, NO, UNSURE],
    },
  },
  {
    nwlpInitialProblem: {
      inclusion: [NO_WATER],
      exclusion: [PRESSURE],
    },
    nwlpFacilitiesAffected: {
      inclusion: [FIRST_FEED],
      exclusion: [NOT_FIRST_FEED],
    },
    nwlpStopTap: {
      inclusion: [YES, NO, UNSURE],
    },
    nwlpAnyWater: {
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    nwlpInitialProblem: {
      inclusion: [NO_WATER],
      exclusion: [PRESSURE],
    },
    nwlpFacilitiesAffected: {
      inclusion: [FIRST_FEED],
      exclusion: [NOT_FIRST_FEED],
    },
    nwlpStopTap: {
      inclusion: [YES, NO, UNSURE],
    },
    nwlpAnyWater: {
      inclusion: [NO],
      exclusion: [YES],
    },
    isNoWaterEnabled: {
      presence: true,
      inclusion: [true],
      exclusion: [false],
    },
    nwlpNoticeDateTime: {
      presence: true,
    },
    nwlpNoticeConfirm: {
      inclusion: ['TRUE'],
    },
  },
]);

export { nwlpPropertyTypeStep };
