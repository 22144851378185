import moment from 'moment';
import Service from './service';
import { API_ENDPOINT_CALLBACKS } from '../constants';

/*
fetchSlots

Make a request for slots given the reasonId

reasonId - Id of the reason type
successHandler - Function to call on successful response
errorHandler - Function to call on erroneous response

*/
const fetchSlots = (reasonId, successHandler, errorHandler) => {
  return Service.get(`${API_ENDPOINT_CALLBACKS}/slots/${reasonId}`)
    .then(({ data }) => {
      const availableSlots = data.availableSlots.map((day) => {
        const newTimeSlots = day.timeSlots.map((timeSlot) => {
          const startDate = moment(timeSlot.from);
          const startTime = startDate.format('HH:mm');
          const endDate = moment(timeSlot.to);
          const newEndTime = endDate.format('HH:mm');
          return { range: timeSlot, description: `${startTime} to ${newEndTime}`, time: timeSlot.from };
        });

        return { ...day, timeSlots: newTimeSlots };
      });
      successHandler(availableSlots);
    })
    .catch(errorHandler);
};

const callbacksApi = { fetchSlots };

export default callbacksApi;
