import { Control, Heading, Step, Message, ButtonRow } from '../../../models';
import { NO, YES } from '../../../../constants';
import { incidentConfirm } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', incidentConfirm.heading);
const instructionMessageBlock = new Message('step-hint', incidentConfirm.hint).setState('info');
const addressTotalControl = new Control('hidden', 'incidentTotal');
const nearbyControl = new Control('question', 'incidentResults').setLabel('');
nearbyControl.addOption(incidentConfirm.yes, YES, 'IconCheckTick');
nearbyControl.addOption(incidentConfirm.no, NO, 'IconCross');
const buttonsBlock = new ButtonRow().hideContinueButton();

const incidentConfirmStep = new Step('step', 'incident-results');
incidentConfirmStep.addBlock(headingBlock);
incidentConfirmStep.addBlock(instructionMessageBlock);
incidentConfirmStep.addBlock({ type: 'incidentList', name: 'incidentList' });
incidentConfirmStep.addBlock(addressTotalControl);
incidentConfirmStep.addBlock(nearbyControl);
incidentConfirmStep.addBlock(buttonsBlock);
incidentConfirmStep.setIsContained(true);
incidentConfirmStep.setTitle(incidentConfirm.title);

incidentConfirmStep.setConstraints({
  isCleanInitial: {
    inclusion: [true],
  },
  isWasteInitial: {
    exclusion: [true],
  },
  incidentTotal: {
    presence: { AllowEmpty: true },
    numericality: {
      greaterThan: 0,
    },
  },
});

export { incidentConfirmStep };
