import Service from './service';
import { API_ENDPOINT_PROPERTIES } from '../constants';
/*
fetchAddresses

Make a http request ot GET the address given the parameters

postcode - A string representing the search postcode
buildingNumber - A string representing the optional building number
successHandler -Function to call on successful response
errorHandler -Function to call on erroneous response

*/
const fetchAddresses = (lookupValues, successHandler, errorHandler) => {
  return Service.get(
    `${API_ENDPOINT_PROPERTIES}?postcode=${lookupValues.postcode}&houseNameOrNumber=${lookupValues.buildingNumber}`,
  )
    .then(({ data }) => {
      const addresses = data.map((item) => {
        return {
          id: item.propertyReference,
          address: item.address,
          label: item.address,
          postcode: item.postcode,
          easting: item.easting,
          northing: item.northing,
          isCommercial: item.isCommercial,
        };
      });
      successHandler(addresses);
    })
    .catch((error) => {
      errorHandler(error);
    });
};

const addressApi = { fetchAddresses };

export default addressApi;
