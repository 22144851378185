import { ButtonRow, Control, Heading, Step } from '../../../models';
import { YES, NO, UNSURE, OTHER, GROUND } from '../../../../constants';
import { waterSmell } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', waterSmell.heading);
const waterSmellControl = new Control('question', 'waterSmell');
waterSmellControl.setLabel(waterSmell.heading);
waterSmellControl.addOption(waterSmell.yes, YES, 'IconBadSmell');
waterSmellControl.addOption(waterSmell.no, NO, 'IconCross');
waterSmellControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const waterSmellStep = new Step('step', 'water-smell');
waterSmellStep.addBlock(headingBlock);
waterSmellStep.addBlock(waterSmellControl);
waterSmellStep.addBlock(buttonsBlock);
waterSmellStep.setTitle(waterSmell.title);

waterSmellStep.setConstraints([
  {
    waterType: {
      presence: true,
      inclusion: [UNSURE],
    },
    constantPressure: {
      presence: true,
      inclusion: [NO],
    },
  },
  {
    externalWaterOrigin: {
      presence: true,
      inclusion: [GROUND],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
    },
    externalConstantPressure: {
      presence: true,
      inclusion: [NO],
    },
  },
  {
    coverType: {
      presence: true,
      inclusion: [OTHER],
    },
    externalConstantPressure: {
      presence: true,
      inclusion: [NO],
    },
  },
]);

export { waterSmellStep };
