function InfoBlock(id, text, classNames) {
  this.classnames = classNames;
  this.type = 'info';
  this.id = id;
  this.text = text;
}

InfoBlock.prototype.setConstraints = function(constraints) {
  this.constraints = constraints;
  return this;
};

export default InfoBlock;
