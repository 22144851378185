import {
  YES,
  NO,
  EXTERNAL,
  PUBLIC,
  COVER,
  DRAIN,
  GROUND,
  ODOUR,
  WATERCOURSE,
  TOWARDS_WATERCOURSE,
  GULLY,
  PROPERTY,
  OUTBUILDING,
  GARDEN,
  POOLING,
  DAMP,
  INTERNAL,
  MAIN_WEBSITE_URL,
} from '../../../../constants';
import { Heading, Link, Step, ButtonRow, InfoBlock, Control } from '../../../models';
import { backToMainWebsite, wasteOoh } from '../../../../constants/Copy';

const headingBlock = new Heading('step-heading', wasteOoh.heading);
const infoBlock1 = new InfoBlock('info-block1', wasteOoh.detail[0], 'p-tb');
const infoBlock2 = new InfoBlock('info-block2', wasteOoh.detail[1]);
const infoBlock3 = new InfoBlock('info-block3', wasteOoh.detail[2], 'p-t');
const infoBlock4 = new InfoBlock('info-block4', wasteOoh.detail[3], 'p-tb');
const wasteOohControl = new Control('hidden', 'wasteOoh').setDefault(YES);
const linkBlockMainSite = new Link('link-block-main-site', backToMainWebsite, 'p-t')
  .setLink(MAIN_WEBSITE_URL)
  .setTarget('_self');
const buttonsBlock = new ButtonRow();

const wasteOohStep = new Step('step', 'waste-OOH');
wasteOohStep.setIsContained(true);
wasteOohStep.addBlock(headingBlock);
wasteOohStep.addBlock(infoBlock1);
wasteOohStep.addBlock(infoBlock2);
wasteOohStep.addBlock(infoBlock3);
wasteOohStep.addBlock(infoBlock4);
wasteOohStep.addBlock(buttonsBlock);
wasteOohStep.addBlock(linkBlockMainSite);
wasteOohStep.addBlock(wasteOohControl);
wasteOohStep.setTitle(wasteOoh.title);

wasteOohStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteBoundaryEscapeLocation: {
      inclusion: [PROPERTY],
      exclusion: [WATERCOURSE, TOWARDS_WATERCOURSE, GULLY, GARDEN, OUTBUILDING],
    },
    wasteBoundaryEscapeProperty: {
      inclusion: [POOLING],
      exclusion: [DAMP],
    },
    isWorkingHours: {
      presence: true,
      inclusion: [false],
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteBoundaryEscapeLocation: {
      inclusion: [GULLY, TOWARDS_WATERCOURSE],
      exclusion: [WATERCOURSE, PROPERTY, GARDEN, OUTBUILDING],
    },
    wasteBoundaryEscapeInternal: {
      inclusion: [YES, NO],
      exclusion: [],
    },
    isWorkingHours: {
      presence: true,
      inclusion: [false],
      exclusion: [true],
    },
  },
]);

export { wasteOohStep };
