function NwlpContact() {
  this.type = 'nwlpContact';
}

NwlpContact.prototype.setConstraints = function(constraints) {
  this.constraints = constraints;
  return this;
};

export default NwlpContact;
