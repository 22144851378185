import { ButtonRow, Control, Heading, Step } from '../../models';
import {
  YES,
  UNSURE,
  WASTE,
  CLEAN,
  GULLY,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  COVER,
  DRAIN,
  GROUND,
  ODOUR,
  POLLUTION,
} from '../../../constants';
import { boundaryRainfallAffected } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', boundaryRainfallAffected.heading);
const boundaryRainfallAffectedControl = new Control('question', 'boundaryRainfallAffected');
boundaryRainfallAffectedControl.setLabel(boundaryRainfallAffected.heading);
boundaryRainfallAffectedControl.addOption(boundaryRainfallAffected.yes, YES, 'IconRain');
boundaryRainfallAffectedControl.addOption(boundaryRainfallAffected.notSure, UNSURE, 'IconCross');
boundaryRainfallAffectedControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const boundaryRainfallAffectedStep = new Step('step', 'boundary-rainfall-affected');
boundaryRainfallAffectedStep.addBlock(headingBlock);
boundaryRainfallAffectedStep.addBlock(boundaryRainfallAffectedControl);
boundaryRainfallAffectedStep.addBlock(buttonsBlock);
boundaryRainfallAffectedStep.setTitle(boundaryRainfallAffected.title);

boundaryRainfallAffectedStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [COVER],
      exclusion: [DRAIN, GROUND, ODOUR],
    },
    wasteExternalCoverType: {
      inclusion: [OTHER],
      exclusion: [METER, SLUICE_VALVE, STOPTAP, GRATE, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [GROUND],
      exclusion: [DRAIN, COVER, ODOUR],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [INTERNAL, EXTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [GULLY, SQUARE, SLUICE_VALVE, STOPTAP, METER],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
  },
]);

export { boundaryRainfallAffectedStep };
