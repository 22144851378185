import { Heading, Step, ButtonRow, InfoBlock, Banner, Control } from '../../../models';
import {
  OWNER,
  UNSURE,
  YORKSHIREWATER,
  INTERNAL,
  PUBLIC,
  EXTERNAL,
  ODOUR,
  WATERCOURSE,
  GARDEN,
  BLOCKED,
  DRAIN,
  COVER,
  GROUND,
  NO,
  YES,
  NONE,
  BOTH,
  OUTBUILDING,
  TOWARDS_WATERCOURSE,
  GULLY,
  PROPERTY,
  KITCHEN,
  HOUSE,
  BATHROOM,
  CLEAR,
  UPSTAIRS_TOILET,
  DOWNSTAIRS_BATH,
  MULTIPLE_TOILETS,
  DOWNSTAIRS_TOILET,
  UPSTAIRS_FACILITIES,
  DOWNSTAIRS_FACILITIES,
  GRATE,
  MANHOLE,
  UPSTAIRS_BATH,
  SINGLE,
  MULTIPLE,
  DIFFERENT,
  SAME,
  TOILET,
  APPLIANCE,
  SINK,
  FLOOR,
  CELLAR,
  GROUND_FLOOR,
  GARAGE,
  OTHER,
  UPSTAIRS,
  SLOW_DRAINAGE,
  CHUTE,
  PIPE,
  AIRBRICK,
  INTERMITTENT,
  CONSTANT,
  PRESSURE,
  NO_WATER,
  LOW_PRESSURE,
  HIGH_PRESSURE,
  NOT_FIRST_FEED,
  HOT_ONLY,
  HOT_COLD,
  FIRST_FEED,
} from '../../../../constants';
import { wasteDrainageInfo, privateIssueBanner, privateIssueQuestion, reviewForCoC } from '../../../../constants/Copy';

const banner = new Banner(privateIssueBanner);
const headingBlock = new Heading('step-heading', wasteDrainageInfo.heading);
const infoBlock1 = new InfoBlock('info-block1', wasteDrainageInfo.detail[0], 'p-tb');
const headingBlock2 = new Heading('step-heading2', wasteDrainageInfo.subHeading, 2).setSize('md');
const infoBlock2 = new InfoBlock('info-block2', wasteDrainageInfo.detail[1], 'p-b');
const infoBlock3 = new InfoBlock('info-block3', wasteDrainageInfo.detail[2], 'p-b');
const infoBlock5 = new InfoBlock('info-block5', wasteDrainageInfo.detail[4], 'p-b');
const infoBlock6 = new InfoBlock('info-block6', wasteDrainageInfo.detail[5], 'p-b');
const buttonsBlock = new ButtonRow();

const wasteDrainageInfoColStep = new Step('step', 'waste-drainage-info-col');
wasteDrainageInfoColStep.setIsContained(true);
wasteDrainageInfoColStep.addBlock(new Control('hidden', 'wasteDrainageInfoCol').setDefault(true));
wasteDrainageInfoColStep.addBlock(
  new Control('hidden', 'wasteDrainageInfoColTxt').setLabel(privateIssueQuestion).setDefault(reviewForCoC),
);
wasteDrainageInfoColStep.addBlock(banner);
wasteDrainageInfoColStep.addBlock(headingBlock);
wasteDrainageInfoColStep.addBlock(infoBlock1);
wasteDrainageInfoColStep.addBlock(headingBlock2);
wasteDrainageInfoColStep.addBlock(infoBlock2);
wasteDrainageInfoColStep.addBlock(infoBlock3);
wasteDrainageInfoColStep.addBlock(infoBlock5);
wasteDrainageInfoColStep.addBlock(infoBlock6);
wasteDrainageInfoColStep.addBlock(buttonsBlock);
wasteDrainageInfoColStep.setTitle(wasteDrainageInfo.title);

wasteDrainageInfoColStep.setConstraints([
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      inclusion: [NO],
      exclusion: [YES],
    },
    wasteBoundaryIssues: {
      inclusion: [NONE],
      exclusion: [BLOCKED, ODOUR, BOTH],
    },
    wasteResponsibility: {
      inclusion: [OWNER],
      exclusion: [YORKSHIREWATER, UNSURE],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteBoundaryEscapeLocation: {
      inclusion: [GARDEN, OUTBUILDING],
      exclusion: [WATERCOURSE, TOWARDS_WATERCOURSE, GULLY, PROPERTY],
    },
    wasteBoundaryIssues: {
      inclusion: [NONE],
      exclusion: [BLOCKED, ODOUR, BOTH],
    },
    wasteResponsibility: {
      inclusion: [OWNER],
      exclusion: [YORKSHIREWATER, UNSURE],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [KITCHEN],
      exclusion: [HOUSE, ODOUR, BATHROOM],
    },
    wasteInternalExtdrain: {
      inclusion: [CLEAR],
      exclusion: [BLOCKED, UNSURE],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [BATHROOM],
      exclusion: [HOUSE, ODOUR, KITCHEN],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_TOILET],
      exclusion: [
        UPSTAIRS_BATH,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalExtother: {
      inclusion: [NO],
      exclusion: [GRATE, MANHOLE, ODOUR],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [BATHROOM],
      exclusion: [HOUSE, ODOUR, KITCHEN],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [SINGLE],
      exclusion: [MULTIPLE],
    },
    wasteInternalExtother: {
      inclusion: [NO],
      exclusion: [GRATE, MANHOLE, ODOUR],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [BATHROOM],
      exclusion: [HOUSE, ODOUR, KITCHEN],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [MULTIPLE],
      exclusion: [SINGLE],
    },
    wasteInternalSame: {
      inclusion: [DIFFERENT],
      exclusion: [SAME],
    },
    wasteInternalExtother: {
      inclusion: [NO],
      exclusion: [GRATE, MANHOLE, ODOUR],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_TOILET],
      exclusion: [
        UPSTAIRS_BATH,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalExtother: {
      inclusion: [NO],
      exclusion: [GRATE, MANHOLE, ODOUR],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [SINGLE],
      exclusion: [MULTIPLE],
    },
    wasteInternalExtother: {
      inclusion: [NO],
      exclusion: [GRATE, MANHOLE, ODOUR],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [MULTIPLE],
      exclusion: [SINGLE],
    },
    wasteInternalSame: {
      inclusion: [DIFFERENT],
      exclusion: [SAME],
    },
    wasteInternalExtother: {
      inclusion: [NO],
      exclusion: [GRATE, MANHOLE, ODOUR],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [APPLIANCE],
      exclusion: [TOILET, SINK, FLOOR, CELLAR],
    },
    wasteInternalSink: {
      inclusion: [CLEAR],
      exclusion: [BLOCKED],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [APPLIANCE],
      exclusion: [TOILET, SINK, FLOOR, CELLAR],
    },
    wasteInternalSink: {
      inclusion: [BLOCKED],
      exclusion: [CLEAR],
    },
    wasteInternalExtdrain: {
      inclusion: [CLEAR],
      exclusion: [BLOCKED, UNSURE],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [SINK],
      exclusion: [TOILET, APPLIANCE, FLOOR, CELLAR],
    },
    wasteInternalExtdrain: {
      inclusion: [CLEAR],
      exclusion: [BLOCKED, UNSURE],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [FLOOR, CELLAR],
      exclusion: [TOILET, APPLIANCE, SINK],
    },
    wasteInternalArea: {
      inclusion: [GROUND_FLOOR, GARAGE, OTHER, UPSTAIRS],
      exclusion: [CELLAR],
    },
    wasteInternalOther: {
      inclusion: [NONE],
      exclusion: [BLOCKED, MANHOLE, ODOUR, SLOW_DRAINAGE],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [FLOOR, CELLAR],
      exclusion: [TOILET, APPLIANCE, SINK],
    },
    wasteInternalArea: {
      inclusion: [CELLAR],
      exclusion: [GROUND_FLOOR, GARAGE, OTHER, UPSTAIRS],
    },
    wasteInternalOther: {
      inclusion: [NONE],
      exclusion: [BLOCKED, MANHOLE, ODOUR, SLOW_DRAINAGE],
    },
    wasteInternalSource: {
      inclusion: [CHUTE, AIRBRICK],
      exclusion: [PIPE, OTHER],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [FLOOR, CELLAR],
      exclusion: [TOILET, APPLIANCE, SINK],
    },
    wasteInternalArea: {
      inclusion: [CELLAR],
      exclusion: [GROUND_FLOOR, GARAGE, OTHER, UPSTAIRS],
    },
    wasteInternalOther: {
      inclusion: [NONE],
      exclusion: [BLOCKED, MANHOLE, ODOUR, SLOW_DRAINAGE],
    },
    wasteInternalSource: {
      inclusion: [PIPE],
      exclusion: [CHUTE, AIRBRICK, OTHER],
    },
    wasteInternalFreq: {
      inclusion: [INTERMITTENT],
      exclusion: [CONSTANT],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    nwlpInitialProblem: {
      presence: true,
      inclusion: [PRESSURE],
      exclusion: [NO_WATER],
    },
    nwlpPressureProblem: {
      inclusion: [LOW_PRESSURE],
      exclusion: [HIGH_PRESSURE],
    },
    nwlpPressureSupply: {
      inclusion: [NOT_FIRST_FEED, HOT_ONLY],
      exclusion: [HOT_COLD],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    nwlpInitialProblem: {
      presence: true,
      inclusion: [NO_WATER],
      exclusion: [PRESSURE],
    },
    nwlpFacilitiesAffected: {
      inclusion: [NOT_FIRST_FEED],
      exclusion: [FIRST_FEED],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
]);

export { wasteDrainageInfoColStep };
