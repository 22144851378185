import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  YES,
  NO,
  COVER,
  METER,
  PLUMBING,
  ABOVE_GROUND_STOP_TAP,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  TRIANGLE,
} from '../../../../constants';
import { coverType, images } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', coverType.heading);
const coverTypeControl = new Control('question', 'coverType').setLabel(coverType.heading).setDefault('');
coverTypeControl.addOption(coverType.triangle, TRIANGLE, null, false, false, images.triangleSewage);
coverTypeControl.addOption('Large square', SQUARE, null, false, false, images.largeSquareSewage);
coverTypeControl.addOption(coverType.rectangle, SLUICE_VALVE, null, false, false, images.sluiceRectangle);
coverTypeControl.addOption(coverType.smallSquare, STOPTAP, null, false, false, images.sluiceSquare);
coverTypeControl.addOption(coverType.circle, METER, null, false, false, images.meter);
coverTypeControl.addOption(coverType.other, OTHER, 'IconQuestion', false, false, null);
coverTypeControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const coverTypeStep = new Step('step', 'cover-type');
coverTypeStep.addBlock(headingBlock);
coverTypeStep.addBlock(coverTypeControl);
coverTypeStep.addBlock(buttonsBlock);
coverTypeStep.setTitle(coverType.title);

coverTypeStep.setConstraints([
  {
    externalCoverType: {
      exclusion: [GRATE, SQUARE, STOPTAP, METER, SLUICE_VALVE, OTHER],
    },
    cleanAccess: {
      exclusion: [YES, NO],
    },
    waterOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [PLUMBING, METER, ABOVE_GROUND_STOP_TAP],
    },
  },
  {
    externalCoverType: {
      exclusion: [GRATE, SQUARE, STOPTAP, METER, SLUICE_VALVE, OTHER],
    },
    cleanAccess: {
      exclusion: [YES, NO],
    },
    waterFromCover: {
      presence: true,
      exclusion: [NO],
      inclusion: [YES],
    },
  },
]);

export { coverTypeStep };
