import { Accordion, ButtonRow, Heading, Message, Step, Control, InfoBlock } from '../../../models';
import {
  YES,
  NO,
  UNSURE,
  WASTE,
  CLEAN,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  ACCESS,
  ACCIDENT,
  BOTH,
  OWNER,
  YORKSHIREWATER,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  GULLY,
  DAMP,
  GUSHING,
  LARGE,
  LOCATION_METHOD_ADDRESS_LOOKUP,
  LOCATION_METHOD_CURRENT_LOCATION,
  LOCATION_METHOD_MAP_SEARCH,
  MEDIUM,
  NONE,
  PROPERTY_DAMAGE,
  SMALL,
  TRICKLING,
  COVER,
  DRAIN,
  GROUND,
  HOUSE,
  PRIVATE,
  ODOUR,
  POLLUTION,
  BLOCKED,
  WATERCOURSE,
  TOWARDS_WATERCOURSE,
  PROPERTY,
  OUTBUILDING,
  GARDEN,
  POOLING,
  FARM,
  CHEMICAL,
  GAS,
  BATHROOM,
  KITCHEN,
} from '../../../../constants';
import { uploadPage } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', uploadPage.heading);
const infoBlock = new InfoBlock('step-info', uploadPage.subHeader);
const hintBlockPublic = new Message('step-hint', uploadPage.calloutBoxTop).setState('info');
const safetyBlockExternal = new Message('step-hint', uploadPage.calloutBoxSafety).setState('warning');
const tipsBlock = new Accordion('accordion-photos', uploadPage.accordionHeader).setContent(uploadPage.accordionBody);
const uploadBlock = {
  id: 'image-upload',
  type: 'fileUpload',
  name: 'attachments',
  ctaText: uploadPage.buttonText,
};
const buttonsBlock = new ButtonRow();
const buttonsBlockDisabled = new ButtonRow();
buttonsBlockDisabled.setContinueButtonDisabled(true);
buttonsBlockDisabled.setContinueButtonText('Uploading');
buttonsBlockDisabled.setContinueButtonState('default');
const wastePhotoControl = new Control('hidden', 'wastePhotoStep').setDefault(YES);

const wastePhotoStep = new Step('step', 'waste-photos');
wastePhotoStep.addBlock(headingBlock);
wastePhotoStep.addBlock(infoBlock);
wastePhotoStep.addBlock(hintBlockPublic);
wastePhotoStep.addBlock(safetyBlockExternal);
wastePhotoStep.addBlock(tipsBlock);
wastePhotoStep.addBlock(uploadBlock);
wastePhotoStep.addBlock(buttonsBlock);
wastePhotoStep.addBlock(buttonsBlockDisabled);
wastePhotoStep.addBlock(wastePhotoControl);
wastePhotoStep.setIsContained(true);
wastePhotoStep.setTitle('Waste photos');

wastePhotoStep.setConstraints([
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [COVER],
      exclusion: [DRAIN, GROUND, ODOUR],
    },
    wasteExternalCoverType: {
      presence: true,
      inclusion: [METER, OTHER, SLUICE_VALVE, STOPTAP],
      exclusion: [GRATE, SQUARE],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    cleanBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
    cleanBoundaryAccessSupply: {
      presence: true,
      inclusion: [NO, YES],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [COVER],
      exclusion: [DRAIN, GROUND, ODOUR],
    },
    wasteExternalCoverType: {
      presence: true,
      inclusion: [OTHER, GRATE, SQUARE],
      exclusion: [METER, SLUICE_VALVE, STOPTAP],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [ACCESS, BOTH, NONE, PROPERTY_DAMAGE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
    wasteBoundaryAccessSupply: {
      presence: true,
      inclusion: [NO, YES],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [DRAIN, COVER, ODOUR],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [YES],
      exclusion: [UNSURE],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
    wasteBoundaryAccessSupply: {
      presence: true,
      inclusion: [YES, NO],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [DRAIN, COVER, ODOUR],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    cleanBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
    cleanBoundaryAccessSupply: {
      presence: true,
      inclusion: [YES, NO],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [DRAIN, COVER, ODOUR],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    cleanBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
    cleanBoundaryAccessSupply: {
      presence: true,
      inclusion: [YES, NO],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [DRAIN, COVER, ODOUR],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
    wasteBoundaryAccessSupply: {
      presence: true,
      inclusion: [YES, NO],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [POLLUTION],
      exclusion: [GROUND, ODOUR, COVER],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteOriginPollution: {
      presence: true,
      inclusion: [GRATE, PRIVATE, YORKSHIREWATER, UNSURE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [POLLUTION],
      exclusion: [GROUND, ODOUR, COVER],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteOriginPollution: {
      presence: true,
      inclusion: [GRATE, PRIVATE, YORKSHIREWATER, UNSURE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [WASTE, UNSURE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    cleanBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [WASTE, UNSURE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    cleanBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [WASTE, UNSURE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    cleanBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [WASTE, UNSURE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    cleanBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },

  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [WASTE],
      exclusion: [CLEAN, UNSURE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [WASTE],
      exclusion: [CLEAN, UNSURE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [YES],
      exclusion: [UNSURE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [YES],
      exclusion: [UNSURE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [SQUARE],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, OTHER, GULLY],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [SQUARE],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, OTHER, GULLY],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [GULLY],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, OTHER, SQUARE],
    },
    wasteExternalCoverBlocked: {
      presence: true,
      inclusion: [GUSHING],
      exclusion: [BLOCKED],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [GULLY],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, OTHER, SQUARE],
    },
    wasteExternalCoverBlocked: {
      presence: true,
      inclusion: [GUSHING],
      exclusion: [BLOCKED],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [SLUICE_VALVE, STOPTAP, METER],
      exclusion: [OTHER, SQUARE, GULLY],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    cleanBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [SLUICE_VALVE, STOPTAP, METER],
      exclusion: [OTHER, SQUARE, GULLY],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    cleanBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, SQUARE, GULLY],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [WASTE],
      exclusion: [UNSURE, CLEAN],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, SQUARE, GULLY],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [WASTE],
      exclusion: [UNSURE, CLEAN],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [YES],
      exclusion: [UNSURE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, SQUARE, GULLY],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [YES],
      exclusion: [UNSURE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SQUARE, GULLY, SLUICE_VALVE, STOPTAP, METER],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [UNSURE, WASTE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    cleanBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SQUARE, GULLY, SLUICE_VALVE, STOPTAP, METER],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [UNSURE, WASTE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    cleanBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    cleanBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    cleanBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    cleanBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    cleanBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    wasteBoundaryIssues: {
      presence: true,
      inclusion: [BLOCKED, ODOUR, BOTH],
      exclusion: [NONE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    wasteBoundaryIssues: {
      presence: true,
      inclusion: [NONE],
      exclusion: [BLOCKED, ODOUR, BOTH],
    },
    wasteResponsibility: {
      presence: true,
      inclusion: [YORKSHIREWATER, UNSURE],
      exclusion: [OWNER],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteBoundaryEscapeLocation: {
      presence: true,
      inclusion: [WATERCOURSE],
      exclusion: [TOWARDS_WATERCOURSE, GULLY, PROPERTY, OUTBUILDING, GARDEN],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteBoundaryEscapeLocation: {
      presence: true,
      inclusion: [GARDEN, OUTBUILDING],
      exclusion: [WATERCOURSE, TOWARDS_WATERCOURSE, GULLY, PROPERTY],
    },
    wasteBoundaryIssues: {
      presence: true,
      inclusion: [BLOCKED, ODOUR, BOTH],
      exclusion: [NONE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteBoundaryEscapeLocation: {
      presence: true,
      inclusion: [GARDEN, OUTBUILDING],
      exclusion: [WATERCOURSE, TOWARDS_WATERCOURSE, GULLY, PROPERTY],
    },
    wasteBoundaryIssues: {
      presence: true,
      inclusion: [NONE],
      exclusion: [BLOCKED, ODOUR, BOTH],
    },
    wasteResponsibility: {
      presence: true,
      inclusion: [YORKSHIREWATER, UNSURE],
      exclusion: [OWNER],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteBoundaryEscapeLocation: {
      presence: true,
      inclusion: [PROPERTY],
      exclusion: [WATERCOURSE, TOWARDS_WATERCOURSE, GULLY, GARDEN, OUTBUILDING],
    },
    wasteBoundaryEscapeProperty: {
      presence: true,
      inclusion: [POOLING],
      exclusion: [DAMP],
    },
    isWorkingHours: {
      presence: true,
      inclusion: [true],
      exclusion: [false],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteBoundaryEscapeLocation: {
      presence: true,
      inclusion: [PROPERTY],
      exclusion: [WATERCOURSE, TOWARDS_WATERCOURSE, GULLY, GARDEN, OUTBUILDING],
    },
    wasteBoundaryEscapeProperty: {
      presence: true,
      inclusion: [POOLING],
      exclusion: [DAMP],
    },
    isWorkingHours: {
      presence: true,
      inclusion: [false],
      exclusion: [true],
    },
    wasteOoh: {
      presence: true,
      inclusion: [YES],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteBoundaryEscapeLocation: {
      presence: true,
      inclusion: [PROPERTY],
      exclusion: [WATERCOURSE, TOWARDS_WATERCOURSE, GULLY, GARDEN, OUTBUILDING],
    },
    wasteBoundaryEscapeProperty: {
      presence: true,
      inclusion: [DAMP],
      exclusion: [POOLING],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteBoundaryEscapeLocation: {
      presence: true,
      inclusion: [GULLY, TOWARDS_WATERCOURSE],
      exclusion: [WATERCOURSE, PROPERTY, GARDEN, OUTBUILDING],
    },
    wasteBoundaryEscapeInternal: {
      presence: true,
      inclusion: [YES, NO],
      exclusion: [],
    },
    isWorkingHours: {
      presence: true,
      inclusion: [true],
      exclusion: [false],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
    wasteBoundaryEscape: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteBoundaryEscapeLocation: {
      presence: true,
      inclusion: [GULLY, TOWARDS_WATERCOURSE],
      exclusion: [WATERCOURSE, PROPERTY, GARDEN, OUTBUILDING],
    },
    wasteBoundaryEscapeInternal: {
      presence: true,
      inclusion: [YES, NO],
      exclusion: [],
    },
    isWorkingHours: {
      presence: true,
      inclusion: [false],
      exclusion: [true],
    },
    wasteOoh: {
      presence: true,
      inclusion: [YES],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      presence: true,
      inclusion: [ODOUR],
      exclusion: [HOUSE, BATHROOM, KITCHEN],
    },
    wasteOdourType: {
      presence: true,
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      presence: true,
      inclusion: [DRAIN, BLOCKED, BOTH],
      exclusion: [NONE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [ODOUR],
      exclusion: [COVER, GROUND, DRAIN],
    },
    wasteOdourType: {
      presence: true,
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      presence: true,
      inclusion: [DRAIN, BLOCKED, BOTH],
      exclusion: [NONE],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      presence: true,
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      presence: true,
      inclusion: [DRAIN, BLOCKED, BOTH],
      exclusion: [NONE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      presence: true,
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      presence: true,
      inclusion: [DRAIN, BLOCKED, BOTH],
      exclusion: [NONE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
]);

buttonsBlock.setConstraints({
  'attachments.isUploading': {
    inclusion: [false],
    exclusion: [true],
  },
});

buttonsBlockDisabled.setConstraints({
  'attachments.isUploading': {
    presence: true,
    inclusion: [true],
  },
});

export { wastePhotoStep };
