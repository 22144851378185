import { ButtonRow, Control, Heading, Hook, Step } from '../../../models';
import {
  CONSTANT,
  INTERMITTENT,
  AIRBRICK,
  CHUTE,
  OTHER,
  PIPE,
  BLOCKED,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  KITCHEN,
  HOUSE,
  ODOUR,
  BATHROOM,
  APPLIANCE,
  TOILET,
  SINK,
  FLOOR,
  CELLAR,
  GROUND_FLOOR,
  GARAGE,
  UPSTAIRS,
  SLOW_DRAINAGE,
  MANHOLE,
  NONE,
  STEP_ACCESS_HOURS_LOOKUP,
} from '../../../../constants';
import { wasteInternalFreq } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteInternalFreq.heading);
const wasteInternalFreqControl = new Control('question', 'wasteInternalFreq');
wasteInternalFreqControl.setLabel(wasteInternalFreq.heading);
wasteInternalFreqControl.setDefault('');
wasteInternalFreqControl.addOption(wasteInternalFreq.constant, CONSTANT, 'Icon24Hours');
wasteInternalFreqControl.addOption(wasteInternalFreq.intermittent, INTERMITTENT, 'IconTime');
wasteInternalFreqControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteInternalFreqStep = new Step('step', 'waste-internal-freq');
wasteInternalFreqStep.addBlock(headingBlock);
wasteInternalFreqStep.addBlock(wasteInternalFreqControl);
wasteInternalFreqStep.addBlock(buttonsBlock);
wasteInternalFreqStep.exitHook = new Hook(STEP_ACCESS_HOURS_LOOKUP, '');
wasteInternalFreqStep.setTitle(wasteInternalFreq.title);

wasteInternalFreqStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [FLOOR, CELLAR],
      exclusion: [TOILET, APPLIANCE, SINK],
    },
    wasteInternalArea: {
      inclusion: [CELLAR],
      exclusion: [GROUND_FLOOR, GARAGE, OTHER, UPSTAIRS],
    },
    wasteInternalOther: {
      inclusion: [NONE],
      exclusion: [BLOCKED, MANHOLE, ODOUR, SLOW_DRAINAGE],
    },
    wasteInternalSource: {
      inclusion: [PIPE],
      exclusion: [CHUTE, AIRBRICK, OTHER],
    },
  },
]);

export { wasteInternalFreqStep };
