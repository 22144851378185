import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layouts } from 'yw-ui.v2/dist/components';

import { loadConfig } from './actions/StepsAction';
import { Steps } from './scenes';
import { SERVICE_TITLE, MAIN_WEBSITE_URL, SOURCE_CLEAN } from './constants';

import 'yw-ui.v2/dist/css/yw-ui.v2.min.css';
import './App.scss';
import ColleagueModeHeaderBlock from './scenes/blocks/ColleagueModeHeaderBlock/ColleagueModeHeaderBlock';

export class App extends Component {
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get('source') || SOURCE_CLEAN;
    const altSource = window.sessionStorage.getItem('source') || SOURCE_CLEAN;
    this.props.loadConfig({ source, altSource });
  }

  render() {
    const colleague = window.sessionStorage.getItem('token') !== null;
    return (
      <Layouts.AppWrapper title={SERVICE_TITLE} toLink={MAIN_WEBSITE_URL}>
        {colleague && <ColleagueModeHeaderBlock />}
        <main role="main">
          <Switch>
            <Route path="/" exact component={(route) => <Steps route={route} tracker={this.props.tracker} />} />
            <Route path="/:slug" component={(route) => <Steps route={route} tracker={this.props.tracker} />} />
          </Switch>
        </main>
      </Layouts.AppWrapper>
    );
  }
}

App.propTypes = {
  loadConfig: PropTypes.func,
  tracker: PropTypes.object,
};

App.defaultProps = {
  loadConfig: () => {},
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  loadConfig: (config) => dispatch(loadConfig(config)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
