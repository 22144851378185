import { ButtonRow, Control, Heading, Step } from '../../../models';
import { EXTERNAL, INTERNAL, PUBLIC, COVER, GROUND, ODOUR, POLLUTION } from '../../../../constants';
import { wasteExternalOrigin } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteExternalOrigin.heading);
const wasteExternalOriginControl = new Control('question', 'wasteExternalOrigin');
wasteExternalOriginControl.setLabel(wasteExternalOrigin.heading);
wasteExternalOriginControl.addOption(wasteExternalOrigin.ground, GROUND, 'IconGround');
wasteExternalOriginControl.addOption(wasteExternalOrigin.odour, ODOUR, 'IconBadSmell');
wasteExternalOriginControl.addOption(wasteExternalOrigin.pollution, POLLUTION, 'IconPollution2');
wasteExternalOriginControl.addOption(wasteExternalOrigin.cover, COVER, 'IconCover');
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteExternalOriginStep = new Step('step', 'waste-external-origin');
wasteExternalOriginStep.addBlock(headingBlock);
wasteExternalOriginStep.addBlock(wasteExternalOriginControl);
wasteExternalOriginStep.addBlock(buttonsBlock);
wasteExternalOriginStep.setTitle(wasteExternalOrigin.title);

wasteExternalOriginStep.setConstraints({
  initialLocationWaste: {
    inclusion: [PUBLIC],
    exclusion: [EXTERNAL, INTERNAL],
  },
});

export { wasteExternalOriginStep };
