import { Step } from '../../../models';
import { DISCOLOURED_WATER_URL, INTERNAL } from '../../../../constants';

export const discolouredRedirect = new Step('redirect', 'discoloured-redirect')
  .setRedirectUrl(DISCOLOURED_WATER_URL)
  .setConstraints({
    initialLocation: {
      presence: true,
      inclusion: [INTERNAL],
    },
    waterQualityIssue: {
      presence: true,
      inclusion: ['DISCOLOURED'],
    },
  });
