import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { HeadingBlock, Icon } from 'yw-ui.v2';
import { SVG_NAMESPACE_URL } from '../../../constants';

class FileList extends Component {
  handleRemove = (attachmentId) => {
    this.props.onRemove(attachmentId);
  };

  onKeyUp = (e, attachmentId) => {
    if (e.key === 'Enter' || e.key === 'Space') {
      this.props.onRemove(attachmentId);
    }
  };

  render() {
    const { files, heading, id } = this.props;
    return (
      <Fragment>
        {files && files.length > 0 && (
          <div id={id} data-qa={id} className="u-p--200-lg u-pull-top--300 u-mb--500 c-content">
            <HeadingBlock id={`${id}_heading`} data-qa={`${id}_heading`} size="sm" priority={2}>
              {heading}
            </HeadingBlock>
            <span className="c-file-list" id={`${id}_list`} data-qa={`${id}_list`}>
              {files.map((file, i) => {
                const conditionalRowClasses = classNames('c-file-row', {
                  'has-error': file.hasErrored,
                  'is-pending': file.isUploading,
                });
                return (
                  <div
                    key={`${id}-file-${i}`}
                    className={conditionalRowClasses}
                    id={`${id}_attachment-${i}`}
                    data-qa={`${id}_attachment-${i}`}
                  >
                    {!file.isUploading && !file.hasErrored && (
                      <Icon
                        icon="IconCheckTick"
                        colour="ywMediumGreen"
                        size="150"
                        className="c-file-list_icon c-icon--weight-150"
                        id={`${id}_icon-${i}`}
                        qa={`${id}_icon-${i}`}
                      />
                    )}
                    {file.hasErrored && (
                      <Icon
                        colour="ywMediumRed"
                        icon="IconCross"
                        size="150"
                        className="c-file-list_icon c-icon--weight-150"
                        id={`${id}_icon-${i}`}
                        qa={`${id}_icon-${i}`}
                      />
                    )}

                    <span className="c-file-list_filename" id={`${id}_filename-${i}`} data-qa={`${id}_filename-${i}`}>
                      {file.filename}
                    </span>
                    {file.isUploading && !file.hasErrored && (
                      <span className="c-file-list_status" id={`${id}_status-${i}`} data-qa={`${id}_status-${i}`}>
                        <svg width="40" height="10" viewBox="0 0 120 30" xmlns={SVG_NAMESPACE_URL} fill="#19809f">
                          <circle cx="15" cy="15" r="15">
                            <animate
                              attributeName="r"
                              from="15"
                              to="15"
                              begin="0s"
                              dur="0.8s"
                              values="15;9;15"
                              calcMode="linear"
                              repeatCount="indefinite"
                            />
                            <animate
                              attributeName="fill-opacity"
                              from="1"
                              to="1"
                              begin="0s"
                              dur="0.8s"
                              values="1;.5;1"
                              calcMode="linear"
                              repeatCount="indefinite"
                            />
                          </circle>
                          <circle cx="60" cy="15" r="9" fillOpacity="0.3">
                            <animate
                              attributeName="r"
                              from="9"
                              to="9"
                              begin="0s"
                              dur="0.8s"
                              values="9;15;9"
                              calcMode="linear"
                              repeatCount="indefinite"
                            />
                            <animate
                              attributeName="fillOpacity"
                              from="0.5"
                              to="0.5"
                              begin="0s"
                              dur="0.8s"
                              values=".5;1;.5"
                              calcMode="linear"
                              repeatCount="indefinite"
                            />
                          </circle>
                          <circle cx="105" cy="15" r="15">
                            <animate
                              attributeName="r"
                              from="15"
                              to="15"
                              begin="0s"
                              dur="0.8s"
                              values="15;9;15"
                              calcMode="linear"
                              repeatCount="indefinite"
                            />
                            <animate
                              attributeName="fillOpacity"
                              from="1"
                              to="1"
                              begin="0s"
                              dur="0.8s"
                              values="1;.5;1"
                              calcMode="linear"
                              repeatCount="indefinite"
                            />
                          </circle>
                        </svg>
                      </span>
                    )}
                    {!file.isUploading && (
                      <span
                        className="c-file-list_check"
                        role="button"
                        onClick={() => this.handleRemove(file.id)}
                        onKeyUp={(e) => this.onKeyUp(e, file.id)}
                        tabIndex="0"
                        id={`${id}_remove-file-${i}`}
                        data-qa={`${id}_remove-file-${i}`}
                      >
                        remove
                      </span>
                    )}
                    {file.hasErrored && (
                      <span className="c-file-list_error" id={`${id}_error-${i}`} data-qa={`${id}_error-${i}`}>
                        {file.error}
                      </span>
                    )}
                  </div>
                );
              })}
            </span>
          </div>
        )}
      </Fragment>
    );
  }
}

FileList.propTypes = {
  files: PropTypes.array,
  heading: PropTypes.string,
  id: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
};

FileList.defaultProps = {
  files: null,
  heading: 'Uploaded files',
};
export default FileList;
