import {
  MAIN_WEBSITE_URL,
  GARDEN,
  OUTBUILDING,
  PROPERTY,
  GULLY,
  WATERCOURSE,
  TOWARDS_WATERCOURSE,
  NO,
  YES,
  DECP_PROBLEM_SUBMIT_URL,
} from '../../../constants';
import { backToMainWebsite, thankYouPollution } from '../../../constants/Copy';
import { Heading, InfoBlock, Step, Link, RedirectButton } from '../../models';

const headingBlock = new Heading('step-title', thankYouPollution.heading);
const thankYouInfoBlock1 = new InfoBlock('thank-you-block', thankYouPollution.detail[0], 'p-b');
const headingBlock2 = new Heading('what-next-heading', thankYouPollution.subHeading, 2).setSize('sm');
const thankYouInfoBlock2 = new InfoBlock('what-next-block', thankYouPollution.detail[1], 'p-b');
const mainSiteLink = new Link('main-website-link', backToMainWebsite).setLink(MAIN_WEBSITE_URL).setTarget('_self');
const colleagueButtonBlock = new RedirectButton(thankYouPollution.redirect, DECP_PROBLEM_SUBMIT_URL);

const thankYouPollutionStep = new Step('step', 'thank-you-pollution');
thankYouPollutionStep.addBlock(headingBlock);
thankYouPollutionStep.addBlock(thankYouInfoBlock1);
thankYouPollutionStep.addBlock(headingBlock2);
thankYouPollutionStep.addBlock(thankYouInfoBlock2);
thankYouPollutionStep.addBlock(mainSiteLink);
thankYouPollutionStep.addBlock(colleagueButtonBlock);
thankYouPollutionStep.setIsContained(true);
thankYouPollutionStep.setTitle(thankYouPollution.title);

mainSiteLink.setConstraints({
  isColleagueMode: {
    inclusion: [NO],
    exclusion: [YES],
  },
});

colleagueButtonBlock.setConstraints({
  isColleagueMode: {
    presence: true,
    inclusion: [YES],
    exclusion: [NO],
  },
});

thankYouPollutionStep.setConstraints({
  wasteBoundaryEscapeLocation: {
    inclusion: [WATERCOURSE],
    exclusion: [PROPERTY, TOWARDS_WATERCOURSE, GULLY, GARDEN, OUTBUILDING],
  },
  problemSubmitted: {
    presence: {
      AllowEmpty: false,
    },
  },
  isWorkingHours: {
    presence: true,
    inclusion: [false],
    exclusion: [true],
  },
});

export { thankYouPollutionStep };
