import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  GULLY,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  SQUARE,
  BLOCKED,
  GUSHING,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  COVER,
  GROUND,
  ODOUR,
  POLLUTION,
} from '../../../../constants';
import { wasteExternalCoverBlocked } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteExternalCoverBlocked.heading);
const coverBlockedControl = new Control('question', 'wasteExternalCoverBlocked');
coverBlockedControl.setLabel(wasteExternalCoverBlocked.heading);
coverBlockedControl.setDefault('');
coverBlockedControl.addOption(wasteExternalCoverBlocked.blocked, BLOCKED, 'IconDampPatch', false);
coverBlockedControl.addOption(wasteExternalCoverBlocked.gushing, GUSHING, 'IconGushing', false);
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteExternalCoverBlockedStep = new Step('step', 'waste-external-cover-blocked');
wasteExternalCoverBlockedStep.addBlock(headingBlock);
wasteExternalCoverBlockedStep.addBlock(coverBlockedControl);
wasteExternalCoverBlockedStep.addBlock(buttonsBlock);
wasteExternalCoverBlockedStep.setTitle(wasteExternalCoverBlocked.title);

wasteExternalCoverBlockedStep.setConstraints({
  initialLocationWaste: {
    inclusion: [PUBLIC],
    exclusion: [EXTERNAL, INTERNAL],
  },
  wasteExternalOrigin: {
    inclusion: [COVER],
    exclusion: [ODOUR, POLLUTION, GROUND],
  },
  wasteExternalCover: {
    inclusion: [GULLY],
    exclusion: [SQUARE, SLUICE_VALVE, STOPTAP, METER, OTHER],
  },
});

export { wasteExternalCoverBlockedStep };
