import { Heading, Step, ButtonRow, InfoBlock, Control, Banner } from '../../../models';
import {
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  CHEMICAL,
  DRAIN,
  FARM,
  GAS,
  COVER,
  GROUND,
  HOUSE,
  ODOUR,
  POLLUTION,
  BATHROOM,
  KITCHEN,
  NO,
  YES,
} from '../../../../constants';
import {
  wasteGasInfoCol,
  emergencyPrivateIssueBanner,
  privateIssueQuestion,
  reviewForCoC,
} from '../../../../constants/Copy';

const banner = new Banner(emergencyPrivateIssueBanner);
const headingBlock = new Heading('step-heading', wasteGasInfoCol.heading);
const infoBlock1 = new InfoBlock('info-block1', wasteGasInfoCol.info, 'p-tb');
const headingBlock2 = new Heading('step-heading2', wasteGasInfoCol.subHeading, 2).setSize('sm');
const infoBlock2 = new InfoBlock('info-block2', wasteGasInfoCol.bulletHead, 'u-pb--100');
const bullet1 = new InfoBlock('bullet-point1', wasteGasInfoCol.bullets[0], 'u-pt--0 u-pb--50 u-mb--0');
const bullet2 = new InfoBlock('bullet-point1', wasteGasInfoCol.bullets[1], 'u-pt--0 u-pb--50 u-mb--0');
const bullet3 = new InfoBlock('bullet-point1', wasteGasInfoCol.bullets[2], 'u-pt--0 u-pb--50 u-mb--0');
const buttonsBlock = new ButtonRow();

const wasteGasInfoColStep = new Step('step', 'waste-gas-info-col');
wasteGasInfoColStep.setIsContained(true);
wasteGasInfoColStep.addBlock(new Control('hidden', 'wasteGasInfoCol').setDefault(true));
wasteGasInfoColStep.addBlock(
  new Control('hidden', 'wasteGasInfoColTxt').setLabel(privateIssueQuestion).setDefault(reviewForCoC),
);
wasteGasInfoColStep.addBlock(banner);
wasteGasInfoColStep.addBlock(headingBlock);
wasteGasInfoColStep.addBlock(infoBlock1);
wasteGasInfoColStep.addBlock(headingBlock2);
wasteGasInfoColStep.addBlock(infoBlock2);
wasteGasInfoColStep.addBlock(bullet1);
wasteGasInfoColStep.addBlock(bullet2);
wasteGasInfoColStep.addBlock(bullet3);
wasteGasInfoColStep.addBlock(buttonsBlock);
wasteGasInfoColStep.setTitle(wasteGasInfoCol.title);

wasteGasInfoColStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [ODOUR],
      exclusion: [HOUSE, BATHROOM, KITCHEN],
    },
    wasteOdourType: {
      inclusion: [GAS],
      exclusion: [CHEMICAL, FARM, DRAIN],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [ODOUR],
      exclusion: [COVER, GROUND, DRAIN],
    },
    wasteOdourType: {
      inclusion: [GAS],
      exclusion: [CHEMICAL, FARM, DRAIN],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [GAS],
      exclusion: [CHEMICAL, FARM, DRAIN],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
]);

export { wasteGasInfoColStep };
