import { Step } from '../../../models';
import {
  INTERNAL,
  EXTERNAL,
  ODOUR,
  WATER_SUPPLY,
  SLOW_DRAINAGE,
  NO,
  NO_WATER,
  LOW_PRESSURE,
  CALLBACK_2_URL,
} from '../../../../constants';

export const callbackRedirect = new Step('redirect', 'callback-redirect')
  .setRedirectUrl(CALLBACK_2_URL)
  .setConstraints([
    {
      initialLocation: {
        inclusion: [INTERNAL],
      },
      initialProblem: {
        inclusion: [WATER_SUPPLY],
      },
      supplyIssue: {
        inclusion: [NO_WATER, LOW_PRESSURE],
      },
      waterFromCover: {
        exclusion: [NO],
      },
    },
    {
      initialLocation: {
        inclusion: [EXTERNAL, INTERNAL],
      },
      initialProblem: {
        inclusion: [ODOUR, SLOW_DRAINAGE],
      },
      waterFromCover: {
        exclusion: [NO],
      },
    },
  ]);
