import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  YES,
  NO,
  UNSURE,
  WASTE,
  ACCESS,
  BOTH,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  NONE,
  COVER,
  GROUND,
  OVERFLOW_PIPE,
  PROPERTY_DAMAGE,
} from '../../../../constants';
import { externalWasteRisk } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', externalWasteRisk.heading);
const externalWasteRiskControl = new Control('question', 'externalWasteRisk');
externalWasteRiskControl.setLabel(externalWasteRisk.heading);
externalWasteRiskControl.setDefault('');
externalWasteRiskControl.addOption(externalWasteRisk.propertyRisk, PROPERTY_DAMAGE, 'IconHouse');
externalWasteRiskControl.addOption(externalWasteRisk.access, ACCESS, 'IconAccessWalking');
externalWasteRiskControl.addOption(externalWasteRisk.both, BOTH, 'IconPropertyAccess');
externalWasteRiskControl.addOption(externalWasteRisk.none, NONE, 'IconCross');
externalWasteRiskControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const externalWasteRiskStep = new Step('step', 'external-waste-risk');
externalWasteRiskStep.addBlock(headingBlock);
externalWasteRiskStep.addBlock(externalWasteRiskControl);
externalWasteRiskStep.addBlock(buttonsBlock);
externalWasteRiskStep.setTitle(externalWasteRisk.title);

externalWasteRiskStep.setConstraints([
  {
    initialLocation: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [UNSURE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
    },
    externalConstantPressure: {
      inclusion: [NO],
    },
    externalWaterSmell: {
      inclusion: [YES],
    },
  },
  {
    initialLocation: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [UNSURE],
    },
    externalRainfallAffected: {
      inclusion: [YES],
    },
  },
  {
    initialLocation: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [WASTE],
    },
  },
  {
    initialLocation: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
      exclusion: [OVERFLOW_PIPE, METER, GROUND],
    },
    externalCoverType: {
      inclusion: [GRATE, SQUARE],
      exclusion: [STOPTAP, METER, SLUICE_VALVE, OTHER],
    },
  },
]);

externalWasteRiskStep.setValidation({
  wasteRisk: {
    presence: {
      message: externalWasteRisk.validation,
      allowEmpty: false,
    },
  },
});

export { externalWasteRiskStep };
