import { Heading, Step, ButtonRow, InfoBlock, Link } from '../../../models';
import { FIX_LEAKS_URL, YES, MAIN_WEBSITE_URL, NO } from '../../../../constants';
import { backToMainWebsite, howWeFixLeaks, rtimInformation } from '../../../../constants/Copy';

const headingBlock = new Heading('step-heading', rtimInformation.heading);
const infoBlock = new InfoBlock('info-block', rtimInformation.detail[0], 'p-b');
const headingBlock2 = new Heading('step-heading2', rtimInformation.subHeading, 2).setSize('md');
const infoBlock2 = new InfoBlock('info-block2', rtimInformation.detail[1]);
const linkBlock1 = new Link('link-block1', howWeFixLeaks).setLink(FIX_LEAKS_URL).setTarget('_self');
const linkBlock2 = new Link('link-block2', backToMainWebsite, 'p-t').setLink(MAIN_WEBSITE_URL).setTarget('_self');
const buttonsBlock = new ButtonRow().hideContinueButton();

const rtimOffRampStep = new Step('step', 'rtim-information');
rtimOffRampStep.setIsContained(true);
rtimOffRampStep.addBlock(headingBlock);
rtimOffRampStep.addBlock(infoBlock);
rtimOffRampStep.addBlock(headingBlock2);
rtimOffRampStep.addBlock(infoBlock2);
rtimOffRampStep.addBlock(linkBlock1);
rtimOffRampStep.addBlock(linkBlock2);
rtimOffRampStep.addBlock(buttonsBlock);
rtimOffRampStep.setTitle(rtimInformation.title);

rtimOffRampStep.setConstraints({
  incidentResults: {
    inclusion: [YES],
  },
  incidentTotal: {
    presence: true,
    numericality: {
      greaterThan: 0,
    },
  },
  isColleagueMode: {
    presence: false,
    inclusion: [NO],
    exclusion: [YES],
  },
});

export { rtimOffRampStep };
