import { Heading, Step, ButtonRow, InfoBlock, Link } from '../../../models';
import {
  METER,
  PIPE_MEETS_METER,
  STOPTAP,
  UNSURE,
  INSIDE,
  OUTSIDE,
  INTERNAL,
  EXTERNAL,
  NO,
  YES,
  COVER,
  OVERFLOW_PIPE,
  GROUND,
  PUBLIC,
  PLUMBING,
  MAIN_WEBSITE_URL,
  LOCAL_PLUMBER_URL,
} from '../../../../constants';
import { backToMainWebsite, cleanIntStopTapInfo, localPlumber } from '../../../../constants/Copy';

const headingBlock = new Heading('step-heading', cleanIntStopTapInfo.heading);
const infoBlock = new InfoBlock('info-block', cleanIntStopTapInfo.detail[0], 'p-b');
const headingBlock2 = new Heading('step-heading2', cleanIntStopTapInfo.subHeading, 2).setSize('md');
const infoBlock2 = new InfoBlock('info-block2', cleanIntStopTapInfo.detail[1]);
const infoBlock3 = new InfoBlock('info-block3', cleanIntStopTapInfo.detail[2]);
const infoBlock4 = new InfoBlock('info-block4', cleanIntStopTapInfo.detail[3]);
const linkBlock = new Link('link-block', localPlumber).setLink(LOCAL_PLUMBER_URL).setTarget('_self');
const linkBlock2 = new Link('link-block2', backToMainWebsite).setLink(MAIN_WEBSITE_URL).setTarget('_self');
const buttonsBlock = new ButtonRow().hideContinueButton();

const cleanInternalStopTapInfoStep = new Step('step', 'clean-intstoptap-info');
cleanInternalStopTapInfoStep.setIsContained(true);
cleanInternalStopTapInfoStep.addBlock(headingBlock);
cleanInternalStopTapInfoStep.addBlock(infoBlock);
cleanInternalStopTapInfoStep.addBlock(headingBlock2);
cleanInternalStopTapInfoStep.addBlock(infoBlock2);
cleanInternalStopTapInfoStep.addBlock(infoBlock3);
cleanInternalStopTapInfoStep.addBlock(infoBlock4);
cleanInternalStopTapInfoStep.addBlock(linkBlock);
cleanInternalStopTapInfoStep.addBlock(linkBlock2);
cleanInternalStopTapInfoStep.addBlock(buttonsBlock);
cleanInternalStopTapInfoStep.setTitle(cleanIntStopTapInfo.title);

cleanInternalStopTapInfoStep.setConstraints([
  {
    initialLocation: {
      inclusion: [INTERNAL],
      exclusion: [PUBLIC, EXTERNAL],
    },
    internalWaterOrigin: {
      presence: true,
      inclusion: [METER],
    },
    cleanMeterInternal: {
      presence: true,
      inclusion: [STOPTAP],
      exclusion: [METER, PIPE_MEETS_METER, UNSURE],
    },
    isColleagueMode: {
      presence: false,
      inclusion: [NO],
      exclusion: [YES],
    },
  },
  {
    initialLocation: {
      inclusion: [INTERNAL],
      exclusion: [PUBLIC, EXTERNAL],
    },
    internalWaterOrigin: {
      presence: true,
      inclusion: [PLUMBING],
    },
    isColleagueMode: {
      presence: false,
      inclusion: [NO],
      exclusion: [YES],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      presence: true,
      inclusion: [METER],
      exclusion: [COVER, OVERFLOW_PIPE, GROUND],
    },
    cleanMeterLeaking: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    cleanMeterLocation: {
      presence: true,
      inclusion: [INSIDE],
      exclusion: [OUTSIDE],
    },
    cleanMeterInternal: {
      presence: true,
      inclusion: [STOPTAP],
      exclusion: [METER, PIPE_MEETS_METER, UNSURE],
    },
    isColleagueMode: {
      presence: false,
      inclusion: [NO],
      exclusion: [YES],
    },
  },
]);

export { cleanInternalStopTapInfoStep };
