import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SHORT_MONTHS, nwlpNoticeConfirm } from '../../../constants/Copy';

export class DateTimeConfirmBlock extends Component {
  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours || 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutes} ${ampm}`;
  }

  render() {
    const date = new Date(this.props.form.values.nwlpNoticeDateTime);
    const nwlpNoticeDateTimeString = `${date.getDate()} ${
      SHORT_MONTHS[date.getMonth()]
    } ${date.getFullYear()} at ${this.formatAMPM(date)},`;
    const str = nwlpNoticeConfirm.detail.replace('{nwlpNoticeDateTimeString}', nwlpNoticeDateTimeString);
    return <p dangerouslySetInnerHTML={{ __html: str }} />;
  }
}

DateTimeConfirmBlock.propTypes = {
  form: PropTypes.object.isRequired,
};

export default DateTimeConfirmBlock;
