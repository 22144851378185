import { Control, Heading, Step, ButtonRow } from '../../models';
import { HIGH_PRESSURE, LOW_PRESSURE, NO_WATER, PRESSURE } from '../../../constants';
import { nwlpPressureProblem } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', nwlpPressureProblem.heading);
const nwlpPressureProblemControl = new Control('question', 'nwlpPressureProblem');
nwlpPressureProblemControl.setLabel(nwlpPressureProblem.heading);
nwlpPressureProblemControl.addOption(nwlpPressureProblem.highPressure, HIGH_PRESSURE, 'IconTap3');
nwlpPressureProblemControl.addOption(nwlpPressureProblem.lowPressure, LOW_PRESSURE, 'IconDripping');
const buttonsBlock = new ButtonRow().hideContinueButton();

const nwlpPressureProblemStep = new Step('step', 'nwlp-pressure-problem');
nwlpPressureProblemStep.addBlock(headingBlock);
nwlpPressureProblemStep.addBlock(nwlpPressureProblemControl);
nwlpPressureProblemStep.addBlock(buttonsBlock);
nwlpPressureProblemStep.setTitle(nwlpPressureProblem.title);

nwlpPressureProblemStep.setConstraints({
  nwlpInitialProblem: {
    inclusion: [PRESSURE],
    exclusion: [NO_WATER],
  },
});

export { nwlpPressureProblemStep };
