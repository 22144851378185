import { ActionTypes } from '../constants';

export const initialState = {
  files: [],
  isUploading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ATTACHMENT_UPLOADING:
      return {
        ...state,
        isUploading: action.isUploading,
      };
    case ActionTypes.ATTACHMENT_UPLOAD_ADD:
      return {
        ...state,
        files: [
          ...state.files.filter((file) => file.filename !== action.filename),
          ...[
            {
              filename: action.filename,
              id: state.files.length + 1,
              isUploading: true,
              hasErrored: false,
              error: null,
              isVideo: action.isVideo,
            },
          ],
        ],
      };
    case ActionTypes.ATTACHMENT_UPLOAD_REMOVE:
      return {
        ...state,
        files: [...state.files.filter((attachment) => attachment.id !== action.attachmentId)],
      };
    case ActionTypes.ATTACHMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        files: [
          ...state.files.map((file) => {
            if (action.filename === file.filename) {
              return { ...file, id: action.attachmentId, isUploading: false, isVideo: action.isVideo };
            }
            return file;
          }),
        ],
      };
    case ActionTypes.ATTACHMENT_UPLOAD_ERROR:
      return {
        ...state,
        files: [
          ...state.files.map((file) => {
            if (action.filename === file.filename) {
              return { ...file, error: action.error, hasErrored: true, isUploading: false };
            }
            return file;
          }),
        ],
      };
    default:
      return state;
  }
};
