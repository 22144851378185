import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import outcomeReducer from './OutcomeReducer';
import stepsReducer from './StepsReducer';
import attachmentReducer from './AttachmentReducer';
import hookReducer from './HookReducer';
import LocationReducer from './LocationReducer';

import { initialState as initialOutcomeState } from './OutcomeReducer';
import { initialState as initialStepsState } from './StepsReducer';
import { initialState as initialHookState } from './HookReducer';
import { initialState as initialAttachmentState } from './AttachmentReducer';
import { initialState as initialLocationState } from './LocationReducer';

export const initialState = {
  outcome: initialOutcomeState,
  steps: initialStepsState,
  hooks: initialHookState,
  attachments: initialAttachmentState,
  locations: initialLocationState,
};

export default (history) =>
  combineReducers({
    outcome: outcomeReducer,
    router: connectRouter(history),
    steps: stepsReducer,
    hooks: hookReducer,
    attachments: attachmentReducer,
    locations: LocationReducer,
  });
