import axios from 'axios';

class Service {
  constructor() {
    let service = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
      validateStatus: function(status) {
        return status === 200;
      },
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleSuccess(response) {
    return Promise.resolve({ status: response.status, data: response.data });
  }

  handleError = (error) => {
    return Promise.reject({ status: error.response.status, message: error.message });
  };

  get(path) {
    return this.service.get(path);
  }

  patch(path, payload, callback) {
    return this.service.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload,
    });
  }

  post(path, payload) {
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload,
    });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Service();
