import { ATTACHMENT_IMAGE_TOTAL_LIMIT, ATTACHMENT_VIDEO_TOTAL_LIMIT, ActionTypes } from '../constants';

import {
  ATTACHMENT_ERROR_UNKNOWN,
  ATTACHMENT_ERROR_TOO_BIG,
  ATTACHMENT_MAX_FILE_SIZE,
  ATTACHMENT_ERROR_FILE_TYPE,
  ATTACHMENT_ERROR_UPLOAD_LIMIT,
} from '../constants';
import attachmentApi from '../api/attachments';

export const attachmentIsUploading = (bool) => ({
  type: ActionTypes.ATTACHMENT_UPLOADING,
  isUploading: bool,
});

export function attachmentHasErrored(hasErrored, filename, error) {
  return {
    type: ActionTypes.ATTACHMENT_UPLOAD_ERROR,
    hasErrored,
    filename,
    error,
  };
}

export function attachmentAdd(filename, isVideo) {
  return {
    type: ActionTypes.ATTACHMENT_UPLOAD_ADD,
    filename,
    isVideo: isVideo,
  };
}

export function attachmentSuccess(filename, attachmentId, isVideo) {
  return {
    type: ActionTypes.ATTACHMENT_UPLOAD_SUCCESS,
    filename,
    attachmentId,
    isVideo,
  };
}

export function attachmentRemove(attachmentId) {
  return {
    type: ActionTypes.ATTACHMENT_UPLOAD_REMOVE,
    attachmentId,
  };
}

export function uploadAttachment(file, successHandler, errorHandler) {
  return (dispatch, getState) => {
    const {
      attachments: { files },
    } = getState();

    const uploadedVideoCount = files.filter((o) => o.hasErrored === false && o.isVideo === true).length;
    const uploadedImageCount = files.filter((o) => o.hasErrored === false && o.isVideo === false).length;

    const isVideo = file.type.includes('video/');
    dispatch(attachmentAdd(file.name, isVideo));

    if (uploadedVideoCount >= ATTACHMENT_VIDEO_TOTAL_LIMIT && isVideo) {
      dispatch(attachmentHasErrored(true, file.name, ATTACHMENT_ERROR_UPLOAD_LIMIT));
      if (errorHandler) {
        errorHandler(new Error(ATTACHMENT_ERROR_TOO_BIG));
      }
    } else if (uploadedImageCount >= ATTACHMENT_IMAGE_TOTAL_LIMIT && !isVideo) {
      dispatch(attachmentHasErrored(true, file.name, ATTACHMENT_ERROR_UPLOAD_LIMIT));
      if (errorHandler) {
        errorHandler(new Error(ATTACHMENT_ERROR_TOO_BIG));
      }
    } else if (file.size > ATTACHMENT_MAX_FILE_SIZE) {
      dispatch(attachmentHasErrored(true, file.name, ATTACHMENT_ERROR_TOO_BIG));
      if (errorHandler) {
        errorHandler(new Error(ATTACHMENT_ERROR_TOO_BIG));
      }
    } else if (!file.type.includes('image/') && !isVideo) {
      dispatch(attachmentHasErrored(true, file.name, ATTACHMENT_ERROR_FILE_TYPE));
      if (errorHandler) {
        errorHandler(new Error(ATTACHMENT_ERROR_FILE_TYPE));
      }
    } else {
      return attachmentApi.uploadAttachment(
        file,
        (response) => {
          dispatch(attachmentSuccess(file.name, response.data.attachmentId, isVideo));
          if (successHandler) {
            successHandler(response.data);
          }
        },
        (error) => {
          if (error.response) {
            if (error.response.status === 413) {
              dispatch(attachmentHasErrored(true, file.name, ATTACHMENT_ERROR_TOO_BIG));
            } else {
              dispatch(attachmentHasErrored(true, file.name, ATTACHMENT_ERROR_UNKNOWN));
            }
          } else {
            dispatch(attachmentHasErrored(true, file.name, ATTACHMENT_ERROR_UNKNOWN));
          }

          if (errorHandler) {
            errorHandler(ATTACHMENT_ERROR_UNKNOWN);
          }
        },
      );
    }
  };
}

export function uploadAttachments(files) {
  return (dispatch, getState) => {
    dispatch(attachmentIsUploading(true));

    const filesArr = Array.from(files);
    let promises = [];

    filesArr.forEach((file) => {
      promises.push(dispatch(uploadAttachment(file)));
    });

    Promise.all(promises).then(function() {
      dispatch(attachmentIsUploading(false));
    });
  };
}
