import { ButtonRow, Control, Heading, Hook, Step } from '../../../models';
import {
  UNSURE,
  BLOCKED,
  CLEAR,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  KITCHEN,
  HOUSE,
  ODOUR,
  BATHROOM,
  APPLIANCE,
  TOILET,
  SINK,
  FLOOR,
  CELLAR,
  STEP_ACCESS_HOURS_LOOKUP,
} from '../../../../constants';
import { wasteInternalExtdrain } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteInternalExtdrain.heading);
const wasteInternalExtdrainControl = new Control('question', 'wasteInternalExtdrain');
wasteInternalExtdrainControl.setLabel(wasteInternalExtdrain.heading);
wasteInternalExtdrainControl.setDefault('');
wasteInternalExtdrainControl.addOption(wasteInternalExtdrain.clear, CLEAR, 'IconDripping');
wasteInternalExtdrainControl.addOption(wasteInternalExtdrain.blocked, BLOCKED, 'IconInternalBlockage');
wasteInternalExtdrainControl.addOption(wasteInternalExtdrain.unsure, UNSURE, 'IconQuestion');
wasteInternalExtdrainControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteInternalExtdrainStep = new Step('step', 'waste-internal-extdrain');
wasteInternalExtdrainStep.addBlock(headingBlock);
wasteInternalExtdrainStep.addBlock(wasteInternalExtdrainControl);
wasteInternalExtdrainStep.addBlock(buttonsBlock);
wasteInternalExtdrainStep.setIsContained(false);
wasteInternalExtdrainStep.exitHook = new Hook(STEP_ACCESS_HOURS_LOOKUP, '');
wasteInternalExtdrainStep.setTitle(wasteInternalExtdrain.title);

wasteInternalExtdrainStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [KITCHEN],
      exclusion: [HOUSE, ODOUR, BATHROOM],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [APPLIANCE],
      exclusion: [TOILET, SINK, FLOOR, CELLAR],
    },
    wasteInternalSink: {
      inclusion: [BLOCKED],
      exclusion: [CLEAR],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [SINK],
      exclusion: [TOILET, APPLIANCE, FLOOR, CELLAR],
    },
  },
]);

export { wasteInternalExtdrainStep };
