import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  PUBLIC,
  WASTE,
  CLEAN,
  UNSURE,
  INTERNAL,
  EXTERNAL,
  GROUND,
  ODOUR,
  POLLUTION,
  COVER,
} from '../../../../constants';
import { externalWasteWaterType } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', externalWasteWaterType.heading);
const externalWasteWaterTypeControl = new Control('question', 'externalWasteWaterType');
externalWasteWaterTypeControl.setLabel(externalWasteWaterType.heading);
externalWasteWaterTypeControl.addOption(externalWasteWaterType.clean, CLEAN, 'IconGlass', false, 'clean water');
externalWasteWaterTypeControl.addOption(externalWasteWaterType.waste, WASTE, 'IconToiletRoll', false, 'sewage');
externalWasteWaterTypeControl.addOption(externalWasteWaterType.unsure, UNSURE, 'IconQuestion', false, 'unsure');
externalWasteWaterTypeControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const externalWasteWaterTypeStep = new Step('step', 'external-waste-water-type');
externalWasteWaterTypeStep.addBlock(headingBlock);
externalWasteWaterTypeStep.addBlock({ type: 'message', id: 'step-hint', message: externalWasteWaterType.hint });
externalWasteWaterTypeStep.addBlock(externalWasteWaterTypeControl);
externalWasteWaterTypeStep.addBlock(buttonsBlock);
externalWasteWaterTypeStep.setTitle(externalWasteWaterType.title);

externalWasteWaterTypeStep.setConstraints({
  initialLocationWaste: {
    inclusion: [PUBLIC],
    exclusion: [EXTERNAL, INTERNAL],
  },
  wasteExternalOrigin: {
    inclusion: [GROUND],
    exclusion: [ODOUR, POLLUTION, COVER],
  },
});

export { externalWasteWaterTypeStep };
