import { ButtonRow, Control, Heading, Step } from '../../models';
import {
  YES,
  NO,
  UNSURE,
  WASTE,
  CLEAN,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  OTHER,
  COVER,
  GROUND,
  ODOUR,
  POLLUTION,
} from '../../../constants';
import { externalFlowConstant } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', externalFlowConstant.heading);
const flowConstantControl = new Control('question', 'externalConstantPressure');
flowConstantControl.setLabel(externalFlowConstant.heading);
flowConstantControl.addOption(externalFlowConstant.yes, YES, 'IconConstantPressure');
flowConstantControl.addOption(externalFlowConstant.no, NO, 'IconCross');
flowConstantControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const externalFlowConstantStep = new Step('step', 'external-flow-constant');
externalFlowConstantStep.addBlock(headingBlock);
externalFlowConstantStep.addBlock(flowConstantControl);
externalFlowConstantStep.addBlock(buttonsBlock);
externalFlowConstantStep.setTitle(externalFlowConstant.title);

externalFlowConstantStep.setConstraints([
  {
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [UNSURE],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
  },
]);

export { externalFlowConstantStep };
