import { ButtonRow, Control, Heading, Step } from '../../../models';
import { pollutionFrom } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', pollutionFrom.heading);
const pollutionFromControl = new Control('question', 'pollutionFrom');
pollutionFromControl.setLabel(pollutionFrom.heading);
pollutionFromControl.addOption(
  pollutionFrom.pipe,
  'FROM_PIPE',
  null,
  false,
  false,
  '/images/processed/choice-from-pipe.jpg',
);
pollutionFromControl.addOption(
  pollutionFrom.cover,
  'FROM_COVER_OR_GRATE',
  null,
  false,
  false,
  '/images/processed/choice-from-cover-grate.jpg',
);
pollutionFromControl.addOption(
  pollutionFrom.ywSite,
  'FROM_YW_SITE',
  null,
  false,
  false,
  '/images/processed/choice-from-yw-site.jpg',
);
pollutionFromControl.addOption(
  pollutionFrom.ground,
  'FROM_GROUND',
  null,
  false,
  false,
  '/images/processed/choice-from-ground.jpg',
);
pollutionFromControl.addOption(
  pollutionFrom.pumping,
  'FROM_PUMPING_STATION',
  null,
  false,
  false,
  '/images/processed/choice-from-pumping-station.jpg',
);
const buttonsBlock = new ButtonRow().hideContinueButton();

const pollutionFromStep = new Step('step', 'pollution-from');
pollutionFromStep.addBlock(headingBlock);
pollutionFromStep.addBlock(pollutionFromControl);
pollutionFromStep.addBlock(buttonsBlock);
pollutionFromStep.setTitle(pollutionFrom.title);

pollutionFromStep.setConstraints([
  {
    initialProblem: { inclusion: ['POLLUTION'] },
  },
]);

export { pollutionFromStep };
