import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  YES,
  NO,
  UNSURE,
  WASTE,
  CLEAN,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  ACCESS,
  ACCIDENT,
  BOTH,
  INSIDE,
  OUTSIDE,
  NONE,
  PROPERTY_DAMAGE,
  WORSENING,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  DAMP,
  GUSHING,
  LARGE,
  MEDIUM,
  COVER,
  OVERFLOW_PIPE,
  GROUND,
  SMALL,
  TRICKLING,
} from '../../../../constants';
import { cleanAccessSupply } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', cleanAccessSupply.heading);
const externalProblemControl = new Control('question', 'cleanAccess');
externalProblemControl.setLabel(cleanAccessSupply.heading);
externalProblemControl.labelOff();
externalProblemControl.addOption(cleanAccessSupply.yes, YES, 'IconAccessWalking');
externalProblemControl.addOption(cleanAccessSupply.no, NO, 'IconCross');
const buttonsBlock = new ButtonRow().hideContinueButton();

const cleanAccessSupplyStep = new Step('step', 'clean-access-supply');
cleanAccessSupplyStep.addBlock(headingBlock);
cleanAccessSupplyStep.addBlock(externalProblemControl);
cleanAccessSupplyStep.addBlock(buttonsBlock);
cleanAccessSupplyStep.setTitle(cleanAccessSupply.title);

cleanAccessSupplyStep.setConstraints([
  {
    initialLocation: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
      exclusion: [OVERFLOW_PIPE, METER, GROUND],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [UNSURE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
    },
    externalConstantPressure: {
      inclusion: [NO],
    },
    cleanCoverSeverity: {
      inclusion: [GUSHING, TRICKLING],
    },
    cleanCoverRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
      exclusion: [OVERFLOW_PIPE, METER, GROUND],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [UNSURE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
    },
    externalConstantPressure: {
      inclusion: [NO],
    },
    externalWaterSmell: {
      inclusion: [YES],
    },
    externalWasteRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteSeverity: {
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
      exclusion: [OVERFLOW_PIPE, METER, GROUND],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [UNSURE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
    },
    externalConstantPressure: {
      inclusion: [YES],
    },
    cleanCoverSeverity: {
      inclusion: [GUSHING, TRICKLING],
    },
    cleanCoverRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
      exclusion: [OVERFLOW_PIPE, METER, GROUND],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [UNSURE],
    },
    externalRainfallAffected: {
      inclusion: [YES],
    },
    externalWasteRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteSeverity: {
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
      exclusion: [OVERFLOW_PIPE, METER, GROUND],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [WASTE],
    },
    externalWasteRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteSeverity: {
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
      exclusion: [OVERFLOW_PIPE, METER, GROUND],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [CLEAN],
    },
    cleanCoverSeverity: {
      inclusion: [GUSHING, TRICKLING],
    },
    cleanCoverRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
      exclusion: [OVERFLOW_PIPE, METER, GROUND],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    waterType: {
      presence: true,
      inclusion: [WASTE],
    },
    wasteRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteSeverity: {
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
      exclusion: [OVERFLOW_PIPE, METER, GROUND],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    waterType: {
      presence: true,
      inclusion: [UNSURE],
    },
    constantPressure: {
      inclusion: [YES, NO],
    },
    rainfallAffected: {
      inclusion: [YES, NO],
    },
    waterSmell: {
      inclusion: [YES, NO],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
      exclusion: [OVERFLOW_PIPE, METER, GROUND],
    },
    externalCoverType: {
      inclusion: [GRATE, SQUARE, STOPTAP, METER, SLUICE_VALVE],
    },
    cleanMeterLocation: {
      inclusion: [OUTSIDE],
      exclusion: [INSIDE],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    cleanCoverRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
    cleanMeterLocation: {
      presence: true,
      inclusion: [OUTSIDE],
      exclusion: [INSIDE],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    eligibility: {
      inclusion: [YES],
    },
    cleanMeterLocation: {
      presence: true,
      inclusion: [OUTSIDE],
      exclusion: [INSIDE],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      presence: true,
      inclusion: [METER],
      exclusion: [COVER, GROUND, OVERFLOW_PIPE],
    },
    cleanMeterLeaking: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    cleanMeterLocation: {
      presence: true,
      inclusion: [OUTSIDE],
      exclusion: [INSIDE],
    },
    cleanMeterSeverityExt: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, WORSENING, BOTH, NONE],
    },
  },
]);

export { cleanAccessSupplyStep };
