import { Control, Heading, Step, ButtonRow } from '../../models';
import {
  YES,
  NO,
  UNSURE,
  HIGH_PRESSURE,
  LOW_PRESSURE,
  PRESSURE,
  NO_WATER,
  HOT_COLD,
  NOT_FIRST_FEED,
  HOT_ONLY,
  FIRST_FEED,
} from '../../../constants';
import { nwlpProblemNeighbour } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', nwlpProblemNeighbour.heading);
const nwlpProblemNeighbourControl = new Control('question', 'nwlpProblemNeighbour');
nwlpProblemNeighbourControl.setLabel(nwlpProblemNeighbour.heading);
nwlpProblemNeighbourControl.addOption(nwlpProblemNeighbour.yes, YES, 'IconCheckTick');
nwlpProblemNeighbourControl.addOption(nwlpProblemNeighbour.no, NO, 'IconCross');
nwlpProblemNeighbourControl.addOption(nwlpProblemNeighbour.notSure, UNSURE, 'IconQuestion');
const buttonsBlock = new ButtonRow().hideContinueButton();

const nwlpProblemNeighbourStep = new Step('step', 'nwlp-problem-neighbour');
nwlpProblemNeighbourStep.addBlock(headingBlock);
nwlpProblemNeighbourStep.addBlock(nwlpProblemNeighbourControl);
nwlpProblemNeighbourStep.addBlock(buttonsBlock);
nwlpProblemNeighbourStep.setTitle(nwlpProblemNeighbour.title);

nwlpProblemNeighbourStep.setConstraints([
  {
    nwlpInitialProblem: {
      inclusion: [PRESSURE],
      exclusion: [NO_WATER],
    },
    nwlpPressureProblem: {
      inclusion: [HIGH_PRESSURE],
      exclusion: [LOW_PRESSURE],
    },
  },
  {
    nwlpInitialProblem: {
      inclusion: [PRESSURE],
      exclusion: [NO_WATER],
    },
    nwlpPressureProblem: {
      inclusion: [LOW_PRESSURE],
      exclusion: [HIGH_PRESSURE],
    },
    nwlpPressureSupply: {
      inclusion: [HOT_COLD],
      exclusion: [NOT_FIRST_FEED, HOT_ONLY],
    },
    nwlpStopTap: {
      inclusion: [YES, NO, UNSURE],
    },
    nwlpPropertyType: {
      inclusion: [NO],
      exclusion: [YES],
    },
  },
  {
    nwlpInitialProblem: {
      inclusion: [NO_WATER],
      exclusion: [PRESSURE],
    },
    nwlpFacilitiesAffected: {
      inclusion: [FIRST_FEED],
      exclusion: [NOT_FIRST_FEED],
    },
    nwlpStopTap: {
      inclusion: [YES, NO, UNSURE],
    },
    nwlpAnyWater: {
      inclusion: [YES],
      exclusion: [NO],
    },
    nwlpPropertyType: {
      inclusion: [NO],
      exclusion: [YES],
    },
  },
  {
    nwlpInitialProblem: {
      inclusion: [NO_WATER],
      exclusion: [PRESSURE],
    },
    nwlpFacilitiesAffected: {
      inclusion: [FIRST_FEED],
      exclusion: [NOT_FIRST_FEED],
    },
    nwlpStopTap: {
      inclusion: [YES, NO, UNSURE],
    },
    nwlpAnyWater: {
      inclusion: [NO],
      exclusion: [YES],
    },
    isNoWaterEnabled: {
      presence: true,
      inclusion: [true],
      exclusion: [false],
    },
    nwlpNoticeDateTime: {
      presence: true,
    },
    nwlpNoticeConfirm: {
      inclusion: ['TRUE'],
    },
    nwlpPropertyType: {
      inclusion: [YES, NO],
    },
  },
]);

export { nwlpProblemNeighbourStep };
