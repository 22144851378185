import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GOOGLEMAPS_API_URL } from '../../../constants/Settings';

export class StaticMapBlock extends Component {
  render() {
    return <img src={this.GetURLString()} alt="static map location" />;
  }

  GetURLString() {
    const { lookups } = this.props;
    return `${GOOGLEMAPS_API_URL}/staticmap?center=${lookups.map.lat},${lookups.map.lng}&style=feature:poi|visibility:off&zoom=17&size=600x300&maptype=hybrid&markers=%7Ccolor:blue%7C${lookups.map.lat},${lookups.map.lng}&key=AIzaSyDZgFAmoq4CwSK8zTOZodOesbS-Nv5dftg&callback=Function.prototype`;
  }
}

StaticMapBlock.propTypes = {
  form: PropTypes.object,
  lookups: PropTypes.object.isRequired,
  lookupsLoading: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

StaticMapBlock.defaultProps = {
  form: {},
  lookupsLoading: false,
};
export default StaticMapBlock;
