import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'yw-ui.v2';

class IncidentBlock extends Component {
  getCategoryDescription(category) {
    switch (category) {
      case '9104':
      case '9105':
        return 'Inspection';
      case 'MR35':
        return 'Repair';
      case '2384':
        return 'External Escape - Jet Van O/S boundary';
      case '2391':
        return 'Blockage-Jet Van';
      case '2346':
        return 'Attend Site Jet Van';
      case '2314':
        return 'Attend reactive pollution';
      case '2345':
        return 'Resolution Unit';
      case '2347':
        return 'Vactor Van';
      case '2344':
        return 'Network Controller';
      case '9207':
        return 'Rising main (Physical asset inspection)';
      default:
        return '-';
    }
  }

  render() {
    const {
      lookups: { incidents },
    } = this.props;

    const model = incidents.map((incident) => {
      return {
        location: incident.location,
        status: this.getCategoryDescription(incident.category),
        distance: `${incident.radiusDistance} ${incident.radiusDistance === 1 ? 'metre' : 'metres'}`,
      };
    });

    return (
      <Fragment>
        <div className="c-form-row__label">
          <strong className="c-form-row__label-text">Nearby Problems</strong>
        </div>
        <DataTable.Table id="incidentTable" qa="incidentTable" className="u-mb--300">
          <DataTable.Head>
            <DataTable.Row>
              <DataTable.Heading minWidth={250}>On or near</DataTable.Heading>
              <DataTable.Heading align="centre" width={120}>
                Distance
              </DataTable.Heading>
              <DataTable.Heading align="centre" width={120}>
                Status
              </DataTable.Heading>
            </DataTable.Row>
          </DataTable.Head>
          <DataTable.Body>
            {model.map((inc, i) => {
              return (
                <DataTable.Row key={i}>
                  <DataTable.Cell>{inc.location}</DataTable.Cell>
                  <DataTable.Cell>{inc.distance}</DataTable.Cell>
                  <DataTable.Cell>{inc.status}</DataTable.Cell>
                </DataTable.Row>
              );
            })}
          </DataTable.Body>
        </DataTable.Table>
      </Fragment>
    );
  }
}

IncidentBlock.propTypes = {
  lookups: PropTypes.object.isRequired,
};

export default IncidentBlock;
