import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FluidField } from 'yw-ui.v2';

class UKAddressResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commercial: false,
      location: '',
      utmLocation: {
        easting: '',
        northing: '',
      },
    };
  }

  onAddressSelect = (label, id) => {
    const { addresses } = this.props;
    const address = addresses.find((i) => i.id === id);
    if (address) {
      this.props.form.handleChange('locationDetails', address.label);
      this.props.form.handleChange('postcode', address.postcode);
      this.props.form.handleChange('utmLocation', {
        easting: address.easting,
        northing: address.northing,
      });
      this.props.form.handleChange('isCommercial', address.isCommercial);
      this.setState({ commercial: address.isCommercial });
      this.setState({ postcode: address.postcode });
    }
  };

  componentDidMount() {
    if (this.props.answers.isCommercial !== '') {
      this.updateStateBeforeAnswerWipe();
    } else {
      this.updateStateAfterAnswerWipe();
    }
    this.updateAfterStateAndAnswerCompare();
  }

  updateAfterStateAndAnswerCompare() {
    this.props.form.handleChange(
      'isCommercial',
      this.state.commercial !== this.props.answers.isCommercial
        ? this.props.answers.isCommercial
        : this.state.commercial,
    );
    this.props.form.handleChange(
      'locationDetails',
      this.state.location !== this.props.answers.locationDetails
        ? this.props.answers.locationDetails
        : this.state.location,
    );
    this.props.form.handleChange(
      'postcode',
      this.state.postcode !== this.props.answers.postcode ? this.props.answers.postcode : this.state.postcode,
    );
    if (this.props.answers.utmLocation !== undefined) {
      this.props.form.handleChange(
        'utmLocation',
        this.state.utmLocation.easting !== this.props.answers.utmLocation.easting ||
          this.state.utmLocation.northing !== this.props.answers.utmLocation.northing
          ? {
              easting: this.props.answers.utmLocation.easting,
              northing: this.props.answers.utmLocation.northing,
            }
          : {
              easting: this.state.utmLocation.easting,
              northing: this.state.utmLocation.northing,
            },
      );
    }
  }

  updateStateAfterAnswerWipe() {
    this.props.form.handleChange('isCommercial', this.state.commercial);
    this.props.form.handleChange('locationDetails', this.state.location);
    this.props.form.handleChange('postcode', this.state.postcode);
    this.props.form.handleChange('utmLocation', {
      easting: this.state.utmLocation.easting,
      northing: this.state.utmLocation.northing,
    });
  }

  updateStateBeforeAnswerWipe() {
    this.setState({ commercial: this.props.answers.isCommercial }, () => {
      this.props.form.handleChange('isCommercial', this.state.commercial);
    });
    this.setState({ location: this.props.answers.locationDetails }, () => {
      this.props.form.handleChange('locationDetails', this.state.location);
    });
    this.setState({ postcode: this.props.answers.postcode }, () => {
      this.props.form.handleChange('postcode', this.state.postcode);
    });
    if (this.props.answers.utmLocation !== undefined) {
      this.setState(
        {
          utmLocation: {
            easting: this.props.answers.utmLocation.easting,
            northing: this.props.answers.utmLocation.northing,
          },
        },
        () => {
          this.props.form.handleChange('utmLocation', {
            easting: this.state.utmLocation.easting,
            northing: this.state.utmLocation.northing,
          });
        },
      );
    }
  }

  render() {
    const { addresses } = this.props;
    let confirmOptions = addresses;
    let label = 'Select an address';
    let hideLabel = true;
    let isScrollable = true;

    if (addresses.length === 1) {
      confirmOptions = [
        { id: addresses[0].id, label: 'Yes' },
        { id: 'N', label: 'No' },
      ];
      hideLabel = false;
      isScrollable = false;
      label = '';
    }

    return (
      <Fragment>
        <FluidField
          id="addressConfirm"
          qa="addressConfirm"
          component="radio"
          name="addressConfirm"
          label={label}
          options={confirmOptions}
          hideLabel={hideLabel}
          isScrollable={isScrollable}
          onChange={this.onAddressSelect.bind(this)}
        />
      </Fragment>
    );
  }
}

UKAddressResult.propTypes = {
  answers: PropTypes.object.isRequired,
  addresses: PropTypes.array.isRequired,
  form: PropTypes.object,
};

UKAddressResult.defaultProps = {};

export default UKAddressResult;
