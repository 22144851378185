import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  YES,
  UNSURE,
  WASTE,
  CLEAN,
  ACCESS,
  BOTH,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  TRIANGLE,
  LOCATION_METHOD_CURRENT_LOCATION,
  LOCATION_METHOD_MAP_SEARCH,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  NONE,
  ABOVE_GROUND_STOP_TAP,
  COVER,
  GROUND,
  PROPERTY_DAMAGE,
} from '../../../../constants';
import { wasteRisk } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteRisk.heading);
const wasteRiskControl = new Control('question', 'wasteRisk');
wasteRiskControl.setLabel(wasteRisk.heading);
wasteRiskControl.setDefault('');
wasteRiskControl.addOption(wasteRisk.propertyRisk, PROPERTY_DAMAGE, 'IconHouse');
wasteRiskControl.addOption(wasteRisk.access, ACCESS, 'IconAccessWalking');
wasteRiskControl.addOption(wasteRisk.both, BOTH, 'IconPropertyAccess');
wasteRiskControl.addOption(wasteRisk.none, NONE, 'IconCross');
wasteRiskControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteRiskStep = new Step('step', 'waste-risk');
wasteRiskStep.addBlock(headingBlock);
wasteRiskStep.addBlock(wasteRiskControl);
wasteRiskStep.addBlock(buttonsBlock);
wasteRiskStep.setTitle(wasteRisk.title);

wasteRiskStep.setConstraints([
  {
    initialLocation: {
      presence: true,
      exclusion: ['INTERNAL'],
    },
    waterType: {
      presence: true,
      inclusion: [WASTE],
    },
  },
  {
    initialLocation: {
      presence: true,
      exclusion: ['INTERNAL'],
    },
    waterSmell: {
      presence: true,
      inclusion: [YES],
    },
  },
  {
    initialLocation: {
      presence: true,
      exclusion: ['INTERNAL'],
    },
    rainfallAffected: {
      presence: true,
      inclusion: [YES],
    },
  },
  {
    initialLocation: {
      presence: true,
      exclusion: ['INTERNAL'],
    },
    coverType: {
      presence: true,
      inclusion: [TRIANGLE, SQUARE],
    },
  },
  {
    initialLocation: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_MAP_SEARCH, LOCATION_METHOD_CURRENT_LOCATION],
    },
    waterOrigin: {
      presence: true,
      inclusion: [GROUND, OTHER],
      exclusion: [COVER, ABOVE_GROUND_STOP_TAP],
    },
    externalWaterType: {
      presence: true,
      inclusion: [WASTE],
      exclusion: [CLEAN, UNSURE],
    },
  },
  {
    initialLocation: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_MAP_SEARCH, LOCATION_METHOD_CURRENT_LOCATION],
    },
    coverType: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [METER, SLUICE_VALVE, STOPTAP, GRATE, SQUARE],
    },
    externalWaterType: {
      presence: true,
      inclusion: [WASTE],
      exclusion: [CLEAN, UNSURE],
    },
  },
  {
    initialLocation: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [YES],
      exclusion: [UNSURE],
    },
  },
]);
wasteRiskStep.setValidation({
  wasteRisk: {
    presence: {
      message: wasteRisk.validation,
      allowEmpty: false,
    },
  },
});

export { wasteRiskStep };
