function SummaryTable(id) {
  this.id = id;
  this.type = 'summaryTable';
  this.rows = [];
}

SummaryTable.prototype.addRow = function(row) {
  this.rows = [...this.rows, row];
  return this;
};

SummaryTable.prototype.setConstraints = function(constraints) {
  this.constraints = constraints;
  return this;
};

export default SummaryTable;
