import { ButtonRow, Control, Heading, Step, DateTimeConfirm } from '../../models';
import { FIRST_FEED, NO, NOT_FIRST_FEED, NO_WATER, PRESSURE, UNSURE, YES } from '../../../constants';
import { nwlpNoticeConfirm } from '../../../constants/Copy';

const dateTimeConfirm = new DateTimeConfirm();
const headingBlock = new Heading('step-title', nwlpNoticeConfirm.heading);
const nwlpNoticeConfirmControl = new Control('hidden', 'nwlpNoticeConfirm').setDefault('TRUE');
const buttonsBlock = new ButtonRow();

const nwlpNoticeConfirmStep = new Step('step', 'nwlp-notice-confirm');
nwlpNoticeConfirmStep.addBlock(headingBlock);
nwlpNoticeConfirmStep.addBlock(dateTimeConfirm);
nwlpNoticeConfirmStep.addBlock(nwlpNoticeConfirmControl);
nwlpNoticeConfirmStep.addBlock(buttonsBlock);
nwlpNoticeConfirmStep.setIsContained(true);
nwlpNoticeConfirmStep.setTitle(nwlpNoticeConfirm.title);

nwlpNoticeConfirmStep.setConstraints([
  {
    nwlpInitialProblem: {
      inclusion: [NO_WATER],
      exclusion: [PRESSURE],
    },
    nwlpFacilitiesAffected: {
      inclusion: [FIRST_FEED],
      exclusion: [NOT_FIRST_FEED],
    },
    nwlpStopTap: {
      inclusion: [YES, NO, UNSURE],
    },
    nwlpAnyWater: {
      inclusion: [NO],
      exclusion: [YES],
    },
    isNoWaterEnabled: {
      presence: true,
      inclusion: [true],
      exclusion: [false],
    },
    nwlpNoticeDateTime: {
      presence: true,
    },
  },
]);

export { nwlpNoticeConfirmStep };
