import {
  ACCIDENT,
  BOTH,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  GUSHING,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  NONE,
  ABOVE_GROUND_STOP_TAP,
  COVER,
  GROUND,
  PROPERTY_DAMAGE,
  RUNNING_QUICKLY,
  TRICKLING,
} from '../../../../constants';
import { ButtonRow, Control, Heading, Step } from '../../../models';
import { cleanRisk } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', cleanRisk.heading);
const cleanRiskControl = new Control('question', 'cleanRisk');
cleanRiskControl.setLabel(cleanRisk.heading);
cleanRiskControl.setDefault('');
cleanRiskControl.addOption(cleanRisk.propertyDamage, PROPERTY_DAMAGE, 'IconHouse');
cleanRiskControl.addOption(cleanRisk.accident, ACCIDENT, 'IconAccessWalking');
cleanRiskControl.addOption(cleanRisk.both, BOTH, 'IconPropertyAccess');
cleanRiskControl.addOption(cleanRisk.none, NONE, 'IconCross');
cleanRiskControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const cleanRiskStep = new Step('step', 'clean-risk');
cleanRiskStep.addBlock(headingBlock);
cleanRiskStep.addBlock(cleanRiskControl);
cleanRiskStep.addBlock(buttonsBlock);
cleanRiskStep.setIsContained(false);
cleanRiskStep.setTitle(cleanRisk.title);

cleanRiskStep.setConstraints([
  {
    cleanSeverity: {
      presence: true,
      inclusion: [RUNNING_QUICKLY, GUSHING, TRICKLING],
    },
  },
  {
    initialLocation: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    waterOrigin: {
      presence: true,
      inclusion: [ABOVE_GROUND_STOP_TAP],
      exclusion: [COVER, GROUND, OTHER],
    },
  },
  {
    initialLocation: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    coverType: {
      presence: true,
      inclusion: [METER, OTHER, STOPTAP],
      exclusion: [GRATE, SQUARE, SLUICE_VALVE],
    },
    cleanSeverity: {
      presence: true,
      inclusion: [RUNNING_QUICKLY, GUSHING, TRICKLING],
    },
  },
]);

cleanRiskStep.setValidation({
  cleanRisk: {
    presence: {
      message: cleanRisk.validation,
      allowEmpty: false,
    },
  },
});

export { cleanRiskStep };
