import { ButtonRow, Control, Heading, Step } from '../../models';
import {
  GULLY,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  COVER,
  DRAIN,
  GROUND,
  ODOUR,
  POLLUTION,
  CLEAN,
  WASTE,
  UNSURE,
} from '../../../constants';
import { boundaryWaterType } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', boundaryWaterType.heading);
const boundaryWaterTypeControl = new Control('question', 'boundaryWaterType');
boundaryWaterTypeControl.setLabel(boundaryWaterType.heading);
boundaryWaterTypeControl.addOption(boundaryWaterType.clean, CLEAN, 'IconGlass', false, 'clean water');
boundaryWaterTypeControl.addOption(boundaryWaterType.waste, WASTE, 'IconToiletRoll', false, 'sewage');
boundaryWaterTypeControl.addOption(boundaryWaterType.unsure, UNSURE, 'IconQuestion', false, 'unsure');
boundaryWaterTypeControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const boundaryWaterTypeStep = new Step('step', 'boundary-water-type');
boundaryWaterTypeStep.addBlock(headingBlock);
boundaryWaterTypeStep.addBlock({ type: 'message', id: 'step-hint', message: boundaryWaterType.hint });
boundaryWaterTypeStep.addBlock(boundaryWaterTypeControl);
boundaryWaterTypeStep.addBlock(buttonsBlock);
boundaryWaterTypeStep.setTitle(boundaryWaterType.title);

boundaryWaterTypeStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [COVER],
      exclusion: [DRAIN, GROUND, ODOUR],
    },
    wasteExternalCoverType: {
      inclusion: [OTHER],
      exclusion: [METER, SLUICE_VALVE, STOPTAP, GRATE, SQUARE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [INTERNAL, EXTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [GULLY, SQUARE, SLUICE_VALVE, STOPTAP, METER],
    },
  },
]);

export { boundaryWaterTypeStep };
