import { ButtonRow, Control, Heading, HeadingNoDiv, InfoBlock, Step } from '../../../models';
import {
  YES,
  NO,
  UNSURE,
  METER,
  PIPE_MEETS_METER,
  STOPTAP,
  INSIDE,
  OUTSIDE,
  EXTERNAL,
  COVER,
  OVERFLOW_PIPE,
  GROUND,
  INTERNAL,
  PUBLIC,
} from '../../../../constants';
import { cleanCondensationConfirm } from '../../../../constants/Copy';

const headingBlock = new Heading('step-heading', cleanCondensationConfirm.heading);
const infoBlock = new InfoBlock('info-block', cleanCondensationConfirm.detail[0], 'p-b');
const infoBlock2 = new InfoBlock('info-block2', cleanCondensationConfirm.detail[1], 'p-b');
const headingNoDiv = new HeadingNoDiv(
  'step-heading2',
  cleanCondensationConfirm.condensation,
  'u-mb--0 u-pb--200 c-message c-message--info',
  2,
).setSize('sm');
const infoBlock3 = new InfoBlock(
  'info-block',
  cleanCondensationConfirm.detail[2],
  'u-pt--0 u-pb--200 u-mb--0 c-message c-message--info',
);
const infoBlock4 = new InfoBlock(
  'bullet-point-info4',
  cleanCondensationConfirm.detail[3],
  'u-pt--0 u-pb--50 u-pl--450 u-mb--0 c-message c-message--info',
);
const infoBlock5 = new InfoBlock(
  'bullet-point-info5',
  cleanCondensationConfirm.detail[4],
  'u-pt--0 u-pb--0 u-pl--450 u-mb--0 c-message c-message--info',
);
const headingNoDiv2 = new HeadingNoDiv(
  'step-heading3',
  cleanCondensationConfirm.label,
  'u-mb--0 u-pt--200 u-pb--0 c-message c-message--info',
  3,
).setSize('sm');
const radio = new Control('radio', 'cleanCondenConfirm', 'u-mb--0 u-pt--0 u-pb--0 c-message c-message--info')
  .setLabel(cleanCondensationConfirm.label)
  .labelOff()
  .addOption(cleanCondensationConfirm.no, NO, null)
  .addOption(cleanCondensationConfirm.yes, YES, null)
  .setDefault(NO);
const buttonsBlock = new ButtonRow();

const cleanCondensationConfirmStep = new Step('step', 'clean-conden-confirm');
cleanCondensationConfirmStep.setIsContained(true);
cleanCondensationConfirmStep.addBlock(headingBlock);
cleanCondensationConfirmStep.addBlock(infoBlock);
cleanCondensationConfirmStep.addBlock(infoBlock2);
cleanCondensationConfirmStep.addBlock(headingNoDiv);
cleanCondensationConfirmStep.addBlock(infoBlock3);
cleanCondensationConfirmStep.addBlock(infoBlock4);
cleanCondensationConfirmStep.addBlock(infoBlock5);
cleanCondensationConfirmStep.addBlock(headingNoDiv2);
cleanCondensationConfirmStep.addBlock(radio);
cleanCondensationConfirmStep.addBlock(buttonsBlock);
cleanCondensationConfirmStep.setTitle(cleanCondensationConfirm.title);

cleanCondensationConfirmStep.setConstraints([
  {
    initialLocation: {
      inclusion: [INTERNAL],
      exclusion: [PUBLIC, EXTERNAL],
    },
    internalWaterOrigin: {
      presence: true,
      inclusion: [METER],
    },
    cleanMeterInternal: {
      presence: true,
      inclusion: [METER],
      exclusion: [PIPE_MEETS_METER, STOPTAP, UNSURE],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      presence: true,
      inclusion: [METER],
      exclusion: [COVER, OVERFLOW_PIPE, GROUND],
    },
    cleanMeterLeaking: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    cleanMeterLocation: {
      presence: true,
      inclusion: [INSIDE],
      exclusion: [OUTSIDE],
    },
    cleanMeterInternal: {
      presence: true,
      inclusion: [METER],
      exclusion: [PIPE_MEETS_METER, STOPTAP, UNSURE],
    },
  },
]);

export { cleanCondensationConfirmStep };
