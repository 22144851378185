import { Heading, Control, Step, ButtonRow, InfoBlock } from '../../models';
import {
  NO,
  UNSURE,
  YES,
  PRESSURE,
  NO_WATER,
  LOW_PRESSURE,
  HIGH_PRESSURE,
  HOT_COLD,
  NOT_FIRST_FEED,
  HOT_ONLY,
  FIRST_FEED,
} from '../../../constants';
import { nwlpStopTap } from '../../../constants/Copy';

const headingBlock = new Heading('step-heading', nwlpStopTap.heading);
const infoBlock1 = new InfoBlock('info-block1', nwlpStopTap.blurb);
const heading = new Heading('step-heading2', nwlpStopTap.actions, 2).setSize('sm');
const infoBlock2 = new InfoBlock('bullet-point1', nwlpStopTap.actionsArr[0], 'u-pt--0 u-pb--50 u-pl u-mb--0');
const infoBlock3 = new InfoBlock('bullet-point2', nwlpStopTap.actionsArr[1], 'u-pt--0 u-pb--50 u-pl u-mb--0');
const headingBlock2 = new Heading('step-heading2', nwlpStopTap.solveIssue, 2, 'p-t').setSize('md');
const radio = new Control('radio', 'nwlpStopTap').setLabel(nwlpStopTap.solveIssue).labelOff();
radio.addOption(nwlpStopTap.yes, YES, null);
radio.addOption(nwlpStopTap.no, NO, null);
radio.addOption(nwlpStopTap.unsure, UNSURE, null);
radio.setDefault(YES);
const buttonsBlock = new ButtonRow();

const nwlpStopTapStep = new Step('step', 'nwlp-stop-tap');
nwlpStopTapStep.setIsContained(true);
nwlpStopTapStep.addBlock(headingBlock);
nwlpStopTapStep.addBlock(infoBlock1);
nwlpStopTapStep.addBlock(heading);
nwlpStopTapStep.addBlock(infoBlock2);
nwlpStopTapStep.addBlock(infoBlock3);
nwlpStopTapStep.addBlock(headingBlock2);
nwlpStopTapStep.addBlock(radio);
nwlpStopTapStep.addBlock(buttonsBlock);
nwlpStopTapStep.setTitle(nwlpStopTap.title);

nwlpStopTapStep.setConstraints([
  {
    nwlpInitialProblem: {
      inclusion: [PRESSURE],
      exclusion: [NO_WATER],
    },
    nwlpPressureProblem: {
      inclusion: [LOW_PRESSURE],
      exclusion: [HIGH_PRESSURE],
    },
    nwlpPressureSupply: {
      inclusion: [HOT_COLD],
      exclusion: [NOT_FIRST_FEED, HOT_ONLY],
    },
  },
  {
    nwlpInitialProblem: {
      inclusion: [NO_WATER],
      exclusion: [PRESSURE],
    },
    nwlpFacilitiesAffected: {
      inclusion: [FIRST_FEED],
      exclusion: [NOT_FIRST_FEED],
    },
  },
]);

export { nwlpStopTapStep };
