import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  YES,
  NO,
  UNSURE,
  WASTE,
  CLEAN,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  ACCIDENT,
  BOTH,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  GULLY,
  GUSHING,
  LOCATION_METHOD_ADDRESS_LOOKUP,
  LOCATION_METHOD_CURRENT_LOCATION,
  LOCATION_METHOD_MAP_SEARCH,
  NONE,
  PROPERTY_DAMAGE,
  TRICKLING,
  COVER,
  DRAIN,
  GROUND,
  ODOUR,
  POLLUTION,
} from '../../../../constants';
import { cleanBoundaryRisk } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', cleanBoundaryRisk.heading);
const cleanBoundaryRiskControl = new Control('question', 'cleanBoundaryRisk');
cleanBoundaryRiskControl.setLabel(cleanBoundaryRisk.heading);
cleanBoundaryRiskControl.setDefault('');
cleanBoundaryRiskControl.addOption(cleanBoundaryRisk.propertyDamage, PROPERTY_DAMAGE, 'IconHouse');
cleanBoundaryRiskControl.addOption(cleanBoundaryRisk.accident, ACCIDENT, 'IconAccessWalking');
cleanBoundaryRiskControl.addOption(cleanBoundaryRisk.both, BOTH, 'IconPropertyAccess');
cleanBoundaryRiskControl.addOption(cleanBoundaryRisk.none, NONE, 'IconCross');
cleanBoundaryRiskControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const cleanBoundaryRiskStep = new Step('step', 'clean-boundary-risk');
cleanBoundaryRiskStep.addBlock(headingBlock);
cleanBoundaryRiskStep.addBlock(cleanBoundaryRiskControl);
cleanBoundaryRiskStep.addBlock(buttonsBlock);
cleanBoundaryRiskStep.setIsContained(false);
cleanBoundaryRiskStep.setTitle(cleanBoundaryRisk.title);

cleanBoundaryRiskStep.setConstraints([
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [COVER],
      exclusion: [DRAIN, GROUND, ODOUR],
    },
    wasteExternalCoverType: {
      presence: true,
      inclusion: [METER, OTHER, SLUICE_VALVE, STOPTAP],
      exclusion: [GRATE, SQUARE],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [DRAIN, COVER, ODOUR],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [DRAIN, COVER, ODOUR],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [WASTE, UNSURE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [WASTE, UNSURE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [WASTE, UNSURE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [WASTE, UNSURE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [GROUND],
      exclusion: [ODOUR, POLLUTION, COVER],
    },
    externalWasteWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    externalConstantPressure: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    externalWaterSmell: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [SLUICE_VALVE, STOPTAP, METER],
      exclusion: [OTHER, SQUARE, GULLY],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [SLUICE_VALVE, STOPTAP, METER],
      exclusion: [OTHER, SQUARE, GULLY],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SQUARE, GULLY, SLUICE_VALVE, STOPTAP, METER],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [UNSURE, WASTE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SQUARE, GULLY, SLUICE_VALVE, STOPTAP, METER],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [UNSURE, WASTE],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
  {
    initialLocationWaste: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      presence: true,
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      presence: true,
      inclusion: [OTHER],
      exclusion: [SLUICE_VALVE, STOPTAP, METER, GULLY, SQUARE],
    },
    boundaryWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [WASTE, CLEAN],
    },
    boundaryRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      presence: true,
      inclusion: [NO],
      exclusion: [YES],
    },
    locateMethod: {
      presence: true,
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
    cleanBoundarySeverity: {
      presence: true,
      inclusion: [GUSHING, TRICKLING],
    },
    wasteRtimInformationCol: {
      presence: false,
      exclusion: [true],
    },
  },
]);

cleanBoundaryRiskStep.setValidation({
  cleanBoundaryRisk: {
    presence: {
      message: 'Please select at least one',
      allowEmpty: false,
    },
  },
});

export { cleanBoundaryRiskStep };
