import { Control, Heading, Step, ButtonRow } from '../../../models';
import { EXTERNAL, NO, YES, COVER, OVERFLOW_PIPE, GROUND, METER } from '../../../../constants';
import { cleanMeterLeaking } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', cleanMeterLeaking.heading);
const cleanMeterLeakingControl = new Control('question', 'cleanMeterLeaking');
cleanMeterLeakingControl.setLabel(cleanMeterLeaking.heading);
cleanMeterLeakingControl.addOption(cleanMeterLeaking.yes, YES, 'IconMeter');
cleanMeterLeakingControl.addOption(cleanMeterLeaking.no, NO, 'IconCross');
const buttonsBlock = new ButtonRow().hideContinueButton();

const cleanMeterLeakingStep = new Step('step', 'clean-meter-leaking');
cleanMeterLeakingStep.addBlock(headingBlock);
cleanMeterLeakingStep.addBlock(cleanMeterLeakingControl);
cleanMeterLeakingStep.addBlock(buttonsBlock);
cleanMeterLeakingStep.setTitle(cleanMeterLeaking.title);

cleanMeterLeakingStep.setConstraints({
  initialLocation: {
    inclusion: [EXTERNAL],
  },
  externalWaterOrigin: {
    presence: true,
    inclusion: [METER],
    exclusion: [COVER, OVERFLOW_PIPE, GROUND],
  },
});

export { cleanMeterLeakingStep };
