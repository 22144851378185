import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  YES,
  NO,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  TRIANGLE,
  GUSHING,
  LOCATION_METHOD_CURRENT_LOCATION,
  LOCATION_METHOD_MAP_SEARCH,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  ABOVE_GROUND_STOP_TAP,
  COVER,
  GROUND,
  PLUMBING,
  TRICKLING,
  CLEAN,
  WASTE,
  UNSURE,
} from '../../../../constants';
import { cleanSeverity } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', cleanSeverity.heading);
const cleanSeverityControl = new Control('question', 'cleanSeverity');
cleanSeverityControl.setLabel(cleanSeverity.heading);
cleanSeverityControl.addOption(cleanSeverity.yes, GUSHING, 'IconGushing', false, GUSHING);
cleanSeverityControl.addOption(cleanSeverity.no, TRICKLING, 'IconCross', false, TRICKLING);
cleanSeverityControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const cleanSeverityStep = new Step('step', 'clean-severity');
cleanSeverityStep.addBlock(headingBlock);
cleanSeverityStep.addBlock(cleanSeverityControl);
cleanSeverityStep.addBlock(buttonsBlock);
cleanSeverityStep.setTitle(cleanSeverity.title);

cleanSeverityStep.setConstraints([
  {
    initialLocation: {
      exclusion: [INTERNAL, EXTERNAL],
    },
    waterType: {
      presence: true,
      inclusion: [CLEAN],
    },
    coverType: {
      exclusion: [TRIANGLE, SQUARE, SLUICE_VALVE],
    },
  },
  {
    initialLocation: {
      exclusion: [INTERNAL, EXTERNAL],
    },
    waterSmell: {
      presence: true,
      inclusion: [NO],
    },
  },
  {
    initialLocation: {
      exclusion: [INTERNAL, EXTERNAL],
    },
    constantPressure: {
      presence: true,
      inclusion: [YES],
    },
  },
  {
    initialLocation: {
      exclusion: [INTERNAL, EXTERNAL],
    },
    waterOrigin: {
      presence: true,
      inclusion: [PLUMBING, METER, ABOVE_GROUND_STOP_TAP],
    },
  },
  {
    initialLocation: {
      exclusion: [INTERNAL, EXTERNAL],
    },
    coverType: {
      presence: true,
      inclusion: [STOPTAP, METER, SLUICE_VALVE],
      exclusion: [TRIANGLE, SQUARE],
    },
  },
  {
    initialLocation: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_MAP_SEARCH, LOCATION_METHOD_CURRENT_LOCATION],
    },
    waterOrigin: {
      inclusion: [GROUND, OTHER],
      exclusion: [COVER, ABOVE_GROUND_STOP_TAP],
    },
    waterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [WASTE, UNSURE],
    },
  },
  {
    initialLocation: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_MAP_SEARCH, LOCATION_METHOD_CURRENT_LOCATION],
    },
    coverType: {
      inclusion: [OTHER],
      exclusion: [METER, SLUICE_VALVE, STOPTAP, GRATE, SQUARE],
    },
    externalWaterType: {
      presence: true,
      inclusion: [CLEAN],
      exclusion: [WASTE, UNSURE],
    },
  },
  {
    initialLocation: {
      exclusion: [INTERNAL, EXTERNAL],
    },
    externalConstantPressure: {
      presence: true,
      inclusion: [YES],
    },
  },
]);

export { cleanSeverityStep };
