import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'yw-ui.v2';

const RedirectButton = ({ text, redirectUrl }) => {
  function onClick() {
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.href = redirectUrl;
  }

  return (
    <Button id="continue" qa="continue" state={'primary'} onClick={onClick}>
      {text}
    </Button>
  );
};

RedirectButton.propTypes = {
  text: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
};

export default RedirectButton;
