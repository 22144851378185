import { Control, Heading, Step, ButtonRow } from '../../models';
import {
  YES,
  NO,
  PRESSURE,
  NO_WATER,
  LOW_PRESSURE,
  HIGH_PRESSURE,
  HOT_COLD,
  NOT_FIRST_FEED,
  HOT_ONLY,
  UNSURE,
  FIRST_FEED,
} from '../../../constants';
import { nwlpNoticeToday } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', nwlpNoticeToday.heading);
const nwlpNoticeTodayControl = new Control('question', 'nwlpNoticeToday');
nwlpNoticeTodayControl.setLabel(nwlpNoticeToday.heading);
nwlpNoticeTodayControl.addOption(nwlpNoticeToday.yes, YES, 'IconCheckTick');
nwlpNoticeTodayControl.addOption(nwlpNoticeToday.no, NO, 'IconCross');
const buttonsBlock = new ButtonRow().hideContinueButton();

const nwlpNoticeTodayStep = new Step('step', 'nwlp-notice-today');
nwlpNoticeTodayStep.addBlock(headingBlock);
nwlpNoticeTodayStep.addBlock(nwlpNoticeTodayControl);
nwlpNoticeTodayStep.addBlock(buttonsBlock);
nwlpNoticeTodayStep.setTitle(nwlpNoticeToday.title);

nwlpNoticeTodayStep.setConstraints([
  {
    nwlpInitialProblem: {
      inclusion: [PRESSURE],
      exclusion: [NO_WATER],
    },
    nwlpPressureProblem: {
      inclusion: [LOW_PRESSURE],
      exclusion: [HIGH_PRESSURE],
    },
    nwlpPressureSupply: {
      inclusion: [HOT_COLD],
      exclusion: [NOT_FIRST_FEED, HOT_ONLY],
    },
    nwlpStopTap: {
      inclusion: [YES, NO, UNSURE],
    },
    nwlpPropertyType: {
      inclusion: [NO],
      exclusion: [YES],
    },
    nwlpProblemNeighbour: {
      inclusion: [YES, NO, UNSURE],
    },
  },
  {
    nwlpInitialProblem: {
      inclusion: [NO_WATER],
      exclusion: [PRESSURE],
    },
    nwlpFacilitiesAffected: {
      inclusion: [FIRST_FEED],
      exclusion: [NOT_FIRST_FEED],
    },
    nwlpStopTap: {
      inclusion: [YES, NO, UNSURE],
    },
    nwlpAnyWater: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    nwlpPropertyType: {
      inclusion: [NO],
      exclusion: [YES],
    },
    nwlpProblemNeighbour: {
      inclusion: [YES, NO, UNSURE],
    },
  },
]);

export { nwlpNoticeTodayStep };
