import { Heading, Step, ButtonRow, InfoBlock, Control, Banner } from '../../../models';
import { YES, NO } from '../../../../constants';
import {
  duplicateIssueBanner,
  duplicateIssueQuestion,
  reviewForCoC,
  wasteRtimInformation,
} from '../../../../constants/Copy';

const banner = new Banner(duplicateIssueBanner);
const headingBlock = new Heading('step-heading', wasteRtimInformation.heading);
const infoBlock = new InfoBlock('info-block', wasteRtimInformation.detail[0], 'p-b');
const headingBlock2 = new Heading('step-heading2', wasteRtimInformation.subHeading).setSize('md');
const infoBlock2 = new InfoBlock('info-block2', wasteRtimInformation.detail[1]);
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteRtimInformationColStep = new Step('step', 'waste-rtim-information-col');
wasteRtimInformationColStep.setIsContained(true);
wasteRtimInformationColStep.addBlock(new Control('hidden', 'wasteRtimInformationCol').setDefault(true));
wasteRtimInformationColStep.addBlock(
  new Control('hidden', 'wasteRtimInformationColTxt').setLabel(duplicateIssueQuestion).setDefault(reviewForCoC),
);
wasteRtimInformationColStep.addBlock(banner);
wasteRtimInformationColStep.addBlock(headingBlock);
wasteRtimInformationColStep.addBlock(infoBlock);
wasteRtimInformationColStep.addBlock(headingBlock2);
wasteRtimInformationColStep.addBlock(infoBlock2);
wasteRtimInformationColStep.addBlock(buttonsBlock);
wasteRtimInformationColStep.setTitle(wasteRtimInformation.title);

wasteRtimInformationColStep.setConstraints({
  wasteIncidentResults: {
    inclusion: [YES],
  },
  incidentTotalWaste: {
    presence: { AllowEmpty: true },
    numericality: {
      greaterThan: 0,
    },
  },
  isColleagueMode: {
    presence: true,
    inclusion: [YES],
    exclusion: [NO],
  },
});

export { wasteRtimInformationColStep };
