import { Step } from '../../../models';
import { INTERNAL, TASTE_SMELLS_URL } from '../../../../constants';

export const tasteSmellRedirect = new Step('redirect', 'taste-smell-redirect')
  .setRedirectUrl(TASTE_SMELLS_URL)
  .setConstraints({
    initialLocation: {
      presence: true,
      inclusion: [INTERNAL],
    },
    waterQualityIssue: {
      presence: true,
      inclusion: ['TASTE_OR_SMELL'],
    },
  });
