import { ButtonRow, Control, Heading, Step } from '../../models';
import { YES, NO, EXTERNAL, INTERNAL, PUBLIC, COVER, DRAIN, GROUND, ODOUR } from '../../../constants';
import { wasteBoundaryEscape } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteBoundaryEscape.heading);
const wasteBoundaryEscapeControl = new Control('question', 'wasteBoundaryEscape');
wasteBoundaryEscapeControl.setLabel(wasteBoundaryEscape.heading);
wasteBoundaryEscapeControl.addOption(wasteBoundaryEscape.yes, YES, 'IconCheckTick');
wasteBoundaryEscapeControl.addOption(wasteBoundaryEscape.no, NO, 'IconCross');
wasteBoundaryEscapeControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteBoundaryEscapeStep = new Step('step', 'waste-boundary-escape');
wasteBoundaryEscapeStep.addBlock(headingBlock);
wasteBoundaryEscapeStep.addBlock(wasteBoundaryEscapeControl);
wasteBoundaryEscapeStep.addBlock(buttonsBlock);
wasteBoundaryEscapeStep.setTitle(wasteBoundaryEscape.title);

wasteBoundaryEscapeStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [DRAIN],
      exclusion: [COVER, GROUND, ODOUR],
    },
  },
]);

export { wasteBoundaryEscapeStep };
