import { ButtonRow, Heading, InfoBlock, Link, Step } from '../../../models';
import {
  INTERNAL,
  WATER_SUPPLY,
  NO,
  NO_WATER,
  LOW_PRESSURE,
  CALLBACK_1_URL,
  WATER_PRESSURE_URL,
} from '../../../../constants';
import { noWaterInfo } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', noWaterInfo.heading);
const noWaterInfoHeading1 = new Heading('read-help-heading', noWaterInfo.subHeading, 2).setSize('md');
const noWaterInfoBlock1 = new InfoBlock('read-help-block', noWaterInfo.detail[0]);
const noWaterInfoLink1 = new Link('read-help-link', noWaterInfo.helpPages)
  .setState('primary')
  .setLink(WATER_PRESSURE_URL);
const noWaterInfoHeading2 = new Heading('read-help-block', noWaterInfo.subHeading2, 2).setSize('md');
const noWaterInfoBlock2 = new InfoBlock('next-steps-block', noWaterInfo.detail[1]);
const callbackLink = new Link('callback-link', noWaterInfo.callback).setLink(CALLBACK_1_URL);
const buttonsBlock = new ButtonRow().hideContinueButton();

const noWaterInfoStep = new Step('step', 'water-supply-problem');
noWaterInfoStep.addBlock(headingBlock);
noWaterInfoStep.addBlock(noWaterInfoHeading1);
noWaterInfoStep.addBlock(noWaterInfoBlock1);
noWaterInfoStep.addBlock(noWaterInfoLink1);
noWaterInfoStep.addBlock(noWaterInfoHeading2);
noWaterInfoStep.addBlock(noWaterInfoBlock2);
noWaterInfoStep.addBlock(callbackLink);
noWaterInfoStep.addBlock(buttonsBlock);
noWaterInfoStep.setIsContained(true);
noWaterInfoStep.setTitle(noWaterInfo.title);

noWaterInfoStep.setConstraints([
  {
    initialLocation: {
      inclusion: [INTERNAL],
    },
    initialProblem: {
      inclusion: [WATER_SUPPLY],
    },
    supplyIssue: {
      inclusion: [NO_WATER, LOW_PRESSURE],
    },
    waterFromCover: {
      exclusion: [NO],
    },
  },
]);

export { noWaterInfoStep };
