import { Heading, Step, ButtonRow, InfoBlock, Banner, Control } from '../../../models';
import { EXTERNAL, NO, YES, COVER, OVERFLOW_PIPE, GROUND, METER } from '../../../../constants';
import {
  cleanLeakingMeterInfo,
  privateIssueBanner,
  privateIssueQuestion,
  reviewForCoC,
} from '../../../../constants/Copy';

const banner = new Banner(privateIssueBanner);
const headingBlock = new Heading('step-heading', cleanLeakingMeterInfo.heading);
const infoBlock = new InfoBlock('info-block', cleanLeakingMeterInfo.detail[0], 'p-b');
const headingBlock2 = new Heading('step-heading2', cleanLeakingMeterInfo.subHeading, 2).setSize('md');
const infoBlock2 = new InfoBlock('info-block2', cleanLeakingMeterInfo.detail[1]);
const infoBlock3 = new InfoBlock('info-block3', cleanLeakingMeterInfo.detail[2]);
const infoBlock4 = new InfoBlock('info-block4', cleanLeakingMeterInfo.detail[3]);
const infoBlock5 = new InfoBlock('info-block5', cleanLeakingMeterInfo.detail[4], 'p-b');
const buttonsBlock = new ButtonRow();

const cleanLeakingMeterInfoColStep = new Step('step', 'clean-leakingmeter-info-col');
cleanLeakingMeterInfoColStep.setIsContained(true);
cleanLeakingMeterInfoColStep.addBlock(new Control('hidden', 'cleanLeakingmeterInfoCol').setDefault(true));
cleanLeakingMeterInfoColStep.addBlock(
  new Control('hidden', 'cleanLeakingMeterInfoColTxt').setLabel(privateIssueQuestion).setDefault(reviewForCoC),
);
cleanLeakingMeterInfoColStep.addBlock(banner);
cleanLeakingMeterInfoColStep.addBlock(headingBlock);
cleanLeakingMeterInfoColStep.addBlock(infoBlock);
cleanLeakingMeterInfoColStep.addBlock(headingBlock2);
cleanLeakingMeterInfoColStep.addBlock(infoBlock2);
cleanLeakingMeterInfoColStep.addBlock(infoBlock3);
cleanLeakingMeterInfoColStep.addBlock(infoBlock4);
cleanLeakingMeterInfoColStep.addBlock(infoBlock5);
cleanLeakingMeterInfoColStep.addBlock(buttonsBlock);
cleanLeakingMeterInfoColStep.setTitle(cleanLeakingMeterInfo.title);

cleanLeakingMeterInfoColStep.setConstraints({
  initialLocation: {
    inclusion: [EXTERNAL],
  },
  externalWaterOrigin: {
    presence: true,
    inclusion: [METER],
    exclusion: [COVER, OVERFLOW_PIPE, GROUND],
  },
  cleanMeterLeaking: {
    presence: true,
    inclusion: [NO],
    exclusion: [YES],
  },
  isColleagueMode: {
    presence: true,
    inclusion: [YES],
    exclusion: [NO],
  },
});

export { cleanLeakingMeterInfoColStep };
