import React from 'react';
import PropTypes from 'prop-types';
import { FormRow, SelectInput } from 'yw-ui.v2';
import { Option_No, Option_Yes } from '../../../constants';

export const SentimentSection = ({
  name,
  title,
  options,
  cols,
  placeholder,
  isRequired,
  form,
  disabled,
  valid,
  message,
  onChange,
  dirty,
}) => {
  const field = {
    valid: form.valid[name] || valid,
    value: form.values[name],
    errors: form.errors[name],
    onChange: (e, i) => {
      form.handleChange(e, i);
      onChange(dirty);
    },
    onBlur: form.handleBlur,
  };

  const generateOptions = (options) => {
    function generate(o) {
      const valueIfNo = o === Option_No ? 'false' : o;
      const id = o === Option_Yes ? 'true' : valueIfNo;
      return { id: id, label: o };
    }
    return options.map(generate);
  };

  return (
    <FormRow
      name={`FormRow_${name}`}
      qa={`FormRow_${name}`}
      label={title}
      isRequiredMarker={isRequired}
      valid={valid}
      messages={[message]}
    >
      <SelectInput
        options={generateOptions(options)}
        id={name}
        qa={name}
        name={name}
        cols={cols}
        placeholder={placeholder}
        className={''}
        field={field}
        disabled={disabled}
      />
    </FormRow>
  );
};

SentimentSection.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  cols: PropTypes.number,
  placeholder: PropTypes.string,
  form: PropTypes.object,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  valid: PropTypes.bool,
  message: PropTypes.string,
  dirty: PropTypes.string,
  onChange: PropTypes.func,
};

SentimentSection.defaultProps = {
  cols: 12,
  placeholder: 'Please select an answer',
  isRequired: false,
  disabled: false,
  valid: true,
  message: 'Please select a value',
};
