import { Heading, Step, ButtonRow, InfoBlock, Control, Banner } from '../../../models';
import {
  YES,
  NO,
  UNSURE,
  METER,
  PIPE_MEETS_METER,
  STOPTAP,
  INSIDE,
  OUTSIDE,
  EXTERNAL,
  COVER,
  OVERFLOW_PIPE,
  GROUND,
  INTERNAL,
  PUBLIC,
} from '../../../../constants';
import { cleanCondenInfo, privateIssueBanner, privateIssueQuestion, reviewForCoC } from '../../../../constants/Copy';

const banner = new Banner(privateIssueBanner);
const headingBlock = new Heading('step-heading', cleanCondenInfo.heading);
const infoBlock = new InfoBlock('info-block', cleanCondenInfo.detail[0], 'p-tb');
const headingBlock2 = new Heading('step-heading2', cleanCondenInfo.subHeading1, 2).setSize('md');
const infoBlock2 = new InfoBlock('info-block2', cleanCondenInfo.detail[1], 'p-b');
const headingBlock3 = new Heading('step-heading2', cleanCondenInfo.subHeading3, 2).setSize('md');
const infoBlock3 = new InfoBlock('info-block3', cleanCondenInfo.detail[2], 'p-b');
const headingBlock4 = new Heading('step-heading2', cleanCondenInfo.subHeading2, 2).setSize('md');
const infoBlock4 = new InfoBlock('info-block4', cleanCondenInfo.detail[3]);
const infoBlock5 = new InfoBlock('info-block5', cleanCondenInfo.detail[4]);
const infoBlock6 = new InfoBlock('info-block6', cleanCondenInfo.detail[5]);
const infoBlock7 = new InfoBlock('info-block7', cleanCondenInfo.detail[6]);
const infoBlock8 = new InfoBlock('info-block8', cleanCondenInfo.detail[7], 'p-b');
const buttonsBlock = new ButtonRow();

const cleanCondenInfoColStep = new Step('step', 'clean-conden-info-col');
cleanCondenInfoColStep.setIsContained(true);
cleanCondenInfoColStep.addBlock(banner);
cleanCondenInfoColStep.addBlock(headingBlock);
cleanCondenInfoColStep.addBlock(new Control('hidden', 'cleanCondenInfoCol').setDefault(true));
cleanCondenInfoColStep.addBlock(
  new Control('hidden', 'cleanCondenInfoColTxt').setLabel(privateIssueQuestion).setDefault(reviewForCoC),
);
cleanCondenInfoColStep.addBlock(infoBlock);
cleanCondenInfoColStep.addBlock(headingBlock2);
cleanCondenInfoColStep.addBlock(infoBlock2);
cleanCondenInfoColStep.addBlock(headingBlock3);
cleanCondenInfoColStep.addBlock(infoBlock3);
cleanCondenInfoColStep.addBlock(headingBlock4);
cleanCondenInfoColStep.addBlock(infoBlock4);
cleanCondenInfoColStep.addBlock(infoBlock5);
cleanCondenInfoColStep.addBlock(infoBlock6);
cleanCondenInfoColStep.addBlock(infoBlock7);
cleanCondenInfoColStep.addBlock(infoBlock8);
cleanCondenInfoColStep.addBlock(buttonsBlock);
cleanCondenInfoColStep.setTitle(cleanCondenInfo.title);

cleanCondenInfoColStep.setConstraints([
  {
    initialLocation: {
      inclusion: [INTERNAL],
      exclusion: [PUBLIC, EXTERNAL],
    },
    internalWaterOrigin: {
      inclusion: [METER],
    },
    cleanMeterInternal: {
      inclusion: [METER],
      exclusion: [PIPE_MEETS_METER, STOPTAP, UNSURE],
    },
    cleanCondenConfirm: {
      inclusion: [NO],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
  {
    cleanCondenConfirm: {
      inclusion: [NO],
    },
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      presence: true,
      inclusion: [METER],
      exclusion: [COVER, OVERFLOW_PIPE, GROUND],
    },
    cleanMeterLeaking: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    cleanMeterLocation: {
      presence: true,
      inclusion: [INSIDE],
      exclusion: [OUTSIDE],
    },
    cleanMeterInternal: {
      presence: true,
      inclusion: [METER],
      exclusion: [PIPE_MEETS_METER, STOPTAP, UNSURE],
    },
    isColleagueMode: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
  },
]);

export { cleanCondenInfoColStep };
