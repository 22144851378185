import { ButtonRow, Control, Heading, Step } from '../../models';
import {
  YES,
  NO,
  UNSURE,
  ACCESS,
  BOTH,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  DAMP,
  LARGE,
  MEDIUM,
  NONE,
  PROPERTY_DAMAGE,
  SMALL,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  COVER,
  DRAIN,
  GROUND,
  ODOUR,
  KITCHEN,
  HOUSE,
  BATHROOM,
  BLOCKED,
  CLEAR,
  MANHOLE,
  UPSTAIRS_TOILET,
  DOWNSTAIRS_BATH,
  MULTIPLE_TOILETS,
  DOWNSTAIRS_TOILET,
  UPSTAIRS_FACILITIES,
  DOWNSTAIRS_FACILITIES,
  UPSTAIRS_BATH,
  SINGLE,
  MULTIPLE,
  DIFFERENT,
  SAME,
  TOILET,
  APPLIANCE,
  SINK,
  FLOOR,
  CELLAR,
  MANY,
  ONCE,
} from '../../../constants';
import { wasteBoundaryAccessSupply } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteBoundaryAccessSupply.heading);
const wasteBoundaryAccessSupplyControl = new Control('question', 'wasteBoundaryAccessSupply');
wasteBoundaryAccessSupplyControl.setLabel(wasteBoundaryAccessSupply.heading);
wasteBoundaryAccessSupplyControl.labelOff();
wasteBoundaryAccessSupplyControl.addOption(wasteBoundaryAccessSupply.yes, YES, 'IconAccessWalking');
wasteBoundaryAccessSupplyControl.addOption(wasteBoundaryAccessSupply.no, NO, 'IconCross');
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteBoundaryAccessSupplyStep = new Step('step', 'waste-boundary-access-supply');
wasteBoundaryAccessSupplyStep.addBlock(headingBlock);
wasteBoundaryAccessSupplyStep.addBlock(wasteBoundaryAccessSupplyControl);
wasteBoundaryAccessSupplyStep.addBlock(buttonsBlock);
wasteBoundaryAccessSupplyStep.setTitle(wasteBoundaryAccessSupply.title);

wasteBoundaryAccessSupplyStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [COVER],
      exclusion: [DRAIN, GROUND, ODOUR],
    },
    wasteExternalCoverType: {
      inclusion: [OTHER, GRATE, SQUARE],
      exclusion: [METER, SLUICE_VALVE, STOPTAP],
    },
    wasteBoundaryRisk: {
      inclusion: [ACCESS, BOTH, NONE, PROPERTY_DAMAGE],
    },
    wasteBoundarySeverity: {
      presence: true,
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [GROUND],
      exclusion: [DRAIN, COVER, ODOUR],
    },
    boundaryRainfallAffected: {
      inclusion: [YES],
      exclusion: [UNSURE],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [GROUND],
      exclusion: [DRAIN, COVER, ODOUR],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      inclusion: [YES],
      exclusion: [NO],
    },
    wasteBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCESS, BOTH, NONE],
    },
    wasteBoundarySeverity: {
      inclusion: [DAMP, SMALL, MEDIUM, LARGE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [KITCHEN],
      exclusion: [HOUSE, ODOUR, BATHROOM],
    },
    wasteInternalExtdrain: {
      inclusion: [BLOCKED, UNSURE],
      exclusion: [CLEAR],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [BATHROOM],
      exclusion: [HOUSE, ODOUR, KITCHEN],
    },
    wasteInternalFclty1: {
      inclusion: [DOWNSTAIRS_BATH, MULTIPLE_TOILETS, DOWNSTAIRS_TOILET, UPSTAIRS_FACILITIES, DOWNSTAIRS_FACILITIES],
      exclusion: [UPSTAIRS_TOILET, UPSTAIRS_BATH],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [BATHROOM],
      exclusion: [HOUSE, ODOUR, KITCHEN],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_TOILET],
      exclusion: [
        UPSTAIRS_BATH,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalExtother: {
      inclusion: [GRATE, MANHOLE, ODOUR],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [BATHROOM],
      exclusion: [HOUSE, ODOUR, KITCHEN],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [SINGLE],
      exclusion: [MULTIPLE],
    },
    wasteInternalExtother: {
      inclusion: [GRATE, MANHOLE, ODOUR],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [BATHROOM],
      exclusion: [HOUSE, ODOUR, KITCHEN],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [MULTIPLE],
      exclusion: [SINGLE],
    },
    wasteInternalSame: {
      inclusion: [DIFFERENT],
      exclusion: [SAME],
    },
    wasteInternalExtother: {
      inclusion: [GRATE, MANHOLE, ODOUR],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [BATHROOM],
      exclusion: [HOUSE, ODOUR, KITCHEN],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_BATH],
      exclusion: [
        UPSTAIRS_TOILET,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalFclty2Count: {
      inclusion: [MULTIPLE],
      exclusion: [SINGLE],
    },
    wasteInternalSame: {
      inclusion: [SAME],
      exclusion: [DIFFERENT],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_TOILET],
      exclusion: [
        UPSTAIRS_BATH,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalExtother: {
      inclusion: [GRATE, MANHOLE, ODOUR],
      exclusion: [NO],
    },
    wasteInternalToilet: {
      inclusion: [MANY],
      exclusion: [ONCE, NONE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [MULTIPLE_TOILETS, DOWNSTAIRS_TOILET],
      exclusion: [UPSTAIRS_BATH, UPSTAIRS_TOILET, DOWNSTAIRS_BATH, UPSTAIRS_FACILITIES, DOWNSTAIRS_FACILITIES],
    },
    wasteInternalToilet: {
      inclusion: [MANY],
      exclusion: [ONCE, NONE],
    },
  },
]);

export { wasteBoundaryAccessSupplyStep };
