import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  GAS,
  CHEMICAL,
  FARM,
  DRAIN,
  HOUSE,
  ODOUR,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  COVER,
  GROUND,
  POLLUTION,
  BATHROOM,
  KITCHEN,
} from '../../../../constants';
import { wasteOdourType } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteOdourType.heading);
const wasteOdourTypeControl = new Control('question', 'wasteOdourType');
wasteOdourTypeControl.setLabel(wasteOdourType.heading);
wasteOdourTypeControl.setDefault('');
wasteOdourTypeControl.addOption(wasteOdourType.gas, GAS, 'IconFlame');
wasteOdourTypeControl.addOption(wasteOdourType.chemical, CHEMICAL, 'IconPetrol');
wasteOdourTypeControl.addOption(wasteOdourType.farm, FARM, 'IconTractor');
wasteOdourTypeControl.addOption(wasteOdourType.drain, DRAIN, 'IconConstantPressure');
wasteOdourTypeControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteOdourTypeStep = new Step('step', 'waste-odour-type');
wasteOdourTypeStep.addBlock(headingBlock);
wasteOdourTypeStep.addBlock(wasteOdourTypeControl);
wasteOdourTypeStep.addBlock(buttonsBlock);
wasteOdourTypeStep.setIsContained(false);
wasteOdourTypeStep.setTitle(wasteOdourType.title);

wasteOdourTypeStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [ODOUR],
      exclusion: [HOUSE, BATHROOM, KITCHEN],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [ODOUR],
      exclusion: [COVER, GROUND, DRAIN],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
  },
]);

export { wasteOdourTypeStep };
