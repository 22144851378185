import {
  YES,
  NO,
  UNSURE,
  CLEAN,
  ACCIDENT,
  BOTH,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  GUSHING,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  NONE,
  COVER,
  GROUND,
  OVERFLOW_PIPE,
  PROPERTY_DAMAGE,
  TRICKLING,
} from '../../../../constants';
import { ButtonRow, Control, Heading, Step } from '../../../models';
import { cleanCoverRisk } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', cleanCoverRisk.heading);
const cleanCoverRiskControl = new Control('question', 'cleanCoverRisk');
cleanCoverRiskControl.setLabel(cleanCoverRisk.heading);
cleanCoverRiskControl.setDefault('');
cleanCoverRiskControl.addOption(cleanCoverRisk.propertyDamage, PROPERTY_DAMAGE, 'IconHouse');
cleanCoverRiskControl.addOption(cleanCoverRisk.accident, ACCIDENT, 'IconAccessWalking');
cleanCoverRiskControl.addOption(cleanCoverRisk.both, BOTH, 'IconPropertyAccess');
cleanCoverRiskControl.addOption(cleanCoverRisk.none, NONE, 'IconCross');
cleanCoverRiskControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const cleanCoverRiskStep = new Step('step', 'clean-cover-risk');
cleanCoverRiskStep.addBlock(headingBlock);
cleanCoverRiskStep.addBlock(cleanCoverRiskControl);
cleanCoverRiskStep.addBlock(buttonsBlock);
cleanCoverRiskStep.setIsContained(false);
cleanCoverRiskStep.setTitle(cleanCoverRisk.title);

cleanCoverRiskStep.setConstraints([
  {
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [UNSURE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
    },
    externalConstantPressure: {
      inclusion: [NO],
    },
    externalWaterSmell: {
      inclusion: [NO],
    },
    cleanCoverSeverity: {
      inclusion: [GUSHING, TRICKLING],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [UNSURE],
    },
    externalRainfallAffected: {
      inclusion: [UNSURE],
    },
    externalConstantPressure: {
      inclusion: [YES],
    },
    cleanCoverSeverity: {
      inclusion: [GUSHING, TRICKLING],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
    },
    externalCoverType: {
      inclusion: [OTHER],
    },
    externalWaterType: {
      inclusion: [CLEAN],
    },
    cleanCoverSeverity: {
      inclusion: [GUSHING, TRICKLING],
    },
  },
  {
    initialLocation: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
      exclusion: [OVERFLOW_PIPE, METER, GROUND],
    },
    externalCoverType: {
      inclusion: [STOPTAP, METER, SLUICE_VALVE],
      exclusion: [GRATE, SQUARE, OTHER],
    },
    cleanCoverSeverity: {
      inclusion: [GUSHING, TRICKLING],
    },
  },
]);

cleanCoverRiskStep.setValidation({
  cleanCoverRisk: {
    presence: {
      message: cleanCoverRisk.validation,
      allowEmpty: false,
    },
  },
});

export { cleanCoverRiskStep };
