import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AccordionBlock from '../AccordionBlock';
import AddressListBlock from '../AddressListBlock';
import PlacesListBlock from '../PlacesListBlock';
import ButtonRowBlock from '../ButtonRowBlock';
import ChoiceBlock from '../ChoiceBlock';
import DayListBlock from '../DayListBlock';
import FieldBlock from '../FieldBlock';
import FileUploadBlock from '../FileUploadBlock';
import HeadingsBlock from '../HeadingsBlock';
import HeadingNoDivBlock from '../HeadingNoDivBlock';
import InfoBlock from '../InfoBlock';
import LinkBlock from '../LinkBlock';
import MapBlock from '../MapBlock';
import MapSearchBlock from '../MapSearchBlock';
import MessageBlock from '../MessageBlock';
import TextBlock from '../TextBlock';
import SlotListBlock from '../SlotListBlock';
import IncidentBlock from '../IncidentBlock';
import SummaryBlock from '../SummaryBlock';
import SummaryTableBlock from '../SummaryTableBlock';
import StaticMapBlock from '../StaticMapBlock';
import TermsAndConditionsBlock from '../TermsAndConditionsBlock';
import PrioritisedCheckboxBlock from '../PrioritisedCheckboxBlock/PrioritisedCheckboxBlock';
import CountedCheckboxBlock from '../CountedCheckboxBlock/CountedCheckboxBlock';
import RedirectButton from '../RedirectButton';
import ColleagueSummaryBlock from '../ColleagueSummaryBlock';
import DateTimeSelectBlock from '../DateTimeSelectBlock/DateTimeSelectBlock';
import DateTimeConfirmBlock from '../DateTimeConfirmBlock/DateTimeConfirmBlock';
import NwlpContactBlock from '../NwlpContactBlock';
import BannerBlock from '../BannerBlock/BannerBlock';

class BlockRenderer extends Component {
  render() {
    const {
      block,
      answers,
      form,
      lookups,
      lookupsLoading,
      validStep,
      stepTo,
      stepForward,
      onBack,
      summary,
      attachmentRemove,
      uploadAttachments,
      attachments,
      SetMapHasDragged,
      SetIsYorkshire,
      locations,
    } = this.props;

    const blockTypes = {
      accordionSingle: AccordionBlock,
      addresses: AddressListBlock,
      days: DayListBlock,
      buttons: ButtonRowBlock,
      redirectButton: RedirectButton,
      checkbox: FieldBlock,
      radio: FieldBlock,
      text: FieldBlock,
      textarea: FieldBlock,
      fileUpload: FileUploadBlock,
      heading: HeadingsBlock,
      headingNoDiv: HeadingNoDivBlock,
      info: InfoBlock,
      link: LinkBlock,
      mapResult: MapBlock,
      staticMapResult: StaticMapBlock,
      TermsAndConditions: TermsAndConditionsBlock,
      placesResults: PlacesListBlock,
      mapSearch: MapSearchBlock,
      message: MessageBlock,
      textBlock: TextBlock,
      question: ChoiceBlock,
      slots: SlotListBlock,
      incidentList: IncidentBlock,
      summary: SummaryBlock,
      summaryTable: SummaryTableBlock,
      prioritisedCheckbox: PrioritisedCheckboxBlock,
      countedCheckbox: CountedCheckboxBlock,
      colleagueSummary: ColleagueSummaryBlock,
      dateTimeSelect: DateTimeSelectBlock,
      dateTimeConfirm: DateTimeConfirmBlock,
      nwlpContact: NwlpContactBlock,
      banner: BannerBlock,
    };

    const noOp = () => {
      return null;
    };

    const BlockComponent = blockTypes[block.type] || noOp;

    return (
      <BlockComponent
        {...block}
        answers={answers}
        lookups={lookups}
        lookupsLoading={lookupsLoading}
        form={form}
        stepTo={stepTo}
        onBack={onBack}
        stepForward={stepForward}
        summary={summary}
        validStep={validStep}
        attachments={attachments}
        uploadAttachments={uploadAttachments}
        attachmentRemove={attachmentRemove}
        SetMapHasDragged={SetMapHasDragged}
        SetIsYorkshire={SetIsYorkshire}
        locations={locations}
      />
    );
  }
}

BlockRenderer.propTypes = {
  block: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  lookups: PropTypes.object.isRequired,
  locations: PropTypes.object.isRequired,
  lookupsLoading: PropTypes.bool,
  validStep: PropTypes.object.isRequired,
  stepTo: PropTypes.func.isRequired,
  stepForward: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  summary: PropTypes.string,
  attachments: PropTypes.object.isRequired,
  attachmentRemove: PropTypes.func.isRequired,
  uploadAttachments: PropTypes.func.isRequired,
  SetMapHasDragged: PropTypes.func.isRequired,
  SetIsYorkshire: PropTypes.func.isRequired,
};

BlockRenderer.defaultProps = { summary: '', lookupsLoading: false };

export default BlockRenderer;
