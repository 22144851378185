import { ButtonRow, Control, Heading, Step } from '../../../models';
import { INTERNAL, METER, PLUMBING, OTHER } from '../../../../constants';
import { waterOriginInternal } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', waterOriginInternal.heading);
const waterOriginInternalControl = new Control('question', 'internalWaterOrigin');
waterOriginInternalControl.setLabel(waterOriginInternal.heading);
waterOriginInternalControl.addOption(waterOriginInternal.meter, METER, 'IconMeter');
waterOriginInternalControl.addOption(waterOriginInternal.stoptap, PLUMBING, 'IconTap');
waterOriginInternalControl.addOption(waterOriginInternal.somewhereElse, OTHER, 'IconQuestion');
const buttonsBlock = new ButtonRow().hideContinueButton();

const waterOriginInternalStep = new Step('step', 'water-origin-private');
waterOriginInternalStep.addBlock(headingBlock);
waterOriginInternalStep.addBlock({
  type: 'message',
  id: 'step-hint',
  message: waterOriginInternal.hint,
});
waterOriginInternalStep.addBlock(waterOriginInternalControl);
waterOriginInternalStep.addBlock(buttonsBlock);
waterOriginInternalStep.setTitle(waterOriginInternal.title);

waterOriginInternalStep.setConstraints({
  initialLocation: {
    inclusion: [INTERNAL],
  },
});

export { waterOriginInternalStep };
