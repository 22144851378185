import { DECP_PROBLEM_SUBMIT_URL, FIX_LEAKS_URL, MAIN_WEBSITE_URL, NO, YES } from '../../../constants';
import { backToMainWebsite, howWeFixLeaks, thankYou } from '../../../constants/Copy';
import { Heading, InfoBlock, Step, Link, Control, RedirectButton } from '../../models';

const headingBlock = new Heading('step-title', thankYou.heading);
const thankYouInfoBlock1 = new InfoBlock('thank-you-block', thankYou.detail[0], 'p-b');
const thankYouInfoHeading2 = new Heading('what-next-heading', thankYou.subHeading, 2).setSize('md');
const thankYouInfoBlock2 = new InfoBlock('what-next-block', thankYou.detail[1]);
const thankYouInfoLink1 = new Link('main-website-link', howWeFixLeaks).setLink(FIX_LEAKS_URL, 'p-b').setTarget('_self');
const thankYouInfoLink2 = new Link('main-website-link', backToMainWebsite).setLink(MAIN_WEBSITE_URL).setTarget('_self');
const colleagueButtonBlock = new RedirectButton(thankYou.redirect, DECP_PROBLEM_SUBMIT_URL);
const hiddenStepControl = new Control('hidden', 'hasSubmitted');
hiddenStepControl.setDefault(true);

const thankYouStep = new Step('step', 'thank-you');
thankYouStep.addBlock(headingBlock);
thankYouStep.addBlock(thankYouInfoBlock1);
thankYouStep.addBlock(thankYouInfoHeading2);
thankYouStep.addBlock(thankYouInfoBlock2);
thankYouStep.addBlock(thankYouInfoLink2);
thankYouStep.addBlock(hiddenStepControl);
thankYouStep.addBlock(colleagueButtonBlock);
thankYouStep.setIsContained(true);
thankYouStep.setTitle(thankYou.title);

thankYouInfoHeading2.setConstraints({
  isColleagueMode: {
    inclusion: [NO],
    exclusion: [YES],
  },
});

thankYouInfoBlock2.setConstraints({
  isColleagueMode: {
    inclusion: [NO],
    exclusion: [YES],
  },
});

thankYouInfoLink1.setConstraints({
  isColleagueMode: {
    inclusion: [NO],
    exclusion: [YES],
  },
});

thankYouInfoLink2.setConstraints({
  isColleagueMode: {
    inclusion: [NO],
    exclusion: [YES],
  },
});

colleagueButtonBlock.setConstraints({
  isColleagueMode: {
    presence: true,
    inclusion: [YES],
    exclusion: [NO],
  },
});

thankYouStep.setConstraints({
  problemSubmitted: {
    presence: {
      AllowEmpty: false,
    },
  },
});

export { thankYouStep };
