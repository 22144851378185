import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  WHOLE_PROPERTY,
  CELLAR,
  ONE_ROOM,
  MULTIPLE_ROOMS,
  UPSTAIRS,
  GROUND_FLOOR,
  APPLIANCE,
  SHOWER_TOILET_SINK,
  OTHER,
  UNDER_KITCHEN_SINK,
  INSIDE,
  INSIDE_OUTSIDE,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  HOUSE,
  ODOUR,
  DRAIN,
  BLOCKED,
  BOTH,
  NONE,
  FARM,
  CHEMICAL,
  GAS,
  BATHROOM,
  KITCHEN,
} from '../../../../constants';
import { wasteOdourLocation } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteOdourLocation.heading);
const wasteOdourLocationControl = new Control('question', 'wasteOdourLocation');
wasteOdourLocationControl.setLabel(wasteOdourLocation.heading);
wasteOdourLocationControl.setDefault('');
wasteOdourLocationControl.addOption(wasteOdourLocation.wholeProperty, WHOLE_PROPERTY, 'IconHouse2');
wasteOdourLocationControl.addOption(wasteOdourLocation.groundFloor, GROUND_FLOOR, 'IconGroundFloor');
wasteOdourLocationControl.addOption(wasteOdourLocation.upstairs, UPSTAIRS, 'IconUpStairs');
wasteOdourLocationControl.addOption(wasteOdourLocation.multipleRooms, MULTIPLE_ROOMS, 'IconDoors');
wasteOdourLocationControl.addOption(wasteOdourLocation.oneRoom, ONE_ROOM, 'IconDoor');
wasteOdourLocationControl.addOption(wasteOdourLocation.cellar, CELLAR, 'IconCellar');
wasteOdourLocationControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteOdourLocationStep = new Step('step', 'waste-odour-location');
wasteOdourLocationStep.addBlock(headingBlock);
wasteOdourLocationStep.addBlock(wasteOdourLocationControl);
wasteOdourLocationStep.addBlock(buttonsBlock);
wasteOdourLocationStep.setIsContained(false);
wasteOdourLocationStep.setTitle(wasteOdourLocation.title);

wasteOdourLocationStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [ODOUR],
      exclusion: [HOUSE, BATHROOM, KITCHEN],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [NONE],
      exclusion: [DRAIN, BLOCKED, BOTH],
    },
    wasteOdourOutside: {
      inclusion: [INSIDE],
      exclusion: [INSIDE_OUTSIDE],
    },
    wasteOdourSource: {
      inclusion: [OTHER],
      exclusion: [APPLIANCE, SHOWER_TOILET_SINK, UNDER_KITCHEN_SINK],
    },
  },
]);

export { wasteOdourLocationStep };
