import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, FluidField } from 'yw-ui.v2';
import { LOCATION_METHOD_CURRENT_LOCATION } from '../../../constants';

export const MapSearchBlock = ({ stepTo }) => {
  const handleGetLocation = () => {
    stepTo({ target: 'map', params: { locateMethod: LOCATION_METHOD_CURRENT_LOCATION, mapSearch: '' } });
  };

  return (
    <Fragment>
      <div>
        <FluidField
          label="Enter a postcode, town, street or landmark"
          id="mapSearch"
          qa="mapSearch"
          name="mapSearch"
          className="c-form-row--collapse"
          cols={9}
        />
      </div>

      <div className="u-mb--300 u-pb--300 u-pull-top--200-xs-o u-pull-top--400-sm">
        <Button
          state="link"
          icon="IconCrossHair"
          iconSize="200"
          iconPosition="left"
          id="currentLocation"
          qa="currentLocation"
          onClick={handleGetLocation}
        >
          Use my current location
        </Button>
      </div>
    </Fragment>
  );
};

MapSearchBlock.propTypes = {
  stepTo: PropTypes.func.isRequired,
  validStep: PropTypes.object.isRequired,
};

export default MapSearchBlock;
