import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';

const InfoBlock = ({ answers, id, text, classnames }) => {
  return (
    <Fragment>
      {text &&
        validate
          .format(text, answers)
          .split('\n')
          .map((paragraph, i) => {
            return (
              <p
                className={classnames}
                id={id + '-' + i}
                data-qa={id}
                key={i}
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            );
          })}
    </Fragment>
  );
};

InfoBlock.propTypes = {
  answers: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  classnames: PropTypes.string,
};

export default InfoBlock;
