import {
  ButtonRow,
  Link,
  Heading,
  Hook,
  Message,
  Step,
  SummaryTable,
  SummaryTableRow,
  InfoBlock,
  Control,
  ColleagueSummary,
} from '../../models';
import { NO, STEP_SUBMISSION_LOOKUP, YES } from '../../../constants';
import { summary } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', summary.heading);
const noResultMessageBlock = new Message('NO-RESULTS', summary.error);
const callbackMissedMessageBlock = new Message('CALLBACK-MISSED', summary.timePassed);
callbackMissedMessageBlock.setNextStep(summary.newTime, 'choose a new time', {
  target: 'appointment-day',
});
const invalidMessageBlock = {
  id: 'alreadySubmittedBlock',
  type: 'message',
  message: summary.alreadySubmitted,
  state: 'error',
  renderHtml: true,
  nextSteps: [
    {
      text: summary.sameReport,
    },
    {
      text: summary.differentReport,
    },
  ],
};
const hintBlock = new Message('step-hint', summary.sendTechnician).setState('info');
const contactInfoBlock = new InfoBlock('contact-info-heading', summary.givenDetails);
const contactNameRow = new SummaryTableRow('nameSummary', 'Name', '%{firstName} %{lastName}');
const emailSummaryRow = new SummaryTableRow('emailSummary', 'Email', '%{email}');
const telephoneSummaryRow = new SummaryTableRow('telephoneSummary', 'Telephone', '%{telephone}');
const contactSummaryBlock = new SummaryTable('contactSummary');
contactSummaryBlock.addRow(contactNameRow);
contactSummaryBlock.addRow(emailSummaryRow);
contactSummaryBlock.addRow(telephoneSummaryRow);
const changeDetailsLinkBlock = new Link('link-block', summary.changeDetails)
  .setEditSlug('contact-details')
  .setEditMode(true);
const hiddenStepControl = new Control('hidden', 'hasSubmitted').setDefault(false);
const hiddenStep2Control = new Control('hidden', 'problemSubmitted');
const buttonsBlock = new ButtonRow().setContinueButtonText(summary.confirm);
const buttonsBlockFailed = new ButtonRow().setContinueButtonText(summary.confirm);
const buttonsBlockDisabled = new ButtonRow().setContinueButtonText(summary.confirm).setContinueButtonDisabled(true);
const submitHook = new Hook(STEP_SUBMISSION_LOOKUP, '');
const colleagueSummary = new ColleagueSummary();
const colleagueSummaryValidControl = new Control('hidden', 'colleagueSummaryValid').setDefault(true);

const summaryStep = new Step('step', 'your-summary');
summaryStep.addBlock(headingBlock);
summaryStep.addBlock(noResultMessageBlock);
summaryStep.addBlock({
  type: 'textBlock',
  text: summary.location,
  name: 'locationDetails',
});
summaryStep.addBlock(invalidMessageBlock);
summaryStep.addBlock(colleagueSummaryValidControl);
summaryStep.addBlock(hiddenStepControl);
summaryStep.addBlock(hiddenStep2Control);
summaryStep.addBlock(hintBlock);
summaryStep.addBlock(contactInfoBlock);
summaryStep.addBlock(contactSummaryBlock);
summaryStep.addBlock(changeDetailsLinkBlock);
summaryStep.addBlock(colleagueSummary);
summaryStep.addBlock(buttonsBlock);
summaryStep.addBlock(buttonsBlockDisabled);
summaryStep.addBlock(buttonsBlockFailed);
summaryStep.setIsContained(true);
summaryStep.hasRecaptcha = true;
summaryStep.exitHook = submitHook;
summaryStep.setTitle(summary.title);

noResultMessageBlock.setConstraints([
  {
    'hooks.error': {
      presence: true,
      inclusion: [STEP_SUBMISSION_LOOKUP],
    },
    'hooks.errorResponse.appointmentSlot': {
      inclusion: [undefined],
    },
  },
]);

summaryStep.setValidation({
  colleagueSummaryValid: {
    inclusion: [true],
    exclusion: [false],
  },
});

colleagueSummary.setConstraints({
  isColleagueMode: {
    presence: true,
    inclusion: [YES],
    exclusion: [NO],
  },
});

buttonsBlockDisabled.setConstraints({
  problemSubmitted: { inclusion: [true] },
  hasSubmitted: { presence: { AllowEmpty: true } },
});

buttonsBlockFailed.setConstraints([
  {
    'hooks.error': {
      presence: true,
      inclusion: [STEP_SUBMISSION_LOOKUP],
    },
    'hooks.errorResponse.appointmentSlot': {
      inclusion: [undefined],
    },
  },
]);

submitHook.setValidation({
  appointmentSlot: { isFuture: true },
});

buttonsBlock.setConstraints([
  {
    hasSubmitted: {
      inclusion: [true],
    },
    problemSubmitted: { inclusion: [''] },
  },
]);

contactSummaryBlock.setConstraints([
  {
    firstName: {
      presence: true,
    },
  },
  {
    telephone: {
      presence: true,
    },
  },
  {
    email: {
      presence: true,
    },
  },
]);

invalidMessageBlock.constraints = [
  {
    hasSubmitted: { presence: true },
    problemSubmitted: { inclusion: [true] },
  },
];

callbackMissedMessageBlock.setConstraints([
  {
    'hooks.error': {
      presence: true,
      inclusion: [STEP_SUBMISSION_LOOKUP],
    },
    'hooks.errorResponse.appointmentSlot': {
      presence: true,
    },
    problemSubmitted: { inclusion: [''] },
  },
]);

summaryStep.setConstraints({});

export { summaryStep };
