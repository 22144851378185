import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonRow } from 'yw-ui.v2';

const ButtonRowBlock = ({
  continueButtonState,
  continueButtonText,
  continueButtonDisabled,
  backButtonText,
  onBack,
  showBack,
  showContinue,
}) => {
  return (
    <ButtonRow className="u-mt--200">
      {showBack && (
        <Button type="button" id="back" qa="back" onClick={onBack}>
          {backButtonText}
        </Button>
      )}
      {showContinue && (
        <Button type="submit" id="continue" qa="continue" state={continueButtonState} disabled={continueButtonDisabled}>
          {continueButtonText}
        </Button>
      )}
    </ButtonRow>
  );
};

ButtonRowBlock.propTypes = {
  backButtonText: PropTypes.string,
  continueButtonDisabled: PropTypes.bool,
  continueButtonText: PropTypes.string,
  continueButtonState: PropTypes.string,
  showBack: PropTypes.bool,
  showContinue: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
};

ButtonRowBlock.defaultProps = {
  backButtonText: 'Back',
  continueButtonDisabled: false,
  continueButtonText: 'Continue',
  continueButtonState: 'success',
  showBack: true,
  showContinue: true,
};

export default ButtonRowBlock;
