import { ButtonRow, Control, Heading, Hook, Step } from '../../models';
import {
  LOCATION_METHOD_ADDRESS_LOOKUP,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  STEP_ADDRESS_LOOKUP,
  NO_WATER,
  PRESSURE,
} from '../../../constants';
import { addressLookup } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', addressLookup.heading);
const locateMethodControl = new Control('hidden', 'locateMethod').setDefault(LOCATION_METHOD_ADDRESS_LOOKUP);
const addressTotalControl = new Control('hidden', 'addressTotal').setDefault(0);
const lookupPostcodeControl = new Control('text', 'postcode')
  .setLabel(addressLookup.postcode)
  .setChars(14)
  .isRequired(true)
  .isCollapsed(true)
  .isCondensed(true)
  .setDefault('');
const buildingNameOrNumberControl = new Control('text', 'buildingNumber')
  .setLabel(addressLookup.buildingNumber)
  .setCols(6)
  .setDefault('');
const buttonsBlock = new ButtonRow().setContinueButtonText(addressLookup.continue);

const addressLookupStep = new Step('step', 'address-lookup');
addressLookupStep.setIsContained(true);
addressLookupStep.addBlock(headingBlock);
addressLookupStep.addBlock(locateMethodControl);
addressLookupStep.addBlock(addressTotalControl);
addressLookupStep.addBlock(lookupPostcodeControl);
addressLookupStep.addBlock(buildingNameOrNumberControl);
addressLookupStep.addBlock(buttonsBlock);
addressLookupStep.exitHook = new Hook(STEP_ADDRESS_LOOKUP, { postcode: 'postcode', buildingNumber: 'buildingNumber' });
addressLookupStep.setTitle(addressLookup.title);

addressLookupStep.setConstraints({
  initialLocation: {
    inclusion: [INTERNAL, EXTERNAL],
  },
  initialLocationWaste: {
    exclusion: [PUBLIC],
  },
  nwlpInitialProblem: {
    inclusion: [PRESSURE, NO_WATER],
  },
});

addressLookupStep.setValidation({
  postcode: {
    presence: true,
    postcode: { message: addressLookup.invalidPostcode },
  },
});

export { addressLookupStep };
