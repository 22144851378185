import { GA_FORM_ID, GA_SUCCESS } from '../constants';

const trackEvent = (event, stepSlug) =>
  window.dataLayer &&
  window.dataLayer.push({
    event: event,
    form_id: GA_FORM_ID,
    form_name: stepSlug,
    form_state: GA_SUCCESS,
  });

const googleAnalytics = {
  trackEvent,
};

export default googleAnalytics;
