function Link(id, text) {
  this.id = id;
  this.text = text;
  this.type = 'link';
  this.state = 'link';
  this.target = '_blank';
}

Link.prototype.setRoute = function(route) {
  this.route = route;
  return this;
};

Link.prototype.setLink = function(link) {
  this.link = link;
  return this;
};

Link.prototype.setCondensed = function(bool) {
  this.isCondensed = bool || true;
  return this;
};

Link.prototype.setFormat = function(bool) {
  this.isFormatted = bool || true;
  return this;
};

Link.prototype.setConstraints = function(constraints) {
  this.constraints = constraints;
  return this;
};

Link.prototype.setTarget = function(target) {
  this.target = target;
  return this;
};

Link.prototype.setState = function(state) {
  this.state = state;
  return this;
};

Link.prototype.setEditSlug = function(editSlug) {
  this.editSlug = editSlug;
  return this;
};

Link.prototype.setEditMode = function(bool) {
  this.editMode = bool;
  return this;
};

export default Link;
