import { ButtonRow, Control, Heading, Step } from '../../../models';
import { YES, NO, UNSURE, EXTERNAL, INTERNAL, PUBLIC } from '../../../../constants';
import { flowConstant } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', flowConstant.heading);
const flowConstantControl = new Control('question', 'constantPressure');
flowConstantControl.setLabel(flowConstant.heading);
flowConstantControl.addOption(flowConstant.yes, YES, 'IconConstantPressure');
flowConstantControl.addOption(flowConstant.no, NO, 'IconCross');
flowConstantControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const flowConstantStep = new Step('step', 'flow-constant');
flowConstantStep.addBlock(headingBlock);
flowConstantStep.addBlock(flowConstantControl);
flowConstantStep.addBlock(buttonsBlock);
flowConstantStep.setTitle(flowConstant.title);

flowConstantStep.setConstraints([
  {
    rainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
    },
    waterType: {
      presence: true,
      inclusion: [UNSURE],
    },
  },
  {
    initialLocation: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    waterType: {
      presence: true,
      inclusion: [UNSURE],
    },
    rainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
  },
  {
    initialLocation: {
      presence: true,
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    externalRainfallAffected: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [YES],
    },
  },
]);

export { flowConstantStep };
