import { Heading, Step, ButtonRow, InfoBlock, Link } from '../../../models';
import {
  PUBLIC,
  OVERFLOW_PIPE,
  COVER,
  METER,
  GROUND,
  INTERNAL,
  MAIN_WEBSITE_URL,
  LOCAL_PLUMBER_URL,
  GET_IN_TOUCH_URL,
  NO,
  YES,
} from '../../../../constants';
import { backToMainWebsite, cleanOverflowInformation, localPlumber } from '../../../../constants/Copy';

const headingBlock = new Heading('step-heading', cleanOverflowInformation.heading);
const infoBlock = new InfoBlock('info-block', cleanOverflowInformation.detail[0], 'p-tb');
const headingBlock2 = new Heading('step-heading2', cleanOverflowInformation.subHeading, 2).setSize('md');
const infoBlock2 = new InfoBlock('info-block2', cleanOverflowInformation.detail[1]);
const linkBlock1 = new Link('link-block1', localPlumber).setLink(LOCAL_PLUMBER_URL).setTarget('_self');
const infoBlock3 = new InfoBlock('info-block4', cleanOverflowInformation.detail[2]);
const linkBlock2 = new Link('link-block2', cleanOverflowInformation.getInTouch)
  .setLink(GET_IN_TOUCH_URL)
  .setTarget('_self');
const linkBlock = new Link('link-block', backToMainWebsite, 'p-t')
  .setLink(MAIN_WEBSITE_URL)
  .setTarget('_self')
  .setFormat(true);
const buttonsBlock = new ButtonRow().hideContinueButton();

const overflowPipeOffRampStep = new Step('step', 'clean-overflow-info');
overflowPipeOffRampStep.setIsContained(true);
overflowPipeOffRampStep.addBlock(headingBlock);
overflowPipeOffRampStep.addBlock(infoBlock);
overflowPipeOffRampStep.addBlock(headingBlock2);
overflowPipeOffRampStep.addBlock(infoBlock2);
overflowPipeOffRampStep.addBlock(linkBlock1);
overflowPipeOffRampStep.addBlock(infoBlock3);
overflowPipeOffRampStep.addBlock(linkBlock2);
overflowPipeOffRampStep.addBlock(linkBlock);
overflowPipeOffRampStep.addBlock(buttonsBlock);
overflowPipeOffRampStep.setTitle(cleanOverflowInformation.title);

overflowPipeOffRampStep.setConstraints({
  externalWaterOrigin: {
    inclusion: [OVERFLOW_PIPE],
    exclusion: [COVER, METER, GROUND],
  },
  initialLocation: {
    exclusion: [PUBLIC, INTERNAL],
  },
  isColleagueMode: {
    presence: false,
    inclusion: [NO],
    exclusion: [YES],
  },
});

export { overflowPipeOffRampStep };
