import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  PUBLIC,
  COVER,
  GROUND,
  OTHER,
  ABOVE_GROUND_STOP_TAP,
  LEAK_OR_FLOOD,
  POLLUTION,
  NO,
} from '../../../../constants';
import { waterOriginPublic } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', waterOriginPublic.heading);
const waterOriginPublicControl = new Control('question', 'waterOrigin');
waterOriginPublicControl.setLabel(waterOriginPublic.heading);
waterOriginPublicControl.addOption(waterOriginPublic.cover, COVER, 'IconCover');
waterOriginPublicControl.addOption(waterOriginPublic.ground, GROUND, 'IconGround2');
waterOriginPublicControl.addOption(waterOriginPublic.stopTap, ABOVE_GROUND_STOP_TAP, 'IconTap');
waterOriginPublicControl.addOption(waterOriginPublic.other, OTHER, 'IconQuestion');
const buttonsBlock = new ButtonRow().hideContinueButton();

const waterOriginPublicStep = new Step('step', 'water-origin-public');
waterOriginPublicStep.addBlock(headingBlock);
waterOriginPublicStep.addBlock(waterOriginPublicControl);
waterOriginPublicStep.addBlock(buttonsBlock);
waterOriginPublicStep.setTitle(waterOriginPublic.title);

waterOriginPublicStep.setConstraints({
  initialLocation: {
    presence: true,
    inclusion: [PUBLIC],
  },
  initialProblem: {
    presence: true,
    inclusion: [LEAK_OR_FLOOD],
    exclusion: [POLLUTION],
  },
  waterFromCover: {
    exclusion: [NO],
  },
  rtimInformationCol: {
    presence: false,
    exclusion: [true],
  },
});

export { waterOriginPublicStep };
