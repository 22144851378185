import { ActionTypes } from '../constants';

export const initialState = {
  lookups: {},
  error: null,
  errorResponse: null,
  hasError: false,
  isLoading: false,
  lookupCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.HOOK_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case ActionTypes.HOOK_SUCCESS:
      return {
        ...state,
        lookups: {
          ...state.lookups,
          [action.key]: action.response,
        },
        lookup: action.key,
      };
    case ActionTypes.HOOK_ERROR:
      return {
        ...state,
        hasError: action.hasError,
        error: action.error,
        errorResponse: action.errorResponse,
      };
    case ActionTypes.HOOK_COUNT:
      return {
        ...state,
        lookupCount: state.lookupCount + 1 || 0,
      };
    default:
      return state;
  }
};
