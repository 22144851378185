import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  LOCATION_METHOD_CURRENT_LOCATION,
  LOCATION_METHOD_MAP_SEARCH,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  ABOVE_GROUND_STOP_TAP,
  COVER,
  GROUND,
  WASTE,
  CLEAN,
  UNSURE,
} from '../../../../constants';
import { externalWaterType } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', externalWaterType.heading);
const externalWaterTypeControl = new Control('question', 'externalWaterType');
externalWaterTypeControl.setLabel(externalWaterType.heading);
externalWaterTypeControl.addOption(externalWaterType.clean, CLEAN, 'IconGlass', false, 'clean water');
externalWaterTypeControl.addOption(externalWaterType.waste, WASTE, 'IconToiletRoll', false, 'sewage');
externalWaterTypeControl.addOption(externalWaterType.unsure, UNSURE, 'IconQuestion', false, 'unsure');
externalWaterTypeControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const externalWaterTypeStep = new Step('step', 'external-water-type');
externalWaterTypeStep.addBlock(headingBlock);
externalWaterTypeStep.addBlock({
  type: 'message',
  id: 'step-hint',
  message: externalWaterType.hint,
});
externalWaterTypeStep.addBlock(externalWaterTypeControl);
externalWaterTypeStep.addBlock(buttonsBlock);
externalWaterTypeStep.setTitle(externalWaterType.title);

externalWaterTypeStep.setConstraints([
  {
    initialLocation: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    externalWaterOrigin: {
      inclusion: [COVER],
    },
    externalCoverType: {
      inclusion: [OTHER],
      exclusion: [STOPTAP, GRATE, SQUARE, METER, SLUICE_VALVE],
    },
  },
  {
    initialLocation: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    locateMethod: {
      exclusion: [LOCATION_METHOD_MAP_SEARCH, LOCATION_METHOD_CURRENT_LOCATION],
    },
    waterOrigin: {
      presence: true,
      inclusion: [GROUND, OTHER],
      exclusion: [ABOVE_GROUND_STOP_TAP, COVER],
    },
  },
]);

export { externalWaterTypeStep };
