import { ButtonRow, Control, Heading, Step } from '../../models';
import {
  YES,
  NO,
  UNSURE,
  ACCIDENT,
  BOTH,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  GUSHING,
  NONE,
  PROPERTY_DAMAGE,
  TRICKLING,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  COVER,
  DRAIN,
  GROUND,
  ODOUR,
} from '../../../constants';
import { cleanBoundaryAccessSupply } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', cleanBoundaryAccessSupply.heading);
const cleanBoundaryAccessSupplyControl = new Control('question', 'cleanBoundaryAccessSupply');
cleanBoundaryAccessSupplyControl.setLabel(cleanBoundaryAccessSupply.heading);
cleanBoundaryAccessSupplyControl.labelOff();
cleanBoundaryAccessSupplyControl.addOption(cleanBoundaryAccessSupply.yes, YES, 'IconAccessWalking');
cleanBoundaryAccessSupplyControl.addOption(cleanBoundaryAccessSupply.no, NO, 'IconCross');
const buttonsBlock = new ButtonRow().hideContinueButton();

const cleanBoundaryAccessSupplyStep = new Step('step', 'clean-boundary-access-supply');
cleanBoundaryAccessSupplyStep.addBlock(headingBlock);
cleanBoundaryAccessSupplyStep.addBlock(cleanBoundaryAccessSupplyControl);
cleanBoundaryAccessSupplyStep.addBlock(buttonsBlock);
cleanBoundaryAccessSupplyStep.setTitle(cleanBoundaryAccessSupply.title);

cleanBoundaryAccessSupplyStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [COVER],
      exclusion: [DRAIN, GROUND, ODOUR],
    },
    wasteExternalCoverType: {
      inclusion: [METER, OTHER, SLUICE_VALVE, STOPTAP],
      exclusion: [GRATE, SQUARE],
    },
    cleanBoundarySeverity: {
      inclusion: [GUSHING, TRICKLING],
    },
    cleanBoundaryRisk: {
      presence: true,
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [GROUND],
      exclusion: [DRAIN, COVER, ODOUR],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      inclusion: [YES],
      exclusion: [NO],
    },
    cleanBoundarySeverity: {
      inclusion: [GUSHING, TRICKLING],
    },
    cleanBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [GROUND],
      exclusion: [DRAIN, COVER, ODOUR],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
    boundaryFlowConstant: {
      inclusion: [NO],
      exclusion: [YES],
    },
    boundaryWaterSmell: {
      inclusion: [NO],
      exclusion: [YES],
    },
    cleanBoundarySeverity: {
      inclusion: [GUSHING, TRICKLING],
    },
    cleanBoundaryRisk: {
      inclusion: [PROPERTY_DAMAGE, ACCIDENT, BOTH, NONE],
    },
  },
]);

export { cleanBoundaryAccessSupplyStep };
