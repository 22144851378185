import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'yw-ui.v2';

import { LOADING_TEXT } from '../../../constants';

class Preloader extends PureComponent {
  render() {
    const { height, message, qa } = this.props;
    return (
      <div
        style={{
          position: 'relative',
          height: `${height}px`,
          width: '100%',
        }}
      >
        <Loader message={message} qa={qa} />
      </div>
    );
  }
}

Preloader.propTypes = {
  height: PropTypes.number,
  message: PropTypes.string,
  qa: PropTypes.string.isRequired,
};

Preloader.defaultProps = {
  height: 250,
  message: LOADING_TEXT,
};

export default Preloader;
