import { Banner, ButtonRow, Control, Heading, InfoBlock, Step } from '../../../models';
import {
  GULLY,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  SQUARE,
  BLOCKED,
  GUSHING,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  COVER,
  GROUND,
  ODOUR,
  POLLUTION,
  NO,
  YES,
} from '../../../../constants';
import { wasteCouncilInfo, councilIssueBanner, privateIssueQuestion, reviewForCoC } from '../../../../constants/Copy';

const banner = new Banner(councilIssueBanner);
const headingBlock = new Heading('step-heading', wasteCouncilInfo.heading);
const infoBlock = new InfoBlock('info-block', wasteCouncilInfo.detail[0], 'p-b');
const headingBlock2 = new Heading('step-heading2', wasteCouncilInfo.subHeading, 2).setSize('md');
const infoBlock2 = new InfoBlock('info-block2', wasteCouncilInfo.detail[1], 'p-b');
const buttonsBlock = new ButtonRow();

const wasteCouncilInfoColStep = new Step('step', 'waste-council-info-col');
wasteCouncilInfoColStep.setIsContained(true);
wasteCouncilInfoColStep.addBlock(new Control('hidden', 'wasteCouncilInfoCol').setDefault(true));
wasteCouncilInfoColStep.addBlock(
  new Control('hidden', 'wasteCouncilInfoColTxt').setLabel(privateIssueQuestion).setDefault(reviewForCoC),
);
wasteCouncilInfoColStep.addBlock(banner);
wasteCouncilInfoColStep.addBlock(headingBlock);
wasteCouncilInfoColStep.addBlock(infoBlock);
wasteCouncilInfoColStep.addBlock(headingBlock2);
wasteCouncilInfoColStep.addBlock(infoBlock2);
wasteCouncilInfoColStep.addBlock(buttonsBlock);
wasteCouncilInfoColStep.setTitle(wasteCouncilInfo.title);

wasteCouncilInfoColStep.setConstraints({
  initialLocationWaste: {
    inclusion: [PUBLIC],
    exclusion: [EXTERNAL, INTERNAL],
  },
  wasteExternalOrigin: {
    inclusion: [COVER],
    exclusion: [ODOUR, POLLUTION, GROUND],
  },
  wasteExternalCover: {
    inclusion: [GULLY],
    exclusion: [SQUARE, SLUICE_VALVE, STOPTAP, METER, OTHER],
  },
  wasteExternalCoverBlocked: {
    inclusion: [BLOCKED],
    exclusion: [GUSHING],
  },
  isColleagueMode: {
    presence: true,
    inclusion: [YES],
    exclusion: [NO],
  },
});

export { wasteCouncilInfoColStep };
