import { PUBLIC, MAIN_WEBSITE_URL } from '../../../constants';
import { addressLookupInfo, backToMainWebsite } from '../../../constants/Copy';
import { ButtonRow, Heading, Step, InfoBlock, Link } from '../../models';

const headingBlock = new Heading('step-title', addressLookupInfo.heading);
const infoBlock1 = new InfoBlock('info-block-1', addressLookupInfo.detail[0], 'p-b');
const headingBlock2 = new Heading('step-heading2', addressLookupInfo.subHeading, 2).setSize('md');
const infoBlock2 = new InfoBlock('info-block2', addressLookupInfo.detail[1]);
const infoBlock3 = new InfoBlock('info-block-3', addressLookupInfo.detail[2], 'p-t');
const infoBlock4 = new InfoBlock('info-block4', addressLookupInfo.detail[3], 'p-t');
const linkBlock = new Link('link-block', backToMainWebsite, 'p-t')
  .setLink(MAIN_WEBSITE_URL)
  .setTarget('_self')
  .setFormat(true);
const buttonsBlock = new ButtonRow().hideContinueButton();

const addressLookupInfoStep = new Step('step', 'address-lookup-info');
addressLookupInfoStep.addBlock(headingBlock);
addressLookupInfoStep.addBlock(infoBlock1);
addressLookupInfoStep.addBlock(headingBlock2);
addressLookupInfoStep.addBlock(infoBlock2);
addressLookupInfoStep.addBlock(infoBlock3);
addressLookupInfoStep.addBlock(infoBlock4);
addressLookupInfoStep.addBlock(linkBlock);
addressLookupInfoStep.addBlock(buttonsBlock);
addressLookupInfoStep.setIsContained(true);
addressLookupInfoStep.setTitle(addressLookupInfo.title);

addressLookupInfoStep.setConstraints([
  {
    addressTotal: {
      presence: true,
      numericality: { equalTo: 0 },
    },
    initialLocation: {
      exclusion: [PUBLIC],
    },
    initialLocationWaste: {
      exclusion: [PUBLIC],
    },
  },
  {
    addressConfirm: {
      inclusion: ['N'],
    },
    initialLocation: {
      exclusion: [PUBLIC],
    },
    initialLocationWaste: {
      exclusion: [PUBLIC],
    },
  },
]);

export { addressLookupInfoStep };
