import React from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { HeadingBlock } from 'yw-ui.v2';

const HeadingsBlock = ({ id, title, description, priority, size, answers, className }) => {
  const parsedTitle = validate.format(title, answers);
  const parsedDescription = validate.format(description, answers);

  return (
    <HeadingBlock
      size={size}
      priority={priority}
      id={id}
      qa={id}
      title={parsedTitle}
      description={parsedDescription}
      className={className}
    />
  );
};

HeadingsBlock.propTypes = {
  answers: PropTypes.object.isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  priority: PropTypes.number,
  size: PropTypes.string,
};

export default HeadingsBlock;
