import { Heading, Control, Step, ButtonRow } from '../../../models';
import { INTERNAL, PUBLIC, EXTERNAL, HOUSE, ODOUR, BATHROOM, KITCHEN } from '../../../../constants';
import { wasteOriginInternal } from '../../../../constants/Copy';

const headingBlock = new Heading('step-heading', wasteOriginInternal.heading);
const wasteOriginInternalControl = new Control('question', 'wasteOriginInternal');
wasteOriginInternalControl.setLabel(wasteOriginInternal.heading);
wasteOriginInternalControl.addOption(wasteOriginInternal.house, HOUSE, 'IconFloodHouseInternal');
wasteOriginInternalControl.addOption(wasteOriginInternal.odour, ODOUR, 'IconBadSmell');
wasteOriginInternalControl.addOption(wasteOriginInternal.bathroom, BATHROOM, 'IconInternalBlockage');
wasteOriginInternalControl.addOption(wasteOriginInternal.kitchen, KITCHEN, 'IconWashingMachine2');
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteOriginInternalStep = new Step('step', 'waste-origin-internal');
wasteOriginInternalStep.addBlock(headingBlock);
wasteOriginInternalStep.addBlock(wasteOriginInternalControl);
wasteOriginInternalStep.addBlock(buttonsBlock);
wasteOriginInternalStep.setTitle(wasteOriginInternal.title);

wasteOriginInternalStep.setConstraints({
  initialLocationWaste: {
    inclusion: [INTERNAL],
    exclusion: [EXTERNAL, PUBLIC],
  },
});

export { wasteOriginInternalStep };
