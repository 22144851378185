import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormRow } from 'yw-ui.v2';
import { MULTIPLE, SINGLE } from '../../../constants';

export class CountedCheckboxBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: this.props.options.map((option) => ({
        option: option,
        selected: false,
      })),
      count: null,
    };
  }

  onChange = (event, index) => {
    const newItems = Array.from(this.state.items);
    newItems[index].selected = event.target.checked;
    const selected = newItems.filter((x) => x.selected);
    var value = selected.map((x) => x.option.value).join(',');
    if (value === '') {
      value = undefined;
    }
    const count = selected.length === 1 ? SINGLE : MULTIPLE;
    this.setState({ items: newItems });
    this.setState({ count: count });
    this.props.form.handleChange(this.props.name, value);
    this.props.form.handleChange(this.props.count, count);
  };

  render() {
    return (
      <FormRow
        id="checkbox-block-row"
        qa="checkbox-block-row"
        name="field_name"
        valid={this.props.form.submitCount === 0 || this.state.count !== 0}
        condensed={true}
        messages={[this.props.error]}
        hideLabel={true}
        label=""
      >
        {this.props.options.map((option, index) => (
          <label className="c-checkbox" key={option.value}>
            <input
              id={option.value}
              name={option.value}
              type="checkbox"
              className="c-checkbox__input"
              value={this.state.items[index].selected}
              onChange={(event) => this.onChange(event, index)}
            />
            <span className="c-checkbox__checkmark" />
            {option.text}
          </label>
        ))}
      </FormRow>
    );
  }
}

CountedCheckboxBlock.propTypes = {
  form: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  count: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
};

export default CountedCheckboxBlock;
