import { Control, Heading, Step, ButtonRow, InfoBlock } from '../../models';
import { NOT_FIRST_FEED, FIRST_FEED, NO_WATER, PRESSURE } from '../../../constants';
import { nwlpFacilitiesAffected } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', nwlpFacilitiesAffected.heading);
const infoBlock = new InfoBlock('infoBlock', nwlpFacilitiesAffected.detail, 'c-message c-message--info');
const nwlpFacilitiesAffectedControl = new Control('question', 'nwlpFacilitiesAffected');
nwlpFacilitiesAffectedControl.setLabel(nwlpFacilitiesAffected.heading);
nwlpFacilitiesAffectedControl.addOption(nwlpFacilitiesAffected.firstFeed, FIRST_FEED, 'IconTap4');
nwlpFacilitiesAffectedControl.addOption(nwlpFacilitiesAffected.notFirstFeed, NOT_FIRST_FEED, 'IconTap5');
const buttonsBlock = new ButtonRow().hideContinueButton();

const nwlpFacilitiesAffectedStep = new Step('step', 'nwlp-facilities-affected');
nwlpFacilitiesAffectedStep.addBlock(headingBlock);
nwlpFacilitiesAffectedStep.addBlock(infoBlock);
nwlpFacilitiesAffectedStep.addBlock(nwlpFacilitiesAffectedControl);
nwlpFacilitiesAffectedStep.addBlock(buttonsBlock);
nwlpFacilitiesAffectedStep.setTitle(nwlpFacilitiesAffected.title);

nwlpFacilitiesAffectedStep.setConstraints({
  nwlpInitialProblem: {
    inclusion: [NO_WATER],
    exclusion: [PRESSURE],
  },
});

export { nwlpFacilitiesAffectedStep };
