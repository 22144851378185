import { Control, Heading, Step, ButtonRow } from '../../../models';
import {
  YES,
  NO,
  UNSURE,
  INSIDE,
  OUTSIDE,
  METER,
  PIPE_MEETS_METER,
  STOPTAP,
  FAST_DRIP,
  GUSHING,
  EXTERNAL,
  COVER,
  OVERFLOW_PIPE,
  GROUND,
  INTERNAL,
  PUBLIC,
  SLOW_DRIP,
  STEADY_FLOW,
} from '../../../../constants';
import { cleanMeterSeverityInt } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', cleanMeterSeverityInt.heading);
const cleanMeterSeverityIntControl = new Control('question', 'cleanMeterSeverityInt');
cleanMeterSeverityIntControl.setLabel(cleanMeterSeverityInt.heading);
cleanMeterSeverityIntControl.addOption(cleanMeterSeverityInt.slow, SLOW_DRIP, 'IconWater3', false, 'slow drip');
cleanMeterSeverityIntControl.addOption(cleanMeterSeverityInt.fast, FAST_DRIP, 'IconDripping', false, 'fast drip');
cleanMeterSeverityIntControl.addOption(
  cleanMeterSeverityInt.steady,
  STEADY_FLOW,
  'IconLowPressure',
  false,
  'steady flow',
);
cleanMeterSeverityIntControl.addOption(cleanMeterSeverityInt.gushing, GUSHING, 'IconGushing', false, GUSHING);
const buttonsBlock = new ButtonRow().hideContinueButton();

const cleanMeterSeverityIntStep = new Step('step', 'clean-meter-severity-int');
cleanMeterSeverityIntStep.addBlock(headingBlock);
cleanMeterSeverityIntStep.addBlock(cleanMeterSeverityIntControl);
cleanMeterSeverityIntStep.addBlock(buttonsBlock);
cleanMeterSeverityIntStep.setTitle(cleanMeterSeverityInt.title);

cleanMeterSeverityIntStep.setConstraints([
  {
    initialLocation: {
      inclusion: [INTERNAL],
      exclusion: [PUBLIC, EXTERNAL],
    },
    internalWaterOrigin: {
      presence: true,
      inclusion: [METER],
    },
    cleanMeterInternal: {
      inclusion: [METER, PIPE_MEETS_METER, UNSURE],
      exclusion: [STOPTAP],
    },
    cleanCondenConfirm: {
      inclusion: [YES],
    },
  },
  {
    cleanCondenConfirm: {
      inclusion: [YES],
    },
    cleanMeterInternal: {
      inclusion: [METER, PIPE_MEETS_METER, UNSURE],
      exclusion: [STOPTAP],
    },
    initialLocation: {
      inclusion: [EXTERNAL],
    },
    externalWaterOrigin: {
      presence: true,
      inclusion: [METER],
      exclusion: [COVER, OVERFLOW_PIPE, GROUND],
    },
    cleanMeterLeaking: {
      presence: true,
      inclusion: [YES],
      exclusion: [NO],
    },
    cleanMeterLocation: {
      presence: true,
      inclusion: [INSIDE],
      exclusion: [OUTSIDE],
    },
  },
]);

export { cleanMeterSeverityIntStep };
