function HeadingNoDiv(id, title, className, priority = 1) {
  this.id = id;
  this.priority = priority;
  this.size = 'lg';
  this.title = title;
  this.type = 'headingNoDiv';
  this.className = className;
}

HeadingNoDiv.prototype.setSize = function(size) {
  this.size = size;
  return this;
};

HeadingNoDiv.prototype.setPriority = function(priority) {
  this.priority = priority;
  return this;
};

HeadingNoDiv.prototype.setClassName = function(className) {
  this.className = className;
  return this;
};

HeadingNoDiv.prototype.setConstraints = function(constraints) {
  this.constraints = constraints;
  return this;
};

export default HeadingNoDiv;
