import { Heading, Step, ButtonRow, InfoBlock, Control, Banner } from '../../../models';
import { EXTERNAL, INTERNAL, PUBLIC, OTHER, NO, YES } from '../../../../constants';
import {
  internalInformation,
  privateIssueBanner,
  privateIssueQuestion,
  reviewForCoC,
} from '../../../../constants/Copy';

const banner = new Banner(privateIssueBanner);
const headingBlock = new Heading('step-heading', internalInformation.heading);
const infoBlock = new InfoBlock('info-block', internalInformation.detail[0], 'p-tb');
const headingBlock2 = new Heading('step-heading2', internalInformation.subHeading, 2).setSize('md');
const infoBlock2 = new InfoBlock('info-block2', internalInformation.detail[1]);
const infoBlock3 = new InfoBlock('info-block3', internalInformation.detail[2]);
const infoBlock4 = new InfoBlock('info-block4', internalInformation.detail[3]);
const infoBlock5 = new InfoBlock('info-block5', internalInformation.detail[4]);
const buttonsBlock = new ButtonRow();

const internalInformationColStep = new Step('step', 'internal-information-col');
internalInformationColStep.setIsContained(true);
internalInformationColStep.addBlock(new Control('hidden', 'internalInformationCol').setDefault(true));
internalInformationColStep.addBlock(
  new Control('hidden', 'internalInformationColTxt').setLabel(privateIssueQuestion).setDefault(reviewForCoC),
);
internalInformationColStep.addBlock(banner);
internalInformationColStep.addBlock(headingBlock);
internalInformationColStep.addBlock(infoBlock);
internalInformationColStep.addBlock(headingBlock2);
internalInformationColStep.addBlock(infoBlock2);
internalInformationColStep.addBlock(infoBlock3);
internalInformationColStep.addBlock(infoBlock4);
internalInformationColStep.addBlock(infoBlock5);
internalInformationColStep.addBlock(buttonsBlock);
internalInformationColStep.setTitle(internalInformation.title);

internalInformationColStep.setConstraints({
  initialLocation: {
    inclusion: [INTERNAL],
    exclusion: [PUBLIC, EXTERNAL],
  },
  internalWaterOrigin: {
    presence: true,
    inclusion: [OTHER],
  },
  isColleagueMode: {
    presence: true,
    inclusion: [YES],
    exclusion: [NO],
  },
});

export { internalInformationColStep };
