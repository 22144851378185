import { ButtonRow, Control, Heading, Step } from '../../models';
import {
  YES,
  NO,
  UNSURE,
  WASTE,
  CLEAN,
  INTERNAL,
  EXTERNAL,
  PUBLIC,
  GULLY,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  COVER,
  DRAIN,
  GROUND,
  ODOUR,
  POLLUTION,
} from '../../../constants';
import { boundaryFlowConstant } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', boundaryFlowConstant.heading);
const boundaryFlowConstantControl = new Control('question', 'boundaryFlowConstant');
boundaryFlowConstantControl.setLabel(boundaryFlowConstant.heading);
boundaryFlowConstantControl.addOption(boundaryFlowConstant.yes, YES, 'IconConstantPressure');
boundaryFlowConstantControl.addOption(boundaryFlowConstant.no, NO, 'IconCross');
boundaryFlowConstantControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const boundaryFlowConstantStep = new Step('step', 'boundary-flow-constant');
boundaryFlowConstantStep.addBlock(headingBlock);
boundaryFlowConstantStep.addBlock(boundaryFlowConstantControl);
boundaryFlowConstantStep.addBlock(buttonsBlock);
boundaryFlowConstantStep.setTitle(boundaryFlowConstant.title);

boundaryFlowConstantStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [COVER],
      exclusion: [DRAIN, GROUND, ODOUR],
    },
    wasteExternalCoverType: {
      inclusion: [OTHER],
      exclusion: [METER, SLUICE_VALVE, STOPTAP, GRATE, SQUARE],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [GROUND],
      exclusion: [DRAIN, COVER, ODOUR],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [INTERNAL, EXTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [COVER],
      exclusion: [ODOUR, POLLUTION, GROUND],
    },
    wasteExternalCover: {
      inclusion: [OTHER],
      exclusion: [GULLY, SQUARE, SLUICE_VALVE, STOPTAP, METER],
    },
    boundaryWaterType: {
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
    boundaryRainfallAffected: {
      inclusion: [UNSURE],
      exclusion: [YES],
    },
  },
]);

export { boundaryFlowConstantStep };
