import { ActionTypes } from '../constants';

export function SetMapHasDragged(bool) {
  return {
    type: ActionTypes.SET_CURRENT_LOCATION,
    mapHasDragged: bool,
  };
}

export function SetIsYorkshire(bool) {
  return {
    type: ActionTypes.SET_IS_YORKSHIRE,
    isYorkshire: bool,
  };
}
