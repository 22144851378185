import { ButtonRow, Control, Heading, Step } from '../../../models';
import { INTERNAL, WATER_SUPPLY, POOR_QUALITY } from '../../../../constants';
import { waterQualityIssue } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', waterQualityIssue.heading);
const waterQualityIssueControl = new Control('question', 'waterQualityIssue');
waterQualityIssueControl.setLabel(waterQualityIssue.heading);
waterQualityIssueControl.addOption(waterQualityIssue.discoloured, 'DISCOLOURED', 'IconDiscoloured');
waterQualityIssueControl.addOption(waterQualityIssue.smells, 'TASTE_OR_SMELL', 'IconBadSmell');
waterQualityIssueControl.addOption(waterQualityIssue.hardWater, 'HARD_WATER', 'IconHardWater');
const buttonsBlock = new ButtonRow().hideContinueButton();

const waterQualityIssueStep = new Step('step', 'water-quality');
waterQualityIssueStep.addBlock(headingBlock);
waterQualityIssueStep.addBlock(waterQualityIssueControl);
waterQualityIssueStep.addBlock(buttonsBlock);
waterQualityIssueStep.setTitle(waterQualityIssue.title);

waterQualityIssueStep.setConstraints({
  initialLocation: {
    presence: true,
    inclusion: [INTERNAL],
  },
  initialProblem: {
    presence: true,
    inclusion: [WATER_SUPPLY],
  },
  supplyIssue: {
    presence: true,
    inclusion: [POOR_QUALITY],
  },
});

export { waterQualityIssueStep };
