import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FluidField } from 'yw-ui.v2';

const PlacesListBlock = ({ answers, label, lookups, name, route, stepTo }) => {
  return (
    <Fragment>
      {lookups.mapSearch.results.length > 1 && (
        <p>
          Showing results for <strong>{answers.mapSearch}</strong>{' '}
          <button
            type="button"
            className="c-button--link u-ml--100"
            onClick={() => stepTo(route)}
            data-qa="search-again-link"
          >
            Search again
          </button>
          .
        </p>
      )}
      <FluidField
        id={name}
        qa={name}
        component="radio"
        name={name}
        label={label}
        options={lookups.mapSearch.results.map(({ place_id, formatted_address }) => ({
          id: place_id,
          label: formatted_address,
        }))}
        hideLabel={true}
        isScrollable={true}
      />
    </Fragment>
  );
};

PlacesListBlock.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  stepTo: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  lookups: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
};

export default PlacesListBlock;
