import { Control, Heading, Step, ButtonRow } from '../../models';
import {
  NOT_FIRST_FEED,
  HOT_ONLY,
  HOT_COLD,
  PRESSURE,
  NO_WATER,
  LOW_PRESSURE,
  HIGH_PRESSURE,
} from '../../../constants';
import { nwlpPressureSupply } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', nwlpPressureSupply.heading);
const nwlpPressureSupplyControl = new Control('question', 'nwlpPressureSupply');
nwlpPressureSupplyControl.setLabel(nwlpPressureSupply.heading);
nwlpPressureSupplyControl.addOption(nwlpPressureSupply.notFirstFeed, NOT_FIRST_FEED, 'IconOne');
nwlpPressureSupplyControl.addOption(nwlpPressureSupply.hotOnly, HOT_ONLY, 'IconTwo');
nwlpPressureSupplyControl.addOption(nwlpPressureSupply.hotCold, HOT_COLD, 'IconThree');
const buttonsBlock = new ButtonRow().hideContinueButton();

const nwlpPressureSupplyStep = new Step('step', 'nwlp-pressure-supply');
nwlpPressureSupplyStep.addBlock(headingBlock);
nwlpPressureSupplyStep.addBlock(nwlpPressureSupplyControl);
nwlpPressureSupplyStep.addBlock(buttonsBlock);
nwlpPressureSupplyStep.setTitle(nwlpPressureSupply.title);

nwlpPressureSupplyStep.setConstraints({
  nwlpInitialProblem: {
    inclusion: [PRESSURE],
    exclusion: [NO_WATER],
  },
  nwlpPressureProblem: {
    inclusion: [LOW_PRESSURE],
    exclusion: [HIGH_PRESSURE],
  },
});

export { nwlpPressureSupplyStep };
