import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormRow } from 'yw-ui.v2';

export class PrioritisedCheckboxBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: this.props.options.map((option) => ({
        option: option,
        selected: false,
      })),
      pathSelector: null,
    };
  }

  onChange = (event, index) => {
    const newItems = Array.from(this.state.items);
    newItems[index].selected = event.target.checked;
    const selected = newItems.filter((x) => x.selected);
    const sorted = selected.sort((a, b) => a.option.priority - b.option.priority);
    const pathSelector = sorted[0] ? sorted[0].option.value : null;
    this.setState({ items: newItems });
    this.setState({ pathSelector: pathSelector });
    this.props.form.handleChange(this.props.selector, pathSelector);
    this.props.form.handleChange(
      this.props.items,
      selected.map((x) => x.option.text),
    );
  };

  render() {
    return (
      <FormRow
        id="checkbox-block-row"
        qa="checkbox-block-row"
        name="field_name"
        valid={this.props.form.submitCount === 0 || this.state.pathSelector !== null}
        condensed={true}
        messages={['You need to select at least one option before continuing']}
        hideLabel={true}
        label=""
      >
        {this.props.options.map((option, index) => (
          <label className="c-checkbox" key={option.value}>
            <input
              id={option.value}
              name={option.value}
              type="checkbox"
              className="c-checkbox__input"
              value={this.state.items[index].selected}
              onChange={(event) => this.onChange(event, index)}
            />
            <span className="c-checkbox__checkmark" />
            {option.text}
          </label>
        ))}
      </FormRow>
    );
  }
}

PrioritisedCheckboxBlock.propTypes = {
  form: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  selector: PropTypes.string.isRequired,
  items: PropTypes.string.isRequired,
};

export default PrioritisedCheckboxBlock;
