import { Heading, Step, ButtonRow, InfoBlock, Link } from '../../../models';
import {
  MAIN_WEBSITE_URL,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  CHEMICAL,
  DRAIN,
  FARM,
  GAS,
  COVER,
  GROUND,
  HOUSE,
  ODOUR,
  POLLUTION,
  BATHROOM,
  KITCHEN,
  backToMainWebsite,
  wasteGasInfo,
  NO,
  YES,
} from '../../../../constants';

const headingBlock = new Heading('step-heading', wasteGasInfo.heading);
const infoBlock1 = new InfoBlock('info-block1', wasteGasInfo.info, 'p-tb');
const headingBlock2 = new Heading('step-heading2', wasteGasInfo.subHeading, 2).setSize('sm');
const infoBlock2 = new InfoBlock('info-block2', wasteGasInfo.bulletHead, 'u-pb--100');
const bullet1 = new InfoBlock('bullet-point1', wasteGasInfo.bullets[0], 'u-pt--0 u-pb--50 u-mb--0');
const bullet2 = new InfoBlock('bullet-point1', wasteGasInfo.bullets[1], 'u-pt--0 u-pb--50 u-mb--0');
const bullet3 = new InfoBlock('bullet-point1', wasteGasInfo.bullets[2], 'u-pt--0 u-pb--50 u-mb--0');
const linkBlock = new Link('main-website-link', backToMainWebsite).setLink(MAIN_WEBSITE_URL).setTarget('_self');
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteGasInfoStep = new Step('step', 'waste-gas-info');
wasteGasInfoStep.setIsContained(true);
wasteGasInfoStep.addBlock(headingBlock);
wasteGasInfoStep.addBlock(infoBlock1);
wasteGasInfoStep.addBlock(headingBlock2);
wasteGasInfoStep.addBlock(infoBlock2);
wasteGasInfoStep.addBlock(bullet1);
wasteGasInfoStep.addBlock(bullet2);
wasteGasInfoStep.addBlock(bullet3);
wasteGasInfoStep.addBlock(linkBlock);
wasteGasInfoStep.addBlock(buttonsBlock);
wasteGasInfoStep.setTitle(wasteGasInfo.title);

wasteGasInfoStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [ODOUR],
      exclusion: [HOUSE, BATHROOM, KITCHEN],
    },
    wasteOdourType: {
      inclusion: [GAS],
      exclusion: [CHEMICAL, FARM, DRAIN],
    },
    isColleagueMode: {
      presence: false,
      inclusion: [NO],
      exclusion: [YES],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [EXTERNAL],
      exclusion: [INTERNAL, PUBLIC],
    },
    wasteOriginBoundary: {
      inclusion: [ODOUR],
      exclusion: [COVER, GROUND, DRAIN],
    },
    wasteOdourType: {
      inclusion: [GAS],
      exclusion: [CHEMICAL, FARM, DRAIN],
    },
    isColleagueMode: {
      presence: false,
      inclusion: [NO],
      exclusion: [YES],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [ODOUR],
      exclusion: [POLLUTION, GROUND, COVER],
    },
    wasteOdourType: {
      inclusion: [GAS],
      exclusion: [CHEMICAL, FARM, DRAIN],
    },
    isColleagueMode: {
      presence: false,
      inclusion: [NO],
      exclusion: [YES],
    },
  },
]);

export { wasteGasInfoStep };
