import { ButtonRow, Control, Heading, Step } from '../../../models';
import { INTERNAL, WATER_SUPPLY, NO_WATER, LOW_PRESSURE, POOR_QUALITY } from '../../../../constants';
import { supplyIssue } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', supplyIssue.heading);
const supplyIssueControl = new Control('question', 'supplyIssue');
supplyIssueControl.setLabel(supplyIssue.heading);
supplyIssueControl.addOption(supplyIssue.noWater, NO_WATER, 'IconDisabled');
supplyIssueControl.addOption(supplyIssue.lowPressure, LOW_PRESSURE, 'IconLowPressure');
supplyIssueControl.addOption(supplyIssue.poorQuality, POOR_QUALITY, 'IconDiscoloured');
const buttonsBlock = new ButtonRow().hideContinueButton();

const supplyIssueStep = new Step('step', 'supply-issue');
supplyIssueStep.addBlock(headingBlock);
supplyIssueStep.addBlock(supplyIssueControl);
supplyIssueStep.addBlock(buttonsBlock);
supplyIssueStep.setTitle(supplyIssue.title);

supplyIssueStep.setConstraints({
  initialLocation: {
    presence: true,
    inclusion: [INTERNAL],
  },
  initialProblem: {
    presence: true,
    inclusion: [WATER_SUPPLY],
  },
});

export { supplyIssueStep };
