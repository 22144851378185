import { Control, Heading, Step, ButtonRow } from '../../../models';
import { INTERNAL, EXTERNAL, PUBLIC } from '../../../../constants';
import { initialLocation } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', initialLocation.heading);
const initialLocationControl = new Control('question', 'initialLocation');
initialLocationControl.setLabel(initialLocation.heading);
initialLocationControl.addOption(initialLocation.internal, INTERNAL, 'IconFloodHouseInternal');
initialLocationControl.addOption(initialLocation.external, EXTERNAL, 'IconFloodHouseExternal');
initialLocationControl.addOption(initialLocation.public, PUBLIC, 'IconPublicArea');
const buttonsBlock = new ButtonRow().hideContinueButton();

const initialLocationStep = new Step('step', 'initial-location');
initialLocationStep.addBlock(headingBlock);
initialLocationStep.addBlock(initialLocationControl);
initialLocationStep.addBlock(buttonsBlock);
initialLocationStep.setTitle(initialLocation.title);

initialLocationStep.setConstraints({
  isWasteInitial: {
    exclusion: [true],
  },
  isCleanInitial: {
    inclusion: [true],
  },
  isNwlpInitial: {
    exclusion: [true],
  },
});

export { initialLocationStep };
