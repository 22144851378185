import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  LOCATION_METHOD_ADDRESS_LOOKUP,
  LOCATION_METHOD_CURRENT_LOCATION,
  LOCATION_METHOD_MAP_SEARCH,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  COVER,
  GROUND,
  GRATE,
  PRIVATE,
  UNSURE,
  YORKSHIREWATER,
  ODOUR,
  POLLUTION,
} from '../../../../constants';
import { wasteOriginPollution } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteOriginPollution.heading);
const wasteOriginPollutionControl = new Control('question', 'wasteOriginPollution');
wasteOriginPollutionControl.setLabel(wasteOriginPollution.heading);
wasteOriginPollutionControl.addOption(wasteOriginPollution.grate, GRATE, 'IconConstantPressure');
wasteOriginPollutionControl.addOption(wasteOriginPollution.private, PRIVATE, 'IconDisabled');
wasteOriginPollutionControl.addOption(wasteOriginPollution.ywSite, YORKSHIREWATER, 'IconDripping');
wasteOriginPollutionControl.addOption(wasteOriginPollution.unsure, UNSURE, 'IconQuestion');
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteOriginPollutionStep = new Step('step', 'waste-origin-pollution');
wasteOriginPollutionStep.addBlock(headingBlock);
wasteOriginPollutionStep.addBlock(wasteOriginPollutionControl);
wasteOriginPollutionStep.addBlock(buttonsBlock);
wasteOriginPollutionStep.setTitle(wasteOriginPollution.title);

wasteOriginPollutionStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [POLLUTION],
      exclusion: [GROUND, ODOUR, COVER],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_CURRENT_LOCATION, LOCATION_METHOD_MAP_SEARCH],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    wasteExternalOrigin: {
      inclusion: [POLLUTION],
      exclusion: [GROUND, ODOUR, COVER],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP],
    },
    addressConfirm: {
      presence: true,
      inclusion: ['N'],
    },
  },
]);

export { wasteOriginPollutionStep };
