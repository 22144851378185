import { ButtonRow, Control, Heading, InfoBlock, Link, Step } from '../../../models';

import { INTERNAL, PLUMBING, NO, LOCATION_METHOD_ADDRESS_LOOKUP, LOCAL_PLUMBER_URL } from '../../../../constants';

/* :: BLOCKS :: */

/* BLOCK: Step heading */
const headingBlock = new Heading('step-title', 'This looks like a private issue');

/* BLOCK: Hidden field */
const privateShutoff = new Control('hidden', 'requireShutoff');
privateShutoff.setDefault('Y');

/* BLOCK: Plumber heading */
const privateInfoHeading1 = new Heading('need-plumber-heading', 'You may need a plumber', 2);
privateInfoHeading1.setSize('md');

/* BLOCK: Plumber info */
const privateInfoBlock1 = new InfoBlock(
  'need-plumber-block',
  'From the answers you have given, it looks like the problem can be solved by a local plumber.\nWaterSafe is a free online directory and national accreditation body for competent and qualified plumbers.',
);

/* BLOCK: Plumber link: Address Lookup */
const privateInfoLink1 = new Link('need-plumber-link', 'Find an approved plumber near %{postcode}');
privateInfoLink1.setLink(`${LOCAL_PLUMBER_URL}/search/?q=%{postcode}`); //TODO: Unused page?
privateInfoLink1.setConstraints({
  postcode: { presence: true },
  locateMethod: { inclusion: [LOCATION_METHOD_ADDRESS_LOOKUP] },
});

/* BLOCK: New window info */
const privateInfoBlock2 = new InfoBlock('new-window-block', 'The above link will take you to the WaterSafe website.');

/* BLOCK: Shutoff heading */
const switchOffHeading = new Heading('next-steps-block', 'Do you need us to switch off your water?', 2);
switchOffHeading.setSize('md');

/* BLOCK: Buttons */
const buttonsBlock = new ButtonRow();
buttonsBlock.setContinueButtonState('primary');
buttonsBlock.setContinueButtonText('Yes I do');

/* :: STEP: PRIVATE PROBLEM :: */
const privateProblemInfoStep = new Step('step', 'private-problem');
privateProblemInfoStep.setConstraints({
  initialLocation: {
    presence: true,
    inclusion: [INTERNAL],
  },
  waterOrigin: {
    presence: true,
    inclusion: [PLUMBING],
  },
  waterFromCover: {
    exclusion: [NO],
  },
});

privateProblemInfoStep.addBlock(headingBlock);
privateProblemInfoStep.addBlock(privateShutoff);
privateProblemInfoStep.addBlock(privateInfoHeading1);
privateProblemInfoStep.addBlock(privateInfoBlock1);
privateProblemInfoStep.addBlock(privateInfoLink1);
privateProblemInfoStep.addBlock(privateInfoBlock2);
privateProblemInfoStep.addBlock(switchOffHeading);
privateProblemInfoStep.addBlock(buttonsBlock);
privateProblemInfoStep.setIsContained(true);
privateProblemInfoStep.setTitle('Private problem');

export { privateProblemInfoStep };
