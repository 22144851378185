import Service from './service';
import { API_ENDPOINT_INCIDENTS } from '../constants';

const getIncidents = (lookupValues, successHandler, errorHandler) => {
  const latitude = lookupValues.mapResult
    ? lookupValues.mapResult.lat
    : lookupValues.hooks.lookups.externalMap
    ? lookupValues.hooks.lookups.externalMap.lat
    : null;
  const longitude = lookupValues.mapResult
    ? lookupValues.mapResult.lng
    : lookupValues.hooks.lookups.externalMap
    ? lookupValues.hooks.lookups.externalMap.lng
    : null;

  const websiteIssueCategoryCodeParam = lookupValues.isWasteInitial ? '' : '&websiteIssueCategoryCode=LeakFlood';
  const incidentType = lookupValues.isWasteInitial ? 'Waste' : 'Clean';
  const url = `${API_ENDPOINT_INCIDENTS}?latitude=${latitude}&longitude=${longitude}${websiteIssueCategoryCodeParam}&incidentType=${incidentType}&radius=300`;
  return Service.get(url)
    .then((resp) => {
      if (resp.data.length === 0) {
        successHandler([]);
      } else {
        successHandler(resp.data);
      }
    })
    .catch((error) => {
      successHandler([]);
      errorHandler(error);
    });
};

const incidentsApi = { getIncidents };

export default incidentsApi;
