import Service from './service';
import { API_ENDPOINT_ATTACHMENTS, ATTACHMENT_POLICY_ID } from '../constants';
/*
uploadAttachment

Use the attachments api to upload images

file - A pointer to the file
successHandler - Function to call on successful response
errorHandler - Function to call on erroneous response

*/
const uploadAttachment = (file, successHandler, errorHandler) => {
  const formData = new FormData();
  formData.append('file', file);

  return Service.post(`${API_ENDPOINT_ATTACHMENTS}?policyId=${ATTACHMENT_POLICY_ID}`, formData)
    .then(successHandler)
    .catch(errorHandler);
};

const attachmentsApi = { uploadAttachment };

export default attachmentsApi;
