import * as R from 'ramda';
import { sprintf } from 'sprintf-js';

import Service from './service';
import validationService from '../api/validation';
import {
  ALPHABET,
  WASTE_BOUNDARY_ESCAPE_LOCATION_QUESTION,
  API_ENDPOINT_PROBLEMS,
  BLOCK_QUESTION_TYPES,
  GUSHING,
  ABOVE_GROUND_STOP_TAP,
  COVER,
  GROUND,
  METER,
  PLUMBING,
  RUNNING_QUICKLY,
  SUMMARY_CLEAN_SEVERITY_TEMPLATE,
  SUMMARY_FROM_TEMPLATE,
  SUMMARY_PROPOSITION_TEMPLATE,
  TRICKLING,
  Option_NA,
} from '../constants';
import outcomeConfig from '../data/outcomes';

/*
postOutcome

Make a http POST to the problem api given the values

problemData - An object containing the value bag of answers
successHandler -Function to call on successful response
errorHandler -Function to call on erroneous response

*/

const getRangeFromStartTime = (slots, startTime) => {
  let output;
  if (slots && startTime) {
    slots.forEach(({ timeSlots }) => {
      timeSlots.forEach((timeSlot) => {
        if (timeSlot.time === startTime) {
          output = timeSlot.range;
        }
      });
    });
  }
  return output;
};

const postOutcome = (valueBag, successHandler, errorHandler, uniquePath) => {
  const lens = R.lensPath(['hooks', 'lookups', 'callbacks']);
  const lensVal = R.view(lens, valueBag);
  let timeSlotRange;
  if (lensVal) {
    timeSlotRange = getRangeFromStartTime(lensVal, valueBag.appointmentSlot);
  }

  let desc = '';
  if (valueBag.mapResult) {
    desc = valueBag.mapResult.locationDetails;
  } else if (valueBag.locationDetails) {
    desc = valueBag.locationDetails;
  }

  let postcode = '';
  if (valueBag.mapResult) {
    postcode = valueBag.mapResult.postcode;
  } else if (valueBag.postcode) {
    postcode = valueBag.postcode;
  }

  const { status } = valueBag;
  if (valueBag.wasteBoundaryEscapeLocations) {
    valueBag.wasteBoundaryEscapeLocations.forEach((item, index) => {
      status.userResponses[`${WASTE_BOUNDARY_ESCAPE_LOCATION_QUESTION} ${ALPHABET[index]})`] = item;
    });
  }
  const sentimentReportOutcome = valueBag.SentimentReportOutcome !== Option_NA ? valueBag.SentimentReportOutcome : null;
  const sentimentReportOutcome2ndLevel =
    valueBag.SentimentReportOutcome2ndLevel !== Option_NA ? valueBag.SentimentReportOutcome2ndLevel : null;
  const sentimentLogComplaint = valueBag.SentimentLogComplaint !== Option_NA ? valueBag.SentimentLogComplaint : null;
  const sentimentCustomerUsedComplaintWording =
    valueBag.SentimentCustomerUsedComplaintWording !== Option_NA
      ? valueBag.SentimentCustomerUsedComplaintWording
      : null;
  const additionalComments = valueBag.additionalCommentsText || null;
  const colleagueToken = valueBag.colleagueToken || null;

  delete status.userResponses['Which location should we use?'];
  return Service.post(`${API_ENDPOINT_PROBLEMS}`, {
    contactDetails: {
      firstName: valueBag.firstName || null,
      lastName: valueBag.lastName || null,
      phoneNumber: valueBag.telephone || null,
      emailAddress: valueBag.email || null,
      isAnonymous: status.isAnonymous,
    },
    attachments: valueBag.attachments.files
      ? valueBag.attachments.files
          .filter((attachment) => !attachment.hasErrored)
          .map((attachment) => ({ attachmentId: attachment.id.toString(), isVideo: attachment.isVideo }))
      : [],
    location: {
      postcode: postcode && postcode.replace(' ', ''),
      propertyReference: valueBag.addressConfirm || '',
      latLongCoordinates:
        valueBag.mapResult && (valueBag.mapResult.lat || valueBag.mapResult.lng)
          ? {
              latitude: Math.round(valueBag.mapResult.lat * 1000000) / 1000000,
              longitude: Math.round(valueBag.mapResult.lng * 1000000) / 1000000,
            }
          : null,
      locationDescription: desc,
      UtmCoordinates: valueBag.utmLocation
        ? {
            easting: valueBag.utmLocation.easting,
            northing: valueBag.utmLocation.northing,
          }
        : null,
    },
    channel: 'tellus',
    detail: {
      problemType: status.group || null,
      location: status.location || null,
      isSevere: status.isSevere,
      hasCallback: status.hasCallback,
      hasShutoff: status.hasShutoff,
      callbackInfo: valueBag.appointmentSlot
        ? {
            immediateCallback: false,
            slot: timeSlotRange ? { from: timeSlotRange.from, to: timeSlotRange.to } : null,
            reasonId: status.reasonId,
          }
        : null,
      userResponses: status.userResponses,
      JourneyCode: uniquePath,
    },
    ReCaptchaResponse: valueBag.recaptchaCode || null,
    AdditionalComment: additionalComments,
    ColleagueToken: colleagueToken,
    SentimentReportOutcome: sentimentReportOutcome,
    SentimentReportOutcome2ndLevel: sentimentReportOutcome2ndLevel,
    SentimentLogComplaint: sentimentLogComplaint,
    SentimentCustomerUsedComplaintWording: sentimentCustomerUsedComplaintWording,
  })
    .then((response) => {
      successHandler(response);
    })
    .catch((error) => {
      errorHandler(error);
    });
};

/*
reduceOutcomesValues

Take and outcome object and reduce it list of possible values to identify a matching values

accumulator - object to build up with correct outcome names as keys and reduced value from list of possible values
possibleValue - Single possibleValue object

*/
export const reduceOutcomesValues = (accumulator, possibleValue, currentAnswers) => {
  let constraintArr = Array.isArray(possibleValue.constraints)
    ? possibleValue.constraints
    : [possibleValue.constraints];
  let hasPassed = false;

  constraintArr.forEach((constraints) => {
    if (!validationService.validate(currentAnswers, constraints)) {
      hasPassed = true;
    }
  });

  return hasPassed ? [...accumulator, possibleValue.value] : accumulator;
};

/*
reduceOutcomes

Take and outcome object and reduce it list of possible values to identify a matching values

accumulator - object to build up with correct outcome names as keys and reduced value from list of possible values
outcome -Single outcome object

*/
export const reduceOutcomes = (accumulator, outcome, currentAnswers) => {
  const matchingValues = outcome.possibleValues.reduce(
    (acc, val) => reduceOutcomesValues(acc, val, currentAnswers),
    [],
  );
  accumulator[outcome.name] = matchingValues.length ? matchingValues[0] : outcome.default;
  return accumulator;
};

/*
fetchOutcomeSummary

Takes a list of answers and list of steps and summarises the derived problem classifying into a broad location, a broad group, isSevere, hasCallback

currentAnswers - Object containing current value bag
currentSteps - Array of all step objects currently possible
successHandler -A callback fro successful lookup
errorHandler - A callback for failure to derive problem

*/
export const fetchOutcomeSummary = (currentAnswers, currentSteps, successHandler, errorHandler) => {
  const outcomes = outcomeConfig.reduce((acc, out) => reduceOutcomes(acc, out, currentAnswers), {});
  const problemReadableString = evaluateReadableProblem(currentAnswers, outcomes.group);
  const userResponses = evaluateUserResponses(currentSteps, currentAnswers);

  let problemResponse = {
    summary: problemReadableString,
    userResponses,
    ...outcomes,
  };
  return new Promise(function(resolve) {
    setTimeout(() => resolve(problemResponse), 1); // mock delay until async source
  })
    .then(successHandler)
    .catch(errorHandler);
};

export const evaluateUserResponses = (currentSteps, currentAnswers) => {
  const flatQuestions = currentSteps.reduce((questionAcc, step) => {
    const thisStepBlocks = step.blocks;
    if (!thisStepBlocks) {
      return questionAcc;
    }

    const questions = thisStepBlocks.reduce((blockAcc, block) => {
      const currentAnswer = currentAnswers[block.name];

      if (currentAnswer && block.label) {
        if (BLOCK_QUESTION_TYPES.includes(block.type)) {
          if (!block.options) {
            blockAcc[block.label] = currentAnswer;
          } else {
            const matchedOptions = block.options
              .filter((option) => {
                return option.value === currentAnswer || currentAnswer.includes(option.value);
              })
              .map((option) => option.label);

            if (matchedOptions.length) {
              blockAcc[block.label] = matchedOptions.join(', ');
            }
          }
        }
      }

      return blockAcc;
    }, {});

    return { ...questionAcc, ...questions };
  }, {});

  return flatQuestions;
};

/*
evaluateReadableProblem

Takes the current answers and reduces that a single readable sentence or paragraph

currentAnswers - Object containing current value bag

*/
export const evaluateReadableProblem = (currentAnswers, problemGroup) => {
  const problemGroupString = summariseProblemGroup(problemGroup);
  const origin = summariseOrigin(currentAnswers.coverType ? COVER : currentAnswers.waterOrigin);
  const cleanSeverity = summariseCleanSeverity(currentAnswers.cleanSeverity);

  let summaryResponse = '';

  if (problemGroupString) {
    summaryResponse = sprintf(SUMMARY_PROPOSITION_TEMPLATE, problemGroupString);
  }

  if (origin) {
    summaryResponse += sprintf(SUMMARY_FROM_TEMPLATE, origin);
  }

  if (cleanSeverity) {
    summaryResponse += sprintf(SUMMARY_CLEAN_SEVERITY_TEMPLATE, cleanSeverity);
    summaryResponse += `.`;
  }

  return summaryResponse;
};

/*
summariseProblemGroup

Takes a string representing the derived problem group and converts this to a readable string

group - String representing the problem group generated in evaluateProblemGroup

*/
const summariseProblemGroup = (group) => {
  switch (group) {
    case 'WASTE':
      return 'A sewage flood';
    case 'CLEAN':
      return 'A clean water leak';
    case 'METER':
      return 'A clean water leak';
    case 'POLLUTION':
      return 'A water pollution incident';
    default:
      return 'A leak or flood';
  }
};

const summariseOrigin = (origin) => {
  switch (origin) {
    case METER:
      return 'a water meter';
    case PLUMBING:
      return 'a plumbing fixture';
    case COVER:
      return 'a cover';
    case GROUND:
      return 'the ground';
    case ABOVE_GROUND_STOP_TAP:
      return 'an above ground stop tap';
    default:
      return null;
  }
};

const summariseCleanSeverity = (severity) => {
  switch (severity) {
    case GUSHING:
      return GUSHING;
    case RUNNING_QUICKLY:
      return 'running quickly';
    case TRICKLING:
      return 'not gushing';
    default:
      return null;
  }
};

const outcomeApi = { postOutcome, fetchOutcomeSummary };

export default outcomeApi;
