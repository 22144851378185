import { Control, Heading, Step, ButtonRow } from '../../models';
import { PRESSURE, NO_WATER } from '../../../constants';
import { nwlpInitialProblem } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', nwlpInitialProblem.heading);
const nwlpInitialProblemControl = new Control('question', 'nwlpInitialProblem');
nwlpInitialProblemControl.setLabel(nwlpInitialProblem.heading);
nwlpInitialProblemControl.addOption(nwlpInitialProblem.pressure, PRESSURE, 'IconLowPressure');
nwlpInitialProblemControl.addOption(nwlpInitialProblem.noWater, NO_WATER, 'IconCross');
const buttonsBlock = new ButtonRow().hideContinueButton();

const nwlpInitialProblemStep = new Step('step', 'nwlp-initial-problem');
nwlpInitialProblemStep.addBlock(headingBlock);
nwlpInitialProblemStep.addBlock(nwlpInitialProblemControl);
nwlpInitialProblemStep.addBlock(buttonsBlock);
nwlpInitialProblemStep.setTitle(nwlpInitialProblem.title);

nwlpInitialProblemStep.setConstraints({
  isWasteInitial: {
    exclusion: [true],
  },
  isCleanInitial: {
    exclusion: [true],
  },
  isNwlpInitial: {
    inclusion: [true],
  },
});

export { nwlpInitialProblemStep };
