import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Pill } from 'yw-ui.v2';
import { NavBarProfile, NavBarSeparator, Icon } from 'yw-ui.v2/dist/components';
import tokenApi from '../../../api/token';
import { COLLEAGUE_MODE, UNKNOWN_COLLEAGUE } from '../../../constants';

class ColleagueModeHeaderBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: UNKNOWN_COLLEAGUE,
    };
  }

  initials = (name) => {
    if (name) {
      const nameArray = name.split(' ');
      if (nameArray.length === 2) {
        return `${nameArray[0][0]}${nameArray[1][0]}`;
      }
    }
    return name;
  };

  async componentDidMount() {
    var result = await tokenApi.getToken(window.sessionStorage.getItem('token'));
    this.setState({ account: result.colleagueName });
  }

  render() {
    return (
      <div className="colleague-mode-header">
        <Pill
          id="colleague-mode-pill"
          className="colleague-mode-pill"
          data-testid="colleague-mode-pill"
          size="md"
          backgroundColour="ywButtercupYellow"
          textColour="ywDark"
          qa="colleague-mode-pill"
        >
          <span>
            <Icon icon="IconAlertTriangle" colour="ywNightBlue" className="colleague-mode-pill-icon" size="200" />
          </span>
          <span className="colleague-mode-pill-text">{COLLEAGUE_MODE}</span>
        </Pill>

        <span className="colleague-mode-profile">
          <NavBarSeparator id="colleague-mode-separator" qa="colleague-mode-separator" />
          <NavBarProfile
            key="accountnav"
            data-testid="navbar-profile_sample-NavBarProfile"
            displayName={this.state.account}
            initials={this.initials(this.state.account)}
            id="sample-NavBarProfile"
            qa="sample-NavBarProfile"
            theme="ywLight"
          />
        </span>
      </div>
    );
  }
}

ColleagueModeHeaderBlock.propTypes = {
  form: PropTypes.object,
};

export default ColleagueModeHeaderBlock;
