import { ButtonRow, Control, Heading, Hook, Step } from '../../../models';
import {
  APPLIANCE,
  BATHROOM,
  CELLAR,
  DOWNSTAIRS_BATH,
  DOWNSTAIRS_FACILITIES,
  DOWNSTAIRS_TOILET,
  EXTERNAL,
  FLOOR,
  GRATE,
  HOUSE,
  INTERNAL,
  KITCHEN,
  MANHOLE,
  MANY,
  MULTIPLE_TOILETS,
  NO,
  NONE,
  ODOUR,
  ONCE,
  PUBLIC,
  SINK,
  STEP_ACCESS_HOURS_LOOKUP,
  TOILET,
  UPSTAIRS_BATH,
  UPSTAIRS_FACILITIES,
  UPSTAIRS_TOILET,
} from '../../../../constants';
import { wasteInternalToilet } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteInternalToilet.heading);
const wasteInternalToiletControl = new Control('question', 'wasteInternalToilet');
wasteInternalToiletControl.setLabel(wasteInternalToilet.heading);
wasteInternalToiletControl.setDefault('');
wasteInternalToiletControl.addOption(wasteInternalToilet.once, ONCE, 'IconToilet');
wasteInternalToiletControl.addOption(wasteInternalToilet.none, NONE, 'IconToilet');
wasteInternalToiletControl.addOption(wasteInternalToilet.many, MANY, 'IconToilet');
wasteInternalToiletControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteInternalToiletStep = new Step('step', 'waste-internal-toilet');
wasteInternalToiletStep.addBlock(headingBlock);
wasteInternalToiletStep.addBlock(wasteInternalToiletControl);
wasteInternalToiletStep.addBlock(buttonsBlock);
wasteInternalToiletStep.exitHook = new Hook(STEP_ACCESS_HOURS_LOOKUP, '');
wasteInternalToiletStep.setTitle(wasteInternalToilet.title);

wasteInternalToiletStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [UPSTAIRS_TOILET],
      exclusion: [
        UPSTAIRS_BATH,
        DOWNSTAIRS_BATH,
        MULTIPLE_TOILETS,
        DOWNSTAIRS_TOILET,
        UPSTAIRS_FACILITIES,
        DOWNSTAIRS_FACILITIES,
      ],
    },
    wasteInternalExtother: {
      inclusion: [GRATE, MANHOLE, ODOUR],
      exclusion: [NO],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
    wasteInternalFclty1: {
      inclusion: [MULTIPLE_TOILETS, DOWNSTAIRS_TOILET],
      exclusion: [UPSTAIRS_BATH, UPSTAIRS_TOILET, DOWNSTAIRS_BATH, UPSTAIRS_FACILITIES, DOWNSTAIRS_FACILITIES],
    },
  },
]);

export { wasteInternalToiletStep };
