import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  YES,
  UNSURE,
  WASTE,
  CLEAN,
  METER,
  OTHER,
  SLUICE_VALVE,
  STOPTAP,
  GRATE,
  SQUARE,
  LOCATION_METHOD_CURRENT_LOCATION,
  LOCATION_METHOD_MAP_SEARCH,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  ABOVE_GROUND_STOP_TAP,
  COVER,
  GROUND,
} from '../../../../constants';
import { rainfallAffected } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', rainfallAffected.heading);
const rainAffectedControl = new Control('question', 'rainfallAffected');
rainAffectedControl.setLabel(rainfallAffected.heading);
rainAffectedControl.addOption(rainfallAffected.yes, YES, 'IconRain');
rainAffectedControl.addOption(rainfallAffected.unsure, UNSURE, 'IconCross');
rainAffectedControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const rainfallAffectedStep = new Step('step', 'rainfall-affected');
rainfallAffectedStep.addBlock(headingBlock);
rainfallAffectedStep.addBlock(rainAffectedControl);
rainfallAffectedStep.addBlock(buttonsBlock);
rainfallAffectedStep.setTitle(rainfallAffected.title);

rainfallAffectedStep.setConstraints([
  {
    waterType: {
      presence: true,
      inclusion: [UNSURE],
    },
  },
  {
    initialLocation: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_MAP_SEARCH, LOCATION_METHOD_CURRENT_LOCATION],
    },
    waterOrigin: {
      inclusion: [GROUND, OTHER],
      exclusion: [COVER, ABOVE_GROUND_STOP_TAP],
    },
    externalWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
  },
  {
    initialLocation: {
      inclusion: [PUBLIC],
      exclusion: [EXTERNAL, INTERNAL],
    },
    locateMethod: {
      inclusion: [LOCATION_METHOD_MAP_SEARCH, LOCATION_METHOD_CURRENT_LOCATION],
    },
    waterOrigin: {
      inclusion: [COVER],
      exclusion: [ABOVE_GROUND_STOP_TAP, GROUND, OTHER],
    },
    coverType: {
      inclusion: [OTHER],
      exclusion: [METER, SLUICE_VALVE, STOPTAP, GRATE, SQUARE],
    },
    externalWaterType: {
      presence: true,
      inclusion: [UNSURE],
      exclusion: [CLEAN, WASTE],
    },
  },
]);

export { rainfallAffectedStep };
