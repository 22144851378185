import { Heading, Control, Step, ButtonRow, InfoBlock } from '../../../models';
import { EXTERNAL, PUBLIC, INTERNAL, GROUND, YES, NO } from '../../../../constants';
import { supplyPipeEligibility } from '../../../../constants/Copy';

const headingBlock = new Heading('step-heading', supplyPipeEligibility.heading);
const infoBlock1 = new InfoBlock('info-block1', supplyPipeEligibility.detail, 'p-tb');
const headingBlock2 = new Heading('step-heading2', supplyPipeEligibility.subHeading1, 2).setSize('md');
const headingInfoBlock1 = new InfoBlock('heading-info1', supplyPipeEligibility.mayQualify);
const infoBlock2 = new InfoBlock('bullet-point-info2', supplyPipeEligibility.may, 'm-l');
const headingInfoBlock2 = new InfoBlock('heading-info2', supplyPipeEligibility.dontQualify);
const infoBlock3 = new InfoBlock('bullet-point-info3', supplyPipeEligibility.dont[0], 'm-l');
const infoBlock4 = new InfoBlock('bullet-point-info4', supplyPipeEligibility.dont[1], 'm-l');
const infoBlock5 = new InfoBlock('bullet-point-info5', supplyPipeEligibility.dont[2], 'm-l p-b');
const headingBlock5 = new Heading('step-heading5', supplyPipeEligibility.subHeading2, 2).setSize('md');
const radio = new Control('radio', 'eligibility')
  .setLabel(supplyPipeEligibility.subHeading2)
  .labelOff()
  .addOption(supplyPipeEligibility.no, NO, null)
  .addOption(supplyPipeEligibility.yes, YES, null)
  .setDefault(NO);
const buttonsBlock = new ButtonRow();

const supplyPipeEligibilityStep = new Step('step', 'clean-eligibility-boundary');
supplyPipeEligibilityStep.setIsContained(true);
supplyPipeEligibilityStep.addBlock(headingBlock);
supplyPipeEligibilityStep.addBlock(infoBlock1);
supplyPipeEligibilityStep.addBlock(headingBlock2);
supplyPipeEligibilityStep.addBlock(headingInfoBlock1);
supplyPipeEligibilityStep.addBlock(infoBlock2);
supplyPipeEligibilityStep.addBlock(headingInfoBlock2);
supplyPipeEligibilityStep.addBlock(infoBlock3);
supplyPipeEligibilityStep.addBlock(infoBlock4);
supplyPipeEligibilityStep.addBlock(infoBlock5);
supplyPipeEligibilityStep.addBlock(headingBlock5);
supplyPipeEligibilityStep.addBlock(radio);
supplyPipeEligibilityStep.addBlock(buttonsBlock);
supplyPipeEligibilityStep.setTitle(supplyPipeEligibility.title);

supplyPipeEligibilityStep.setConstraints({
  initialLocation: {
    inclusion: [EXTERNAL],
    exclusion: [PUBLIC, INTERNAL],
  },
  externalWaterOrigin: {
    inclusion: [GROUND],
  },
});

export { supplyPipeEligibilityStep };
