import { push, replace } from 'connected-react-router';
import { GA_FORM_START, GA_FORM_STEP, journeyMapping } from '../constants/Settings';
import { ActionTypes, DOCUMENT_TITLE, TITLE_DIVIDER } from '../constants';
import stepsApi from '../api/steps';
import googleAnalytics from '../api/googleAnalytics';
import { evaluateOutcomeSummary } from './OutcomeAction';
import {
  addressLookup,
  incidentLookup,
  geocodeLocation,
  checkMapLocation,
  callbacksLookup,
  stepSubmission,
  workingHoursLookup,
  noWaterEnabledLookup,
  accessHoursLookup,
} from './HookActions';

/*
loadConfig

Fetch the config that will drive the step system and reset the url to the base

*/
export function loadConfig(source) {
  return (dispatch) => {
    // mark config as loading
    dispatch(configIsLoading(true));

    // reset route to first step
    dispatch(replace('/'));

    // fetch steps from config
    return stepsApi.fetchSteps(
      (config) => {
        // set config in store
        dispatch(setConfig(config));

        // switch off config loading
        dispatch(configIsLoading(false));
      },
      (error) => {
        // switch off config loading flag
        dispatch(configIsLoading(false));

        // store error from config api
        dispatch(configHasErrored(true, error));
      },
      source,
    );
  };
}

/*
loadStep

Given a slug this action gets a step from config that matches the slug.
It is not validated against the constraints as this is done on moving forward.
The steps blocks are revalidated against the new questions set.

slug - the url portion that represents the step to be loaded

*/
export function loadStep(slug) {
  return (dispatch, getState) => {
    const {
      attachments,
      outcome: { status },
      hooks,
      locations,
      steps: { initialSteps, answers, isEditing, validStep },
    } = getState();

    // make sure answers are up to date with newly submitted values
    let valueBag = { ...answers, hooks, status, attachments, locations };

    // Check for step with slug matching current slug
    let newStep = initialSteps.filter((step) => step.slug === slug)[0] || initialSteps[0];

    // Revalidate blocks within step against current answers
    const validBlocks = newStep ? stepsApi.getValidBlocks(newStep.blocks, { ...valueBag, isEditing }) : [];

    // re-assemble current step with newly validated blocks
    let validatedStep = { ...newStep, blocks: validBlocks };
    const isReloading = validatedStep && validStep && validatedStep.slug === validStep.slug;

    if (!isEditing && !isReloading) {
      const newAnswers = stepsApi.removeForwardClearableAnswers(answers, initialSteps, slug);
      dispatch(setAnswers({ ...newAnswers }));

      if (validatedStep.entryHook) {
        dispatch(
          performLookup(validatedStep.entryHook, valueBag, function(response, additionalValues) {
            if (additionalValues) {
              const newAnswers = stepsApi.removeForwardClearableAnswers(answers, initialSteps, slug);

              dispatch(setAnswers({ ...newAnswers, ...additionalValues }));
            }
          }),
        );
      }
    }

    // set new step document title
    document.title = validatedStep.title ? `${DOCUMENT_TITLE}${TITLE_DIVIDER}${validatedStep.title}` : DOCUMENT_TITLE;

    dispatch(setStep(validatedStep));

    return Promise.resolve(validatedStep);
  };
}

/*
stepBack

Navigated the user to a legitimate previous step as listed in the visitedSteps.
If a user has used the browser back button to navigate we will need to clean up the visitedSteps to remove
unnecessary forward steps that have not yet been triggered by the method.

*/
export function stepBack() {
  return (dispatch, getState) => {
    const {
      steps: { visitedSteps, validStep, breadcrumbs },
    } = getState();

    let newVisitStep = [];

    // Check for history leaps
    // 1. Check if the user navigated with history to previous steps
    // i.e. does the visitedSteps array include the current step

    // validStep.slug is the current step we are on.
    breadcrumbs.pop();
    dispatch(setBreadcrumbs(breadcrumbs));

    if (visitedSteps.includes(validStep.slug)) {
      let foundMatch = false;
      let counter = 0;

      // Remove future and current steps
      // 1. iterate visited steps until we find current step
      // 2. Add all slugs until that point to new visitedSteps
      while (!foundMatch) {
        const thisVisitedStep = visitedSteps[counter];
        if (thisVisitedStep === validStep.slug) {
          foundMatch = true;
        } else {
          newVisitStep.push(thisVisitedStep);
        }
        counter++;
      }
    } else {
      // we're good this step isnt in the visitedSteps lets use the current visited steps
      newVisitStep = [...visitedSteps];
    }

    // get the end of the visited steps stack as the previous slug
    const previousSlug = newVisitStep.pop();

    // set new visited steps without last slug as we're moving to it
    dispatch(setVisitedSteps(newVisitStep));

    // navigate to the previous slug
    dispatch(push(previousSlug));
  };
}

/*
stepForward

Navigate through the steps in a forward direction from the 'currentSlug' - setting
values in the answers set as we go. This also updates the visited steps by adding
the current slug to the visitedSteps array.

currentSlug - the url part that represents the current step
values - the values from the current step that will be passed forward

*/
export function stepForward(currentSlug, values) {
  return (dispatch, getState) => {
    // set new answers in store
    dispatch(setAnswer(values));

    const {
      attachments,
      outcome: { status },
      hooks,
      locations,
      steps: { initialSteps, answers, isEditing, visitedSteps, validStep },
    } = getState();

    // make sure valuebag contains newly submitted values
    let valueBag = { ...answers, ...values, hooks, status, attachments, locations };

    return dispatch(
      evaluateOutcomeSummary(
        valueBag,
        () => {
          const {
            attachments,
            locations,
            outcome: { status },
            hooks,
            steps: { answers },
          } = getState();

          // make sure valuebag contains newly submitted values
          let valueBag = { ...answers, ...values, hooks, status, attachments, locations };

          return dispatch(
            performLookup(validStep.exitHook, valueBag, function(response, additionalValues) {
              // update answers with any that are return from lookup
              const {
                outcome: { status },
                hooks,
                steps: { answers, breadcrumbs },
              } = getState();
              let newValueBag = { ...answers, ...values, ...additionalValues, hooks, status };

              // Get next valid step
              // 1. Gets a valid step given answers including new answers
              // 2. Is next in not just end of visited stack
              let newValidStep = stepsApi.getNextValidStep(initialSteps, newValueBag, currentSlug);

              if (newValidStep) {
                // Update visited steps
                // 1. Add this step to visited
                // 2. Remove previous steps that are no longer valid
                let newVisSteps = stepsApi.realVisitedSteps(visitedSteps, initialSteps, newValueBag, currentSlug);
                dispatch(setVisitedSteps(newVisSteps));

                if (!isEditing) {
                  // Remove answers for steps that are no longer valid
                  // 1. Remove all answers from answers that are for backward steps that are no longer valid
                  let newAnswers = stepsApi.removeForwardAnswers(newValueBag, initialSteps);
                  dispatch(setAnswers({ ...newAnswers }));
                }

                if (initialSteps && initialSteps[0].slug === validStep.slug) {
                  googleAnalytics.trackEvent(GA_FORM_START, currentSlug);
                } else {
                  googleAnalytics.trackEvent(GA_FORM_STEP, currentSlug);
                }

                if (isEditing) {
                  dispatch(push('your-summary'));
                } else if (newValidStep.type === 'step') {
                  dispatch(push(newValidStep.slug));
                } else if (newValidStep.type === 'redirect') {
                  window.open(newValidStep.redirectURL, '_blank');
                }

                // turn off edit mode
                dispatch(setEditMode(false));

                // newValidStep.slug new step !!!
                breadcrumbs.push(newValidStep.slug);
                dispatch(setBreadcrumbs(breadcrumbs));
                return Promise.resolve(newValidStep);
              }
              return Promise.resolve(null);
            }),
          );
        },
        () => {
          return Promise.resolve(null);
        },
      ),
    );
  };
}

/*
stepTo

Move to a slug without any validation of constraints as a means of moving sideWays in the process or backwards
in the case of edit where the leap is more than one step.

route - An object representing the route { target: string, params: object, editMode: bool }

*/
export function stepTo(route) {
  return (dispatch) => {
    if (route.editMode) {
      dispatch(setEditMode(route.editMode));
    }

    if (route.params) {
      dispatch(setAnswer(route.params));
    }

    if (route.target) {
      dispatch(push(route.target));
    }

    return Promise.resolve(route);
  };
}

/*
performLookup

Given a lookup identifier perform an async lookup given the passed values and return the response to the successHandler
or errorHandler dependent on the outcome.

type - an string identifying the type of lookup
values - additional values to use in the lookup
successHandler - a function to call once a successful response received from the lookup
errorHandler  - a function to call once an error has been received from the lookup

*/

export function performLookup(lookup, valueBag, successHandler, errorHandler) {
  return (dispatch, getState) => {
    const lookups = {
      addresses: addressLookup,
      incidents: incidentLookup,
      callbacks: callbacksLookup,
      mapSearch: geocodeLocation,
      map: checkMapLocation,
      submission: stepSubmission,
      workingHours: workingHoursLookup,
      accessHours: accessHoursLookup,
      noWaterEnabled: noWaterEnabledLookup,
    };

    if (lookup) {
      const lookupAction = lookups[lookup.type];
      if (!lookup.hidePageLoader) {
        dispatch(configIsLoading(true));
      }

      if (lookupAction) {
        dispatch(
          lookupAction(
            lookup,
            valueBag,
            (response, additionalValues) => {
              dispatch(configIsLoading(false));
              if (successHandler) {
                successHandler(response, additionalValues);
              }
            },
            (error) => {
              dispatch(configIsLoading(false));
              if (errorHandler) {
                errorHandler(error);
              }
            },
          ),
        );
      }
    } else {
      if (successHandler) {
        successHandler(null, {});
      }
    }
  };
}

export function CalculateBlockAddress() {
  return (dispatch, getState) => {
    const {
      steps: { initialSteps, answers, breadcrumbs },
    } = getState();

    let visitedStepsAndAnwsers = [];
    for (let i = 0; i < breadcrumbs.length; i++) {
      let stepAnswers = [];
      const step = initialSteps.find((o) => o.slug === breadcrumbs[i]);
      const initialAnswers = step.initialAnswers;

      if (initialAnswers) {
        Object.keys(initialAnswers).forEach((key) => {
          let stepItem = {};
          if (answers[key]) {
            stepItem[key] = answers[key];
            stepAnswers.push(stepItem);
          }
        });
      }

      if (stepAnswers.length > 0) {
        let item = {
          steps: breadcrumbs[i],
          answers: stepAnswers,
        };
        visitedStepsAndAnwsers.push(item);
      }
    }

    const calculatedPath = GetCalculatedStringPath(visitedStepsAndAnwsers, journeyMapping);
    updateHeadJourneyCode(calculatedPath);
    dispatch(setUserPath(calculatedPath));
    return Promise.resolve(calculatedPath);
  };
}

export function GetCalculatedStringPath(visitedStepsAndAnwsers, journeyMapping) {
  const stepsToExclude = [
    'external-photos',
    'external-rough-location',
    'incident-total',
    'incident-total-waste',
    'initial-location-external-waste',
    'map',
    'mapConfirm',
    'map-search',
    'personal-details',
    'photos',
    'incident-results',
    'waste-external-cover-type',
    'waste-incident-results',
    'waste-photos',
    'waste-ooh',
    'is-working-hours',
    'is-access-hours',
    'waste-internal-fclty2-count',
    'waste-internal-text',
    'nwlp-notice-date-time',
    'nwlp-notice-confirmed',
    'is-no-water-enabled',
    'nwlp-notice-datetime',
    'is-colleague-mode',
    'clean-leakingmeter-info-col',
    'rtim-information-col',
    'waste-rtim-information-col',
    'clean-conden-info-col',
    'clean-intstoptap-info-col',
    'clean-overflow-info-col',
    'internal-information-col',
    'clean-supply-info-col',
    'waste-gas-info-col',
    'waste-council-info-col',
    'waste-internal-odour-info-col',
    'waste-drainage-info-col',
  ];
  let calculatedString = '';
  let contactDetailsHit = false;
  visitedStepsAndAnwsers.forEach((visitedStep) => {
    const stepPathMapping = journeyMapping[visitedStep.steps];

    if (isNaN(visitedStep.steps) && stepsToExclude.indexOf(visitedStep.steps) === -1) {
      if (stepPathMapping && stepPathMapping.stepKey && visitedStep.answers) {
        const isContactDetails =
          visitedStep.steps === 'initial-location' || visitedStep.steps === 'initial-location-waste';
        const addValue = contactDetailsHit === false || isContactDetails === false;

        if (addValue) {
          calculatedString += stepPathMapping.stepKey;
          visitedStep.answers.forEach((answer) => {
            Object.keys(answer).forEach((answerItem) => {
              const val = answer[answerItem];
              const stepData = stepPathMapping.answers.find((o) => o.value === val);
              if (stepData) {
                calculatedString += stepData.key;
              }
            });
          });
          if (isContactDetails) {
            contactDetailsHit = true;
          }
        }
      } else {
        // eslint-disable-next-line no-console
        console.warn(`invalid mapping item or step ${JSON.stringify(visitedStep.steps)}`);
      }
    }
  });

  return calculatedString;
}

function updateHeadJourneyCode(code) {
  document.head.setAttribute('data-journeyCode', code);
}

// standard actions
export function configIsLoading(bool) {
  return {
    type: ActionTypes.FETCH_CONFIG_LOADING,
    isLoading: bool,
  };
}

export function setConfig(config) {
  return {
    type: ActionTypes.SET_CONFIG,
    config,
  };
}

export function setStep(newStep) {
  return {
    type: ActionTypes.SET_STEP,
    newStep,
  };
}

export function setAnswers(answers) {
  return {
    type: ActionTypes.SET_ANSWERS,
    answers,
  };
}

export function setAnswer(answer) {
  return {
    type: ActionTypes.SET_ANSWER,
    answer,
  };
}

export function setVisitedSteps(visitedSteps) {
  return {
    type: ActionTypes.SET_VISITED_STEPS,
    visitedSteps,
  };
}

export function setBreadcrumbs(breadcrumbs) {
  return {
    type: ActionTypes.SET_BREADCRUMBS,
    breadcrumbs,
  };
}

export function configHasErrored(hasErrored, error) {
  return {
    type: ActionTypes.FETCH_CONFIG_ERROR,
    hasErrored,
    error,
  };
}

export function setEditMode(isEditing) {
  return {
    type: ActionTypes.SET_EDIT_MODE,
    isEditing,
  };
}

export function setUserPath(path) {
  return {
    type: ActionTypes.SET_USER_PATH,
    path,
  };
}
