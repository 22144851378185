import { ButtonRow, Control, Heading, Step } from '../../../models';
import {
  BLOCKED,
  CLEAR,
  APPLIANCE,
  BATHROOM,
  CELLAR,
  EXTERNAL,
  FLOOR,
  HOUSE,
  INTERNAL,
  KITCHEN,
  ODOUR,
  PUBLIC,
  SINK,
  TOILET,
} from '../../../../constants';
import { wasteInternalSink } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteInternalSink.heading);
const wasteInternalSinkControl = new Control('question', 'wasteInternalSink');
wasteInternalSinkControl.setLabel(wasteInternalSink.heading);
wasteInternalSinkControl.setDefault('');
wasteInternalSinkControl.addOption(wasteInternalSink.clear, CLEAR, 'IconSink');
wasteInternalSinkControl.addOption(wasteInternalSink.blocked, BLOCKED, 'IconInternalBlockage');
wasteInternalSinkControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteInternalSinkStep = new Step('step', 'waste-internal-sink');
wasteInternalSinkStep.addBlock(headingBlock);
wasteInternalSinkStep.addBlock(wasteInternalSinkControl);
wasteInternalSinkStep.addBlock(buttonsBlock);
wasteInternalSinkStep.setTitle(wasteInternalSink.title);

wasteInternalSinkStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [APPLIANCE],
      exclusion: [TOILET, SINK, FLOOR, CELLAR],
    },
  },
]);

export { wasteInternalSinkStep };
