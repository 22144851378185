import { ButtonRow, Control, Heading, Hook, Step } from '../../../models';
import {
  DOWNSTAIRS_BATH,
  DOWNSTAIRS_FACILITIES,
  DOWNSTAIRS_TOILET,
  MULTIPLE_TOILETS,
  UPSTAIRS_BATH,
  UPSTAIRS_FACILITIES,
  UPSTAIRS_TOILET,
  BATHROOM,
  EXTERNAL,
  HOUSE,
  INTERNAL,
  KITCHEN,
  ODOUR,
  PUBLIC,
  TOILET,
  APPLIANCE,
  SINK,
  FLOOR,
  CELLAR,
  STEP_ACCESS_HOURS_LOOKUP,
} from '../../../../constants';
import { wasteInternalFclty1 } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', wasteInternalFclty1.heading);
const wasteInternalFclty1Control = new Control('question', 'wasteInternalFclty1');
wasteInternalFclty1Control.setLabel(wasteInternalFclty1.heading);
wasteInternalFclty1Control.setDefault('');
wasteInternalFclty1Control.addOption(wasteInternalFclty1.multipleToilets, MULTIPLE_TOILETS, 'IconHouse2');
wasteInternalFclty1Control.addOption(wasteInternalFclty1.downstairsToilet, DOWNSTAIRS_TOILET, 'IconSink');
wasteInternalFclty1Control.addOption(wasteInternalFclty1.downstairsBath, DOWNSTAIRS_BATH, 'IconCellar');
wasteInternalFclty1Control.addOption(
  wasteInternalFclty1.downstairsFacilities,
  DOWNSTAIRS_FACILITIES,
  'IconFloodHouseInternal',
);
wasteInternalFclty1Control.addOption(wasteInternalFclty1.upstairsToilet, UPSTAIRS_TOILET, 'IconToilet');
wasteInternalFclty1Control.addOption(wasteInternalFclty1.upstairsBath, UPSTAIRS_BATH, 'IconUpStairs');
wasteInternalFclty1Control.addOption(wasteInternalFclty1.upstairsFacilities, UPSTAIRS_FACILITIES, 'IconSink');
wasteInternalFclty1Control.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteInternalFclty1Step = new Step('step', 'waste-internal-fclty1');
wasteInternalFclty1Step.addBlock(headingBlock);
wasteInternalFclty1Step.addBlock(wasteInternalFclty1Control);
wasteInternalFclty1Step.addBlock(buttonsBlock);
wasteInternalFclty1Step.exitHook = new Hook(STEP_ACCESS_HOURS_LOOKUP, '');
wasteInternalFclty1Step.setTitle(wasteInternalFclty1.title);

wasteInternalFclty1Step.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [BATHROOM],
      exclusion: [HOUSE, ODOUR, KITCHEN],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [HOUSE],
      exclusion: [ODOUR, BATHROOM, KITCHEN],
    },
    wasteInternalEntry: {
      inclusion: [TOILET],
      exclusion: [APPLIANCE, SINK, FLOOR, CELLAR],
    },
  },
]);

export { wasteInternalFclty1Step };
