import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap } from 'yw-ui.v2';

import { Preloader } from '../../../components';
import { LOADING_MAP_TEXT } from '../../../constants/Settings';

import { reverseGeocodeLocation } from '../../../api/location';

export class MapBlock extends Component {
  myRef = null;
  hasEventListner = false;
  listener = null;
  constructor(props) {
    super(props);
    this.state = {
      mapHasDragged: false,
      isYorkshire: false,
    };
    this.myRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.myRef.current && this.myRef.current.state) {
      if (this.myRef.current.state.inYorkshire !== this.state.isYorkshire) {
        this.setState({ isYorkshire: this.myRef.current.state.inYorkshire });
        this.props.SetIsYorkshire(this.myRef.current.state.inYorkshire);
      }

      if (this.hasEventListner === false) {
        this.listener = this.myRef.current.map.addListener('center_changed', () => {
          this.props.SetMapHasDragged(true);
        });
        this.hasEventListner = true;
      }
    }
  }

  componentWillUnmount() {
    if (this.listener) {
      this.listener.remove();
      this.listener = null;
    }
  }

  handleMapDrag = () => {
    this.setState({ mapHasDragged: true });
    this.props.SetMapHasDragged(true);
  };

  handleMapUpdate = (lat, lng, isYorkshire) => {
    this.props.SetIsYorkshire(isYorkshire);

    const location = {
      location: {
        lat: lat,
        lng: lng,
      },
    };

    reverseGeocodeLocation(location, this.successHandler.bind(this));
  };

  successHandler(resp) {
    const postcode = resp.postcodes.length > 0 ? resp.postcodes[0] : '';
    this.props.form.handleChange(this.props.name, {
      lat: resp.lat,
      lng: resp.lng,
      locationDetails: resp.formattedAddress,
      postcode: postcode,
    });
  }

  render() {
    const { lookups } = this.props;

    return (
      <Fragment>
        {!lookups.map && <Preloader qa="map-preloader" height={400} message={LOADING_MAP_TEXT} />}
        {lookups.map && lookups.map.lat && lookups.map.lng && (
          <Fragment>
            <div className="u-mb--500" id="google-map">
              <GoogleMap
                height="small"
                lat={lookups.map.lat}
                lng={lookups.map.lng}
                onLatLngUpdate={this.handleMapUpdate.bind(this)}
                mapDrag={this.handleMapDrag}
                qa="google-map"
                type="hybrid"
                zoom={17}
                ref={this.myRef}
              />
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

MapBlock.propTypes = {
  form: PropTypes.object,
  lookups: PropTypes.object.isRequired,
  lookupsLoading: PropTypes.bool,
  SetMapHasDragged: PropTypes.func,
  SetIsYorkshire: PropTypes.func,
  name: PropTypes.string.isRequired,
};

MapBlock.defaultProps = {
  form: {},
  lookupsLoading: false,
};
export default MapBlock;
