import { ButtonRow, Control, Heading, Step } from '../../../models';
import { DAMAGED_COVER, YES, NO } from '../../../../constants';
import { waterFromCover } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', waterFromCover.heading);
const waterFromCoverControl = new Control('question', 'waterFromCover');
waterFromCoverControl.setLabel(waterFromCover.heading);
waterFromCoverControl.addOption(waterFromCover.yes, YES, 'IconCheckTick');
waterFromCoverControl.addOption(waterFromCover.no, NO, 'IconCross');
waterFromCoverControl.clear = true;
const buttonsBlock = new ButtonRow().hideContinueButton();

const waterFromCoverStep = new Step('step', 'water-from-cover');
waterFromCoverStep.addBlock(headingBlock);
waterFromCoverStep.addBlock(waterFromCoverControl);
waterFromCoverStep.addBlock(buttonsBlock);
waterFromCoverStep.setTitle(waterFromCover.title);

waterFromCoverStep.setConstraints({
  initialProblem: {
    presence: true,
    inclusion: [DAMAGED_COVER],
  },
});

export { waterFromCoverStep };
