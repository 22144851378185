import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormRow } from 'yw-ui.v2';
import { YW_LEGAL_URL, YW_PRIVACY_URL } from '../../../constants/Settings';

export class TermsAndConditionsBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isValid: null,
    };
  }

  componentDidMount() {
    if (this.props?.answers?.TermsAndConditions) {
      this.setState({ isValid: this.props?.answers?.TermsAndConditions });
    }
  }

  onChangeEvent = (e) => {
    this.setState({ isValid: e.target.checked });
    this.props.form.handleChange(this.props.name, e.target.checked === true ? e.target.checked : null);
  };

  getMessage = () => {
    if (this.state.isValid || (this.state.isValid === null && this.props.form.submitCount === 0)) {
      return [];
    }
    return ['You need to agree with the terms and conditions before continuing'];
  };

  render() {
    return (
      <FormRow
        id="terms-row"
        qa="terms-row"
        name="field_name"
        valid={this.state.isValid || (this.state.isValid === null && this.props.form.submitCount === 0)}
        condensed={false}
        className="termsConditions"
        messages={this.getMessage()}
        hideLabel={true}
        info={this.state.isValid || (this.state.isValid === null && this.props.form.submitCount === 0)}
        label={''}
      >
        <label className="c-checkbox">
          <input
            name={this.props.name}
            id={this.props.name}
            type="checkbox"
            defaultChecked={this.state.isValid}
            className="c-checkbox__input"
            value={this.state.isValid || undefined}
            onChange={this.onChangeEvent}
          />
          <span className="c-checkbox__checkmark" />I agree with the&nbsp;
          <a href={YW_LEGAL_URL} target="_blank" rel="noopener noreferrer">
            terms and conditions
          </a>
          &nbsp;and&nbsp;
          <a href={YW_PRIVACY_URL} target="_blank" rel="noopener noreferrer">
            privacy policy
          </a>
        </label>
      </FormRow>
    );
  }
}

TermsAndConditionsBlock.propTypes = {
  name: PropTypes.string.isRequired,
  form: PropTypes.object,
  answers: PropTypes.object,
};

TermsAndConditionsBlock.defaultProps = {
  form: {},
};

export default TermsAndConditionsBlock;
