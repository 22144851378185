import { Heading, Step, ButtonRow, InfoBlock, Link } from '../../../models';
import {
  MAIN_WEBSITE_URL,
  APPLIANCE,
  SHOWER_TOILET_SINK,
  OTHER,
  UNDER_KITCHEN_SINK,
  INSIDE,
  INSIDE_OUTSIDE,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  HOUSE,
  ODOUR,
  DRAIN,
  BLOCKED,
  BOTH,
  NONE,
  FARM,
  CHEMICAL,
  GAS,
  UPSTAIRS,
  MULTIPLE_ROOMS,
  ONE_ROOM,
  WHOLE_PROPERTY,
  GROUND_FLOOR,
  CELLAR,
  PIPE_RESPONSIBILITY_URL,
  LOCAL_PLUMBER_URL,
  BATHROOM,
  KITCHEN,
  NO,
  YES,
} from '../../../../constants';
import {
  backToMainWebsite,
  localPlumber,
  pipeResponsibility,
  wasteInternalOdourInfo,
} from '../../../../constants/Copy';

const headingBlock = new Heading('step-heading', wasteInternalOdourInfo.heading);
const infoBlock = new InfoBlock('info-block', wasteInternalOdourInfo.detail[0], 'p-tb');
const headingBlock2 = new Heading('step-heading2', wasteInternalOdourInfo.subHeading, 2).setSize('sm');
const infoBlock2 = new InfoBlock('info-block2', wasteInternalOdourInfo.detail[1]);
const infoBlock3 = new InfoBlock('info-block3', wasteInternalOdourInfo.detail[2]);
const infoBlock4 = new InfoBlock('info-block4', wasteInternalOdourInfo.detail[3]);
const linkBlock1 = new Link('link-block3', pipeResponsibility).setLink(PIPE_RESPONSIBILITY_URL).setTarget('_self');
const linkBlock2 = new Link('link-block4', localPlumber).setLink(LOCAL_PLUMBER_URL).setTarget('_self');
const linkBlock = new Link('link-block', backToMainWebsite, 'p-t')
  .setLink(MAIN_WEBSITE_URL)
  .setTarget('_self')
  .setFormat(true);
const buttonsBlock = new ButtonRow().hideContinueButton();

const wasteInternalOdourInfoStep = new Step('step', 'waste-internal-odour-info');
wasteInternalOdourInfoStep.setIsContained(true);
wasteInternalOdourInfoStep.addBlock(headingBlock);
wasteInternalOdourInfoStep.addBlock(infoBlock);
wasteInternalOdourInfoStep.addBlock(headingBlock2);
wasteInternalOdourInfoStep.addBlock(infoBlock2);
wasteInternalOdourInfoStep.addBlock(infoBlock3);
wasteInternalOdourInfoStep.addBlock(infoBlock4);
wasteInternalOdourInfoStep.addBlock(linkBlock1);
wasteInternalOdourInfoStep.addBlock(linkBlock2);
wasteInternalOdourInfoStep.addBlock(linkBlock);
wasteInternalOdourInfoStep.addBlock(buttonsBlock);
wasteInternalOdourInfoStep.setTitle(wasteInternalOdourInfo.title);

wasteInternalOdourInfoStep.setConstraints([
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [ODOUR],
      exclusion: [HOUSE, BATHROOM, KITCHEN],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [NONE],
      exclusion: [DRAIN, BLOCKED, BOTH],
    },
    wasteOdourOutside: {
      inclusion: [INSIDE],
      exclusion: [INSIDE_OUTSIDE],
    },
    wasteOdourSource: {
      inclusion: [APPLIANCE, SHOWER_TOILET_SINK, UNDER_KITCHEN_SINK],
      exclusion: [OTHER],
    },
    isColleagueMode: {
      presence: false,
      inclusion: [NO],
      exclusion: [YES],
    },
  },
  {
    initialLocationWaste: {
      inclusion: [INTERNAL],
      exclusion: [EXTERNAL, PUBLIC],
    },
    wasteOriginInternal: {
      inclusion: [ODOUR],
      exclusion: [HOUSE, BATHROOM, KITCHEN],
    },
    wasteOdourType: {
      inclusion: [DRAIN],
      exclusion: [GAS, CHEMICAL, FARM],
    },
    wasteOdourOther: {
      inclusion: [NONE],
      exclusion: [DRAIN, BLOCKED, BOTH],
    },
    wasteOdourOutside: {
      inclusion: [INSIDE],
      exclusion: [INSIDE_OUTSIDE],
    },
    wasteOdourSource: {
      inclusion: [OTHER],
      exclusion: [APPLIANCE, SHOWER_TOILET_SINK, UNDER_KITCHEN_SINK],
    },
    wasteOdourLocation: {
      inclusion: [UPSTAIRS, MULTIPLE_ROOMS, ONE_ROOM],
      exclusion: [WHOLE_PROPERTY, GROUND_FLOOR, CELLAR],
    },
    isColleagueMode: {
      presence: false,
      inclusion: [NO],
      exclusion: [YES],
    },
  },
]);

export { wasteInternalOdourInfoStep };
