import { Heading, Step, ButtonRow, InfoBlock, Link } from '../../../models';
import {
  LOCAL_PLUMBER_URL,
  EXTERNAL,
  INTERNAL,
  PUBLIC,
  MAIN_WEBSITE_URL,
  OTHER,
  PIPE_RESPONSIBILITY_URL,
  NO,
  YES,
} from '../../../../constants';
import { backToMainWebsite, internalInformation, localPlumber, pipeResponsibility } from '../../../../constants/Copy';

const headingBlock = new Heading('step-heading', internalInformation.heading);
const infoBlock = new InfoBlock('info-block', internalInformation.detail[0], 'p-tb');
const headingBlock2 = new Heading('step-heading2', internalInformation.subHeading, 2).setSize('md');
const infoBlock2 = new InfoBlock('info-block2', internalInformation.detail[1]);
const infoBlock3 = new InfoBlock('info-block3', internalInformation.detail[2]);
const infoBlock4 = new InfoBlock('info-block4', internalInformation.detail[3]);
const linkBlock1 = new Link('link-block3', pipeResponsibility).setLink(PIPE_RESPONSIBILITY_URL).setTarget('_self');
const linkBlock2 = new Link('link-block4', localPlumber).setLink(LOCAL_PLUMBER_URL).setTarget('_self');
const linkBlock = new Link('link-block', backToMainWebsite, 'p-t')
  .setLink(MAIN_WEBSITE_URL)
  .setTarget('_self')
  .setFormat(true);
const buttonsBlock = new ButtonRow().hideContinueButton();

const internalOffRampStep = new Step('step', 'internal-information');
internalOffRampStep.setIsContained(true);
internalOffRampStep.addBlock(headingBlock);
internalOffRampStep.addBlock(infoBlock);
internalOffRampStep.addBlock(headingBlock2);
internalOffRampStep.addBlock(infoBlock2);
internalOffRampStep.addBlock(infoBlock3);
internalOffRampStep.addBlock(infoBlock4);
internalOffRampStep.addBlock(linkBlock1);
internalOffRampStep.addBlock(linkBlock2);
internalOffRampStep.addBlock(linkBlock);
internalOffRampStep.addBlock(buttonsBlock);
internalOffRampStep.setTitle(internalInformation.title);

internalOffRampStep.setConstraints({
  initialLocation: {
    inclusion: [INTERNAL],
    exclusion: [PUBLIC, EXTERNAL],
  },
  internalWaterOrigin: {
    presence: true,
    inclusion: [OTHER],
  },
  isColleagueMode: {
    presence: false,
    inclusion: [NO],
    exclusion: [YES],
  },
});

export { internalOffRampStep };
