import { Control, Heading, Step, ButtonRow } from '../../../models';
import { COVER, OVERFLOW_PIPE, METER, GROUND, EXTERNAL } from '../../../../constants';
import { waterOriginExternal } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', waterOriginExternal.heading);
const waterOriginControl = new Control('question', 'externalWaterOrigin');
waterOriginControl.setLabel(waterOriginExternal.heading);
waterOriginControl.addOption(waterOriginExternal.cover, COVER, 'IconCover');
waterOriginControl.addOption(waterOriginExternal.pipe, OVERFLOW_PIPE, 'IconConstantPressure');
waterOriginControl.addOption(waterOriginExternal.meter, METER, 'IconMeter');
waterOriginControl.addOption(waterOriginExternal.ground, GROUND, 'IconGround2');
const buttonsBlock = new ButtonRow().hideContinueButton();

const waterOriginExternalStep = new Step('step', 'water-origin');
waterOriginExternalStep.addBlock(headingBlock);
waterOriginExternalStep.addBlock(waterOriginControl);
waterOriginExternalStep.addBlock(buttonsBlock);
waterOriginExternalStep.setTitle(waterOriginExternal.title);

waterOriginExternalStep.setConstraints({
  initialLocation: {
    inclusion: [EXTERNAL],
  },
});

export { waterOriginExternalStep };
