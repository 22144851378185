import { ButtonRow, Step, Control, Heading, Link } from '../../models';
import {
  LOCATION_METHOD_ADDRESS_LOOKUP,
  LOCATION_METHOD_MAP_SEARCH,
  LOCATION_METHOD_CURRENT_LOCATION,
  NO,
} from '../../../constants';
import { addressResultMultiple, addressResultSingle } from '../../../constants/Copy';

const headingBlock = new Heading('step-title', addressResultMultiple.heading);
const headingBlockSingle = new Heading('step-title', addressResultSingle.heading);
const locateMethodControl = new Control('hidden', 'locateMethod').setDefault(LOCATION_METHOD_ADDRESS_LOOKUP);
const locationControl = new Control('hidden', 'locationDetails').setDefault('');
const utmLocationControl = new Control('hidden', 'utmLocation').setDefault('');
const commercialControl = new Control('hidden', 'isCommercial');
const addressConfirmControl = new Control('addresses', 'addressConfirm').isRequired(true).setDefault('');
addressConfirmControl.setLabel(addressResultMultiple.heading);
addressConfirmControl.route = { target: 'address-lookup', params: { locateMethod: LOCATION_METHOD_ADDRESS_LOOKUP } };
const singleAddressConfirmControl = new Control('addresses', 'addressConfirm').isRequired(true).setDefault('');
singleAddressConfirmControl.setLabel(addressResultMultiple.heading);
singleAddressConfirmControl.route = {
  target: 'address-lookup',
  params: { locateMethod: LOCATION_METHOD_ADDRESS_LOOKUP },
};
const manualLinkBlock = new Link('link-block', addressResultMultiple.missingAddress)
  .setRoute({ target: 'address-lookup-info' })
  .setCondensed(false);
const buttonsBlock = new ButtonRow();

const addressResultStep = new Step('step', 'address-results');
addressResultStep.addBlock(headingBlock);
addressResultStep.addBlock(locateMethodControl);
addressResultStep.addBlock(addressConfirmControl);
addressResultStep.addBlock(manualLinkBlock);
addressResultStep.addBlock(buttonsBlock);
addressResultStep.addBlock(locationControl);
addressResultStep.addBlock(utmLocationControl);
addressResultStep.addBlock(commercialControl);
addressResultStep.setIsContained(true);
addressResultStep.setTitle(addressResultMultiple.title);

const addressResultSingleStep = new Step('step', 'address-result');
addressResultSingleStep.addBlock(headingBlockSingle);
addressResultSingleStep.addBlock(locateMethodControl);
addressResultSingleStep.addBlock(singleAddressConfirmControl);
addressResultSingleStep.addBlock(buttonsBlock);
addressResultSingleStep.addBlock(locationControl);
addressResultSingleStep.addBlock(utmLocationControl);
addressResultSingleStep.addBlock(commercialControl);
addressResultSingleStep.setIsContained(true);
addressResultSingleStep.setTitle(addressResultSingle.title);

addressResultSingleStep.setConstraints({
  addressTotal: {
    presence: true,
    numericality: {
      equalTo: 1,
    },
  },
  locateMethod: {
    exclusion: [LOCATION_METHOD_MAP_SEARCH, LOCATION_METHOD_CURRENT_LOCATION],
  },
  waterFromCover: {
    exclusion: [NO],
  },
});

addressResultSingleStep.setValidation({
  addressConfirm: { presence: { allowEmpty: false, message: addressResultSingle.validation } },
});

addressResultStep.setConstraints({
  addressTotal: {
    presence: true,
    numericality: {
      greaterThan: 1,
    },
  },
  locateMethod: {
    exclusion: [LOCATION_METHOD_MAP_SEARCH, LOCATION_METHOD_CURRENT_LOCATION],
  },
  waterFromCover: {
    exclusion: [NO],
  },
});

addressResultStep.setValidation({
  addressConfirm: { presence: { allowEmpty: false, message: addressResultSingle.validation } },
});

export { addressResultStep, addressResultSingleStep };
