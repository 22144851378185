import { ButtonRow, Control, Heading, Step } from '../../../models';
import { LOCATION_METHOD_MAP_SEARCH } from '../../../../constants';
import { mapSearchResult } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', mapSearchResult.heading);
const mapResultConfirmControl = new Control('placesResults', 'mapResultConfirm');
mapResultConfirmControl.setLabel(mapSearchResult.label);
mapResultConfirmControl.route = { target: 'map-search', params: { locateMethod: LOCATION_METHOD_MAP_SEARCH } };
const buttonsBlock = new ButtonRow();

const mapSearchResultsStep = new Step('step', 'map-search-results');
mapSearchResultsStep.addBlock(headingBlock);
mapSearchResultsStep.addBlock(mapResultConfirmControl);
mapSearchResultsStep.addBlock(buttonsBlock);
mapSearchResultsStep.setIsContained(true);
mapSearchResultsStep.setTitle(mapSearchResult.title);

mapSearchResultsStep.setConstraints({
  'hooks.lookups.mapSearch.totalResults': {
    presence: true,
    numericality: {
      greaterThan: 1,
    },
  },
  mapSearch: {
    presence: true,
  },
  locateMethod: {
    inclusion: ['MAP_SEARCH'],
  },
});

export { mapSearchResultsStep };
