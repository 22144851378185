function Hook(type, params) {
  if (!type) {
    throw new Error('No type provided to hook');
  }
  this.hidePageLoader = false;
  this.params = params;
  this.type = type;
  this.validation = {};
}

Hook.prototype.setPageLoaderHidden = function() {
  this.hidePageLoader = true;
  return this;
};

Hook.prototype.setValidation = function(validation) {
  this.validation = validation;
  return this;
};

export default Hook;
