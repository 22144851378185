import { Control, Heading, Step, ButtonRow } from '../../../models';
import { INSIDE, OUTSIDE, EXTERNAL, NO, YES, COVER, OVERFLOW_PIPE, GROUND, METER } from '../../../../constants';
import { cleanMeterLocation } from '../../../../constants/Copy';

const headingBlock = new Heading('step-title', cleanMeterLocation.heading);
const cleanMeterLocationControl = new Control('question', 'cleanMeterLocation');
cleanMeterLocationControl.setLabel(cleanMeterLocation.heading);
cleanMeterLocationControl.addOption(cleanMeterLocation.inside, INSIDE, 'IconHouse');
cleanMeterLocationControl.addOption(cleanMeterLocation.outside, OUTSIDE, 'IconGround');
const buttonsBlock = new ButtonRow().hideContinueButton();

const cleanMeterLocationStep = new Step('step', 'clean-meter-location');
cleanMeterLocationStep.addBlock(headingBlock);
cleanMeterLocationStep.addBlock(cleanMeterLocationControl);
cleanMeterLocationStep.addBlock(buttonsBlock);
cleanMeterLocationStep.setTitle(cleanMeterLocation.title);

cleanMeterLocationStep.setConstraints({
  initialLocation: {
    inclusion: [EXTERNAL],
  },
  externalWaterOrigin: {
    presence: true,
    inclusion: [METER],
    exclusion: [COVER, OVERFLOW_PIPE, GROUND],
  },
  cleanMeterLeaking: {
    presence: true,
    inclusion: [YES],
    exclusion: [NO],
  },
});

export { cleanMeterLocationStep };
