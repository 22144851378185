import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FieldBlock from '../FieldBlock';
import { ADDITIONAL_COMMENTS_CHAR_COUNT, YES } from '../../../constants';
import Sentiment from '../SentimentBlock/SentimentBlock';

class ColleagueSummaryBlock extends Component {
  render() {
    this.props.form.valid['additionalCommentsText'] = true;
    this.props.form.values.colleagueSummaryValid =
      this.props.form.values.isColleagueMode === YES ? this.props.form.values.sentimentValid : true;

    return (
      <div className="colleague-summary">
        <FieldBlock
          type={'textarea'}
          label={'Additional comments'}
          name={'additionalCommentsText'}
          maxLength={ADDITIONAL_COMMENTS_CHAR_COUNT}
          showMaxLength={true}
          placeholder={'e.g, What else do we need to know?'}
          isRequiredMarker={false}
          onlyShowInColleagueMode={false}
          form={this.props.form}
        />
        <Sentiment form={this.props.form} />
      </div>
    );
  }
}

ColleagueSummaryBlock.propTypes = {
  form: PropTypes.object,
};

export default ColleagueSummaryBlock;
